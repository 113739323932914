import { Duration } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import './TimerComponent.scss';

import playButton from './media/play.json';
import Lottie from 'react-lottie-player';

export type TimerState = "Running" | "Paused" | "Stopped";

export type TimerComponentProps = {
    duration: Duration
    onStateChanged?: (state: TimerState) => void;
}

const TimerComponent: React.FC<TimerComponentProps> = ({ duration, onStateChanged }) => {
    const [state, setState] = useState<TimerState>("Stopped");
    const [initial, setInitial] = useState(true);

    useEffect(() => {
        onStateChanged && onStateChanged(state);
    }, [state, onStateChanged, initial]);

    const updateState = useCallback((state: TimerState) => {
        if (initial) setInitial(false);
        setState(state);
    }, [setState])

    return <div className="timer-component">
        <div className="duration">
            {duration.hours.toFixed(0).padStart(2, '0')}:{duration.minutes.toFixed(0).padStart(2, '0')}:{duration.seconds.toFixed(0).padStart(2, '0')}
        </div>
        {/* {state} */}

        {state !== 'Running' && <div className='action-button' onClick={() => { updateState('Running') }}>
            <Lottie animationData={playButton} play={!initial} loop={false} segments={[0, 14]} direction={-1} style={{ width: 64, height: 64 }} ></Lottie>
        </div>}
        {/* {state === 'Running' && <button onClick={() => { setState('Paused')}}>Pause</button>} */}
        {(state !== 'Stopped') && <div className='action-button' onClick={() => { updateState('Stopped') }}>
            <Lottie animationData={playButton} play={true} loop={false} segments={[0, 14]} style={{ width: 64, height: 64 }}></Lottie>
        </div>}
    </div>
}

export default TimerComponent;