import React, { ReactElement, ReactNode } from 'react';

export type OutletItem = {
    outletName: string,
    item: ReactNode
}

export type OutletProps = {
    name: string
    items?: OutletItem[],
    wrapper?: ReactElement
}

export type OutletHostProps = {
    oItems?: OutletItem[]
}

export const oItem = (outletName: string, item: React.ReactNode): OutletItem => ({ outletName, item });

const Outlet: React.FC<OutletProps> = ({ name, items, wrapper }) => {
    if (!items || items.length < 1)
        return null;

    const match = items.filter(i => i.outletName === name);

    const content = match ? match.map((entry, k) => <React.Fragment key={k}>
        {entry.item}
    </React.Fragment>) : null;

    if (wrapper) {
        return React.cloneElement(wrapper, {}, content);
    }

    return <>{content}</>
}

export default Outlet;