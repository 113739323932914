import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo } from 'react';
import { L } from '../../../../abp/utils';
import { TimeEntity } from '../../../../client/http';
import Outlet, { OutletHostProps } from '../../../../common/components/outlet/Outlet';
import FormValidationConfiguration from '../../../../common/forms/validation/configuration/functional/FormValidationConfiguration';
import DatePicker from '../../../../common/forms/validation/controls/date-picker/DatePicker';
import Input from '../../../../common/forms/validation/controls/input/Input';
import Switch from '../../../../common/forms/validation/controls/switch/Switch';
import TimeInput from '../../../../common/forms/validation/controls/time-input/TimeInput';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { useNaVaFormContext } from '../../../../common/forms/validation/na-va-form/NaVaFormContext';
import { NaVaFormValues } from '../../../../common/forms/validation/na-va-form/types';
import useComputation from '../../../../common/forms/validation/na-va-form/useComputation';
import { FIELD_AMOUNT, FIELD_AMOUNT_MISC, FIELD_AMOUNT_UNIT, FIELD_DATE_FROM, FIELD_DATE_TO, FIELD_DESCRIPTION1, FIELD_DESCRIPTION2, FIELD_END_TIME, FIELD_FORCE_NON_TIME_BASED_SESSION, FIELD_ID, FIELD_IS_PLANNING_ENTITY, FIELD_NAME, FIELD_PLANNING_DATE, FIELD_START_TIME, FIELD_USE_DURATION, FIELD_USE_TIME } from '../../../../data/field-constants/GeneralConstants';
import useMethodContext from '../../../../module-components/method-context/useMethodContext';
import ModuleComponentProvider from '../../../../module-components/module-context/ModuleComponentProvider';
import { Modules } from '../../../../module-components/Modules';
import { timeFromJsDate, timeFromDateTime, timeToDateTime, hoursAmountToDuration, hoursAmountFromTimes, mapTime, hoursAmountToIsoDuration } from '../../../../util/DateTimeUtils';
import { Settings_DefaultDurationMode, Settings_Description1Length, Settings_Description2Length, Settings_ProviderName, Settings_ServiceConnectionId, Settings_TimeBasedAmountUnits } from '../../../../util/SettingNames';
import { AppParts } from '../../../AppParts';
import useKeyPress from '../../../../common/hooks/useKeyPress';
import useKeyCombination from '../../../../common/hooks/useKeyCombination';
import useKeyDown from '../../../../common/hooks/useKeyDown';
import { log } from '../../../../util/LoggingUtils';

const TimeEntityEditorFields: React.FC<{ disabled?: boolean, isPlanningEntity?: boolean, initialValue?: TimeEntity } & OutletHostProps> = (props) => {
    const { disabled: isDisabled, isPlanningEntity, oItems, /*initialValue*/ } = props;

    const { values, errors, setFieldValue, focusField } = useNaVaFormContext();
    const { addMethod, applyMethod } = useMethodContext();

    const startOfHalfHour = useCallback((date: DateTime) => {
        let result = date.startOf("hour");
        if (date.minute <= 30)
            result = result.set({ minute: 30 });
        else {
            result = result.set({ minute: 0 });
            result = result.plus({ hour: 1 });
        }
        return result;
    }, []);

    const initialAmount = 2;
    const amount = getIn(values, FIELD_AMOUNT);
    const nextFrom = useMemo(() => startOfHalfHour(DateTime.now()), [startOfHalfHour]);
    const nextTo = useMemo(() => startOfHalfHour(DateTime.now()).plus({ hours: amount ?? initialAmount }), [startOfHalfHour, amount]);
    const useTime = abp.setting.get(Settings_DefaultDurationMode)?.toLowerCase() === 'time';

    const initialStartTime = useCallback((initialValue: TimeEntity) => initialValue?.startTime ? timeFromJsDate(initialValue.startTime) : timeFromDateTime(nextFrom), [nextFrom]);
    const initialEndTime = useCallback((initialValue: TimeEntity) => initialValue?.endTime ? timeFromJsDate(initialValue.endTime) : timeFromDateTime(nextTo), [nextTo]);

    const amountUnit = getIn(values, FIELD_AMOUNT_UNIT) ?? '';
    const forceNonTimeBasedSession = getIn(values, FIELD_FORCE_NON_TIME_BASED_SESSION);
    const isTimeBasedSession = useCallback((unit: any, forceNonTimeBased: boolean) => forceNonTimeBased ? false : (JSON.parse(abp.setting.get(Settings_TimeBasedAmountUnits) ?? '[]').includes(unit)), []);

    useEffect(() => {
        addMethod("mapTimeEntity", Modules.rewindCore, false, ((entity: TimeEntity) => {
            if (entity) {

                if (entity.startTime && !entity.startTime.getMonth)
                    entity.startTime = DateTime.fromISO(entity.startTime as unknown as string).toLocal().toJSDate();
                if (entity.endTime && !entity.endTime.getMonth)
                    entity.endTime = DateTime.fromISO(entity.endTime as unknown as string).toLocal().toJSDate();

                if (entity.startTime)
                    (entity as any).planningDate = entity.startTime;
                if (entity.startTime)
                    (entity as any).dateFrom = timeFromJsDate(entity.startTime);
                if (entity.endTime)
                    (entity as any).dateTo = timeFromJsDate(entity.endTime);

                (entity as any)[FIELD_USE_TIME] = !entity.useDuration;

                (entity as any)[FIELD_AMOUNT_MISC] = (entity as any)[FIELD_AMOUNT];
            }

            return entity;
        }));

        addMethod("getValidationConfig", Modules.rewindCore, false, ((c?: FormValidationConfiguration) => {
            return {
                ...c,
                [FIELD_DESCRIPTION1]: [
                    {
                        validate: (obj: any) => !!obj[FIELD_DESCRIPTION1],
                        message: L('Validation_FieldMustNotBeEmpty')
                    }
                ],
                [FIELD_DESCRIPTION2]: [
                    {
                        validate: (obj: any) => !!obj[FIELD_DESCRIPTION2],
                        message: L('Validation_FieldMustNotBeEmpty')
                    }
                ],
                [FIELD_AMOUNT]: [
                    {
                        validate: (values: any) => !isTimeBasedSession(values[FIELD_AMOUNT_UNIT], values[FIELD_FORCE_NON_TIME_BASED_SESSION]) || (values[FIELD_AMOUNT] && values[FIELD_AMOUNT] > 0),
                        message: L('Validation_PleaseEnterAmount')
                    }
                ],
                [FIELD_AMOUNT_MISC]: [
                    {
                        validate: (values: any) => isTimeBasedSession(values[FIELD_AMOUNT_UNIT], values[FIELD_FORCE_NON_TIME_BASED_SESSION]) || (values[FIELD_AMOUNT_MISC] && values[FIELD_AMOUNT_MISC] !== 0),
                        message: L('Validation_PleaseEnterAmount')
                    }
                ]
            }
        }));
    }, [addMethod]);

    useEffect(() => {
        addMethod("getInitialValues", Modules.rewindCore, true, (prop: { initialValues: TimeEntity, initialValue: TimeEntity }) => {
            return {
                ...prop,
                initialValues: {
                    ...prop.initialValues,
                    [FIELD_PLANNING_DATE]: DateTime.now().toJSDate(),
                    [FIELD_DATE_FROM]: initialStartTime(prop.initialValue),
                    [FIELD_DATE_TO]: initialEndTime(prop.initialValue),
                    [FIELD_AMOUNT]: hoursAmountFromTimes(initialStartTime(prop.initialValue), initialEndTime(prop.initialValue)),
                    [FIELD_AMOUNT_MISC]: 0,
                    [FIELD_AMOUNT_UNIT]: L('Unit_Hour'),
                    [FIELD_USE_DURATION]: !useTime,
                    [FIELD_USE_TIME]: useTime,
                    [FIELD_IS_PLANNING_ENTITY]: isPlanningEntity,
                    [FIELD_DESCRIPTION2]: ''
                }
            }
        });

        addMethod("mapCreateTimeEntity", Modules.rewindCore, true, (data: { value: TimeEntity, values: NaVaFormValues }) => {
            const { values } = data;
            const isTimeBased = isTimeBasedSession(getIn(values, FIELD_AMOUNT_UNIT), getIn(values, FIELD_FORCE_NON_TIME_BASED_SESSION));
            log("Saving core with value ", data.value, "and values", values);
            return {
                ...data,
                value: {
                    ...data.value,
                    id: getIn(values, FIELD_ID),
                    name: getIn(values, FIELD_NAME),
                    description1: getIn(values, FIELD_DESCRIPTION1),
                    description2: getIn(values, FIELD_DESCRIPTION2),
                    startTime: mapTime(getIn(values, FIELD_DATE_FROM), getIn(values, FIELD_PLANNING_DATE)).toJSDate(),
                    endTime: mapTime(getIn(values, FIELD_DATE_TO), getIn(values, FIELD_PLANNING_DATE)).toJSDate(),
                    duration: isTimeBased ? hoursAmountToIsoDuration(getIn(values, FIELD_AMOUNT)) : '',
                    amount: isTimeBased ? 0 : getIn(values, FIELD_AMOUNT_MISC),
                    isPlanningEntity,
                    isEditable: true,
                    extensions: {},
                    serviceConnectionId: abp.setting.getInt(Settings_ServiceConnectionId),
                    serviceProvider: abp.setting.get(Settings_ProviderName),
                    useDuration: getIn(values, FIELD_USE_DURATION)
                } as TimeEntity
            }
        });
    }, [addMethod, isPlanningEntity]);

    // Switch Time and Duration
    useComputation({
        dependency: FIELD_USE_TIME,
        target: FIELD_USE_DURATION,
        computation: useTime => !useTime
    });

    // Amount <-> Start- / End-Time

    useComputation({
        dependency: FIELD_AMOUNT,
        target: FIELD_DATE_TO,
        // Only compute if amount is valid (not > 24 for example)
        computation: (amount, getValue) => !Object.keys(errors).find(k => k === FIELD_AMOUNT) ?
            timeFromDateTime(timeToDateTime(getValue(FIELD_DATE_FROM)).plus(hoursAmountToDuration(amount))) : getValue(FIELD_DATE_TO),
        condition: (amount, getValue) =>
            getValue(FIELD_DATE_TO) && !(getValue(FIELD_DATE_TO) as DateTime).equals(timeToDateTime(getValue(FIELD_DATE_FROM)).plus(hoursAmountToDuration(amount)))
    });

    useComputation({
        dependency: FIELD_DATE_FROM,
        target: FIELD_DATE_TO,
        computation: (dateFrom, getValue) =>
            timeFromDateTime(timeToDateTime(dateFrom).plus(hoursAmountToDuration(getValue(FIELD_AMOUNT)))),
        condition: (_, getValue) => hoursAmountToDuration(getValue(FIELD_AMOUNT)).as("hours") > 0
    });

    useComputation({
        dependency: FIELD_DATE_TO,
        target: FIELD_AMOUNT,
        computation: (dateTo, getValue) => {
            log(getValue(FIELD_AMOUNT), hoursAmountFromTimes(getValue(FIELD_DATE_FROM), dateTo));
            return hoursAmountFromTimes(getValue(FIELD_DATE_FROM), dateTo);
        },
        condition: (dateTo, getValue) => getValue(FIELD_AMOUNT) !== hoursAmountFromTimes(getValue(FIELD_DATE_FROM), dateTo)
    });

    useEffect(() => log("core oItems changed"), [oItems]);

    //Keyboard shortcuts
    useKeyCombination('d', 'ctrl', (e) => focusField(FIELD_PLANNING_DATE));
    useKeyCombination('b', 'ctrl', (e) => focusField(FIELD_DESCRIPTION2));

    const [isCtrlDown] = useKeyDown('Control');

    return <React.Fragment key="time-entity-fields-rewind-core">
        <Outlet name="rewind-core$div.dates::before" items={oItems}></Outlet>
        <div className="row dates">
            <div className="col p-0 time-part">
                <DatePicker name={FIELD_PLANNING_DATE} label="" sm={12} lg={5} xxl={3} disabled={isDisabled} displayShortcutKey={isCtrlDown ? 'D' : undefined}/>
                {!isPlanningEntity ?
                    (isTimeBasedSession(amountUnit, forceNonTimeBasedSession) ?
                        <>
                            <Input name={FIELD_AMOUNT} type="number" placeholder="Menge" disabled={isDisabled || getIn(values, FIELD_USE_TIME)} className="amount-input" sm={6} lg={3} suffix={getIn(values, FIELD_AMOUNT_UNIT)?.toUpperCase()} />
                            <div className="duration-switch">

                                <span className="caption">{L('or')}</span>
                                <Switch className="k-switch-xs" name={FIELD_USE_TIME} disabled={isDisabled}></Switch>
                            </div>
                            {/* {!isEditor && <Button type="button" onClick={startSession} label={L('StartWork')} color="secondary" className="action-btn" disabled={isDisabled} />} */}
                        </> :
                        <>
                            <Input name={FIELD_AMOUNT_MISC} type="number" placeholder="Menge" disabled={isDisabled} className="amount-input" sm={6} lg={3} suffix={getIn(values, FIELD_AMOUNT_UNIT)?.toUpperCase()} />
                        </>) :
                    null
                }

                {isTimeBasedSession(amountUnit, forceNonTimeBasedSession) ? <>
                    <span className="caption">{L('from')}</span>
                    <TimeInput name={FIELD_DATE_FROM} label="" disabled={isDisabled || getIn(values, FIELD_USE_DURATION)} className="time-input" />
                    <span className="caption">{L('to')}</span>
                    <TimeInput name={FIELD_DATE_TO} label="" disabled={isDisabled || getIn(values, FIELD_USE_DURATION)} className="time-input" />
                </> :
                    null}

                <Switch name="forceNonTimeBasedSession" className="k-switch-xs switch-align-label" label={L("ForceNonTimeBasedSession")}></Switch>
            </div>
            {/*<div className="col-12 col-lg-7 col-xxl-9 time-part">
                 <Input name={FIELD_AMOUNT} type="number" placeholder="Menge" disabled={false} className="amount-input" sm={6} lg={3} />
                        <span>{L('or')}</span>
                        <Button type="button" onClick={startSession} label={L('StartWork')} color="secondary" className="action-btn" /> 
            </div>*/}
        </div>
        <Outlet name="rewind-core$div.dates::after" items={oItems}></Outlet>
        <Outlet name="rewind-core$div.description::before" items={oItems}></Outlet>
        <div className="row description">
            <Input name={FIELD_DESCRIPTION1} placeholder={L('Description')} sm={12} lg={6} disabled={isDisabled} maxLength={abp.setting.getInt(Settings_Description1Length)}></Input>
            <Input name={FIELD_DESCRIPTION2} placeholder={L('Description2')} sm={12} lg={6} disabled={isDisabled} maxLength={abp.setting.getInt(Settings_Description2Length)} displayShortcutKey={isCtrlDown ? 'B' : undefined}></Input>
        </div>
        <Outlet name="rewind-core$div.description::after" items={oItems}></Outlet>

        <ModuleComponentProvider
            appPartName={AppParts.TimeTracking.TimeEntities.EditorFields}
            props={{ ...props }} inheritForModule={Modules.rewindCore} />
    </React.Fragment>
}

export default TimeEntityEditorFields;