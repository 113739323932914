import React from 'react';
import ControlWrapper, { ControlWrapperProps } from '../control-wrapper/ControlWrapper';
import ShortcutKey from '../../../components/shortcut-key/ShortcutKey';

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export type ButtonProps = {
    label: string;
    title?: HTMLButtonProps['title'];
    type?: HTMLButtonProps['type'];
    color: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link';
    style?: React.CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    ref?: any;
    displayShortcutKey?: string;
} & Omit<ControlWrapperProps, 'label'>

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = props => (
    <ControlWrapper
        xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl}
        offset={props.offset} offsetSm={props.offsetSm} offsetMd={props.offsetMd} offsetLg={props.offsetLg} offsetXl={props.offsetXl} offsetXxl={props.offsetXxl}
        m={props.m} mt={props.mt} mb={props.mb} ml={props.ml} mr={props.mr} mx={props.mx} my={props.my}
        error={props.error} className={props.className} title={props.title}
    >
        <button
            type={props.type || 'button'}
            className={`${['k-button k-button-md k-rounded-md k-button-solid', `k-button-solid-${props.color}`].join(' ')}`}
            style={{ ...props.style, width: '100%' }}
            onClick={props.onClick}
            disabled={props.disabled}
            title={props.title}
            ref={props.ref}>
            {props.label}
            {props.children && props.children}
        </button>
        <ShortcutKey shortcutKey={props.displayShortcutKey} />
    </ControlWrapper>
);

export default Button;