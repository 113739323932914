import React from 'react';
import { PlmControlSection, PlmControlState, PlmItemColumnConfig } from '../components/PlmControlComponents';


export type PlmControlContextType = {
    columnConfig: PlmItemColumnConfig;
    controlState: PlmControlState;
    readOnly?: boolean;
    selectable?: boolean;
    multiSelect?: boolean;
    fetchFromAllProviders?: boolean;
    showTemplates?: boolean;
    replacements: { [key: string]: any };
    replacementsChanged: (values: { [key: string]: any }) => void;
    dispatch:
    (args: {
        type: 'selected/changed' | 'expanded/changed' | 'isEditing/changed',
        payload: { section: PlmControlSection, key: string, item: any, parents?: { section: PlmControlSection, key: string }[], value: boolean; options?: any },
    }) => void
}

const PlmControlContext = React.createContext({});

export default PlmControlContext;