import React, { useMemo } from 'react';
import ApiGrid from '../../../common/components/api-grid/ApiGrid';
import { JobGridProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import useApi, { UseApiResult } from '../../../common/hooks/useApi';
import { Job, JobApi } from '../../../client/http';
import filter from '../../../util/FilterUtils';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';
import { FIELD_DESCRIPTION1, FIELD_DESCRIPTION2, FIELD_DESIGNATION, FIELD_END_DATE, FIELD_EXTENSIONS, FIELD_START_DATE } from '../../../data/field-constants/GeneralConstants';
import { L } from '../../../abp/utils';
import useCrudApi, { UseCrudApiResult } from '../../../common/hooks/useCrudApi';

const JobGrid: React.FC<JobGridProps> = ({ fetchFromAllProviders, internalFilters,
    externalFilters = [filter("status").notEqual("Completed"), filter("status").notEqual("Archive")], data, columns, isEditable = false, ...props
}) => {

    const apiResult = useCrudApi<Job, JobApi>(c => new JobApi(c),
        c => c.apiServicesAppJobGetAllGet(fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
            fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId), internalFilters ? JSON.stringify(internalFilters) : undefined, JSON.stringify(externalFilters)),
        (c, d) => c.apiServicesAppJobCreatePost(({
            ...d,
            serviceProvider: d.serviceProvider ?? abp.setting.get(Settings_ProviderName),
            serviceConnectionId: d.serviceConnectionId ?? abp.setting.getInt(Settings_ServiceConnectionId)
        })),
        (c, id) => c.apiServicesAppJobGetByIdGet(id, fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId),
            fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName)),
        (c, d) => c.apiServicesAppJobUpdatePut(({
            ...d,
            serviceProvider: d.serviceProvider ?? abp.setting.get(Settings_ProviderName),
            serviceConnectionId: d.serviceConnectionId ?? abp.setting.getInt(Settings_ServiceConnectionId)
        })),
        (c, d) => c.apiServicesAppJobDeleteDelete(d)
    );

    const apiResultItem = useMemo((): UseCrudApiResult<Job, JobApi> => {
        if (data)
            return [data.map(j => {
                if (j) {
                    j.extensions = JSON.parse(j?.extensionData ?? '{}');
                }
                return j;
            }), undefined, undefined, undefined, undefined, undefined, undefined, () => { }, undefined];
        else {
            const [apiData, ...others] = apiResult;
            return [apiData.map(j => {
                if (j) {
                    j.extensions = JSON.parse(j?.extensionData ?? '{}');
                }
                return j;
            }), ...others];
        }
    }, [data, apiResult]);

    return <ApiGrid apiResult={apiResultItem} columns={columns ?? [
        { field: FIELD_DESIGNATION, editable: isEditable, title: L('Designation') },
        { field: FIELD_DESCRIPTION1, editable: isEditable, title: L('Description1') },
        { field: FIELD_DESCRIPTION2, editable: isEditable, title: L('Description2') },
        { field: FIELD_START_DATE, editable: isEditable, title: L('StartDate'), editor: 'text' },
        { field: FIELD_END_DATE, editable: isEditable, title: L('EndDate'), editor: 'text' },
        { field: FIELD_EXTENSIONS + 'billToCustomerNumber', editable: isEditable, title: L('BillToCustomerNo') },
        { field: FIELD_EXTENSIONS + 'searchDescription', editable: isEditable, title: L('SearchDescription') },

    ]} isEditable={isEditable} {...props} />;
}

export default JobGrid;