import React, { PropsWithChildren } from 'react';
import Icon, { IconProps } from './Icon';
import './IconButton.scss';
import { classNames } from '@progress/kendo-react-common';

export type IconButtonProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
    & IconProps & {
        buttonType?: 'button' | 'reset' | 'submit',
        usePlaceholders?: boolean
        disabled?: boolean;
    };

const IconButton: React.FC<IconButtonProps> = ({ name, size = "2rem", className = '', buttonType, usePlaceholders, color, disabled, ...props }) => {
    return (buttonType ?
        <button type={buttonType} className={classNames('icon-btn', className, { 'placeholder-button': usePlaceholders, disabled })}>
            <Icon name={name} size={size} color={color}></Icon>
            {usePlaceholders && <div className="button-placeholder"></div>}
        </button> :
        <div className={classNames('icon-btn', className, { 'placeholder-button': usePlaceholders, disabled })} {...props} onClick={disabled ? () => { } : props.onClick}>
            <Icon name={name} size={size} color={color}></Icon>
            {usePlaceholders && <div className="button-placeholder"></div>}
        </div>);
}

export const PlaceholderGroup: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    return (<div className="placeholder-button-group">
        {children}
    </div>);
}

export default IconButton;