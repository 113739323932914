import { L } from "../../../abp/utils";

export const FIELD_JOB_JOURNAL_BATCH = "jobJournalBatch";
export const FIELD_JOB_JOURNAL_BATCH_ID = "journalBatchId";
export const FIELD_UNIT_OF_MEASURE_ID = "unitOfMeasureId";
export const FIELD_UNIT_OF_MEASURE_CODE = "unitOfMeasureCode";
export const FIELD_LINE_TYPE = "lineType";
export const FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION = "lineTypeActivityConfirmation";
export const FIELD_ENTRY_TYPE = "entryType";
export const FIELD_DOCUMENT_DATE = "documentDate";
export const FIELD_GLOBAL_DIMENSION_1_CODE = "globalDimension1Code";
export const FIELD_GLOBAL_DIMENSION_2_CODE = "globalDimension2Code";

export const LineTypes: { [key: string]: { id: string, value: string } } = {
    Usage: { id: '0', value: L('Usage') },
    Budget: {
        id: '1', value: L('Budget')
    },
    Billable: {
        id: '2', value: L('Billable')
    }
    // ,    BudgetAndBillable: { id: '3', value: L('BudgetAndBillable') }
}

export const LineTypesActivityConfirmation: { [id: string]: string } = {
    '1': L('NotInvoiced'),
    '2': L('Invoiced'),
    '3': L('FlatRate'),
    '7': L('NotInvoicedBonus'),
    '9': L('ExpenseReimbursement')
}

export const EntryTypes: { [key: string]: { id: string, value: string } } = {
    Usage: { id: '0', value: L('Usage') },
    Sale: {
        id: '1', value: L('Sale')
    },
    Output: {
        id: '2', value: L('Output')
    }
}