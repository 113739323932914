const constants = {
  userManagement: {
    defaultAdminUserName: 'admin'
  },
  localization: {
    defaultLocalizationSourceName: 'Rewind365UI'
  },
  authorization: {
    encrptedAuthTokenName: 'enc_auth_token'
  },
  appTitle: process.env.REACT_APP_TITLE ?? 'Rewind 365',
  appBaseUrl: process.env.REACT_APP_APP_BASE_URL,
  remoteServiceBaseUrl: process.env.REACT_APP_REMOTE_SERVICE_BASE_URL,
  customer: process.env.REACT_APP_CUSTOMER,
  log: process.env.REACT_APP_LOG,
  postCodeValidationCountries: ['DE']
};
export default constants;
