import React, { useMemo } from 'react';
import { L } from '../../../abp/utils';
import './PasswordComplexity.scss';

// has number
const hasNumber = (password: string) => new RegExp(/[0-9]/).test(password);

// has mix of small and capitals
const hasMixed = (password: string) => new RegExp(/[a-z]/).test(password) && new RegExp(/[A-Z]/).test(password);

// has special chars
const hasSpecial = (password: string) => new RegExp(/[!#@$%^&*)(+=._-]/).test(password);

// set color based on password strength
export const getStrengthData = (strength: number) => {
    if (strength < 2) return { label: L('Poor'), class: 'poor' };
    if (strength < 3) return { label: L('Weak'), class: 'weak' };
    if (strength < 4) return { label: L('Normal'), class: 'normal' };
    if (strength < 5) return { label: L('Good'), class: 'good' };
    if (strength < 6) return { label: L('Strong'), class: 'strong' };
    return { label: L('Poor'), class: 'poor' };
};

// password strength indicator
export const getPasswordStrength = (password: string) => {
    let strengths = 0;
    if (!password) return strengths;
    
    if (password.length > 10) strengths += 1;
    if (hasNumber(password)) strengths += 1;
    if (hasSpecial(password)) strengths += 1;
    if (hasMixed(password)) strengths += 1;    
    if (strengths === 4 && password.length >= 15) strengths +=1;
    return strengths;
};

const PasswordComplexity: React.FC<{ password: string }> = ({ password }) => {
    const data = useMemo(() => getStrengthData(getPasswordStrength(password)), [password]);

    return <div className={`strength-indicator ${data.class}`}>
        <div className="inner">
            {data.label}
        </div>
    </div>
}

export default PasswordComplexity;