import { DateTime, Duration, DurationLike, DurationLikeObject } from "luxon";
import Time from "../common/forms/controls/time-input/Time";

// export const FormatDuration = (): string => { };

/**
 * Sets the given time on the given Date - for example for creating a DateTime from hours and minute
 * @param time The time of day to set as {hours, minutes}
* @param date The Luxon DateTime to the set the time on
 * @returns A Luxon DateTime
 */
export const mapTime = (time: { hour: number, minute: number }, date: DateTime | undefined) => {
    if (date === undefined) date = DateTime.min();
    if (!(date instanceof DateTime)) date = DateTime.fromJSDate(date);
    return DateTime.fromObject({ year: date.year, month: date.month, day: date.day, hour: time.hour, minute: time.minute }, { zone: 'local' });
}

/**
 * Converts a numeric amount of hours to an ISO 8601 Duration String (e.g. 1.75 to "PT1H45M")
 * @param amount The number of hours
 * @returns A Luxon Duration
 */
export const hoursAmountToIsoDuration = (amount: DurationLikeObject | number) =>
    hoursAmountToDuration(amount).shiftTo('hours', 'minutes').toISO()

/**
* Converts a numeric or duration like amount of hours to a Luxon Duration (e.g. 1.75 to 01:45:00)
* @param amount The amount of hours as number or duration like object
* @returns A Luxon Duration
*/
export const hoursAmountToDuration = (amount: DurationLikeObject | number) => {
    if (typeof amount === 'number') {
        return Duration.fromObject(
            { hours: amount as number }
        );
    }
    return Duration.fromObject((amount as DurationLikeObject));
}

/**
* Converts a Luxon Duration to a numeric amount of hours (e.g. 01:45:00 to 1.75)
* @param amount The Luxon Duration
* @returns A number of hours
*/
export const hoursAmountFromDuration = (duration: Duration) =>
    duration.as('hours');

export const hoursAmountFromTimes = (start: Time, end: Time) => {
    const s = timeToDateTime(start);
    var e = timeToDateTime(end);

    if (e < s) {
        e = e.plus(Duration.fromObject({ day: 1 }));
    }
    return hoursAmountFromDuration(s.diff(e)) * -1;
}

/**
* Converts a DurationLike object ({'hours':1, 'minutes': 45}) to a numeric amount of hours (e.g. 01:45:00 to 1.75)
* @param amount The Luxon DurationLike object
* @returns A number of hours
*/
export const hoursAmountFromDurationLikeObject = (duration: DurationLikeObject) =>
    Duration.fromObject(duration).as('hours');


/**
 * Converts a JS style date to a time as {hours, minutes}
 * @param date The JS date to get the time from
 * @returns The time of day as {hours, minutes}
 */
export const timeFromJsDate = (date: Date) => timeFromDateTime(DateTime.fromJSDate(date));

/**
 * Converts a Luxon DateTime to a time as {hours, minutes}
 * @param date The Luxon DateTime to get the time from
 * @returns The time of day as {hours, minutes}
 */
export const timeFromDateTime = (dateTime: DateTime) => Time(dateTime.hour, dateTime.minute);

/**
 * Converts a time as {hours, minutes} t a Luxon DateTime 
 * @param date The time of day as {hours, minutes}
 * @returns The Luxon DateTime 
 */
export const timeToDateTime = (time: Time) => DateTime.fromObject({ hour: time.hour, minute: time.minute });

export const DateTimeMinValue = DateTime.fromISO("0001-01-02T00:00:00");
export const DateTimeMaxValue = DateTime.fromISO("9999-12-30T23:59:59");

export const ShortDate2DigitFormat: Intl.DateTimeFormatOptions = { ...DateTime.DATE_SHORT, day: '2-digit', month: '2-digit' };