import React, { useCallback, useMemo } from 'react';
import ApiComboBox, { ApiComboBoxProps } from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import { ServiceEntitySelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import { useReactiveApi } from '../../../common/hooks/useApi';
import { Configuration, WorkItemsApi } from '../../../client/http';
import { L } from '../../../abp/utils';
import ItemRenderComponent from '../../../common/components/item-render/ItemRenderComponent';
import useSimplePromise from '../../../common/hooks/useSimplePromise';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import './WorkItemSelection.scss';

export type WorkItemSelectionProps = ApiComboBoxProps & ServiceEntitySelectionProps<any> &
{
    itemRenderFields?: string[]
    projectName?: string;
};


const WorkItemRenderComponent: React.FC<{ li: any, itemProps: any }> = ({ li, itemProps }) => {
    var data = itemProps.dataItem;

    const itemChildren = (
        <div className={`item-render item-render__work-item`}>
            <span className="left">
                <span className='data data__System_Id' >#{getIn(data, 'System_Id')}</span>
                <span className='data data__System_Title'>{getIn(data, 'System_Title')}</span>
            </span>
            <span className={`data data__System_State state-${getIn(data, 'System_State')?.toLowerCase()}`}>{getIn(data, 'System_State')}</span>
        </div >
    );
    return React.cloneElement(li, li.props, itemChildren);
};


const WorkItemSelection: React.FC<WorkItemSelectionProps> = ({ selectedItem, data, itemRenderFields,
    name, title, placeholder, filterable, filterFields, dataItemKey, textField, itemRender, content, projectName, ...props }) => {

    const clientFactory = useCallback((c: Configuration) => new WorkItemsApi(c), []);

    const getAllResult = useCallback((c: WorkItemsApi) =>
        c.apiServicesDevOpsWorkItemsGetWorkItemsGet(projectName),
        [projectName]);

    const array = useMemo(() => [], []);
    const emptyResultPromise = useSimplePromise(array);
    const emptyResult = useCallback((c: WorkItemsApi) => emptyResultPromise, [emptyResultPromise]);
    const mapFunction = useCallback((w: any) => {
        let mappedFields = {} as any;
        Object.keys(w.fields).map(k => {
            mappedFields[k.replace('.', '_')] = w.fields[k];
        });

        return { ...w, ...mappedFields };
    }, []);


    const apiResult = useReactiveApi<any, WorkItemsApi>(clientFactory, !!projectName ? getAllResult : emptyResult, mapFunction);

    return <ApiComboBox apiResult={data ? [data, undefined, undefined, () => { }, undefined] : apiResult} name={name} filterable={filterable ?? true} dataItemKey={dataItemKey ?? 'id'}
        textField={textField ?? "System_Title"} placeholder={placeholder ?? L('WorkItem')}
        itemRender={(li, props) => <WorkItemRenderComponent li={li} itemProps={props} />}
        content={content} filterFields={filterFields ?? ['System_Title']} {...props} />

}

export default WorkItemSelection;