import React, { useCallback } from 'react';
import BaseTimeInput, { TimeInputProps as BaseTimeInputProps } from '../../../controls/time-input/TimeInput';
import useNaVaField from '../../na-va-form/NaVaField';
import Time from '../../../controls/time-input/Time';
import { L } from '../../../../../abp/utils';

export type TimeInputProps = Omit<BaseTimeInputProps, 'onChange' | 'onBlur'>;

const TimeInput: React.FC<TimeInputProps> = React.memo(({name, disabled, ...props}) => {
  if (!name) throw new Error('name must not be undefined');

  const {
    error,
    value,
    ref,
    setValue
  } = useNaVaField<Time | null>({ name });

  // Special case
  // BaseTimeInput delays triggering onChange to when it loses focus
  // so we trigger setValue with validation onChange and dont call onBlur at all
  const aggregatedOnChange = useCallback((value: Time | null) => {
    setValue(value, true);
  }, [setValue]);

  return (
    <BaseTimeInput
      name={name}
      innerRef={ref}
      onChange={aggregatedOnChange}
      error={error && L(error)}
      value={value}
      disabled={disabled}
      {...props}
    />
  );
});

export default TimeInput;
