import React, { useMemo, useState } from 'react';
import DashboardCard from '../../../scenes/dashboard/dashboard-card/DashboardCard';
import Button from '../../../common/forms/controls/button/Button';
import useApi from '../../../common/hooks/useApi';
import { AuthenticationApi, ServiceConnectionDto, ServiceProviderDto, ServiceProvidersApi, } from '../../../client/http';
import JobMappingEditor from './JobMappingEditor';
import RessourceMappingEditor from './ResourceMappingEditor';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import { L } from '../../../abp/utils';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { ServiceProviderItemRender } from '../../../scenes/protected-area/side-nav/service-provider-selection/ServiceProviderSelection';
import Dropdown from '../../../common/forms/validation/controls/dropdown/Dropdown';
import StateMappingEditor from './StateMappingEditor';
import './DevOpsAdministration.scss';
import ExpansionPanel from '../../../common/components/expansion-panel/ExpansionPanel';
import SyncProtocolsList from './SyncProtocolsList';
import { log } from '../../../util/LoggingUtils';

export const FIELD_MAP_ITEMS_SOURCE = "mapItemsSource";
export const FIELD_MAP_ITEMS_TARGET = "mapItemsTarget";

const DevOpsAdministration: React.FC = () => {


    const [, graphApi] = useApi<any, AuthenticationApi>(c => new AuthenticationApi(c));

    const [serviceProviders] = useApi<ServiceProviderDto, ServiceProvidersApi>(c => new ServiceProvidersApi(c), c => c.apiServicesAppServiceProvidersGetServiceProvidersGet());

    const serviceConnections = useMemo(() => (serviceProviders as any).reduce((prev: any, current: any) => {
        return current?.serviceConnections ? [...prev,
        ...current.serviceConnections.map((c: ServiceConnectionDto) => ({ ...current, serviceConnectionName: c.displayName, serviceConnectionId: c.id, serviceConnection: c, serviceConnections: undefined }))] : prev
    }, []), [serviceProviders]);

    const [{ values }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const mapFromConnectionId = getIn(values, FIELD_MAP_ITEMS_SOURCE)?.serviceConnectionId;
    const mapToConnectionId = getIn(values, FIELD_MAP_ITEMS_TARGET)?.serviceConnectionId;

    const connectionsSelected = !!mapFromConnectionId && !!mapToConnectionId;
    log(mapFromConnectionId, mapToConnectionId);    

    return <div className="dev-ops-administration px-4">
        <h3>Modulverwaltung - Orgavision DevOps Sync</h3>

        <div className="row p-3 cards-list">


            <NaVaForm
                initialValues={{

                }}
                onSubmit={() => { }}
            >
                <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>

                <div className="row mx-0 my-2 align-items-center">
                    {L('MapFrom')}
                    <Dropdown name={FIELD_MAP_ITEMS_SOURCE} textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} itemRender={ServiceProviderItemRender} />
                    {L('to_Item')}
                    <Dropdown name={FIELD_MAP_ITEMS_TARGET} textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} itemRender={ServiceProviderItemRender} />
                </div>


                {connectionsSelected && <>
                    <DashboardCard title={"Projektdaten"}>
                        <JobMappingEditor onAddMapping={(m) => log("Mapping created: ", m)} onCancel={() => { }} />
                    </DashboardCard>

                    <DashboardCard title={"Ressourcen"}>
                        <RessourceMappingEditor />
                    </DashboardCard>

                    <DashboardCard title={"Arbeitselementstatus"}>
                        <StateMappingEditor />
                    </DashboardCard>
                </>}

                <ExpansionPanel title={"Übertragungsprotokolle"}>
                    <SyncProtocolsList />
                </ExpansionPanel>
            </NaVaForm>

            <Button color="secondary" label="Authorize DevOps" onClick={() => {
                const result = graphApi?.apiAuthenticationDevOpsAuthorizeApiGet().then(r => window.open((r as any)?.result?.location, '_blank'));//(r as any).data?.result?.location);

                // window.open(`${.remoteServiceBaseUrl}/api/authentication/graph/Authorize`, '_blank');
            }}></Button>
        </div>

    </div>
};

export default DevOpsAdministration;