import React, { useCallback, useMemo } from 'react';
import { PlmControlBaseProps, PlmDataControlProps, RowSkeletons, isTemplateFilter, selectPlmControlSortProperty } from './components/PlmControlComponents';
import useInternalPlmControlData from '../../hooks/useInternalPlmControlData';
import { FIELD_IS_TEMPLATE, FIELD_JOB_TYPE_ID, FIELD_POSTING_TYPE } from '../../../../data/field-constants/GeneralConstants';
import { JobTypeLineApi, JobTypeLineDto } from '../../../../client/http';
import filter from '../../../../util/FilterUtils';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import usePlmControlContext from './plm-control-context/usePlmControlContext';
import { PlmColumnDataDisplay } from './components/PlmControlDataDisplay';
import PlmColumnHeaderDisplay from './components/PlmControlHeaderDisplay';
import useInternalPlmListData from '../../hooks/useInternalPlmListData';
import _ from 'lodash';
import { NEW_ENTITY_ID } from '../../../../util/EntityUtils';

export type PlmJobTypeLineListProps = {
    type: 'positive' | 'negative';
    jobTypeCode: string;
    jobTypeId: string;
}

export type PlmJobTypeLineControlProps = PlmDataControlProps & {
    jobTypeId: string,
    jobTypeLine: JobTypeLineDto;
}

const PlmJobTypeLineControl: React.FC<PlmJobTypeLineControlProps> = ({ jobTypeLine, jobTypeId, refetch }) => {

    const { showTemplates, dispatch } = usePlmControlContext();

    const [internalJobTypeLine, , handleChange, handleAction] = useInternalPlmControlData<JobTypeLineDto, JobTypeLineApi>(
        jobTypeLine,
        {
            [FIELD_JOB_TYPE_ID]: jobTypeId,
            [FIELD_IS_TEMPLATE]: showTemplates === true
        },
        'jobTypeLines',
        c => new JobTypeLineApi(c),
        c => c.apiServicesModulesSdtPlmJobTypeLineCreatePost,
        c => c.apiServicesModulesSdtPlmJobTypeLineUpdatePut,
        c => c.apiServicesModulesSdtPlmJobTypeLineDeleteDelete,
        refetch
    );

    const localDispatch = useCallback((args: Parameters<PlmControlBaseProps['dispatch']>[0]) => {

        // fetch items on expand
        if (args.type === 'expanded/changed' && args.payload.value === true) {
            // fetchJobTasks();
        }
        dispatch(args);
    }, [dispatch]);

    return <PlmColumnDataDisplay item={internalJobTypeLine} section="jobTypeLines" level={2} className="plm-job-type-line-item"
        onChange={handleChange} onAction={handleAction} />
}

const POSITIVE_ADJMT = 'Positive Adjmt.';
const NEGATIVE_ADJMT = 'Negative Adjmt.';

const PlmJobTypeLineList: React.FC<PlmJobTypeLineListProps> = ({ type, jobTypeCode, jobTypeId }) => {

    const { fetchFromAllProviders, controlState, readOnly, showTemplates } = usePlmControlContext();

    const internalJobTypeControlStateFilters = controlState?.jobTypeLines?.filter?.internalFilters;
    const internalJobTypeLineFilters = useMemo(() => [
        ...internalJobTypeControlStateFilters,
        ...isTemplateFilter(showTemplates),
        filter('JobTypeId').equals(jobTypeId),
        filter('PostingType').equals(type === 'positive' ? POSITIVE_ADJMT : NEGATIVE_ADJMT)
    ], [internalJobTypeControlStateFilters, showTemplates, jobTypeId, type]);

    const externalJobTypeLineFilters = controlState?.jobTypeLines?.filter?.externalFilters;

    const getAll = useCallback((c: JobTypeLineApi) => {
        return c.apiServicesModulesSdtPlmJobTypeLineGetAllGet(
            fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
            fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId),
            internalJobTypeLineFilters ? JSON.stringify(internalJobTypeLineFilters) : undefined,
            JSON.stringify(externalJobTypeLineFilters)
        );
    }, [fetchFromAllProviders, internalJobTypeLineFilters, externalJobTypeLineFilters]);

    const [jobTypeLines, fetchJobTypeLines, addRow, isLoading] = useInternalPlmListData<JobTypeLineDto, JobTypeLineApi>(
        c => new JobTypeLineApi(c),
        getAll,
        'jobTypeLines',
        {
            jobTypeCode,
            [FIELD_POSTING_TYPE]: type === 'positive' ? POSITIVE_ADJMT : NEGATIVE_ADJMT
        }
    )

    return (<>

        <PlmColumnHeaderDisplay section="jobTypeLines" level={1} headersLevel={3} onAction={addRow} readOnly={readOnly || jobTypeId === NEW_ENTITY_ID}
            hasNoData={!isLoading && !jobTypeLines?.length} noDataLabel={type === 'positive' ? 'Keine Zugangsbuchungen vorhanden' : 'Keine Abgangsbuchungen vorhanden'} />

        {_.sortBy(jobTypeLines ?? [], (j) => selectPlmControlSortProperty(j))?.map((jtl) => {

            return (<PlmJobTypeLineControl jobTypeId={jobTypeId} jobTypeLine={jtl} refetch={fetchJobTypeLines} />)
        })}

        {isLoading && <RowSkeletons level={1} />}
    </>);
}

export default PlmJobTypeLineList;