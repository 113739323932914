import React, { PropsWithoutRef, useEffect, useState } from 'react';
import DashboardCard from '../../../scenes/dashboard/dashboard-card/DashboardCard';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import ModuleComponentProvider from '../../../module-components/module-context/ModuleComponentProvider';
import { DevOpsAppParts } from '../DevOpsAppParts';
import { ProjectSelectionProps } from '../service-entities/ProjectSelection';
import { WorkItemViewProps } from '../service-entities/WorkItemView';
import useApi from '../../../common/hooks/useApi';
import { DashboardDataApi, DataMapping, MappedWorkItem, WorkItemSyncApi } from '../../../client/http';
import Dropdown from '../../../common/forms/validation/controls/dropdown/Dropdown';
import { DateTime } from 'luxon';
import { IsEmptyGuid } from '../../../util/Values';
import Button from '../../../common/forms/controls/button/Button';
import Icon from '../../../common/components/icon/Icon';
import { useNavigate } from 'react-router-dom';
import { FIELD_CATEGORY, FIELD_JOB_ID, FIELD_JOB_TASK_ID } from '../../../data/field-constants/GeneralConstants';
import IconButton, { PlaceholderGroup } from '../../../common/components/icon/IconButton';
import { log } from '../../../util/LoggingUtils';

const RecentWorkItems: React.FC = () => {

    const navigate = useNavigate();
    const [{ values }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const [, workItemSyncApi] = useApi<any, WorkItemSyncApi>(c => new WorkItemSyncApi(c));
    const [, dashboardDataApi] = useApi<any, DashboardDataApi>(c => new DashboardDataApi(c));
    const [resourceMappings, setResourceMappings] = useState();
    const [workItemData, setWorkItemData] = useState<MappedWorkItem[] | undefined>();

    const projectName = getIn(values, ['project', 'name']);

    useEffect(() => {
        workItemSyncApi?.apiServicesDevOpsWorkItemSyncGetResourceMappingsGet().then(d => setResourceMappings((d as any).result.map((m: DataMapping) => m.sourceValue)));
    }, [workItemSyncApi])

    const selectedUser = getIn(values, "user");

    useEffect(() => {
        if (!selectedUser) return;
        dashboardDataApi?.apiServicesDevOpsDashboardDataGetCurrentWorkStateWithJobDataGet(10, undefined, selectedUser).then(d => setWorkItemData((d as any).result));
    }, [dashboardDataApi, selectedUser])

    return (<DashboardCard sm={12} title="Aktuelle Arbeitselemente">
        <NaVaForm
            initialValues={{}}
            onSubmit={() => { }}>
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>
            <div className="text-muted">Benutzer auswählen:</div>
            <Dropdown name="user" data={resourceMappings} />

            {/* <div className="text-muted">Projekt auswählen:</div> */}
            {/* <ModuleComponentProvider appPartName={DevOpsAppParts.ServiceEntities.Selection.Project} props={{ foreignKeyName: 'projectName', name: 'project' } as ProjectSelectionProps} /> */}

            {/* <ModuleComponentProvider appPartName={DevOpsAppParts.ServiceEntities.View.WorkItem} props={{ projectName } as WorkItemViewProps} /> */}

        </NaVaForm>

        <div className="work-item-view p-4">
            <table className="w-100 table table-striped table-bordered table-no-outer-border">
                <thead>
                    <tr>
                        <th className="text-center">Id</th>
                        <th className="text-center">Type</th>
                        <th>Title</th>
                        <th>Assigned To</th>
                        <th>State</th>
                        <th>Area</th>
                        <th>Target Date</th>
                        <th>Changed Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (workItemData as any)?.length > 0 && workItemData?.map((item) => {
                            // var avatarRef = getIn(w, ['System_AssignedTo', '_links', 'avatar', 'href']);
                            // var assignedToAvatar = getIn(w, ['System_AssignedTo', 'imageUrl']);
                            const w = item.item!;
                            const jd = item.jobData;
                            const targetDate = DateTime.fromISO(w.fields!['Microsoft.VSTS.Scheduling.TargetDate']);
                            const isDue = targetDate.diffNow().valueOf() < 0;
                            log(isDue);

                            return <tr key={w.id}>
                                <td className="align-middle text-center">{w.fields!['System.Id']}</td>
                                <td className="align-middle text-center">{w.fields!['System.WorkItemType']}</td>
                                <td className="align-middle">{w.fields!['System.Title']}</td>
                                <td className="align-middle">
                                    {/* {assignedToAvatar && <img src={assignedToAvatar} alt="Avatar"></img>} */}
                                    {getIn(w.fields, ['System.AssignedTo', 'displayName'])}</td>
                                <td className="align-middle">{w.fields!['System.State']}</td>
                                <td className="align-middle">{w.fields!['System.AreaPath'] ?? w.fields!['System.NodeName']}</td>
                                <td className="align-middle">
                                    <div className={'d-flex align-items-center ' + (isDue ? 'text-danger' : '')}>
                                        {targetDate.toRelative() ?? <span>&ndash;</span>}
                                        {isDue && <Icon name="icon-appointment-reminders" className="ms-1" size="1.1rem" style={{ position: 'relative', 'top': '-.4rem' }} color="currentColor" />}
                                    </div>
                                </td>
                                <td className="align-middle">{DateTime.fromISO(w.fields!['System.ChangedDate']).toRelative()}</td>
                                <td className="align-middle">

                                    {jd && !IsEmptyGuid(jd.jobId) && !IsEmptyGuid(jd.jobTaskId) &&
                                        <PlaceholderGroup>
                                            <IconButton name="icon-clock-add" title="Zeiteinheit erfassen"
                                                onClick={
                                                    () => navigate("/app/time-tracking/entities", {
                                                        state:
                                                        {
                                                            [FIELD_JOB_ID]: jd.jobId,
                                                            [FIELD_JOB_TASK_ID]: jd.jobTaskId,
                                                            [FIELD_CATEGORY]: jd.categoryId
                                                        }
                                                    })
                                                } usePlaceholders={true} />
                                            <IconButton name="icon-search" title="In Azure DevOps anzeigen"
                                                onClick={
                                                    () => {
                                                        window?.open(getIn(w, ['_links', 'html', 'href']), '_blank')?.focus();
                                                    }
                                                } usePlaceholders={true} />
                                        </PlaceholderGroup>
                                        // <Button label="" color="light"
                                        //    >
                                        //     <Icon name="icon-clock-add" size="1.5rem" />
                                        //     <span>
                                        //         Zeiten erfassen
                                        //     </span>
                                        // </Button>
                                    }
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>

    </DashboardCard >)

}

export default RecentWorkItems;
