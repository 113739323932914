import { useEffect } from "react";
import { PlmControlContextType } from "./PlmControlContext";
import usePlmControlContext from "./usePlmControlContext";
import useMethodContext from "../../../../../module-components/method-context/useMethodContext";
import { MethodContextType } from "../../../../../module-components/method-context/MethodContext";

const PlmControlContextPuller: React.FC<{    
    onContextChanged: (state: PlmControlContextType) => void
}> = ({ onContextChanged }) => {
    const plmContext = usePlmControlContext();

    useEffect(() => {
        onContextChanged && onContextChanged(plmContext);
    }, [plmContext, onContextChanged]);

    return <></>
}


export default PlmControlContextPuller;