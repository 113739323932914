import { DateTime, Duration } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { L } from '../../../abp/utils';
import { GroupBasedPredictionResultDto, PredictionServiceConfigurationApi, PredictionServiceConfigurationDto, PredictionServicesApi } from '../../../client/http';
import useAuthConfiguration from '../../hooks/useAuthConfiguration';
import './GroupPredictionsOverview.scss';
import { log } from '../../../util/LoggingUtils';

const GroupPredictionsOverview: React.FC = () => {
    const authConfig = useAuthConfiguration();
    const predictionConfigurationsClient = useMemo(() => new PredictionServiceConfigurationApi(authConfig, authConfig?.basePath), [authConfig]);
    const predictionServicesClient = useMemo(() => new PredictionServicesApi(authConfig, authConfig?.basePath), [authConfig]);

    const [predictions, setPredictions] = useState<GroupBasedPredictionResultDto[]>([]);
    const [predictionConfigurations, setPredictionConfigurations] = useState<PredictionServiceConfigurationDto[]>([]);

    const loadPredictions = useCallback((serviceType: string) => {
        log("load predictions");
        //@ts-ignore
        predictionServicesClient.apiServicesAppPredictionServicesGetPredictionGet(serviceType).then(r => setPredictions([...predictions ?? [], ...r.result]))
    }, [predictionServicesClient, setPredictions]);

    useEffect(() => {
        //@ts-ignore
        authConfig?.basePath && predictionConfigurationsClient.apiServicesAppPredictionServiceConfigurationGetAllGet().then(r => { log(r?.result?.items); setPredictionConfigurations(r?.result?.items) });
    }, [predictionConfigurationsClient, authConfig]);

    useEffect(() => {
        log(predictionConfigurations, predictionConfigurations?.length)
        setPredictions([]);
        predictionConfigurations && predictionConfigurations.length > 0 && predictionConfigurations.map(c => loadPredictions(c.predictionServiceType!))
    }, [predictionConfigurations, loadPredictions])

    return <div className="group-predictions-overview">
        {predictions && predictions.map((p, i) => (
            <div className="prediction" key={i}>
                <h5>{abp.utils.formatString(L("BecauseYouAttendedMeeting_2"), DateTime.fromISO(p.originalEvent?.startTime as any).toRelativeCalendar(), p.originalEvent?.description1)}:</h5>
                <div className="suggestions">
                    {p.entities?.map((e, i2) => {
                        const duration = Duration.fromISOTime(e?.duration as any ?? '');
                        return (
                            <div className="suggestion" key={i2}>
                                {abp.utils.formatString(L("hasRecordedTheFollowingEntity_3"), e.userFk?.fullName, (duration?.hours ? `${duration.hours + (duration.days ?? 0) * 24} Std. ` : '') + `${duration?.minutes ?? 0} Min.`, `${e.jobFk?.description1} ${e.jobTaskFk?.description1} ${e.description1} ${e.description2}`)}
                            </div>)
                    })}
                </div>

            </div>
        ))}

    </div>
}

export default GroupPredictionsOverview;