import React, { ReactNode } from 'react';
import { Dropdown, DropdownProps } from '../../../../common/forms/controls/dropdown/Dropdown';
import classNames from 'classnames';

export type DropdownGroupProps = Pick<DropdownProps, 'name' | 'className' | 'value' | 'data' | 'dataItemKey' | 'textField' | 'onChange' | 'disabled' | 'popupSettings'> & {
    label: ReactNode;
    isCollapsed?: boolean;
}

const DropdownGroup: React.FC<DropdownGroupProps> = ({ label, isCollapsed, ...dropdownProps }) => {
    return <div className={classNames('dropdown-group', { 'collapsed': isCollapsed })}>
        <span className="dropdown-label">
            {label}
        </span>
        {!isCollapsed && <Dropdown {...dropdownProps} />}
    </div>
}

export default DropdownGroup;