import React, { useState, useEffect, useCallback } from 'react';
import qs from 'qs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import Input from '../../common/forms/validation/controls/input/Input';
import Button from '../../common/forms/controls/button/Button';
import { useAuthenticator } from '../../authentication/authenticator/Authenticator';
import Dropdown from '../../common/forms/validation/controls/dropdown/Dropdown';
import AppConsts from '../../abp/constants';
import LoadingIndicator from '../../common/components/loading-indicator/LoadingIndicator';

import './Login.scss';
import { L } from '../../abp/utils';
import { getTenantUserConfiguration } from '../../abp/getUserConfiguration';
import _ from 'lodash';
import AppVersion from '../../common/components/app-version/AppVersion';
import { AppParts } from '../../modules/AppParts';
import ModuleComponentProvider from '../../module-components/module-context/ModuleComponentProvider';
import Switch from '../../common/forms/validation/controls/switch/Switch';
import ControlWrapper from '../../common/forms/controls/control-wrapper/ControlWrapper';

export interface AbpResponse<TData> {
  result: TData;
  targetUrl: string | null;
  success: boolean;
  error: string | null;
  unAuthorizedRequest: boolean;
  __abp: boolean;
}

export interface AbpListData<TItem> {
  totalCount: number;
  items: TItem[];
}

export interface AbpTenant {
  id: number;
  tenancyName: string;
  name: string;
  editionDisplayName: string;
  connectionString: string;
  isActive: boolean;
  creationTime: string;
  subscriptionEndDateUtc: string | null;
  editionId: number;
  isInTrialPeriod: boolean;
}

export const DEFAULT_TENANT = "defaultTenant";
export const DEFAULT_USER = "defaultUser";

const Login: React.FC = () => {
  const { authenticate, isAuthenticating, isAuthenticated, authenticationError } = useAuthenticator();
  const location = useLocation();
  const query = qs.parse(location.search);
  const navigate = useNavigate();

  const [tenants, setTenants] = useState<{ caption: string; value: number | null }[]>([]);


  useEffect(() => {
    axios.get<AbpResponse<AbpListData<AbpTenant>>>(`${AppConsts.remoteServiceBaseUrl}/api/services/app/Tenant/GetTenants?MaxResultCount=100`)
      .then(result => {
        const tenants = [
          // {
          //   caption: 'Ohne Mandant',
          //   value: null
          // },
          ...result.data.result.items.filter(t => t.tenancyName.toLowerCase() !== 'default').map(t => ({ caption: t.name, value: t.id }))
        ];
        setTenants(tenants);
      })
      .catch(() => { });
  }, []);

  const getDefaultUser = useCallback(() => {
    const defaultUser = localStorage.getItem(DEFAULT_USER);

    return defaultUser || '';
  }, []);

  const getDefaultTenant = useCallback(() => {
    if (!tenants || tenants.length < 1)
      return '';

    const defaultTenant = localStorage.getItem(DEFAULT_TENANT);
    const foundTenant = tenants.find(i => i.value?.toString() === defaultTenant);

    if (defaultTenant && foundTenant)
      return foundTenant;

    return tenants[0];
  }, [tenants]);


  useEffect(() => {
    const defaultTenant = getDefaultTenant();
    const usernameRef = document.getElementById('input-username')
    const passwordRef = document.getElementById('input-password')

    if (defaultTenant !== '' && defaultTenant.value) {
      if (getDefaultUser() !== '')
        passwordRef && passwordRef.focus();
      else
        usernameRef && usernameRef.focus();
    }
  }, [tenants])

  const [, rerender] = useState({});

  const updateConfig = useCallback((tenantId: number) => {
    getTenantUserConfiguration(tenantId).then(result => {
      _.merge(abp, result.data.result);
      rerender({});
    });
  }, []);

  useEffect(() => {
    const defaultTenant = getDefaultTenant();
    if (defaultTenant) {
      updateConfig(defaultTenant.value!);
    }
  }, [getDefaultTenant, updateConfig]);

  if (isAuthenticated) {
    // return (
    //   <Route render={() => <Redirect to={{
    //     pathname: query.returnUrl && typeof query.returnUrl === 'string' ? decodeURIComponent(query.returnUrl) : '/',
    //     state: { from: location }
    //   }}/>} />
    // );
    navigate(query.returnUrl && typeof query.returnUrl === 'string' ? decodeURIComponent(query.returnUrl) : '/',
      {
        state: { from: location }
      });
  }

  return (
    // <div className="p-4 shadow-sm" style={{ backgroundColor: '#fff' }}>
    <div className="p-4">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 p-4 d-flex align-items-center">
          <ModuleComponentProvider appPartName={AppParts.Application.LoginLogo} getSingleComponent={true} />
        </div>
        <div className="col-12 border-sm-left col-md-6 p-4">
          <h1 className="pt-0 pb-2">
            <span>Willkommen.</span>
            <br />
            Bitte&nbsp;melden Sie&nbsp;sich&nbsp;an.
          </h1>
          <NaVaForm
            initialValues={{
              tenant: getDefaultTenant(),
              username: getDefaultUser(),
              password: '',
              rememberClient: false
            }}
            enableReinitialize={true}
            validationConfig={{
              tenant: [
                {
                  validate: (obj: any) => !!obj.tenant,
                  message: L('PleaseProvideTenantName')
                }
              ],
              username: [
                {
                  validate: (obj: any) => !!obj.username,
                  message: L('PleaseProvideUsername')
                }
              ],
              password: [
                {
                  validate: (obj: any) => !!obj.password,
                  message: L('PleaseProvidePassword')
                },
                {
                  validate: (_: any) => authenticationError,
                  message: L('InvalidUsernameOrPassword')
                }
              ]
            }}
            onSubmit={(values: any) => {
              authenticate(values.tenant.value, values.username, values.password, values.rememberClient);
              localStorage.setItem(DEFAULT_TENANT, values.tenant.value);
              localStorage.setItem(DEFAULT_USER, values.username);
            }}
          >
            {/* <NaVaFormStatePuller onValuesChanged={values => setReportOptionData(values)} onErrorsChanged={values => setReportOptionErrors(values)}></NaVaFormStatePuller> */}
            {isAuthenticating ?
              <LoadingIndicator />
              :
              <>
                <div className="form-row">
                  <Dropdown name="tenant" data={tenants} disabled={!tenants} textField="caption" dataItemKey="value" md={12} mb={2} onChange={(e) => e.target.value.value && updateConfig(e.target.value.value)} />
                  <Input type="text" name="username" placeholder={L('Username')} md={12} mb={2} id="input-username" /*ref={usernameRef}*/ />
                  <Input type="password" name="password" placeholder={L('Password')} md={12} mb={2} id="input-password" /*ref={passwordRef}*/ />
                  <Button type="submit" color="primary" label={L('Login')} md={12} className="login-btn" />
                  <Switch label={L("StayLoggedIn")} name="rememberClient" className="centered my-3" />
                  <Link to="/reset-credentials" className="d-block text-center">{L('ForgotPassword')}</Link>
                </div>
                {
                  authenticationError &&
                  <div className="bg-danger mt-3 p-4 shadow text-light login-message">
                    Ihr Konto oder Kennwort ist nicht korrekt. Wenn Sie Ihr Kennwort nicht mehr wissen, lassen Sie es durch einen Administrator zurücksetzen.
                  </div>
                }
              </>
            }
          </NaVaForm>
        </div>
      </div>
      <AppVersion />
    </div >
  );
};

export default Login;
