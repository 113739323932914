import { useEffect } from 'react';
import useEventCallback from '../../../hooks/useEventCallback';
import { useNaVaFormContext } from './NaVaFormContext';
import { ComputationOptions } from './types';

export default function useComputation({ dependency, target, computation, condition }: ComputationOptions): void {
  const { registerComputation, unregisterComputation } = useNaVaFormContext();

  const strDep = typeof dependency === 'string' ? dependency : dependency.join('.');
  const strTarget = typeof target === 'string' ? target : target.join('.');
  const actualComputation = useEventCallback(computation);
  const actualCondition = useEventCallback((value, getValue) => !condition || condition(value, getValue));

  useEffect(() => {
    const opts = {
      dependency: strDep,
      target: strTarget,
      computation: actualComputation,
      condition: actualCondition
    };
    registerComputation(opts);
    return () => {
      unregisterComputation(opts);
    };
  }, [registerComputation, unregisterComputation, strDep, strTarget, actualComputation, actualCondition]);
}
