import React from 'react';

export type ModuleContextType = {
    registerComponent: (name: string, module: string, replace: boolean, component: (props?: any) => React.ReactNode, key?: string) => void,
    getComponents: (appPartName: string, moduleName?: string, key?: string, inheritForModule?: string, overrideValues?: boolean) => ((props: any) => React.ReactNode)[] | null,
    components: ModuleContextValues
}

export type ModuleContextValues = { [name: string]: { module: string, key?: string, component: (props?: any) => React.ReactNode }[] }

const ModuleContext = React.createContext({});

export default ModuleContext;