import React from 'react';
import { useNavigate } from 'react-router-dom';
import SdtLogoBig from './sdt-logo-big.png';
import SdtLogoSmall from './sdt-logo-small.png';

const SdtLogo: React.FC<{ collapsed: boolean }> = ({ collapsed = false }) => {

    const navigate = useNavigate();

    return <header onClick={() => navigate('/')} className={collapsed ? "" : "d-flex justify-content-center"}>
        {collapsed ?
            <img alt="Sedotec" src={SdtLogoSmall} height="56px" width="56px" style={{ height: 'auto', position: 'relative', left: '-0.7rem' }} /> :
            <img alt="Sedotec" src={SdtLogoBig} height="30px" width="auto" />
        }
        {!collapsed && <h1 style={{ top: '0.15rem', fontSize: '1.4rem', fontWeight: 600 }}>PLM</h1>}
    </header>
}

export default SdtLogo;