import React, { useCallback, useMemo } from 'react';
import { JobApi, JobDto } from '../../../../client/http';
import { selectPlmControlSortProperty, PlmDataControlProps, isTemplateFilter, RowSkeletons, selectPlmControlTemplateSortProperty } from './components/PlmControlComponents';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import { NEW_ENTITY_ID, getExtensionValue } from '../../../../util/EntityUtils';
import classNames from 'classnames';
import _ from 'lodash';
import { FIELD_DESIGNATION, FIELD_ID, FIELD_IS_TEMPLATE } from '../../../../data/field-constants/GeneralConstants';
import PlmJobTaskList from './PlmJobTaskControl';
import useInternalPlmControlData from '../../hooks/useInternalPlmControlData';
import usePlmControlContext from './plm-control-context/usePlmControlContext';
import useInternalPlmListData from '../../hooks/useInternalPlmListData';
import { PlmColumnDataDisplay } from './components/PlmControlDataDisplay';
import PlmColumnHeaderDisplay from './components/PlmControlHeaderDisplay';
import { JobDefaultValues } from '../../data/entityDefaults';

export type PlmJobControlProps = PlmDataControlProps & PlmJobListProps & {
    job: JobDto
};

const PlmJobControl: React.FC<PlmJobControlProps> = React.memo(({ job, constrainToList, refetch }) => {

    const { controlState, showTemplates } = usePlmControlContext();

    const [internalJob, , handleChange, handleAction] = useInternalPlmControlData<JobDto, JobApi>(
        job,
        { [FIELD_IS_TEMPLATE]: showTemplates === true },
        'job',
        c => new JobApi(c),
        c => c.apiServicesAppJobCreatePost,
        c => c.apiServicesAppJobUpdatePut,
        c => c.apiServicesAppJobDeleteDelete,
        refetch
    );

    const isExpanded = internalJob && !!controlState.job.expanded?.find(x => x.key === getIn(internalJob, FIELD_ID));


    // const localDispatch = useCallback((args: Parameters<PlmControlBaseProps['dispatch']>[0]) => {

    //     // fetch items on expand
    //     if (args.type === 'expanded/changed' && args.payload.value === true) {
    //         fetchJobTasks();
    //     }
    // }, [fetchJobTasks]);    

    return <div className={classNames({ 'unsaved': getIn(internalJob, FIELD_ID) === NEW_ENTITY_ID })}>
        <PlmColumnDataDisplay item={internalJob} section="job" level={constrainToList ? 0 : 1} className="plm-job-item job-row" expandable={!constrainToList}
            onChange={handleChange} onAction={handleAction}
        />

        {isExpanded && <PlmJobTaskList jobId={getIn(internalJob, FIELD_ID)} jobNumber={getIn(internalJob, FIELD_DESIGNATION)} readOnly={getIn(internalJob, FIELD_ID) === NEW_ENTITY_ID} />}
    </div>
})

export type PlmJobListProps = {
    constrainToList?: boolean;
}

const PlmJobList: React.FC<PlmJobListProps> = ({ constrainToList }) => {

    const { controlState, readOnly, showTemplates } = usePlmControlContext();

    const internalJobControlStateFilters = controlState.job.filter.internalFilters;
    const internalJobFilters = useMemo(() => [
        ...internalJobControlStateFilters,
        ...isTemplateFilter(showTemplates),
    ], [internalJobControlStateFilters, showTemplates]);

    const externalJobFilters = controlState.job.filter.externalFilters;

    const getAll = useCallback((c: JobApi) => {
        return c.apiServicesAppJobGetAllGet(
            abp.setting.get(Settings_ProviderName),
            abp.setting.getInt(Settings_ServiceConnectionId),
            JSON.stringify(internalJobFilters),
            JSON.stringify(externalJobFilters))
    },
        [internalJobFilters, externalJobFilters]);

    const [jobs, fetchJobs, addRow, isLoading] = useInternalPlmListData<JobDto, JobApi>(
        c => new JobApi(c),
        getAll,
        'job',
        {
            ...JobDefaultValues,
        });

    return <div className="plm-job-list">

        <PlmColumnHeaderDisplay section='job' level={constrainToList ? 0 : 1} headersLevel={1} onAction={addRow} readOnly={readOnly} />

        {
            _.orderBy(jobs ?? [], [selectPlmControlTemplateSortProperty, selectPlmControlSortProperty])?.map(job => {
                return <PlmJobControl job={job} refetch={fetchJobs} key={getIn(job, FIELD_ID)} constrainToList={constrainToList} />
            })
        }

        {isLoading && <RowSkeletons level={2} />}
    </div >
}

export default PlmJobList;