import React, { ReactNode } from 'react';
import "./SwitchGroup.scss";
import Switch, { SwitchProps } from '../../../../common/forms/controls/switch/Switch';
import { L } from '../../../../abp/utils';
import classNames from 'classnames';

export type SwitchGroupProps = Pick<SwitchProps, 'className' | 'onChange' | 'value' | 'disabled'> & {
    label: ReactNode;
    isCollapsed?: boolean;
}

const SwitchGroup: React.FC<SwitchGroupProps> = ({ label, isCollapsed = false, ...switchProps }) => {
    return (<div className={classNames('switch-group', { 'collapsed': isCollapsed }, `value__${(switchProps.value ?? false).toString()}`)}>
        <span className="switch-label">
            {label}
        </span>
        {!isCollapsed && <Switch className={classNames(switchProps.className, 'k-switch-xs')}  {...switchProps} />}
    </div>)
}

export default SwitchGroup;