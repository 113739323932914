import React from 'react';
import useModuleContext from './useModuleContext';

const ModuleComponentProvider: React.FC<{
    appPartName: string, moduleName?: string, props?: any, key?: string,
    inheritForModule?: string, getSingleComponent?: boolean, className?: string
}> = ({ appPartName, moduleName, props, key, inheritForModule, getSingleComponent, className }) => {

    const { getComponents } = useModuleContext();

    return <>
        {getComponents(appPartName, moduleName, key, inheritForModule, getSingleComponent ?? true)?.map((component, i) =>
            component({ ...props, className, key: `${appPartName}${moduleName || ''}${i}` })
        )}
    </>
}

export default ModuleComponentProvider;