import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { ReportOptionDto } from '../../client/http';
import ComboBox from '../../common/forms/validation/controls/combo-box/ComboBox';
import DatePicker from '../../common/forms/validation/controls/date-picker/DatePicker';
import Input from '../../common/forms/validation/controls/input/Input';
import Switch from '../../common/forms/validation/controls/switch/Switch';

export const parseOptionDefaultValue = (type: string, value: string) => {
    switch (type) {
        case "System.DateTime":
            return DateTime.fromISO(value).toJSDate();
        case "System.Int32":
        case "System.Int64":
            return parseInt(value);
        case "System.Boolean":
            return value === 'true';
        case "System.String":
        default:
            return value;
    }
};

export const mapOptionValuesToType = (values: any, options: ReportOptionDto[]) => {
    [...options,
    { identifier: "reportDate", type: "System.DateTime" },
    { identifier: "reportTime", type: "System.String" }]
        .map(o => {
            if (o.identifier && Object.hasOwn(values, o.identifier)) {
                values[o.identifier] = parseOptionDefaultValue(o.type ?? "", values[o.identifier]);
            }
            return null;
        });

    return values;
}

const ReportOptionControl: React.FC<{ option: ReportOptionDto, disabled?: boolean }> = ({ option, disabled }) => {

    const switchControl = 'switch';
    const text = 'text';
    const number = 'number';
    const date = 'date';
    const dropdown = 'dropdown'

    const getControl = useCallback(() => {
        switch (option.displayControlType?.toLowerCase()) {
            case switchControl:
                return <Switch name={option.identifier!} label={option.name} disabled={disabled} />
            case text:
                return <Input name={option.identifier!} type="text" label={option.name} disabled={disabled} />
            case number:
                return <Input name={option.identifier!} type="number" label={option.name} disabled={disabled} />
            case date:
                return <DatePicker name={option.identifier!} label={option.name} disabled={disabled} />
            case dropdown:
                return <ComboBox name={option.identifier!} label={option.name} disabled={disabled} data={option.valuesFilter && JSON.parse(option.valuesFilter).map((v: string) => parseOptionDefaultValue(option.type!, v))} />
            default:
                return <></>
        }
    }, [option])

    return <div className="report-option" key={option.id}>
        {getControl()}
    </div>
}

export default ReportOptionControl