import { getValue } from '@testing-library/user-event/dist/utils/edit/getValue';
import React, { useCallback, useEffect } from 'react';
import { FIELD_ID } from '../../../../../data/field-constants/GeneralConstants';
import { NullGuid } from '../../../../../util/Values';
import { UseApiResult } from '../../../../hooks/useApi';
import { getIn } from '../../na-va-form/commonUtils';
import { useNaVaFormContext } from '../../na-va-form/NaVaFormContext';
import useComputation from '../../na-va-form/useComputation';
import ComboBox, { ComboBoxProps } from '../combo-box/ComboBox';
import { log } from '../../../../../util/LoggingUtils';

export type ApiComboBoxProps = ComboBoxProps & { foreignKeyName: string, foreignKeyDefaultValue?: any };
export type ApiComboBoxPropsOf<T, TApi> = ApiComboBoxProps & { apiResult: UseApiResult<T, TApi> };

const ApiComboBox: React.FC<ApiComboBoxPropsOf<any, any>> = ({ apiResult, foreignKeyName, foreignKeyDefaultValue = '', ...props }) => {

    const [items,] = apiResult;

    const { values, setValues, setFieldValue } = useNaVaFormContext();
    const dataItemKey = props.dataItemKey ?? 'id';
    const selectedId = getIn(values, foreignKeyName);
    const selectedItem = getIn(values, [props.name]);

    // Update foreign keys according to the id
    const updateForeignKey = useCallback((idField: string, idDefault: any, fkField: string, collection: any[]) => {
        if (collection && values[idField] && values[idField] !== idDefault && (!values[fkField] || values[fkField][FIELD_ID] !== values[idField])) {
            const item = collection.find(c => c.id === values[idField]);
            setFieldValue(fkField, item);
            !!item && setFieldValue(idField, idDefault);
            log("updated foreign key:", idField, fkField, values[idField], item)
        }
    }, [setFieldValue, values]);

    // Reset id's when foreign keys are emptied
    // useComputation({
    //     dependency: props.name,
    //     target: foreignKeyName,
    //     computation: () => foreignKeyDefaultValue,
    //     condition: (j) => !j
    // });

    // Initial load
    useEffect(() => {
        updateForeignKey(foreignKeyName, foreignKeyDefaultValue, props.name, items);
    }, [updateForeignKey, items, foreignKeyName, foreignKeyDefaultValue, props]);

    // useEffect(() => {
    //     if (!items)
    //         return;
    //     console.log("selectedId changing", props.name, selectedId, selectedItem, items.find(i => i[dataItemKey] === selectedId));
    //     if ((selectedId && !selectedItem) || (selectedItem && !selectedItem[foreignKeyName] === selectedId)) {
    //         setValues({ ...values, [props.name]: items.find(i => i[dataItemKey] === selectedId) });
    //         console.log("selectedId changed", items.find(i => i[dataItemKey] === selectedId));
    //     }
    // }, [selectedId, items, setValues, foreignKeyName, dataItemKey, props.name, selectedItem]);

    // useComputation(() => {
    //     setFieldValue(itemsField, items);
    // }, [items, setFieldValue]);

    //     useComputation({
    //         dependency: foreignKeyName,
    //         target: props.name,
    //         computation: (id, getValue) => items.find(i => i[dataItemKey] === selectedId),
    //         condition: (id, getValue) => items && items.length && ((id && !getValue(props.name)) || (getValue(props.name) && !getValue(props.name)[foreignKeyName] === id))
    // })

    // useEffect(() => {
    //     console.log("xyz", props.name, foreignKeyName, selectedId, selectedItem);
    // }, [selectedId, selectedItem])

    return <ComboBox {...props} data={items} popupSettings={{ width: props?.popupSettings?.width ?? 'min(750px,50vw)' }} />
};

export default ApiComboBox;