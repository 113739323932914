import React, { useCallback } from 'react';
import { ActionColumnActionType, ItemSkeleton, PlmControlGroupRenderDisplay } from './components/PlmControlComponents';
import { L } from '../../../../abp/utils';
import useInternalPlmControlData from '../../hooks/useInternalPlmControlData';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { FIELD_DESIGNATION, FIELD_ID, FIELD_IS_TEMPLATE, FIELD_JOB_ID, FIELD_JOB_TYPE, FIELD_JOB_TYPE_ID } from '../../../../data/field-constants/GeneralConstants';
import { JobTypeApi, JobTypeDto } from '../../../../client/http';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import Button from '../../../../common/forms/controls/button/Button';
import Icon from '../../../../common/components/icon/Icon';
import { NEW_ENTITY_ID } from '../../../../util/EntityUtils';
import classNames from 'classnames';
import useCrudApi from '../../../../common/hooks/useCrudApi';
import { showNotification } from '../../../../common/components/notifications/NotificationHost';
import PlmJobTypeLineList from './PlmJobTypeLineControl';
import usePlmControlContext from './plm-control-context/usePlmControlContext';
import { JobTypeDefaultValues } from '../../data/entityDefaults';
import ModuleComponentProvider from '../../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../../AppParts';
import { Modules } from '../../../../module-components/Modules';
import { JobTypeItemRender, JobTypeSelectionProps, TemplatedJobTypeItemRender } from '../../service-entitites/JobTypeSelection';
import filter from '../../../../util/FilterUtils';
import NaVaForm from '../../../../common/forms/validation/na-va-form/NaVaForm';

export type PlmJobTypeControlProps = {
    isLoading: boolean;
    jobType: any;
    jobId: string;
    onJobTypeChanged: (action: 'created' | 'updated' | 'deleted', id?: string, code?: string,) => void;
};


const PlmJobTypeControl: React.FC<PlmJobTypeControlProps> = ({ isLoading, jobId, jobType, onJobTypeChanged }) => {

    const { readOnly, showTemplates } = usePlmControlContext();

    const onSave = useCallback((item: JobTypeDto, successful: boolean) => {
        if (successful) {
            onJobTypeChanged('updated', getIn(item, FIELD_ID), getIn(item, FIELD_DESIGNATION))
        }
    }, [onJobTypeChanged])

    const [internalJobType, setInternalJobType, handleChange, handleAction] = useInternalPlmControlData<JobTypeDto, JobTypeApi>(
        jobType,
        {},
        'jobType',
        c => new JobTypeApi(c),
        c => c.apiServicesModulesSdtPlmJobTypeCreatePost,
        c => c.apiServicesModulesSdtPlmJobTypeUpdatePut,
        c => c.apiServicesModulesSdtPlmJobTypeDeleteDelete,
        undefined,
        undefined,
        onSave
    );

    const [, api, create] = useCrudApi<JobTypeDto, JobTypeApi>(c => new JobTypeApi(c),
        undefined,
        (c, d) => c.apiServicesModulesSdtPlmJobTypeCreatePost(({
            ...d,
            extensionData: JSON.stringify(d.extensions),
            serviceProvider: d.serviceProvider ?? abp.setting.get(Settings_ProviderName),
            serviceConnectionId: d.serviceConnectionId ?? abp.setting.getInt(Settings_ServiceConnectionId)
        })),

    );

    const handleCreate = useCallback(async () => {
        if (!api || !create) return;

        const jobTypeId = getIn(internalJobType, FIELD_ID);

        if (jobTypeId !== undefined || jobTypeId != null) return;

        try {
            setInternalJobType({ id: NEW_ENTITY_ID } as JobTypeDto);
            var result = await create(api, { ...internalJobType, ...JobTypeDefaultValues, [FIELD_ID]: undefined, [FIELD_IS_TEMPLATE]: showTemplates });

            onJobTypeChanged('created', (result as any).result[FIELD_ID], undefined);

        } catch (error) {
            showNotification('Erstellen fehlgeschlagen', 'Fehler beim Speichern aufgetreten', 'error', undefined, true);
            return false;
        }
    }, [internalJobType, api, showTemplates, setInternalJobType, onJobTypeChanged, create]);

    const handleSelect = useCallback(async (jt: JobTypeDto) => {
        const jobTypeId = getIn(internalJobType, FIELD_ID);

        if (jobTypeId !== undefined || jobTypeId != null) return;

        try {
            onJobTypeChanged('updated', jt.id, jt.designation);

        } catch (error) {
            showNotification('Projektart festlegen fehlgeschlagen', 'Fehler beim Speichern aufgetreten', 'error', undefined, true);
            return false;
        }
    }, [internalJobType, onJobTypeChanged]);

    const handleActionInternal = useCallback((action: ActionColumnActionType) => {
        handleAction(action);

        switch (action) {
            case 'save':
                onJobTypeChanged('updated', getIn(internalJobType, FIELD_ID), getIn(internalJobType, FIELD_DESIGNATION));
                break;
            case 'delete':
                onJobTypeChanged('deleted', getIn(internalJobType, FIELD_ID))
                break;

        }
    }, [internalJobType, handleAction, onJobTypeChanged]);

    if (!jobType && isLoading) {
        return (<>
            <ItemSkeleton level={1} />
            <ItemSkeleton level={1} />
            <ItemSkeleton level={1} />
        </>
        )
    }

    return <>
        {internalJobType ?
            <div className={classNames('plm-job-type-wrapper', { 'unsaved': getIn(internalJobType, FIELD_ID) === NEW_ENTITY_ID })}>
                <PlmControlGroupRenderDisplay item={internalJobType} section="jobType" level={1} className='plm-job-type-item job-type-row'
                    expandable wrapperClassName='row__jobType' onChange={handleChange} onAction={handleActionInternal}
                />
                <PlmControlGroupRenderDisplay item={internalJobType} section="jobTypePosAdj" level={1} className='plm-job-type-item job-type-row'
                    expandable wrapperClassName='row__posAdj' onChange={handleChange} onAction={handleActionInternal}
                />
                <PlmJobTypeLineList jobTypeId={getIn(internalJobType, FIELD_ID)} jobTypeCode={getIn(internalJobType, FIELD_DESIGNATION)} type="positive" />
                <PlmControlGroupRenderDisplay item={internalJobType} section="jobTypeNegAdj" level={1} className='plm-job-type-item job-type-row'
                    expandable wrapperClassName='row__negAdj' onChange={handleChange} onAction={handleActionInternal}
                />
                <PlmJobTypeLineList jobTypeId={getIn(internalJobType, FIELD_ID)} jobTypeCode={getIn(internalJobType, FIELD_DESIGNATION)} type="negative" />
            </div> :
            <div className="plm-job-type-item item__empty my-2 d-flex align-items-center">
                <span className="me-3">
                    {L('Keine Projektart hinterlegt')}
                </span>
                {!readOnly &&
                    <div className="d-flex gap-3">
                        <NaVaForm
                            initialValues={{}}
                            onSubmit={() => { }}
                        >
                            <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.JobType} getSingleComponent={true}
                                props={{
                                    name: FIELD_JOB_TYPE,
                                    foreignKeyName: FIELD_JOB_TYPE_ID,
                                    jobTypeFilters: showTemplates ? [filter(FIELD_IS_TEMPLATE).equals(true)] : undefined,
                                    onChange: v => handleSelect(v.target.value),
                                    itemRender: showTemplates ? TemplatedJobTypeItemRender : JobTypeItemRender,
                                    jobId: jobId
                                } as JobTypeSelectionProps} />
                        </NaVaForm>
                        <Button color="light" label="" onClick={handleCreate}>
                            <Icon name="icon-add" size="1.5rem" />
                            {L('Create')}
                        </Button>
                    </div >
                }
            </div>
        }
    </>
}

export default PlmJobTypeControl;