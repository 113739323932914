import * as React from 'react';
import { L } from '../../../abp/utils';
import { CustomerApi, CustomerDto } from '../../../client/http';
import ItemRenderComponent from '../../../common/components/item-render/ItemRenderComponent';
import ApiComboBox from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import useApi from '../../../common/hooks/useApi';
import { FIELD_NAME, FIELD_NAME2 } from '../../../data/field-constants/GeneralConstants';
import { CustomerSelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';

const CustomerSelection: React.FC<CustomerSelectionProps> = ({ fetchFromAllProviders, selectedItem, itemRenderFields, data,
    name, title, placeholder, filterable, filterFields, dataItemKey, textField, itemRender, content, footer, ...props }) => {

    const apiResult = useApi<CustomerDto, CustomerApi>(c => new CustomerApi(c), c => c.apiServicesAppCustomerGetAllGet(fetchFromAllProviders ? undefined :
        abp.setting.get(Settings_ProviderName),fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId)));

    return <ApiComboBox apiResult={data ? [data, undefined, undefined, () => { }, undefined] : apiResult} name={name}
        filterable={filterable ?? true} filterFields={filterFields ?? [FIELD_NAME, FIELD_NAME2]}
        dataItemKey={dataItemKey ?? 'id'} textField={textField ?? FIELD_NAME}
        itemRender={(li, props) => <ItemRenderComponent li={li} itemProps={props} fields={[FIELD_NAME, FIELD_NAME2]} />}
        placeholder={placeholder ?? L('Customer')} footer={footer} content={content} {...props} />
}

export default CustomerSelection;