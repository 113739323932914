import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import PlmControlContext, { PlmControlContextType } from "./PlmControlContext";
import { PlmControlSection, PlmControlState, defaultPlmControlState } from "../components/PlmControlComponents";
import { log } from "../../../../../util/LoggingUtils";

export type PlmControlContextProviderProps = Omit<PlmControlContextType, 'controlState' | 'dispatch' | 'replacements' | 'replacementsChanged'> & {
    controlStateConfig?: PlmControlContextType['controlState'];
    replacements?: PlmControlContextType['replacements'],
    onSelectionChanged?: (selection: { section: PlmControlSection, keys?: string[], items?: any[] }[]) => void;
}

const PlmControlContextProvider: React.FC<PropsWithChildren<PlmControlContextProviderProps>> = ({
    controlStateConfig = defaultPlmControlState,
    columnConfig,
    fetchFromAllProviders,
    selectable,
    multiSelect,
    readOnly,
    showTemplates,
    replacements: defaultReplacements,
    onSelectionChanged,
    children
}) => {

    const [controlState, setControlState] = useState<PlmControlState>(controlStateConfig);

    useEffect(() => setControlState(controlStateConfig), [controlStateConfig]);

    const [replacements, setReplacements] = useState<{ [key: string]: any }>(defaultReplacements ?? {});

    const handleReplacementsChanged = useCallback((values: { [key: string]: any }) => {
        setReplacements(r => ({ ...r, ...values }));
    }, []);

    useEffect(() => handleReplacementsChanged(defaultReplacements ?? {}), [defaultReplacements, handleReplacementsChanged]);

    const validateItemChange = useCallback((entry: PlmControlState['isEditing']) => {
        return true;
    }, [])

    const handleDispatch = useCallback((args: Parameters<PlmControlContextType['dispatch']>[0]) => {

        const { section, key, item, parents, value } = args.payload;

        log("PlmControl: action dispatched", args.type, section, key, value)
        switch (args.type) {
            case 'expanded/changed':

                setControlState((s: PlmControlState) => ({
                    ...s,
                    [section]: {
                        ...s[section],
                        expanded:
                            value ? [...s[section]?.expanded ?? [], { key, item, parents }] :
                                [...(s[section]?.expanded ?? []).filter(x => x.key !== key)],
                    }
                }));
                break;
            case 'selected/changed':
                if (!selectable) return;

                setControlState((s: PlmControlState) => {
                    let newState: PlmControlState;

                    // TODO implement multiSelect using ctrl and shift modifierKeys from payload.options

                    if (!!multiSelect) {
                        newState = {
                            ...s,
                            [section]: {
                                ...s[section],
                                selected: value ? [...s[section]?.selected ?? [], { key, item, parents }] :
                                    [...(s[section]?.selected ?? []).filter(x => x.key !== key)],
                            }
                        };
                    } else {
                        newState = {
                            ...s,
                            [section]: {
                                ...s[section],
                                selected: value ? [{ key, item, parents }] : []
                            }
                        };
                    }

                    const { isEditing, ...sections } = newState;
                    onSelectionChanged && onSelectionChanged(Object.keys(sections).map((k) => ({ section: k as PlmControlSection, keys: newState[k as PlmControlSection].selected?.map(x => x.key), items: newState[k as PlmControlSection].selected?.map(x => x.item) })));

                    return newState;
                });

                // [...(controlState[section]?.selected ?? []).filter(x => x.key !== key)], items, parents);
                break;
            case 'isEditing/changed':
                setControlState((s: PlmControlState) => {
                    if (value && readOnly)
                        return { ...s };

                    if (!validateItemChange(s.isEditing))
                        return { ...s };

                    if (value) {
                        return {
                            ...s,
                            isEditing: {
                                section, key, item, parents
                            }
                        }
                    }

                    return {
                        ...s,
                        isEditing: undefined
                    }
                });

                break;
            default:
                break;
        }
    }, [multiSelect, readOnly, selectable, onSelectionChanged, validateItemChange]);

    return <PlmControlContext.Provider value={{
        columnConfig,
        controlState,
        fetchFromAllProviders,
        multiSelect,
        readOnly,
        selectable,
        showTemplates,
        replacements,
        replacementsChanged: handleReplacementsChanged,
        dispatch: handleDispatch,
    } as PlmControlContextType}>{children}</PlmControlContext.Provider>
}

export default PlmControlContextProvider;