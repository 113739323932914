export const Settings_ProviderName = "App.TimeTracking.DefaultServiceProvider";

export const Settings_ServiceConnectionId = "App.TimeTracking.CurrentServiceConnection";

export const Settings_ResourceId = "App.TimeTracking.CurrentResourceId";

export const Settings_DefaultDurationMode = "App.TimeTracking.DefaultDurationMode";

export const Settings_FilterOwnEntitiesByDefault = "App.TimeTracking.FilterOwnEntitiesByDefault";

export const Settings_Description1Length = "App.TimeTracking.Description1Length";
export const Settings_Description2Length = "App.TimeTracking.Description2Length";

export const Settings_TimeBasedAmountUnits = "App.TimeTracking.TimeBasedAmountUnits";