import { useCallback, useEffect, useMemo, useState } from "react";
import useApi from "../../../common/hooks/useApi";
import { showNotification } from "../../../common/components/notifications/NotificationHost";
import { getIn } from "../../../common/forms/validation/na-va-form/commonUtils";
import { FIELD_ID } from "../../../data/field-constants/GeneralConstants";
import { NEW_ENTITY_ID } from "../../../util/EntityUtils";
import { error as l_error } from "../../../util/LoggingUtils";
import _ from "lodash";
import useLoadingState from "../../../common/hooks/useLoadingState";
import usePlmControlContext from "../template-management/plm-job-list/plm-control-context/usePlmControlContext";
import { PlmControlSection } from "../template-management/plm-job-list/components/PlmControlComponents";

// [data, fetchData, addRow, api, setData]
export type UseInternalPlmListDataResult<T, TApi> = [
    T[] | undefined,
    () => Promise<void>,
    () => void,
    boolean,
    TApi | undefined,
    React.Dispatch<React.SetStateAction<T[] | undefined>>
]

// type ApiFunc<T, TApi> = typeof useApi<T, TApi>;
// type ApiArgs<T, TApi> = Parameters<ApiFunc<T, TApi>>;

// eslint-disable-next-line
type ApiArgs<T, TApi> = Parameters<typeof useApi<T, TApi>>; // eslint-disable-line

export default function useInternalPlmListData<T, TApi>(
    apiFunc: ApiArgs<T, TApi>[0],
    getAllFunc: ApiArgs<T, TApi>[1],
    section: PlmControlSection,
    spreadInNewRow?: any | ((data: T[]) => any)
): UseInternalPlmListDataResult<T, TApi> {

    const [, api] = useApi<T, TApi>(apiFunc);

    const { controlState, dispatch } = usePlmControlContext();

    const { isEditing } = controlState;

    const [data, setData] = useState<T[] | undefined>(undefined);

    const [startLoading, loadingComplete, loadingError, isLoading] = useLoadingState();

    const fetchData = useCallback(async () => {
        if (!api) return;

        try {
            startLoading();
            const result = getAllFunc && await getAllFunc(api as TApi);
            setData([...(result as any).result]);
            loadingComplete();

        } catch (error) {
            l_error(error);
            loadingError("Fehler beim Datenabruf");
            showNotification('Fehler beim Datenabruf', 'Daten konnten nicht vom Server abgerufen werden', 'error');
        }
    }, [api, getAllFunc, startLoading, loadingComplete, loadingError]);

    useEffect(() => { fetchData() }, [fetchData]);

    const spreadInNewRowValue = useMemo(() => _.isFunction(spreadInNewRow) ? spreadInNewRow(data ?? []) : spreadInNewRow,
        [data, spreadInNewRow]);

    const addRow = useCallback(() => {
        if (data?.find(x => getIn(x, FIELD_ID) === NEW_ENTITY_ID)) return;

        const newItem = { id: NEW_ENTITY_ID, ...spreadInNewRowValue ?? {} }
        setData(jt => [...jt ?? [], newItem]);

        if (!isEditing?.key) {
            dispatch({
                type: 'isEditing/changed',
                payload: {
                    section, key: NEW_ENTITY_ID, item: newItem, value: true
                }
            });
        }
    }, [data, section, spreadInNewRowValue, isEditing, setData, dispatch]);

    return [data, fetchData, addRow, isLoading, api, setData];
}