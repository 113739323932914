import React from 'react';
import Icon, { IconName } from '../icon/Icon';
import LoadingIndicator from '../loading-indicator/LoadingIndicator';
import './DataViewMessage.scss';

export type DataViewMessageProps = {
    title?: string | React.ReactElement;
    message?: string | React.ReactElement;
    isLoading?: boolean;
    iconName?: IconName;
    iconColor?: string;
    color?: string;
    state?: 'successful' | 'loading' | 'error';
    loadingIndicatorType?: 'spinner' | 'progressBar';
};

const DataViewMessage: React.FC<DataViewMessageProps> =
    ({ title, message, isLoading, iconName = 'icon-info', color, iconColor, loadingIndicatorType = 'spinner' }) => {
        return <div className="data-view-message">
            {
                isLoading ?
                    <LoadingIndicator loadingIndicatorType={loadingIndicatorType} />
                    :
                    <><div className="icon">
                        <Icon name={iconName} size="5rem" color={iconColor}></Icon>
                    </div>
                        {title && <div className="title" style={{ color }}>{title}</div>}
                        {message && <div className="message" style={{ color }}>{message}</div>}
                    </>
            }
        </div>
    }

export default DataViewMessage;

export const DataViewNoData: DataViewMessageProps = {
    title: 'Hier ist nichts',
    message: 'Zu dieser Anfrage wurden keine Ergebnisse gefunden',
    iconName: 'icon-empty-box',
    state: 'successful'
};

export const DataViewLoading: DataViewMessageProps = {
    isLoading: true, state: 'loading'
};

export const DataViewError: DataViewMessageProps = {
    title: 'Ein Fehler ist aufgetreten',
    iconName: 'icon-error',
    state: 'error'
}