import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { Popup, Align } from '@progress/kendo-react-popup';
import { ReactComponent as Logo } from '../../../appLogo.svg';
import { useAuthenticator } from '../../../authentication/authenticator/Authenticator';
import './Header.scss';
import { getUserProfilePicture, getUserSessionInformations, SessionInformation } from '../../../abp/getUserConfiguration';
import { profile } from 'console';
import Button from '../../../common/forms/controls/button/Button';
import { L } from '../../../abp/utils';
import { userInfo } from 'os';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Action, mergeNavActions, NavActions } from '../side-nav/SideNav';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import { useModuleArrayData } from '../../../module-components/ModuleDataProvider';
import useVisibility from '../../../common/hooks/useVisibility';
import ChangePassword from '../../login/ChangePassword';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useOnClickOutside } from 'usehooks-ts';

const POPUP_ALIGNMENT: { anchorAlign: Align; popupAlign: Align } = {
  anchorAlign: {
    horizontal: 'right',
    vertical: 'bottom'
  },
  popupAlign: {
    horizontal: 'right',
    vertical: 'top'
  }
};

export type HeaderProps = {};

const Header: React.FC<HeaderProps> = ({ ...props }) => {
  const anchor = useRef<HTMLLIElement | null>(null);
  const { isAuthenticated, user, logout } = useAuthenticator();
  const [show, setShow] = useState(false);
  const [resetShow, setResetShow] = useState(false);
  const [popupWidth, setPopupWidth] = useState(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [profilePicture, setProfilePicture] = useState<string | undefined>(undefined);
  const [sessionInfo, setSessionInfo] = useState<SessionInformation | undefined>(undefined);
  const isDownloading = useRef(false);
  const [isChangePasswordVisible, showChangePassword, hideChangePassword] = useVisibility();

  const popupRef = useRef<HTMLDivElement>(null);
  const onClickOutside = useCallback(() => {
    show && setShow(false);
    setTimeout(() => setResetShow(false), 500);
  }, [show]);
  useOnClickOutside(popupRef, onClickOutside);
  useEffect(() => { show && setResetShow(true) }, [show]);

  const iterateNavItems = useCallback((actions: Action[], items: Action[] = []): Action[] => {
    const item = actions.find(a => pathname.includes(a.destination));
    item && items.push(item);
    item?.children && iterateNavItems(item.children, items);
    return items;
  }, [pathname]);

  const { data: routeData } = useModuleArrayData('navigation/navItems.json');
  const [breadCrumbItems, setBreadCrumbItems] = useState<Action[]>(iterateNavItems(NavActions(L)));

  useEffect(() => {
    setBreadCrumbItems(iterateNavItems(mergeNavActions(NavActions(L), routeData)));
  }, [iterateNavItems, setBreadCrumbItems, routeData]);

  const onAnchorResize = useCallback(() => setPopupWidth(anchor.current ? anchor.current.clientWidth : 0), []);

  const anchorRef = useCallback((element: HTMLLIElement | null) => {
    if (anchor.current !== null) {
      anchor.current.removeEventListener('resize', onAnchorResize);
    }
    if (element !== null) {
      setPopupWidth(element.clientWidth);
      element.addEventListener('resize', onAnchorResize);
    }
    anchor.current = element;
  }, [onAnchorResize]);

  useEffect(() => {
    if (!isDownloading.current) {
      isDownloading.current = true;


      let userInfoPromise: Promise<any> | undefined;
      if (!profilePicture && user && user.tokenInfo && user.tokenInfo.token) {
        userInfoPromise = getUserProfilePicture(user.tokenInfo.token).then((result: any) => setProfilePicture(result.data.result.profilePicture));
      }

      let userSessionInfoPromise: Promise<any> | undefined;
      if (!sessionInfo && user && user.tokenInfo && user.tokenInfo.token) {
        userSessionInfoPromise = getUserSessionInformations(user.tokenInfo.token).then((result: any) => setSessionInfo(result.data));
      }

      void Promise.all([userInfoPromise, userSessionInfoPromise]).then(() => {
        isDownloading.current = false
      });
    }
  }, [profilePicture, user, sessionInfo]);

  const processLogout = () => {
    logout();
    navigate("/app/home");
  }

  const Breadcrumb = useMemo(() => () => {
    return (<div className="breadcrumb">
      {breadCrumbItems && breadCrumbItems.map((action, index) => {
        return <div className="breadcrumb-item" key={index}>
          <NavLink to={action.destination}>{action.caption}</NavLink>
        </div>
      })}
    </div>);
  }, [breadCrumbItems]);

  return (
    <nav className="app-header">
      <>
        <div className="navbar navbar-light">
          <ul className="navbar-nav flex-row">
            <li className="nav-item dropdown find-all-box-item" ref={anchorRef}>
              <NaVaForm initialValues={{ command: "" }} onSubmit={() => { }}>
                <ComboBox className="find-all-box" name="command" placeholder={L('SearchSomething...')}></ComboBox>
              </NaVaForm>
            </li>
            <li className="nav-item dropdown" ref={anchorRef}>
              <span className="nav-link dropdown-toggle" style={{ cursor: 'pointer' }} id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => !resetShow && setShow(s => !s)}>
                {isAuthenticated && user
                  ? (sessionInfo && sessionInfo.result.tenant ? sessionInfo.result.tenant.tenancyName + '\\' : '') + user.name
                  : 'Unknown'
                }
              </span>
            </li>
          </ul>
          <Popup
            anchor={anchor.current ? anchor.current : undefined}
            show={show}
            popupClass={'popup-content'}
            style={{ zIndex: 9999 }}
            {...POPUP_ALIGNMENT}
          >
            {/* style={{ width: popupWidth }} */}
            <div className="profile-popup" ref={popupRef}>
              <header>
                <div className="user-data">

                  {profilePicture &&
                    <img src={'data:image/jpeg;base64,' + profilePicture} alt="Profile" />
                  }

                  <div>
                    <h2>{sessionInfo?.result?.user?.name} {sessionInfo?.result?.user?.surname}</h2>
                    <p>{sessionInfo?.result?.user?.emailAddress}</p>
                    <Button color="secondary" label={L('ChangePassword')} xs={10} offset={1} onClick={(_: any) => showChangePassword()}></Button>
                  </div>
                </div>
                {/* <div className="mt-2">
                <Button color="light" label={L('SessionProperties')} xs={8} offset={2} onClick={(_: any) => showSessionPropertiesEditor()} />
              </div> */}
              </header>
              <div className="content">
                <h3 className="text-center">{sessionInfo?.result?.tenant?.name}</h3>
                <Button color="primary" label={L('Logout')} xs={6} offset={3} onClick={(_: any) => processLogout()}></Button>
              </div>
            </div>
          </Popup>
        </div>
        {<Breadcrumb />}
      </>

      {isChangePasswordVisible && <Dialog title={L('ChangePassword')} onClose={hideChangePassword}>
        <ChangePassword onComplete={() => hideChangePassword()} />
      </Dialog>}

    </nav >
  );
};

export default Header;
