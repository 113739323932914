import { useState, useCallback, useEffect } from 'react';
import { DataViewError, DataViewLoading, DataViewMessageProps, DataViewNoData } from '../components/data-view-message/DataViewMessage';

// startLoading(), loadingComplete(), loadingError(), isLoading, dataViewMessageData
export type UseDataLoadingStateResult = [
    (loader?: string) => void,
    (data?: any[], loader?: string) => void,
    (error: string, loader?: string) => void,
    boolean,
    DataViewMessageProps | undefined
];

export default function useDataLoadingState(): UseDataLoadingStateResult {
    const [loaders, setLoaders] = useState<string[]>([]);
    const [data, setData] = useState<any[] | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataViewMessageData, setDataViewMessageData] = useState<DataViewMessageProps | undefined>(undefined);

    const updateState = useCallback(() => {
        const loading = loaders.length > 0;
        setIsLoading(loading);

        if (loading) {
            setDataViewMessageData(DataViewLoading);
        }
        else if (error) {
            setDataViewMessageData({ ...DataViewError, message: error });
        }
        else if (!data || data.length < 1) {
            setDataViewMessageData(DataViewNoData);
        } else {
            setDataViewMessageData(undefined);
        }

    }, [loaders, data, error])

    useEffect(() => {
        updateState();
    }, [updateState])

    const startLoading = useCallback((loader: string = 'x') => {
        setLoaders((l) => {
            if (l.find(x => x === loader))
                return l;

            l.push(loader);
            return [...l];
        });
    }, []);

    const loadingComplete = useCallback((data: any[] = [0], loader: string = 'x') => {
        setLoaders((l) => {
            if (!l.find(x => x === loader))
                return l;

            l = l.filter(x => x !== loader);
            return [...l];
        });

        setData(data);
        setError(undefined);
    }, []);

    const loadingError = useCallback((error: string, loader: string = 'x') => {
        setLoaders((l) => {
            if (!l.find(x => x === loader))
                return l;

            l = l.filter(x => x !== loader);
            return l;
        });

        setData(undefined);
        setError(error);
    }, []);

    return [startLoading, loadingComplete, loadingError, isLoading, dataViewMessageData];
}
