import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Rewind365Logo } from '../../../icons/rewind365-logo.svg';
import { ReactComponent as Rewind365MinimalLogo } from '../../../icons/logo-minimal-x.svg';

const RewindLogo: React.FC<{ collapsed: boolean }> = ({ collapsed = false }) => {

    const navigate = useNavigate();

    return <header onClick={() => navigate('/')}>
        {collapsed ? <>
            <Rewind365MinimalLogo />
        </> :
            <>
                <Rewind365Logo style={{position:'relative', top: '.25rem'}}/>
                <h1>Zeitmanagement</h1>
            </>
        }
    </header>
}

export default RewindLogo;