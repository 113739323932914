import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { L } from '../../../../abp/utils';
import { ServiceProviderDto, ServiceProvidersApi, SettingsApi } from '../../../../client/http';
import IconButton from '../../../../common/components/icon/IconButton';
import Button from '../../../../common/forms/controls/button/Button';
import ComboBox from '../../../../common/forms/validation/controls/combo-box/ComboBox';
import Dropdown from '../../../../common/forms/validation/controls/dropdown/Dropdown';
import Input from '../../../../common/forms/validation/controls/input/Input';
import NaVaForm from '../../../../common/forms/validation/na-va-form/NaVaForm';
import { useNaVaFormContext } from '../../../../common/forms/validation/na-va-form/NaVaFormContext';
import useAuthConfiguration from '../../../../common/hooks/useAuthConfiguration';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import './ServiceProviderSelection.scss';
import ModuleComponentProvider from '../../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../../../modules/AppParts';
import { log } from '../../../../util/LoggingUtils';

const CURRENT_SERVICE_CONNECTION_ID = "serviceConnectionId"
const CURRENT_SERVICE_CONNECTION = "serviceConnection"

const providerLogo = (providerName: string) => {
    return <ModuleComponentProvider appPartName={AppParts.Application.ProviderLogo(providerName)} getSingleComponent={true} />
}

export const ServiceProviderItemRender = (li: any, itemProps: any) => {
    const index = itemProps.index;
    const itemChildren = (
        <div className="service-provider-selection-item" title={itemProps.dataItem.serviceConnection.description}>
            <div className="icon">{providerLogo(itemProps.dataItem.providerName)}</div>
            <div className="data">

                <div className="service-provider">
                    {L(itemProps.dataItem.providerName)}
                </div>
                <div className="service-connection">
                    <div>{itemProps.dataItem.serviceConnection.displayName}</div>
                    {/* <div>{itemProps.dataItem.serviceConnection.description}</div> */}
                </div>
            </div>
        </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
};

const ValueHandler: React.FC<{ value: any, name: string }> = ({ value, name }) => {
    const { values, setFieldValue } = useNaVaFormContext();

    useEffect(() => {
        setFieldValue && name && !values[name]?.serviceConnectionId && setFieldValue(name, value);
    }, [setFieldValue, value, name, values]);

    useEffect(() => {
        values[CURRENT_SERVICE_CONNECTION] && setFieldValue(CURRENT_SERVICE_CONNECTION_ID, values[CURRENT_SERVICE_CONNECTION].serviceConnectionId)
    }, [values, setFieldValue]);
    return <></>
}

const ServiceProviderSelection: React.FC<{ className?: string }> = ({ className }) => {
    const [providers, setProviders] = useState<ServiceProviderDto & { serviceConnectionName: string, serviceConnectionId: number }[]>([]);
    const authConfig = useAuthConfiguration();
    const serviceProvidersClient = useMemo(() => new ServiceProvidersApi(authConfig, authConfig?.basePath), [authConfig]);
    const settingsApiClient = useMemo(() => new SettingsApi(authConfig, authConfig?.basePath), [authConfig]);
    const [defaultConnection, setDefaultConnection] = useState({});

    const mapProviders = useCallback((data: any) => {
        const result: any[] = [];

        data.map((sp: any) => {
            sp.serviceConnections.map((sc: any) => {
                result.push({ ...sp, serviceConnection: sc, serviceConnectionName: sc.displayName, serviceConnectionId: sc.id, serviceConnections: undefined });
            });
        });

        return result;
    }, []);

    useEffect(() => {
        //@ts-ignore
        authConfig?.basePath && serviceProvidersClient.apiServicesAppServiceProvidersGetServiceProvidersGet(true).then(d => setProviders(mapProviders(d.result)));
    }, [authConfig, setProviders, mapProviders, serviceProvidersClient]);

    useEffect(() => {
        const connectionId = abp.setting.get("App.TimeTracking.CurrentServiceConnection");
        const defaultVal = providers.find(p => p.serviceConnectionId.toString() === connectionId);
        log("default service connection", connectionId, defaultVal);
        defaultVal && setDefaultConnection(defaultVal);
    }, [providers]);

    const saveChanges = useCallback(async (serviceConnectionId: number | string, serviceProviderName: string) => {
        await Promise.all([settingsApiClient.apiServicesAppSettingsUpdateUserSettingPut(abp.session.tenantId, abp.session.userId, Settings_ServiceConnectionId, serviceConnectionId.toString()),
        settingsApiClient.apiServicesAppSettingsUpdateUserSettingPut(abp.session.tenantId, abp.session.userId, Settings_ProviderName, serviceProviderName)]);

        window.location.reload();

    }, [settingsApiClient]);

    const TitleWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
        const { values } = useNaVaFormContext();

        return <span title={values && values[CURRENT_SERVICE_CONNECTION]?.serviceConnectionName}>
            {children}
        </span>
    };

    return (
        <div className={"service-provider-selection" + (className ? ' ' + className : '')}>
            <NaVaForm initialValues={{
                [CURRENT_SERVICE_CONNECTION]: undefined,
                [CURRENT_SERVICE_CONNECTION_ID]: 0
            }}
                enableReinitialize={true}
                onSubmit={(values) => { saveChanges(values[CURRENT_SERVICE_CONNECTION_ID], values[CURRENT_SERVICE_CONNECTION].providerName) }}>

                <div className="inner">
                    <ValueHandler name={CURRENT_SERVICE_CONNECTION} value={defaultConnection} ></ValueHandler>
                    <TitleWrapper>
                        <Dropdown name={CURRENT_SERVICE_CONNECTION} data={providers} textField="serviceConnectionName" dataItemKey="serviceConnectionId" itemRender={ServiceProviderItemRender} className="col" />
                    </TitleWrapper>
                    <IconButton name="icon-hexagon-synchronize-" title={L("SaveChanges")} buttonType="submit" />
                </div>

            </NaVaForm >
        </div >
    )
}

export default ServiceProviderSelection;
