import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ActionColumnActionType, PlmControlColumn, PlmControlSection, PlmDataControlProps, dispatchEndEdit } from "../template-management/plm-job-list/components/PlmControlComponents";
import { deleteItem, saveItem } from "../data/dataMethods";
import usePlmControlContext from "../template-management/plm-job-list/plm-control-context/usePlmControlContext";
import { getIn } from "../../../common/forms/validation/na-va-form/commonUtils";
import { FIELD_ID } from "../../../data/field-constants/GeneralConstants";
import useCrudApi from "../../../common/hooks/useCrudApi";
import { Settings_ProviderName, Settings_ServiceConnectionId } from "../../../util/SettingNames";
import { L } from "../../../abp/utils";


// internalData, setInternalDta, handleChange
export type UseInternalPlmControlDataResult<T extends { [key: string]: any } & { extensions?: any }> = [
    T | undefined,
    Dispatch<SetStateAction<T | undefined>>,
    (col: PlmControlColumn, value: any) => void,
    (action: ActionColumnActionType) => void,
    (item?: T) => void
];

type ApiArgs<T, TApi> = Parameters<typeof useCrudApi<T, TApi>>; // eslint-disable-line

type ItemType = { [key: string]: any } & { extensions?: any }

type ApiCallType<T, TApi> = (c: TApi) => (body?: T, options?: any) => Promise<any>;

export default function useInternalPlmControlData<T extends ItemType, TApi>(
    baseItem: T,
    parentProperty: { [key: string]: any },
    section: PlmControlSection,
    apiFunc: ApiArgs<T, TApi>[0],
    createFunc: ApiCallType<T, TApi>,
    updateFunc: ApiCallType<T, TApi>,
    deleteFunc: ApiCallType<T, TApi>,
    refetch?: PlmDataControlProps['refetch'],
    onHandleAction?: (action: ActionColumnActionType) => void,
    onSave?: (item: T, successful: boolean) => void,
): UseInternalPlmControlDataResult<T> {

    const create = useCallback((c: TApi, d: T) => {
        const boundCreate = createFunc(c).bind(c);
        return boundCreate({
            ...d,
            extensionData: JSON.stringify(d.extensions),
            serviceProvider: d.serviceProvider ?? abp.setting.get(Settings_ProviderName),
            serviceConnectionId: d.serviceConnectionId ?? abp.setting.getInt(Settings_ServiceConnectionId)
        });
    }, [createFunc]);

    const update = useCallback((c: TApi, d: T) => {
        const boundUpdate = updateFunc(c).bind(c);
        return boundUpdate({
            ...d,
            extensionData: JSON.stringify(d.extensions),
            serviceProvider: d.serviceProvider ?? abp.setting.get(Settings_ProviderName),
            serviceConnectionId: d.serviceConnectionId ?? abp.setting.getInt(Settings_ServiceConnectionId)
        });
    }, [updateFunc]);

    const delete_ = useCallback((c: TApi, d: T) => {
        const boundDelete = deleteFunc(c).bind(c);
        return boundDelete(d);
    }, [deleteFunc]);

    return useInternalPlmControlDataApi(baseItem, parentProperty, section, apiFunc, create, update, delete_, refetch, onHandleAction, onSave);
}

export function useInternalPlmControlDataApi<T extends ItemType, TApi>(
    baseItem: T,
    parentProperty: { [key: string]: any },
    section: PlmControlSection,
    apiFunc: ApiArgs<T, TApi>[0],
    createFunc: ApiArgs<T, TApi>[2],
    updateFunc: ApiArgs<T, TApi>[4],
    deleteFunc: ApiArgs<T, TApi>[5],
    refetch?: PlmDataControlProps['refetch'],
    onHandleAction?: (action: ActionColumnActionType) => void,
    onSave?: (item: T, successful: boolean) => void,
): UseInternalPlmControlDataResult<T> {

    const [internalData, setInternalData] = useState<T | undefined>(undefined);
    const { dispatch } = usePlmControlContext();

    useEffect(() => {
        setInternalData(baseItem);
    }, [baseItem])

    const handleChange = useCallback((col: PlmControlColumn, value: any) => {
        setInternalData(d =>
            col?.isExtensionField ? ({
                ...d,
                extensions: {
                    ...(d as any)?.extensions ?? {},
                    [col?.name]: value
                }
            }) as any : ({
                ...d,
                [col?.name]: value
            }) as any
        );
    }, []);

    const [, api, create, , update, delete_] = useCrudApi<T, TApi>(apiFunc, undefined, createFunc, undefined, updateFunc, deleteFunc);

    const handleSave = useCallback(async (item?: T) => {
        var successful = await saveItem(item ?? internalData as T, parentProperty, api, create, update)

        if (successful) {

            dispatch(dispatchEndEdit(section));

            refetch?.();

            if (item) {
                setInternalData(item);
            }
        }

        onSave && onSave(item ?? internalData ?? {} as T, successful);
    }, [internalData, api, parentProperty, section, setInternalData, dispatch, onSave, create, update, refetch]);

    const handleCancelEdit = useCallback(() => {
        dispatch(dispatchEndEdit(section));

        refetch?.();
    }, [section, dispatch, refetch]);

    const handleDelete = useCallback(async (item?: T) => {
        if (!window.confirm(L('ConfirmDeleteDialog'))) return;

        var successful = await deleteItem(item ?? internalData, api, delete_);

        if (successful) {
            refetch?.();
        }
    }, [internalData, api, delete_, refetch]);

    const handleAction = useCallback((action: ActionColumnActionType) => {
        switch (action) {
            case 'save':
                handleSave();
                break;
            case 'cancel':
                handleCancelEdit();
                break;
            case 'delete':
                handleDelete();
                break;
        }

        onHandleAction && onHandleAction(action);
    }, [handleSave, handleCancelEdit, handleDelete, onHandleAction]);

    return [internalData, setInternalData, handleChange, handleAction, handleSave];
}