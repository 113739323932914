import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { L } from '../../abp/utils';
import { AdministrationApi, CreateUserInput, Resource, ServiceConnectionDto, ServiceProviderDto, ServiceProvidersApi } from '../../client/http';
import Icon from '../../common/components/icon/Icon';
import Button from '../../common/forms/controls/button/Button';
import NaVaFormStatePuller from '../../common/forms/NaVaFormStatePuller';
import ComboBox from '../../common/forms/validation/controls/combo-box/ComboBox';
import Dropdown from '../../common/forms/validation/controls/dropdown/Dropdown';
import Input from '../../common/forms/validation/controls/input/Input';
import Switch from '../../common/forms/validation/controls/switch/Switch';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import { NaVaFormContextType, NaVaFormValues } from '../../common/forms/validation/na-va-form/types';
import useApi from '../../common/hooks/useApi';
import useVisibility from '../../common/hooks/useVisibility';
import ModuleComponentProvider from '../../module-components/module-context/ModuleComponentProvider';
import { ResourceSelectionProps } from '../../module-components/service-entities/ServiceEntityControlProps';
import { AppParts } from '../../modules/AppParts';
import { ServiceProviderItemRender } from '../protected-area/side-nav/service-provider-selection/ServiceProviderSelection';
import { showNotification } from '../../common/components/notifications/NotificationHost';

const UserEditor: React.FC = () => {

    const [{ values, setFieldValue, resetForm }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const [copiedVisible, showCopied, hideCopied] = useVisibility(false);

    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!?-@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = values?.passwordLength;

    const generatePassword = useCallback(() => {
        let password = '';
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }

        setFieldValue && setFieldValue('password', password);
    }, [passwordLength, setFieldValue]);

    useEffect(() => { generatePassword() }, [generatePassword]);

    const copyPassword = useCallback((id: string) => {
        var copyText = document.getElementById(id) as HTMLInputElement;
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
        showCopied();
        setTimeout(() => hideCopied(), 2500);
    }, [showCopied, hideCopied]);

    const [, adminApi] = useApi<any, AdministrationApi>(c => new AdministrationApi(c));
    const [serviceProviders] = useApi<ServiceProviderDto, ServiceProvidersApi>(c => new ServiceProvidersApi(c), c => c.apiServicesAppServiceProvidersGetServiceProvidersGet());

    const serviceConnections = useMemo(() => (serviceProviders as any).reduce((prev: any, current: any) => {
        return current?.serviceConnections ? [...prev,
        ...current.serviceConnections.map((c: ServiceConnectionDto) => ({ ...current, serviceConnectionName: c.displayName, serviceConnectionId: c.id, serviceConnection: c, serviceConnections: undefined }))] : prev
    }, []), [serviceProviders]);

    const mapResource = values?.mapResource;
    const mapEmail = values?.mapEmail;

    const createUser = useCallback((values: NaVaFormValues) => {
        const data = {
            userName: values.username,
            name: values.name,
            surname: values.surname,
            emailAddress: values.email,
            password: values.password,
            isActive: values.isActive,
            roleNames: [values.roleName],
            mappingInputs: []
        } as CreateUserInput;

        if (mapEmail) {

            // User and tenant id will be set to the id of the created user
            // $userId$ will also be replaced by the new user's id
            data.mappingInputs?.push({
                value: values.email,
                type: "System.String",
                serviceConnectionId: values.mapEmailSource.serviceConnectionId,
                serviceProviderName: values.mapEmailSource.providerName
            },
                {
                    value: "$userId$",
                    type: "System.Int64",
                    serviceConnectionId: values.mapEmailTarget.serviceConnectionId,
                    serviceProviderName: values.mapEmailTarget.providerName
                });

            data.mappingName = "ResourceMapping";
        }

        if (mapResource) {

            // User and tenant id will be set to the id of the created user
            // $userId$ will also be replaced by the new user's id
            data.mappingInputs?.push({
                value: "$userId$",
                type: "System.Int64",
                serviceConnectionId: values.mapResourceSource.serviceConnectionId,
                serviceProviderName: values.mapResourceSource.providerName
            },
                {
                    value: (values.resource as Resource).id,

                    type: "System.String",
                    serviceConnectionId: values.mapResourceTarget.serviceConnectionId,
                    serviceProviderName: values.mapResourceTarget.providerName
                });

            data.mappingName = "ResourceMapping";
        }

        adminApi?.apiServicesAppAdministrationAddUserPost(data, abp?.session?.tenantId)
            .then(d => showNotification("Benutzer erstellt", `Benutzer ${data.userName} erfolgreich erstellt.`, 'success', undefined, true))
            .catch(d => showNotification("Benutzer erstellen fehlgeschlagen", `Benutzer ${data.userName} konnte nicht erstellt werden.`, 'error', undefined, true));
    }, [adminApi, mapResource, mapEmail]);


    return <div className="user-editor p-4 pt-0">
        <NaVaForm
            initialValues={{
                name: '',
                surname: '',
                email: '',
                username: '',
                roleName: 'User',
                passwordLength: 12,
                tenantId: abp?.session?.tenantId,
                isActive: true,
                mapResource: true,
                resource: null,
                mapResourceSource: null,
                mapResourceTarget: null,
                mapEmail: false,
                mapEmailSource: null,
                mapEmailTarget: null
            }}
            onSubmit={(v) => { createUser(v) }}>
            <NaVaFormStatePuller onStateChanged={(c) => setFormState(c)} />
            <div className="row">

                <div className="col-sm-12 col-lg-4">
                    <h5>{L('UserData')}</h5>
                    <Input name="tenantId" label={L('TenantId')} sm={12} lg={8} disabled={true} />
                    <Input name="name" label={L('Name')} sm={12} lg={8} />
                    <Input name="surname" label={L('Surname')} sm={12} lg={8} />
                    <Input name="username" label={L('Username')} sm={12} lg={8} />
                    <Input name="email" label={L('Email')} sm={12} lg={8} type="email" />
                    <Switch name="isActive" label={L('IsActive')} className="my-2 mx-2" />
                    <ComboBox name="roleName" label={L('UserRole')} sm={12} lg={8} data={['Administration', 'User']} />
                </div>

                <div className="col-sm-12 col-lg-8">

                    <h5>{L('Password')}</h5>
                    <div className="row col-12 mx-0 my-3">

                        <Input name="password" label={L('Password')} sm={12} id="password-input" />

                        <div className="row my-2 mx-0 px-0">
                            <Input name="passwordLength" type="number" sm={4} />
                            <Button color="light" label={L('Generate')} onClick={() => generatePassword()} sm={4} />
                            <Button color="light" label="" onClick={() => copyPassword('password-input')} sm={4} >
                                {copiedVisible ?
                                    <span className="text-success">
                                        {L('Copied')}&ensp;
                                        <Icon name="icon-checked" color="var(--bs-success)" size="1rem" style={{ position: 'relative', bottom: '.1rem' }} />
                                    </span> : L('Copy')}
                            </Button>
                        </div>

                    </div>


                    <h5>{L('UserMapping')}</h5>

                    <Switch name="mapResource" label={L('MapUserResource')} className="my-2 mt-3" />
                    <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Resource} props={{ name: 'resource', foreignKeyName: 'resourceId', label: 'Ressource', sm: 12, lg: 8, disabled: !mapResource } as ResourceSelectionProps} />
                    <div className="row mx-0 my-2 align-items-center">
                        {L('MapFrom')}
                        <Dropdown name="mapResourceSource" textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} disabled={!mapResource} itemRender={ServiceProviderItemRender} />
                        {L('to_Item')}
                        <Dropdown name="mapResourceTarget" textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} disabled={!mapResource} itemRender={ServiceProviderItemRender} />
                    </div>

                    <Switch name="mapEmail" label={L('MapUserEmail')} className="my-2 mt-3" />
                    <div className="row mx-0 my-2 align-items-center">
                        {L('MapFrom')}
                        <Dropdown name="mapEmailSource" textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} disabled={!mapEmail} itemRender={ServiceProviderItemRender} />
                        {L('to_Item')}
                        <Dropdown name="mapEmailTarget" textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} disabled={!mapEmail} itemRender={ServiceProviderItemRender} />
                    </div>
                </div>
            </div>
            <div className="row mt-5 me-2 justify-content-end">
                <Button color="secondary" label={L("ResetForm")} sm={2} onClick={() => { resetForm(); generatePassword(); }} />
                <Button color="primary" label={L("Save")} sm={2} type="submit" />
            </div>
        </NaVaForm>
    </div>
}

export default UserEditor;