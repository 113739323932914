import React, { ReactChild, useCallback, useEffect, useState } from 'react';
import { getUserSessionInformations, SessionInformation } from '../../../abp/getUserConfiguration';
import { L } from '../../../abp/utils';
import { useAuthenticator } from '../../../authentication/authenticator/Authenticator';
import { SettingDefinition, SettingsApi } from '../../../client/http';
import Icon from '../../../common/components/icon/Icon';
import IconButton from '../../../common/components/icon/IconButton';
import Button from '../../../common/forms/controls/button/Button';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import Input from '../../../common/forms/validation/controls/input/Input';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import useSettings from '../../../common/hooks/useSettings';
import { log } from '../../../util/LoggingUtils';

export type SettingProps = {
    isEditable: boolean;
    editMode?: boolean;
    name: string;
    // value?: string;
    editForm?: (name: string, updateValue: () => void, isEditable: boolean) => ReactChild;
    level: SettingLevel;
    settingDefinition?: SettingDefinition
}

export type SettingLevel = "User" | "Application" | "Tenant";

const Setting: React.FC<SettingProps> = ({ isEditable, editMode = true, name, editForm, level }) => {
    const normalizedName = name.replaceAll('.', '$');
    // const [value, setValue] = useState<string>();
    const [isEditMode, setIsEditMode] = useState(editMode);

    const [{ values, setFieldValue }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const { getSetting, updateSetting } = useSettings();
    useEffect(() => { getSetting({ name, level: level })?.then(r => { log(r); setFieldValue(normalizedName, r.result as any) }) }, [getSetting, setFieldValue, name, normalizedName, level]);

    // const updateValue = useCallback(() => {
    //     switch (mode) {
    //         case 'Application':
    //             //@ts-ignore
    //             settingsApi.apiServicesAppSettingsGetApplicationSettingGet(name).then(val => setValue(val.result));
    //             break;
    //         case 'Tenant':
    //             //@ts-ignore
    //             settingsApi.apiServicesAppSettingsGetTenantSettingGet(name, tenant.id).then(val => setValue(val.result));
    //             break;
    //         case 'User':
    //             //@ts-ignore
    //             settingsApi.apiServicesAppSettingsGetUserSettingGet(name, tenant.id, user.id).then(val => setValue(val.result));
    //             break;
    //     }
    // }, [mode, name, settingsApi, user, tenant]);

    return (<div className="setting">
        {(isEditMode) ? <div className="edit-form">
            <NaVaForm initialValues={{ [normalizedName]: undefined }} onSubmit={val => {
                // updateSetting({ name, value: val[name], level });
                // setIsEditMode(false);
                // updateValue();
            }}>
                <NaVaFormStatePuller onStateChanged={setFormState}></NaVaFormStatePuller>
                <div className="d-flex">
                    <div className="flex-grow-1">
                        {editForm ? editForm(normalizedName, () => updateSetting({ name, value: getIn(values, normalizedName), level }), isEditable) : <Input name={normalizedName} label={L(name)} disabled={!isEditable}></Input>}
                    </div>
                    {/* <div>
                        <IconButton name="icon-check-file" buttonType="submit"></IconButton>
                        <IconButton name="icon-cancel" onClick={() => { setIsEditMode(false); updateValue(); }}></IconButton>
                    </div> */}
                </div>
            </NaVaForm>
        </div> : <div className="readonly">
            <span className='name'>{name}</span> <span className="value">{getIn(values, normalizedName)}</span>
            {isEditable && <IconButton onClick={_ => setIsEditMode(true)} name='icon-ball-point-pen' />
            }
        </div>
        }
    </div >)
}

export default Setting;