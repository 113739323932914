import React, { useEffect, useMemo, useState } from 'react';
import { L } from '../../abp/utils';
import { ReportOptionDto, ReportOptionsApi, ReportTemplateDto, ReportTemplatesApi } from '../../client/http';
import Button from '../../common/forms/controls/button/Button';
import Input from '../../common/forms/validation/controls/input/Input';
import MultiSelect from '../../common/forms/validation/controls/multi-select/MultiSelect';
import Switch from '../../common/forms/validation/controls/switch/Switch';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import useApi from '../../common/hooks/useApi';
import useAuthConfiguration from '../../common/hooks/useAuthConfiguration';
import { FIELD_DESCRIPTION, FIELD_ID, FIELD_IDENTIFIER, FIELD_IS_ENABLED, FIELD_NAME, FIELD_TENANT_ID } from '../../data/field-constants/GeneralConstants';
import { FIELD_DEFAULT_VALUE, FIELD_DISPLAY_CONTROL_TYPE, FIELD_IS_FILTER, FIELD_TEMPLATE_ID, FIELD_TYPE, FIELD_VALUES_FILTER } from '../../data/field-constants/ReportOptionConstants';

const ReportManagement: React.FC<{}> = () => {

    const [data, reportApi] = useApi<ReportTemplateDto, ReportTemplatesApi>((c) => new ReportTemplatesApi(c), a => a.apiServicesAppReportTemplatesGetAllGet());

    const [selectedTemplate, setSelectedTemplate] = useState<ReportTemplateDto | undefined>(undefined);

    // const authConfig = useAuthConfiguration();
    // const apiClient = useMemo(() => new ReportTemplatesApi(authConfig), [authConfig]);
    // const [data, setData] = useState<ReportTemplate[]>([]);

    // useEffect(() => {
    //     apiClient.apiServicesAppReportTemplatesGetAllGet().then((val: any) => setData(val.result)).catch(() => { });
    // }, [apiClient])

    return (<div className="report-editor p-3">
        <h1>Berichtsverwaltung</h1>

        <div className="templates-list">
            <h3>Gespeicherte Vorlagen</h3>

            <Button color="primary" label="Neue Vorlage erstellen" sm={3}></Button>



            {data && data.length > 0 ?
                <div className="items">

                </div> :
                <p>Keine Elemente vorhanden</p>
            }
        </div>


    </div>)
}

const ReportTemplateContainer: React.FC<{template: ReportTemplateDto}> = ({template}) =>{

return <div className="report-template"></div>
}

const ReportOptionContainer: React.FC<{ templateId: number, id: number }> = ({ templateId, id }) => {

    const authConfig = useAuthConfiguration();
    const apiClient = useMemo(() => new ReportOptionsApi(authConfig), [authConfig]);
    const [data, setData] = useState<ReportOptionDto[]>([]);

    useEffect(() => {
        apiClient.apiServicesAppReportOptionsGetAllGet().then((val: any) => setData(val.result)).catch(() => { });
    }, [apiClient])

    const [initialValues, setInitialValues] = useState<ReportOptionDto | undefined>(undefined);
    useEffect(() => {
        if (!id) {
            setInitialValues({
                [FIELD_ID]: id,
                [FIELD_IDENTIFIER]: "",
                [FIELD_NAME]: "",
                [FIELD_DESCRIPTION]: "",
                [FIELD_DEFAULT_VALUE]: "",
                [FIELD_VALUES_FILTER]: "",
                [FIELD_IS_FILTER]: false,
                [FIELD_IS_ENABLED]: true,
                [FIELD_TYPE]: "",
                [FIELD_DISPLAY_CONTROL_TYPE]: "",
                [FIELD_TEMPLATE_ID]: templateId
            }
            )
        } else if (apiClient) {
            apiClient.apiServicesAppReportOptionsGetAllGet().then((val: any) => setInitialValues(val.result));
        }
    }, [apiClient])


    return <div className="report-option">
        {initialValues &&
            <NaVaForm
                initialValues={initialValues}
                onSubmit={() => { }}>
                <Input name={FIELD_IDENTIFIER} type="text" label={L("Identifier")} />
                <Input name={FIELD_NAME} type="text" label={L("Name")} />
                <Input name={FIELD_DESCRIPTION} type="text" label={L("Description")} />
                <Input name={FIELD_DEFAULT_VALUE} type="text" label={L("DefaultValue")} />
                <MultiSelect name={FIELD_VALUES_FILTER} allowCustom={true} label={L("ValuesFilter")} />
                <Switch name={FIELD_IS_FILTER} label={L("IsFilter")} />
                <Switch name={FIELD_IS_ENABLED} label={L("IsFilter")} />
                <Input name={FIELD_TYPE} type="text" label={L("Type")} />
                <Input name={FIELD_DISPLAY_CONTROL_TYPE} type="text" label={L("DisplayControlType")} />
            </NaVaForm>
        }
    </div>
}

export default ReportManagement;