import { defineFont } from '@progress/kendo-drawing/pdf';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import { PDFExport } from '@progress/kendo-react-pdf';
import _, { startsWith, values } from 'lodash';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { L } from '../../abp/utils';
import { CreateReportInput, Job, ReportDataEntryDto, ReportDto, ReportOptionDto, ReportsApi, ReportTemplateDto, ReportTemplatesApi } from '../../client/http';
import DataViewMessage from '../../common/components/data-view-message/DataViewMessage';
import Icon from '../../common/components/icon/Icon';
import Button from '../../common/forms/controls/button/Button';
import NaVaFormStatePuller from '../../common/forms/NaVaFormStatePuller';
import ComboBox from '../../common/forms/validation/controls/combo-box/ComboBox';
import DatePicker from '../../common/forms/validation/controls/date-picker/DatePicker';
import Input from '../../common/forms/validation/controls/input/Input';
import Switch from '../../common/forms/validation/controls/switch/Switch';
import { getIn } from '../../common/forms/validation/na-va-form/commonUtils';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import { NaVaFormContextType, NaVaFormValues } from '../../common/forms/validation/na-va-form/types';
import useApi from '../../common/hooks/useApi';
import useDataLoadingState from '../../common/hooks/useDataLoadingState';
import { FIELD_DATE_FROM, FIELD_DATE_TO } from '../../data/field-constants/FilterConstants';
import ComponentProvider from '../../module-components/ComponentProvider';
import ModuleComponentProvider from '../../module-components/module-context/ModuleComponentProvider';
import { DateTimeMaxValue, DateTimeMinValue } from '../../util/DateTimeUtils';
import filter from '../../util/FilterUtils';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../util/SettingNames';
import ReportDataView from './ReportDataView';
import { mapOptionValuesToType, parseOptionDefaultValue } from './ReportOptionControl';

import './ReportWizard.scss';
import { lastOrDefault } from '../../util/EntityUtils';
import { BootstrapWidth } from '../../common/forms/controls/control-wrapper/ControlWrapper';
import { log } from '../../util/LoggingUtils';

export type ReportOptionAdditionalData = {
    isRequiredForRequest: boolean;
    missingMessage: string;
    mapFromProperty: string;
    mapToProperty: string;
    isInternal: boolean;
    placeholder?: string;
    sm?: BootstrapWidth;
    md?: BootstrapWidth;
    lg?: BootstrapWidth;
    xl?: BootstrapWidth;
    xxl?: BootstrapWidth;
}

type ProcessedReportOption = ReportOptionDto & {
    additionalData: ReportOptionAdditionalData;
    isValid: (data: any) => boolean;
    getFilter: (data: any) => any;
}

const processOptions = (options: ReportOptionDto[]): ProcessedReportOption[] => {
    const result: ProcessedReportOption[] = [];

    for (let option of options) {
        const additionalData: ReportOptionAdditionalData = option.description ? JSON.parse(option.description) : {
            isRequiredForRequest: false
        };

        result.push({
            ...option,
            additionalData,
            isValid: (data: any) => {
                return additionalData.isRequiredForRequest && !getIn(data, [option.identifier!, additionalData.mapFromProperty]) ? false : true;
            },
            getFilter: (data: any) => {
                const [controlType] = option.displayControlType?.split('$') ?? [];
                if (option.defaultValue && !controlType) {
                    // For hidden filters with no display control type, return default value
                    return JSON.parse(option.defaultValue)
                }
                else {
                    let value = getIn(data, additionalData.mapFromProperty ? [option.identifier!, additionalData.mapFromProperty] : option.identifier!);
                    if (value === undefined && option.type?.toLowerCase() === 'system.boolean') {
                        value = false;
                    }
                    if (value !== undefined) {
                        switch (controlType.toLowerCase()) {
                            case 'input':
                                return filter(additionalData.mapToProperty).contains(value);
                            case 'switch':
                                const matchSeparator = ((option.defaultValue ?? '').match(/$/g) || []).length;
                                const escapedSeparator = ((option.defaultValue ?? '').match(/\\$/g) || []).length;
                                const val = escapedSeparator === 0 ? option.defaultValue : option.defaultValue?.replaceAll('\\$', '$');
                                // When a single value is set only return a filter, when Switch is set to true
                                if (matchSeparator === 0 || matchSeparator === escapedSeparator) {
                                    return value ? JSON.parse(val ?? '{}') : null;
                                }
                                else {
                                    const [trueVal, falseVal] = (val ?? '{}${}').split('$');
                                    return value ? JSON.parse(trueVal) : JSON.parse(falseVal);
                                }
                            default:
                                return filter(additionalData.mapToProperty).equals(value);
                        }
                    }
                }

                return undefined;
            }

        })
    }

    return result;
}

const sizing = (size: { sm?: BootstrapWidth, md?: BootstrapWidth, lg?: BootstrapWidth, xl?: BootstrapWidth, xxl?: BootstrapWidth }, sm?: BootstrapWidth, md?: BootstrapWidth, lg?: BootstrapWidth, xl?: BootstrapWidth, xxl?: BootstrapWidth,) => ({
    sm: size.sm ?? sm,
    md: size.md ?? md,
    lg: size.lg ?? lg,
    xl: size.xl ?? xl,
    xxl: size.xxl ?? xxl
})

const OptionFilter: React.FC<{ option: ProcessedReportOption }> = ({ option }) => {

    const [controlType, data] = option.displayControlType?.split('$') ?? [];
    const { sm, md, lg, xl, xxl } = option.additionalData;
    const size = { sm, md, lg, xl, xxl };

    switch (controlType?.toLowerCase()) {
        case 'input':
            if (!data) {
                return <Input name={option.identifier!} {...sizing(size, 4, undefined, 3, 2)} placeholder={L(option.additionalData.placeholder ?? '')} />;
            }
            return <Input name={option.identifier!} type={data} {...sizing(size, 4, undefined, 3, 2)} />;
        case 'datepicker':
            return <DatePicker name={option.identifier!} {...sizing(size, 4, undefined, 3, 2)} />
        case 'component':
            return <ModuleComponentProvider appPartName={data} getSingleComponent={true}
                props={{
                    name: option.identifier!,
                    // If component is a selection component we will probably only save the foreign key
                    // so we must configure the foreignKeyName prop
                    foreignKeyName: data.includes('Selection') ?
                        (option.additionalData.mapFromProperty ?
                            option.identifier + option.additionalData.mapFromProperty
                            : option.identifier! + 'fk')
                        : undefined
                    ,
                    ...sizing(size, 4, undefined, 3)
                }} />
        case 'switch':
            return <Switch name={option.identifier!} label={option.name} {...sizing(size, 2, undefined, undefined, 1)} />
    }

    return null;
}

const ReportWizard: React.FC = () => {

    // Stepper

    const [step, setStep] = useState(0);
    const [maxStep, setMaxStep] = useState(0);

    useEffect(() => {
        if (step > maxStep)
            setMaxStep(step);
    }, [step, maxStep]);

    const handleStepChange = (e: StepperChangeEvent) => {
        setStep(e.value);
    }

    // API

    const [reportTemplates, templatesApi, templatesDataViewMessageData] = useApi<ReportTemplateDto, ReportTemplatesApi>((c) => new ReportTemplatesApi(c), a => a.apiServicesAppReportTemplatesGetAllGet());

    const [, reportApi] = useApi<any, ReportsApi>((c) => new ReportsApi(c));


    // Incoming state if editing existing report

    const location = useLocation();

    const [report, setReport] = useState<ReportDto | undefined>(undefined);

    useEffect(() => {
        const report = (location?.state as any)?.report as ReportDto;
        const reportTemplate = (location?.state as any)?.reportTemplate as ReportTemplateDto;

        if (report && reportTemplate) {
            setReport(report);
            setSelectedTemplate(reportTemplate);

            const options = {
                ...JSON.parse(lastOrDefault(report.dataEntries)?.optionValues ?? "{}"),
                reportTitle: report.reportTitle,
                reportDate: report.reportDate
            };
            setReportOptions(mapOptionValuesToType(options, reportTemplate.options ?? []));

            setStep(1);
        }
    }, [location]);

    // Step 0
    const [selectedTemplate, setSelectedTemplate] = useState<ReportTemplateDto | undefined>(undefined);

    // Select first template
    useEffect(() => {
        reportTemplates && reportTemplates.length > 0 && setSelectedTemplate(reportTemplates[0]);
    }, [reportTemplates])

    // Step 1
    const [{ values: reportOptionData, setValues }, setReportOptionData] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const [reportOptions, setReportOptions] = useState<any>({});

    const [reportOptionErrors, setReportOptionErrors] = useState<any | undefined>(undefined);

    useEffect(() => {
        // Reinitialize option form values when changing the template
        setValues &&
            setValues({
                reportTitle: selectedTemplate?.name,
                ...selectedTemplate?.options?.filter(o => !o.isFilter && o.defaultValue).reduce((val, value, index) => {
                    return { ...val, [value.identifier!]: parseOptionDefaultValue(value.type!, value.defaultValue!) };
                }, {}),
                ...reportOptions,
                reportDate: new Date()
            });

    }, [selectedTemplate, setValues])

    // Step 2
    const [selectedEntities, setSelectedEntities] = useState<any[] | undefined>(undefined);

    const [reportDataLoading, reportDataLoaded, reportDataLoadError, , reportDataMessageViewData] = useDataLoadingState();

    const [reportData, setReportData] = useState<any[] | undefined>(undefined);

    const reportFilterOptions = useMemo(() => selectedTemplate?.options && selectedTemplate.options.filter(o => o.isFilter && o.isEnabled), [selectedTemplate]);
    const processedReportFilterOptions = useMemo(() => (reportFilterOptions && processOptions(reportFilterOptions)) ?? [], [reportFilterOptions]);

    const [{ values: entityQueryFilters, setValues: setEntityQueryFiltersValues, setFieldValue: setEntityQueryFiltersFieldValue }, setEntityQueryFiltersData] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const invalidEntityQueryValue = useMemo(() => {
        return processedReportFilterOptions.find(o => !o.isValid(entityQueryFilters));
    }, [entityQueryFilters, processedReportFilterOptions])

    // const [entityQueryFilters, setEntityQueryFilters] = useState<{ fromDate: DateTime, toDate: DateTime, job?: Job }>({ fromDate: DateTime.now().minus({ months: 1 }), toDate: DateTime.now(), job: undefined });

    // const jobId = entityQueryFilters.job?.id;
    //const entityFilter = useMemo(() =>
    ///*userResourceId ?*/[filter("isPlanningEntity").equals(false) /*, filter("resourceId").Equals(userResourceId)*/,
    //  filter("jobId").equals(jobId ?? '')] /*: undefined*/,
    // [/*userResourceId*/jobId]);

    // const handleChange = useCallback((values: NaVaFormValues) => {
    //     setEntityQueryFilters({
    //         fromDate: DateTime.fromJSDate(values[FIELD_DATE_FROM]),
    //         toDate: DateTime.fromJSDate(values[FIELD_DATE_TO]),
    //         job: values[FIELD_JOB]
    //     });
    // }, []);

    const refreshEntities = useCallback(() => {
        if (!!reportFilterOptions && !!invalidEntityQueryValue)
            return;

        const fromDateIdentifier = processedReportFilterOptions.find(o => o.identifier!.toLowerCase().endsWith('filter_fromdate'))?.identifier;
        const toDateIdentifier = processedReportFilterOptions.find(o => o.identifier!.toLowerCase().endsWith('filter_todate'))?.identifier;
        const fromDate = fromDateIdentifier ? DateTime.fromJSDate(getIn(entityQueryFilters, fromDateIdentifier)!) : DateTimeMinValue;
        const toDate = toDateIdentifier ? DateTime.fromJSDate(getIn(entityQueryFilters, toDateIdentifier)!) : DateTimeMaxValue;
        const internalFilters = processedReportFilterOptions.filter(o => o.additionalData?.isInternal && !["filter_fromdate", "filter_todate"].find(x => o.identifier!.toLowerCase().endsWith(x))).reduce((arr, o) => [...arr, o.getFilter(entityQueryFilters)], [] as any[]).filter(f => !!f);
        const externalFilters = processedReportFilterOptions.filter(o => !o.additionalData?.isInternal && !["filter_fromdate", "filter_todate"].find(x => o.identifier!.toLowerCase().endsWith(x))).reduce((arr, o) => [...arr, o.getFilter(entityQueryFilters)], [] as any[]).filter(f => !!f);
        // const job = getIn(entityQueryFilters, FIELD_JOB);
        // if (!job)
        //     return;

        log(processedReportFilterOptions.filter(o => !o.additionalData?.isInternal && !["filter_fromdate", "filter_todate"].find(x => o.identifier!.toLowerCase().endsWith(x))), internalFilters?.length ? JSON.stringify(internalFilters) : undefined, externalFilters?.length ? JSON.stringify(externalFilters) : undefined,
            fromDate!.toJSDate(), toDate!.toJSDate());

        reportDataLoading();
        templatesApi?.apiServicesAppReportTemplatesGetReportDataGet(selectedTemplate?.id, report?.id, abp.setting.getInt(Settings_ServiceConnectionId), abp.setting.get(Settings_ProviderName),
            internalFilters?.length ? JSON.stringify(internalFilters) : undefined, externalFilters?.length ? JSON.stringify(externalFilters) : undefined, ['categoryFk', 'jobFk', 'jobTaskFk', 'customerFk', 'resourceFk'],
            fromDate!.toJSDate(), toDate!.toJSDate(), undefined, undefined, 1000, 0, undefined).then((d: any) => {
                setReportData(d.result);
                reportDataLoaded(d.result);
            }).catch((e) => {
                reportDataLoadError((e as any).error?.message ?? '');
            });
    }, [entityQueryFilters, invalidEntityQueryValue, processedReportFilterOptions, selectedTemplate?.id/*selectedTemplate, entityFilter, entityQueryFilters, setReportData*/]);

    // Apply incoming filter state

    // from location state
    useEffect(() => {
        const filters = (location?.state as any)?.filters as any;

        if (filters && Object.keys(filters).length > 0 && setEntityQueryFiltersValues) {
            const x = { ...entityQueryFilters, ...filters };
            // console.log("Initializing filters", entityQueryFilters, filters, x);
            setEntityQueryFiltersValues(x);
        }
        
    }, [location, setEntityQueryFiltersValues]);

    // from query
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const filterVal = searchParams.get('filters');        
        if (filterVal) {
            const filters = JSON.parse(Buffer.from(filterVal, 'base64').toString('utf-8'));            

            if (filters && Object.keys(filters).length > 0 && setEntityQueryFiltersValues) {
                const x = { ...entityQueryFilters, ...filters };
                // console.log("Initializing filters", entityQueryFilters, filters, x);
                setEntityQueryFiltersValues(x);
                setSearchParams({});
            }
        }
    }, [searchParams, setEntityQueryFiltersValues]);

    useEffect(() => {
        // Activate this to automatically load data in Step 2
        // We don't load data by default, because we want the user to filter data first
        // if (step === 2) {
        //     refreshEntities();
        // }
    }, [step]);

    useEffect(() => {
        if (!selectedTemplate || !processedReportFilterOptions || !setEntityQueryFiltersValues) return;

        const dateFromFieldName = processedReportFilterOptions.find(o => o.identifier!.toLowerCase().endsWith('filter_fromdate'))?.identifier ?? FIELD_DATE_FROM;
        const dateToFieldName = processedReportFilterOptions.find(o => o.identifier!.toLowerCase().endsWith('filter_todate'))?.identifier ?? FIELD_DATE_TO;

        // Update initial filter values when changing selectedTemplate
        const options = {
            ...entityQueryFilters,
            ...{
                [dateFromFieldName]: getIn(entityQueryFilters, dateFromFieldName) ?? DateTime.now().minus({ months: 1 }).toJSDate(),
                [dateToFieldName]: getIn(entityQueryFilters, dateToFieldName) ?? DateTime.now().toJSDate(),
            },
            // go through processed options
            ...Object.assign({}, ...processedReportFilterOptions.map(o => {
                if (!o.identifier) return {};

                const value = reportOptions[o.identifier];

                // check for date filters and convert with luxon
                // if (o.type === 'System.DateTime' && value) {
                //     const dateValue = DateTime.fromISO(value!).toJSDate();
                //     if (o.identifier!.toLowerCase().endsWith('filter_fromdate')) {
                //         return { [FIELD_DATE_FROM]: dateValue };
                //     }
                //     if (o.identifier!.toLowerCase().endsWith('filter_todate')) {
                //         return { [FIELD_DATE_TO]: dateValue };
                //     }
                //     return { [o.identifier!]: dateValue };
                // }

                // check for selection filters and map foreign key
                if (o.displayControlType?.includes('Selection') && value) {
                    const fkName = (o.additionalData.mapFromProperty ?
                        o.identifier + o.additionalData.mapFromProperty
                        : o.identifier! + 'fk');
                    return { [fkName]: value };
                }

                return value ? { [o.identifier!]: value } : {};
            }))
            // [FIELD_TEXT_FILTER]: entityQueryFilters[FIELD_TEXT_FILTER],
            // [FIELD_JOB]: entityQueryFilters[FIELD_JOB],
            // [FIELD_DATE_FROM]: entityQueryFilters.fromDate.toJSDate(),
            // [FIELD_DATE_TO]: entityQueryFilters.toDate.toJSDate()                
        };

        Object.keys(options).map(option => {
            setEntityQueryFiltersFieldValue(option, options[option]);
            return null;
        });

    }, [selectedTemplate, processedReportFilterOptions, reportOptions, setEntityQueryFiltersValues])

    useEffect(() => log("filters changed", entityQueryFilters), [entityQueryFilters])

    // Step 3
    const reportRef = useRef<PDFExport>(null);

    useEffect(() => {
        defineFont({
            'Source Sans Pro': '/fonts/SourceSansPro-Regular.ttf',
            'Source Sans Pro|Bold': '/fonts/SourceSansPro-Bold.ttf',
            'Source Sans Pro|Semibold': '/fonts/SourceSansPro-SemiBold.ttf',
            'DejaVu Sans': '/fonts/DejaVuSans.ttf',
            'DejaVu Sans|Bold': '/fonts/DejaVuSans-Bold.ttf'
        });
    }, []);

    const navigate = useNavigate();
    const createReport = useCallback(() => {

        if (!selectedTemplate || !reportOptionData || !reportData || reportData.length < 1)
            return;

        // Build options from report options and data filters
        const optionData = { ...reportOptionData };
        processedReportFilterOptions.map(o => {
            if (!o.identifier) return null;

            // If a value for the filter has been set, include it within report data
            const value = getIn(entityQueryFilters, o.additionalData.mapFromProperty ? [o.identifier, o.additionalData.mapFromProperty] : o.identifier);
            if (value) {
                optionData[o.identifier] = value;
            }

            return null;
        });


        if (!report) {
            const data: CreateReportInput = {
                templateId: selectedTemplate.id!,
                reportDate: getIn(reportOptionData, "reportDate"),
                reportTitle: getIn(reportOptionData, "reportTitle"),
                serviceConnectionId: abp.setting.getInt(Settings_ServiceConnectionId),
                serviceProviderName: abp.setting.get(Settings_ProviderName),
                reportData: {
                    optionValues: JSON.stringify(optionData),
                    values: JSON.stringify(reportData),
                }
            };

            reportApi?.apiServicesAppReportsCreatePost(data).then(r => !!parseInt((r as any).result) && navigate(`/app/reporting/edit/${(r as any).result}`));
        }
        else {
            const data: ReportDataEntryDto = {
                optionValues: JSON.stringify(optionData),
                values: JSON.stringify(reportData)
            };

            reportApi?.apiServicesAppReportsUpdatePut(data, report.id!).then(r => { log("update report result ", (r as any).result); !!parseInt((r as any).result) && navigate(`/app/reporting/edit/${(r as any).result}`) });
        }

    }, [selectedTemplate, reportOptionData, reportData, reportApi, report]);


    const steps = ([
        {
            label: "Berichtsvorlage",
            isValid: !!selectedTemplate
        },
        {
            label: "Berichtsoptionen",
            disabled: !selectedTemplate,
            isValid: maxStep < 1 ? undefined : !!reportOptionData && (!reportOptionErrors || !Object.keys(reportOptionErrors).length)
        },
        {
            label: "Berichtsdaten",
            disabled: !selectedTemplate || !reportOptionData,
            isValid: maxStep < 2 ? undefined : !!reportData && reportData.length > 0
        },
        {
            label: "Bericht speichern",
            disabled: !reportData,
            isValid: undefined
        }
    ]);

    useEffect(() => {
        log("rendered wizard", selectedTemplate, processedReportFilterOptions, reportOptions);
    })

    return (<div className="report-wizard px-4">

        <h1>{report ? "Neue Berichtsversion erstellen" : "Neuen Bericht erstellen"}</h1>

        <Stepper
            value={step}
            onChange={handleStepChange}
            items={steps}
            mode="labels"
        />

        <div className="report-wizard-inner row">

            {step === 0 && <div className="step-0 col">
                {report ?
                    <>
                        <div className="title">Sie bearbeiten derzeit einen Bericht:</div>

                        <div className="report-data">
                            <h5>{selectedTemplate?.name} {report.no}</h5>
                        </div>
                    </> :
                    <>
                        <div className="title">Bitte wählen Sie eine Berichtsvorlage aus:</div>

                        {templatesDataViewMessageData ?
                            <DataViewMessage {...templatesDataViewMessageData}
                                {...(!!reportTemplates?.length ?
                                    { message: 'Keine Berichtsvorlagen gefunden. Um einen Bericht zu erstellen, müssen Sie zunächst eine Berichtsvorlage erstellen oder importieren.' }
                                    : {})} />
                            :
                            <div className="items">
                                {reportTemplates.map((rt) => {
                                    return <div className={'report-template-item' + (rt.id === selectedTemplate?.id ? ' selected' : '')} key={rt.id} onClick={() => setSelectedTemplate(rt)}>
                                        <div className="name">
                                            {rt.name}
                                        </div>
                                    </div>
                                })}
                            </div>
                        }

                        {/* {reportTemplates && reportTemplates.length > 0 ?
                            <div className="items">
                                {reportTemplates.map((rt) => {
                                    return <div className={'report-template-item' + (rt.id === selectedTemplate?.id ? ' selected' : '')} key={rt.id} onClick={() => setSelectedTemplate(rt)}>
                                        <div className="name">
                                            {rt.name}
                                        </div>
                                    </div>
                                })}
                            </div> :
                            <p>Keine Berichtsvorlagen gefunden. Um einen Bericht zu erstellen, müssen Sie zunächst eine Berichtsvorlage erstellen oder importieren.</p>
                        } */}
                    </>
                }

            </div>}

            {<div className="step-1 col" style={{ display: step === 1 ? 'block' : 'none' }}>

                {selectedTemplate &&
                    <NaVaForm initialValues={{
                        reportTitle: selectedTemplate.name,
                        ...selectedTemplate.options?.filter(o => !o.isFilter && o.defaultValue).reduce((val, value, index) => {
                            return { ...val, [value.identifier!]: parseOptionDefaultValue(value.type!, value.defaultValue!) };
                        }, {}),
                        ...reportOptions,
                        reportDate: new Date(),
                    }} onSubmit={() => { }}>
                        <NaVaFormStatePuller onStateChanged={values => { log('report options changed', values.values); setReportOptionData(values) }} />
                        <div className="title">Berichtsoptionen</div>
                        <div className="row px-3">
                            <DatePicker name="reportDate" sm={4} label="Berichtsdatum"></DatePicker>
                            <Input name="reportTitle" type="text" sm={5} label="Name dieses Berichts" />
                        </div>

                        <div className="title">Optionen</div>

                        {selectedTemplate.options?.filter(o => !o.isFilter && o.isEnabled).map((o) =>
                            (<ReportOptionControl option={o} />)
                        )}

                    </NaVaForm>
                }
            </div>}

            {<div className="step-2 col" style={{ display: step === 2 ? 'block' : 'none' }}>
                <div className="title">Berichtsdaten</div>
                {selectedTemplate && processedReportFilterOptions && reportOptions && <NaVaForm initialValues={{}} onSubmit={() => { }}
                //Required to reinitialize when selected template is changed
                // enableReinitialize={true}
                >
                    <NaVaFormStatePuller onStateChanged={setEntityQueryFiltersData} />
                    <div className="row px-3 mb-3 data-filters">
                        {/* {reportOptions?.find(o => o.isFilter && o.identifier?.toLowerCase().endsWith("filter_fromdate")) &&
                            <DatePicker name={FIELD_DATE_FROM} sm={4} lg={3} xl={2} />
                        }
                        {reportOptions?.find(o => o.isFilter && o.identifier?.toLowerCase().endsWith("filter_todate")) &&
                            <DatePicker name={FIELD_DATE_TO} sm={4} lg={3} xl={2} />
                        } */}
                        {processedReportFilterOptions/*.filter(o => !["filter_fromdate", "filter_todate"].find(x => o.identifier!.endsWith(x)))*/.map(option => {
                            return <OptionFilter option={option} key={option.identifier} />;
                        })}

                        {/* <JobSelection name={FIELD_JOB} sm={6} lg={4} selectedItem={entityQueryFilters.job} /> */}
                        {/* <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Job}
                            props={{ name: FIELD_JOB, sm: 6, lg: 4, selectedItem: entityQueryFilters.job } as JobSelectionProps} /> */}
                        {/* <div className="col-sm-2 col-lg-2 col-xl-1 d-flex align-items-center"> */}
                        <Button label="Daten aktualisieren" color="light" sm={5} lg={2} onClick={() => refreshEntities()} disabled={!!invalidEntityQueryValue}>
                            <Icon name="icon-circular-arrows" size="1rem" />
                        </Button>
                        {/* </div> */}
                    </div>
                </NaVaForm>}

                {/* {!getIn(entityQueryFilters, FIELD_JOB) && <DataViewMessage {...DataViewNoData} />} */}

                {/* {reportDataLoaderVisible && <LoadingIndicator></LoadingIndicator>} */}

                {/* {!reportDataLoaderVisible && reportData && reportData.length < 1 &&
                    <div className="data-empty">
                    Keine Daten zum Anzeigen vorhanden
                </div>} */}
                {!!invalidEntityQueryValue ?
                    <DataViewMessage iconName="icon-filter" title="Filter unvollständig" message={L(invalidEntityQueryValue.additionalData?.missingMessage ?? '')} />
                    :
                    (reportDataMessageViewData ? <DataViewMessage {...reportDataMessageViewData} /> :
                        selectedTemplate && < ReportDataView data={reportData} onEntitySelected={(e) => setSelectedEntities([e])} type={selectedTemplate!.dataType ?? ""} />)
                }
            </div>}

            {
                step === 3 && <div className="step-3">
                    <div className="tools row">
                        <Button label="Zurück" color="primary" disabled={steps[step - 1]?.disabled} sm={3} onClick={() => setStep(s => s - 1)} />
                        <Button color="light" onClick={() => {
                            reportRef.current?.save();
                        }} label="" sm={3}>
                            <Icon name="icon-view-file" size="1rem" />
                            <span>Vorschau als PDF herunterladen</span>
                        </Button>
                        <Button color="primary" onClick={() => {
                            createReport();
                        }} label="" sm={3}>
                            <Icon name="icon-add-file" size="1rem" color="white" />
                            <span>{report ? "Neue Version speichern" : "Bericht speichern"}</span>
                        </Button>
                    </div>

                    <div className="report-wrapper m-3 p-2">
                        {selectedTemplate?.templatePath &&
                            <ComponentProvider componentPath={selectedTemplate.templatePath} data={{
                                title: getIn(reportOptionData, 'reportTitle'), options: { ...reportOptionData, ...entityQueryFilters }, report: report,
                                templateData: selectedTemplate, data: reportData! as any, pdfRef: reportRef, isPreview: true
                            }} />
                        }
                    </div>
                </div>
            }
        </div >
        {
            step !== 3 && <div className="stepper-control row justify-content-around">
                <Button label="Zurück" color="primary" disabled={step === 0 || steps[step - 1]?.disabled} sm={3} onClick={() => setStep(s => s - 1)} />
                <Button label="Weiter" color="primary" disabled={step === steps.length - 1 || steps[step + 1]?.disabled} sm={3} onClick={() => setStep(s => s + 1)} />
            </div>
        }
    </div >);
}

const ReportOptionControl: React.FC<{ option: ReportOptionDto }> = ({ option }) => {

    const switchControl = 'switch';
    const text = 'text';
    const number = 'number';
    const date = 'date';
    const dropdown = 'dropdown'

    const getControl = useCallback(() => {
        switch (option.displayControlType?.toLowerCase()) {
            case switchControl:
                return <Switch name={option.identifier!} label={option.name} />
            case text:
                return <Input name={option.identifier!} type="text" label={option.name} />
            case number:
                return <Input name={option.identifier!} type="number" label={option.name} />
            case date:
                return <DatePicker name={option.identifier!} label={option.name} />
            case dropdown:
                return <ComboBox name={option.identifier!} label={option.name} data={option.valuesFilter && JSON.parse(option.valuesFilter).map((v: string) => parseOptionDefaultValue(option.type!, v))} />
            default:
                return <></>
        }
    }, [option])

    return <div className="report-option" key={option.id}>
        {getControl()}
    </div>
}

export default ReportWizard;