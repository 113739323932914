import { useRef, useCallback } from 'react';
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

/**
 * Prevents changing a callback functions reference by
 * copying a ref to the callback scoped to the current state/props on each render
 * @param fn The function to use as callback
 */
export default function useEventCallback<T extends(...args: any[]) => any>(fn: T): T {
  const ref = useRef<T>(fn);

  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback(
    (...args: any[]) => ref.current.apply(void 0, args),
    []
  ) as T;
}
