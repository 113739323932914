import { DateTime } from "luxon";

export const adjustUTCDate = (date: Date) => new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
export const adjustUTCDateTime = (date: DateTime) => {
    const jsDate = date.toJSDate();
    return new Date(jsDate.getTime() - (jsDate.getTimezoneOffset() * 60000));
}

export const NullGuid = "00000000-0000-0000-0000-000000000000";
export const IsEmptyGuid = (value?: string) => { return !value || value === NullGuid };
export const MinDate = adjustUTCDateTime(DateTime.fromISO('0001-01-01T00:00:00.0000000').toUTC())
export const MaxDate = adjustUTCDateTime(DateTime.fromISO('9999-12-31T23:59:59.9999999').toUTC())