
export interface Queue<T = any> extends QueueImpl<T> {}

class QueueImpl<T = any> {
  protected _elements: T[];

  public constructor(initialElements: T[] = []) {
    this._elements = initialElements;
  }

  public enqueue(...elements: T[]) {
    this._elements.push(...elements);
  }

  public dequeue(): T {
    if (this.isEmpty) throw new Error('#dequeue() must not be called on empty Queue');
    return this._elements.shift()!;
  }

  public get isEmpty() {
    return this._elements.length === 0;
  }

  public peek(): T {
    if (this.isEmpty) throw new Error('#peek() must not be called on empty Queue');
    return this._elements[0];
  }
}

class DistinctQueueImpl<T = any> extends QueueImpl {
  public constructor(initialElements: T[] = []) {
    super([...new Set(initialElements)]);
  }

  public enqueue(...elements: T[]) {
    const distinctElements = elements.filter(e => !this._elements.includes(e));
    if (distinctElements.length > 0) super.enqueue(...distinctElements);
  }
}

export const Queue = <T = any>(initialElements: T[] = []): Queue<T> => new QueueImpl(initialElements);
export const DistinctQueue = <T = any>(initialElements: T[] = []): Queue<T> => new DistinctQueueImpl(initialElements);
