import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ControlWrapper, { ControlWrapperProps } from '../control-wrapper/ControlWrapper';
import { ComboBox as KendoComboBox, ComboBoxFilterChangeEvent, ComboBoxProps as KendoComboBoxProps } from '@progress/kendo-react-dropdowns';
import { FilterDescriptor, filterBy, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Tooltip, TooltipProps } from '@progress/kendo-react-tooltip';
import { title } from 'process';
import { ColorPalettePropsContext } from '@progress/kendo-react-inputs';
import ShortcutKey from '../../../components/shortcut-key/ShortcutKey';

export type ComboBoxProps = {
    name: string;
    innerRef?: ((instance: HTMLElement | null) => void);
    selectOnFocus?: boolean;
    filterFields?: string[];
    filterLogic?: 'and' | 'or';
    footer?: (item: any) => ReactNode;
    openOnFocus?: boolean;
    displayShortcutKey?: string;
} & ControlWrapperProps & TooltipProps & Pick<KendoComboBoxProps, 'data' | 'dataItemKey' | 'textField' | 'defaultValue' | 'onChange' | 'onBlur' | 'opened' | 'value' | 'disabled' | 'filterable' | 'placeholder' | 'itemRender' | 'popupSettings' | 'style'>;

export const ComboBox: React.FC<ComboBoxProps> = React.memo(({ innerRef, selectOnFocus = true, ...props }) => {
    const listenerRef = useRef<{ element: HTMLInputElement; listener: () => void } | null>(null);

    const actualInnerRef = useCallback((e: KendoComboBox | null) => {
        const element = e && e.element && e.element.querySelector<HTMLElement>('input[role=combobox]');
        innerRef && innerRef(element);

        if (element && selectOnFocus && !!!listenerRef.current?.listener) {
            listenerRef.current = {
                element: (element as HTMLInputElement),
                listener: () => (element as HTMLInputElement).select()
            };
            element.addEventListener('focus', listenerRef.current.listener);
            // console.log("added listener");
        }

        if (!selectOnFocus && listenerRef.current?.listener) {
            element?.removeEventListener('focus', listenerRef.current.listener);
            // console.log("removed listener");
        }
    }, [innerRef, selectOnFocus]);

    const [filteredData, setFilteredData] = useState(props.data ?? [].slice());

    const [currentItem, setCurrentItem] = useState(undefined);

    const filterData = useCallback((filter: FilterDescriptor) => {
        const newData = props.data ?? [].slice();
        // if filter fields have been set, use value from onFilterChange Event but search on multiple fields, else use filter from onFilterChange event
        return filterBy(newData, props.filterFields ?
            { filters: props.filterFields.map(x => ({ field: x, value: filter.value, operator: 'contains', ignoreCase: filter.ignoreCase })), logic: props.filterLogic ?? 'or' } as CompositeFilterDescriptor :
            filter);
    }, [props.data, props.filterFields, props.filterLogic]);

    const filterChange = useCallback((event: ComboBoxFilterChangeEvent) => {
        setFilteredData(filterData(event.filter));

    }, [setFilteredData, filterData]);

    useEffect(() => {
        setCurrentItem(filteredData.length > 0 ? filteredData[0] : undefined);
    }, [setCurrentItem, filteredData]);

    useEffect(() => {
        setFilteredData(props.data ?? [].slice());
    }, [props.data]);

    return (
        <ControlWrapper
            xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl}
            offset={props.offset} offsetSm={props.offsetSm} offsetMd={props.offsetMd} offsetLg={props.offsetLg} offsetXl={props.offsetXl} offsetXxl={props.offsetXxl}
            m={props.m} mt={props.mt} mb={props.mb} ml={props.ml} mr={props.mr} mx={props.mx} my={props.my}
            error={props.error} label={props.label} className={props.className} title={props.title} suffix={props.suffix}
        >
            <Tooltip anchorElement={props.anchorElement ?? "target"} appendTo={props.appendTo} content={props.content}
                parentTitle={props.parentTitle ?? true} position={props.position ?? "top"} tooltipStyle={props.tooltipStyle}
                tooltipClassName={props.tooltipClassName} updateInterval={props.updateInterval} targetElement={props.targetElement}>
                <KendoComboBox
                    name={props.name}
                    ref={actualInnerRef}
                    data={filteredData}
                    dataItemKey={props.dataItemKey}
                    textField={props.textField}
                    defaultValue={props.defaultValue}
                    onChange={(e) => { setCurrentItem(e.value); props.onChange && props.onChange(e) }}
                    onBlur={props.onBlur}
                    filterable={props.filterable}
                    onFilterChange={(e) => filterChange(e)}
                    value={props.value}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    itemRender={props.itemRender}
                    footer={props.footer && props.footer(currentItem)}
                    onFocus={e => (props.openOnFocus ?? true) && e.target.setState({ opened: true })}
                    onClose={() => setCurrentItem(undefined)}
                    opened={props.opened}
                    popupSettings={props.popupSettings}
                    style={props.style}
                />
            </Tooltip>
            <ShortcutKey shortcutKey={props.displayShortcutKey} />
        </ControlWrapper>
    )
});