import { ComboBoxBlurEvent, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';

import React, { useCallback, useEffect, useState } from 'react';
import { ComboBox as BaseComboBox, ComboBoxProps as BaseComboBoxProps } from '../../../controls/combo-box/ComboBox';
import useNaVaField from '../../na-va-form/NaVaField';
import { useNaVaFormContext } from '../../na-va-form/NaVaFormContext';

export type ComboBoxProps = Omit<
    BaseComboBoxProps,
    'value' | 'onFocus' | 'onKeyDown' | 'onKeypress' | 'onKeyUp' | 'innerRef'
> & {textFieldRender?: (item: any) => string };

const ComboBox: React.FC<ComboBoxProps> = React.memo(({ name, dataItemKey, onBlur, onChange, data, textFieldRender, ...props }) => {
    const {
        error,
        ref,
        setValue,
        onBlur: onBlurField,
        value,
        setTouched
    } = useNaVaField({ name });

    const aggregatedOnBlur = useCallback((e:ComboBoxBlurEvent) => {
        // onBlurField(e.syntheticEvent);
        onBlur && onBlur(e);
    }, [onBlur, onBlurField])
    
    const aggregatedOnChange = useCallback((e: ComboBoxChangeEvent) => {
        setTouched(true, true);
        setValue(e.value, true);
        onChange && onChange(e);
    }, [setValue, onChange]);

    return (<BaseComboBox
        name={name}
        innerRef={ref}
        dataItemKey={dataItemKey}
        onChange={aggregatedOnChange}                
        onBlur={aggregatedOnBlur}
        error={error}
        value={(textFieldRender && value) ? {...(value as any), textField: textFieldRender(value)}: value}
        data={(textFieldRender && data) ? data.map(i => ({ ...i, textField: textFieldRender(i) })) : data}
        {...props}
    />)
});

export default ComboBox;


// const ComboBox: React.FC<ComboBoxProps> = React.memo(({ name, dataItemKey, onChange, data, ...props }) => {
//     const {
//         error,
//         value,
//         ref,
//         setValue
        
//     } = useNaVaField({ name });

//     const aggregatedOnChange = useCallback((e: ComboBoxChangeEvent) => {
//         setValue(e.value, true);
//         onChange && onChange(e);
//     }, [setValue, onChange]);

//     return (<BaseComboBox
//         name={name}
//         innerRef={ref}
//         dataItemKey={dataItemKey}
//         onChange={aggregatedOnChange}        
//         error={error}
//         value={value}
//         data={data}
//         {...props}
//     />)
// });

// export default ComboBox;