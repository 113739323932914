import React, { useMemo } from 'react';
import { ChartingTimeInterval } from '../../../client/http';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { L } from '../../../abp/utils';

export const ChartingTimeIntervalData = {
    "Today": { value: ChartingTimeInterval.NUMBER_0, name: L('IntervalToday') },
    "Yesterday": { value: ChartingTimeInterval.NUMBER_1, name: L('IntervalYesterday') },
    "ThisWeek": { value: ChartingTimeInterval.NUMBER_2, name: L('IntervalThisWeek') },
    "LastWeek": { value: ChartingTimeInterval.NUMBER_3, name: L('IntervalLastWeek') },
    "ThisMonth": { value: ChartingTimeInterval.NUMBER_4, name: L('IntervalThisMonth') },
    "LastMonth": { value: ChartingTimeInterval.NUMBER_5, name: L('IntervalLastMonth') },
    "ThisYear": { value: ChartingTimeInterval.NUMBER_6, name: L('IntervalThisYear') },
    "LastYear": { value: ChartingTimeInterval.NUMBER_7, name: L('IntervalLastYear') },
    "Last7Days": { value: ChartingTimeInterval.NUMBER_8, name: L('IntervalLast7Days') },
    "Last14Days": { value: ChartingTimeInterval.NUMBER_9, name: L('IntervalLast14Days') },
    "Last30Days": { value: ChartingTimeInterval.NUMBER_10, name: L('IntervalLast30Days') },
    "Last12Months": { value: ChartingTimeInterval.NUMBER_11, name: L('IntervalLast12Months') },
    "Total": { value: ChartingTimeInterval.NUMBER_12, name: L('IntervalTotal') },
}

const ChartingTimeIntervalSelection: React.FC<{ defaultValue: ChartingTimeInterval, onSelectionChanged: (value: ChartingTimeInterval) => void }> = ({ defaultValue, onSelectionChanged }) => {
    // const data = useMemo(() => { return Object.keys(ChartingTimeIntervalData).map(k => { const item = (ChartingTimeIntervalData as any)[k]; return { ...item, name: L(item) } }) }, []);
    const data = useMemo(() => Object.values(ChartingTimeIntervalData).map(k => ({ ...k, name: L(k.name) })), []);
    const defaultVal = data.find(d => d.value === defaultValue)    

    return <div className="time-interval-selection" >
        <DropDownList data={data} textField="name" defaultValue={defaultVal} dataItemKey="value" onChange={(e) => onSelectionChanged && onSelectionChanged(e.target.value.value)}></DropDownList>
    </div>
}

export default ChartingTimeIntervalSelection;