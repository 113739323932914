import { GridCustomCellProps } from '@progress/kendo-react-grid';
import React, { useCallback } from 'react';
import TemplateInput from '../template-input/TemplateInput';
import { getIn } from '../../forms/validation/na-va-form/commonUtils';
import './TemplateInputGridCell.scss';
import { log } from '../../../util/LoggingUtils';

const TemplateInputGridCell: React.FC<GridCustomCellProps> = ({ tdProps, dataIndex, dataItem, field, onChange }) => {

    const handleChange = useCallback((value: string, plainValue: string) => {
        onChange &&
            onChange({ dataItem, dataIndex, syntheticEvent: {} as any, field, value });
        log("onChange");
    }, [dataIndex, dataItem, field]);

    return tdProps ? (
        <td
            {...tdProps}
            className={tdProps.className + " cell cell__template-input"}
            style={{
                ...tdProps.style,
            }}
        >
            <TemplateInput value={getIn(dataItem, field ?? '')} fontSize=".8rem" onChange={handleChange} />
        </td>
    ) : null;
}

export default TemplateInputGridCell;