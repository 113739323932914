import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { AppParts } from '../../modules/AppParts';
import ModuleFileComponentProvider from '../ModuleFileComponentProvider';
import ModuleContext, { ModuleContextValues } from './ModuleContext';
import { log } from '../../util/LoggingUtils';

const ModuleContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [components, setComponents] = useState<ModuleContextValues>({});

    const registerComponent = useCallback((appPartName: string, module: string, replace: boolean, component: (props?: any) => React.ReactNode, key?: string) => {
        // if (!Object.hasOwn(methods, name) || !methods[name]) {
        //     methods[name] = [];
        // }

        // setMethods(prev => ({ ...prev, [name]: [...(prev[name] ?? []), { module, key, methodPart }] }));
        setComponents(prev => {
            // check if item with this key is already present
            // if addMethod if called multiple times and no different key is provided, the item will be replaced
            const arr = [...prev[appPartName] ?? []];
            const foundItem = arr.find(m => m.module === module && m.key === key);
            if (foundItem) {
                if (!replace) return prev;

                arr.splice(arr.indexOf(foundItem), 1);
            }
            log("added component", appPartName, module, key);
            return ({ ...prev, [appPartName]: [...arr, { module, key, component }] })
        });
    }, [setComponents]);

    const getModules = useCallback((moduleName?: string, inheritForModule?: string, getSingleComponent?: boolean) => {
        // if a module name gets provided, we want to specifically get the component from that module
        let modules = moduleName ? [moduleName] : abp.setting.get("App.UseModules").split(',');

        // if we only want the most specific component, reverse the search order
        if (inheritForModule || getSingleComponent)
            modules = modules.reverse();

        // if the component should be inherited we are looking for the next most specific component
        // and therefore remove the given module from the search list
        if (inheritForModule)
            modules = modules.slice(modules.indexOf(inheritForModule) + 1);

        return modules;
    }, []);

    const getComponents = useCallback((appPartName: string, moduleName?: string, key?: string, inheritForModule?: string, getSingleComponent?: boolean) => {
        if (!Object.hasOwn(components, appPartName)) return null;

        const result: ((props: any) => React.ReactNode)[] = [];

        const modules = getModules(moduleName, inheritForModule, getSingleComponent);
        const matchingComponents = (components[appPartName] ?? []).filter(c => modules.includes(c.module) && c.key === key);

        if (!matchingComponents || matchingComponents.length < 1)
            return null;

        for (const module of modules) {

            const comp = matchingComponents.filter(c => c.module === module);
            if (comp && comp.length) {
                comp.map(c => result.push(c.component));

                if (getSingleComponent || inheritForModule)
                    break;
            }
        }
        return result;
    }, [getModules, components]);

    useEffect(() => log("components updated", components), [components]);

    return <ModuleContext.Provider value={{ registerComponent, getComponents, components }}>
        <ModuleFileComponentProvider appPartName={AppParts.ModuleRegistration} modulePath={""} />
        {children}
    </ModuleContext.Provider>
}

export default ModuleContextProvider;