import { useEffect } from "react";
import { Modules } from "../../module-components/Modules";
import DevOpsAdministration from "./administration/DevOpsAdministration";
import useModuleContext from "../../module-components/module-context/useModuleContext";
import { DevOpsAppParts } from "./DevOpsAppParts";
import AreaSelection from "./service-entities/AreaSelection";
import WorkItemSelection from "./service-entities/WorkItemSelection";
import ProjectSelection from "./service-entities/ProjectSelection";
import WorkItemView from "./service-entities/WorkItemView";
import { AppParts } from "../AppParts";
import RecentWorkItems from "./dashboard/RecentWorkItems";

const ModuleRegistration: React.FC = () => {
    const { registerComponent } = useModuleContext();

    useEffect(() => {
        registerComponent(DevOpsAppParts.Administration, Modules.devOps, true, (props: any) => <DevOpsAdministration {...props} />);

        registerComponent(AppParts.Dashboard.Cards, Modules.devOps, true, (props: any) => <>
            <RecentWorkItems {...props} />
        </>);


        registerComponent(DevOpsAppParts.ServiceEntities.Selection.Area, Modules.devOps, true, (props: any) => <AreaSelection {...props} />);
        registerComponent(DevOpsAppParts.ServiceEntities.Selection.Project, Modules.devOps, true, (props: any) => <ProjectSelection {...props} />);
        registerComponent(DevOpsAppParts.ServiceEntities.Selection.WorkItem, Modules.devOps, true, (props: any) => <WorkItemSelection {...props} />);

        registerComponent(DevOpsAppParts.ServiceEntities.View.WorkItem, Modules.devOps, true, (props: any) => <WorkItemView {...props} />);

    }, [registerComponent]);

    return null;
}

export default ModuleRegistration;