import React, { useMemo } from 'react';
import { L } from '../../../abp/utils';
import { Job, JobApi } from '../../../client/http';
import ApiComboBox from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { useNaVaFormContext } from '../../../common/forms/validation/na-va-form/NaVaFormContext';
import useApi, { UseApiResult } from '../../../common/hooks/useApi';
import { FIELD_DESCRIPTION1, FIELD_DESCRIPTION2, FIELD_DESIGNATION } from '../../../data/field-constants/GeneralConstants';
import { JobSelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import filter from '../../../util/FilterUtils';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';
import JobDetails, { DesignationBillToNameSearchDescriptionItem } from './detail-views/JobDetails';

const JobSelection: React.FC<JobSelectionProps> = ({ fetchFromAllProviders,
    jobFilters = [filter("status").notEqual("Completed"), filter("status").notEqual("Archive")], selectedItem, data,
    name, title, placeholder, filterable, filterFields, dataItemKey, textField, itemRender, content, footer, ...props }) => {
    const { values } = useNaVaFormContext();

    const apiResult = useApi<Job, JobApi>(c => new JobApi(c), c => c.apiServicesAppJobGetAllGet(fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
        fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId), undefined, JSON.stringify(jobFilters)));

    // This is necessary if a field from the extensions is used as textField in the ComboBox
    // If extensions is not initially loaded when the data reaches the ComboBox an exception
    // will be thrown when trying to access toString() on undefined
    const apiResultItem = useMemo((): UseApiResult<Job, JobApi> => {
        if (data)
            return [data.map(j => { j.extensions = JSON.parse(j.extensionData ?? '{}'); return j }), undefined, undefined, () => { }, undefined]
        else {
            const [apiData, ...others] = apiResult;
            return [apiData.map(j => { j.extensions = JSON.parse(j.extensionData ?? '{}'); return j }), ...others];
        }
    }, [data, apiResult]);

    return <ApiComboBox apiResult={apiResultItem} title={title ?? (values[name] && "Job")} name={name}
        filterable={filterable ?? true} filterFields={filterFields ?? [FIELD_DESIGNATION, FIELD_DESCRIPTION1, FIELD_DESCRIPTION2]}
        dataItemKey={dataItemKey ?? 'id'} textField={textField ?? 'description1'} itemRender={itemRender ?? DesignationBillToNameSearchDescriptionItem}
        placeholder={placeholder ?? L('Job')} footer={footer ?? ((i) => <JobDetails data={i} />)}
        content={content ?? (selectedItem ?? getIn(values, name)) ? (() => <JobDetails data={(selectedItem ?? getIn(values, name))!} />) : undefined} {...props} />

}

export default JobSelection;