import React from 'react';
import SdtLogoBig from './sdt-logo-big.png'

const SdtLoginLogo: React.FC = () => {
    return <>
        <img alt="Sedotec" src={SdtLogoBig} style={{height: '4rem', width:'auto'}} />
        <h1 className="m-3 fw-medium" style={{position:'relative', left:'-1.5rem'}}>PLM</h1>
    </>
}

export default SdtLoginLogo;
