import { useCallback, useEffect, useState } from "react";
import constants from "../../abp/constants";
import { useAuthenticator } from "../../authentication/authenticator/Authenticator"
import { Configuration } from "../../client/http";

export default function useAuthConfiguration() {
    const { user, refreshToken, isCurrentlyAuthenticated } = useAuthenticator();

    const token = user?.tokenInfo.token;
    const apiKey = useCallback(() => {
        if (!isCurrentlyAuthenticated()) {
            refreshToken(abp.session.tenantId ?? null);
            return '';
        }

        return `Bearer ${token}`;
    }, [isCurrentlyAuthenticated, refreshToken, token]);

    const [authConfig, setAuthConfig] = useState<Configuration>(new Configuration({ basePath: constants.remoteServiceBaseUrl, apiKey }));

    useEffect(() => console.log("useAuthConfig changed: ", authConfig), [authConfig]);

    useEffect(() => setAuthConfig(new Configuration({ basePath: constants.remoteServiceBaseUrl, apiKey })), [apiKey]);

    return authConfig;
}