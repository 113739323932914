import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultColumnConfig } from '../data/columnConfigurations';
import { defaultPlmControlState } from './plm-job-list/components/PlmControlComponents';
import PlmJobList from './plm-job-list/PlmControl';
import filter from '../../../util/FilterUtils';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import Input from '../../../common/forms/validation/controls/input/Input';
import { FIELD_DESIGNATION } from '../../../data/field-constants/GeneralConstants';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { L } from '../../../abp/utils';
import useLoadingState from '../../../common/hooks/useLoadingState';
import useVisibility from '../../../common/hooks/useVisibility';
import useTakeApiUi from '../../../common/hooks/useTakeApiUi';
import useApi from '../../../common/hooks/useApi';
import { DataExchangeApi, DataExchangeProtocol } from '../../../client/http';
import { showNotification } from '../../../common/components/notifications/NotificationHost';
import { DateTime } from 'luxon';
import Button from '../../../common/forms/controls/button/Button';
import Icon from '../../../common/components/icon/Icon';
import LoadingIndicator from '../../../common/components/loading-indicator/LoadingIndicator';
import { Dialog } from '@progress/kendo-react-dialogs';
import CodeView from '../../../common/components/code-view/CodeView';

export const SDT_EXCHANGE_PROTOCOL_TYPE = 'Rewind365.Modules.SdtPlm.JobEntityExport';

const JobManagement: React.FC = () => {
    const [{ values, setFieldValue }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const [startLoading, loadingComplete, loadingError, isLoading] = useLoadingState();
    const designation = getIn(values, FIELD_DESIGNATION);

    const { takeItems, totalProtocolCount, takeItemControl } = useTakeApiUi(20, 20);

    const [, api] = useApi<DataExchangeProtocol, DataExchangeApi>(c => new DataExchangeApi(c));
    const [protocols, setProtocols] = useState<DataExchangeProtocol[]>([]);

    const fetchProtocols = useCallback(async () => {
        if (!api) return;

        try {
            startLoading();
            var result = await api.apiServicesAppDataExchangeGetProtocolsWithEntriesGet(
                SDT_EXCHANGE_PROTOCOL_TYPE,
                designation ? JSON.stringify(filter('externalReference').contains(designation)) : undefined,
                'creationTime desc',
                takeItems
            );

            loadingComplete();
            setProtocols((result as any).result.items);
            totalProtocolCount.current = (result as any).result.totalCount;

        } catch (error) {
            console.log(error);
            loadingError('Laden fehlgeschlagen');
            showNotification('Laden fehlgeschlagen', 'Protokolle konnten nicht geladen werden', 'error', undefined, true);
            return false;
        }
    }, [designation, api, totalProtocolCount, takeItems, startLoading, loadingComplete, loadingError]);

    useEffect(() => { fetchProtocols() }, [fetchProtocols]);

    const [dialogData, setDialogData] = useState({ data: '', lang: '' });
    const [dialogVisible, showDialog, hideDialog] = useVisibility();

    const showLogDetails = useCallback((data?: string, lang?: string) => {
        lang = lang ?? 'json';

        if (lang === 'json') {
            try {
                data = JSON.stringify(JSON.parse(data ?? '{}'), undefined, 4);
            } catch {
                data = data ?? '';
            }
        } else {
            data = data ?? '';
        }

        setDialogData({ data, lang });
        showDialog();
    }, [showDialog]);


    return <div className="test-new-ui row p-4 my-3">

        <NaVaForm
            initialValues={{
            }}
            onSubmit={() => { }}
        >
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>

            <div className="my-3">
                <Input name={FIELD_DESIGNATION} label={L('Designation')} sm={6} md={3} />
            </div>
        </NaVaForm>

        {!isLoading && protocols?.length ? <table className="table table-striped table-bordered table-no-outer-border">
            <thead>
                <tr>
                    <th className="text-center">Success</th>
                    <th className="text-center">Work Item id</th>
                    <th>Request Time</th>
                    <th>Request Message</th>
                    <th>Response Time</th>
                    <th>Response Message</th>
                    <th>User Friendly Response</th>
                    <th>Comment</th>
                    <th className="text-center">State</th>
                </tr>
            </thead>
            <tbody>
                {protocols.map(p => {
                    return <tr key={p.id}>
                        <td className="text-center align-middle">{p.exchangeState === 'Closed' ? <Icon name="icon-checked" color="var(--bs-success)" size="1.5rem" /> : <Icon name="icon-cancel" color="var(--bs-danger)" size="1.5rem" />}</td>
                        <td className="text-center align-middle">{p.externalReference}</td>
                        <td className="align-middle">
                            {p.requestStartDate ?
                                <>
                                    {DateTime.fromISO(p.requestStartDate as any).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}&nbsp;
                                    ({DateTime.fromISO(p.requestStartDate as any ?? "").toRelative()})
                                </> :
                                ''
                            }
                        </td>
                        <td className="align-middle">
                            <div className="d-flex align-items-center">
                                <div className="text-ellipsis truncate-json">
                                    {p.requestMessage}
                                </div>
                                {p.requestMessage &&
                                    <Button className="ms-2" color="light" label="" onClick={() => showLogDetails(p.requestMessage)}>
                                        <Icon name="icon-file-preview" size="1rem" />&nbsp;
                                        {L('Show')}
                                    </Button>
                                }
                            </div>
                        </td>
                        <td className="align-middle">{p.requestEndDate ? DateTime.fromISO(p.requestEndDate as any).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : ''}</td>
                        <td className="align-middle">
                            <div className="d-flex align-items-center">
                                <div className="text-ellipsis truncate-json">
                                    {p.responseMessage}
                                </div>
                                {p.responseMessage &&
                                    <Button className="ms-2" color="light" label="" onClick={() => showLogDetails(p.responseMessage)}>
                                        <Icon name="icon-file-preview" size="1rem" />&nbsp;
                                        {L('Show')}
                                    </Button>
                                }
                            </div>
                        </td>
                        <td className="align-middle">{p.userFriendlyResponseMessage}</td>
                        <td className="align-middle">{p.comment}</td>
                        <td className="text-center align-middle">{p.exchangeState}</td>
                    </tr>
                })}
            </tbody>
        </table> :
            <span className="text-muted">Keine Protokolle vorhanden</span>
        }
        {isLoading && <LoadingIndicator />}
        {dialogVisible && <Dialog title={L('Details')} onClose={hideDialog}>
            <CodeView {...dialogData} />
        </Dialog>}

        {takeItemControl()}
    </div >
}

export default JobManagement;