import _ from 'lodash';
import { useState, useCallback } from 'react';
import { getAuthorizedUserConfiguration } from '../../abp/getUserConfiguration';
import { useAuthenticator } from '../../authentication/authenticator/Authenticator';
import { SettingsApi } from '../../client/http';
import settings from '../../data/field-constants/AppSettings';
import useApi from './useApi';
import { error } from '../../util/LoggingUtils';

export type UseSettingsResult = { getSetting: (props: SettingProps) => Promise<{ result: any, error: any, success: boolean }> | undefined, updateSetting: (props: UpdateSettingProps) => void };

type AppSettingSettingProps = { name: string, level: 'Application' };
type TenantSettingSettingProps = { name: string, tenantId?: number, level: 'Tenant' };
type UserSettingSettingProps = { name: string, tenantId?: number, userId?: number, level: 'User' };

export type SettingProps = AppSettingSettingProps | TenantSettingSettingProps | UserSettingSettingProps;
export type UpdateSettingProps = SettingProps & { value?: string };

export default function useSettings(): UseSettingsResult {
    const [, settingsApi] = useApi<any, SettingsApi>(c => new SettingsApi(c));
    const { user } = useAuthenticator();

    const getSetting = useCallback((props: SettingProps) => {
        const { name, level } = props;
        switch (level) {
            case 'Application':
                return settingsApi?.apiServicesAppSettingsGetApplicationSettingGet(name) as unknown as Promise<{ result: any, error: any, success: boolean }>;
            case 'Tenant':
                return settingsApi?.apiServicesAppSettingsGetTenantSettingGet(name, props.tenantId ?? abp.session.tenantId) as unknown as Promise<{ result: any, error: any, success: boolean }>;
            case 'User':
                return settingsApi?.apiServicesAppSettingsGetUserSettingGet(name, props.tenantId ?? abp.session.tenantId, props.userId ?? abp.session.userId) as unknown as Promise<{ result: any, error: any, success: boolean }>;
        }
    }, [settingsApi]);

    const updateSetting = useCallback(async (props: UpdateSettingProps) => {
        const { name, level, value } = props;

        if (_.isEqual(abp.setting.get(name), value))
            return;

        switch (level) {
            case 'Application':
                await settingsApi?.apiServicesAppSettingsUpdateApplicationSettingPut(name, value);
                break;
            case 'Tenant':
                await settingsApi?.apiServicesAppSettingsUpdateTenantSettingPut(props.tenantId ?? abp.session.tenantId, name, value);
                break;
            case 'User':
                await settingsApi?.apiServicesAppSettingsUpdateUserSettingPut(props.tenantId ?? abp.session.tenantId, props.userId ?? abp.session.userId, name, value);
                break;
        }

        // Refetch config after updating settings
        user?.tokenInfo?.token &&
            getAuthorizedUserConfiguration(user.tokenInfo.token)
                .then(result => {
                    _.merge(abp, result.data.result);
                }).catch(r => error("Refetching user configuration failed: ", r));
    }, [settingsApi, user]);

    return { getSetting, updateSetting };
}
