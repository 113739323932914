import React, { useCallback, useMemo } from 'react';
import ApiComboBox, { ApiComboBoxProps } from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import { ServiceEntitySelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import { useReactiveApi } from '../../../common/hooks/useApi';
import { Configuration, WorkItemsApi } from '../../../client/http';
import { L } from '../../../abp/utils';
import ItemRenderComponent from '../../../common/components/item-render/ItemRenderComponent';
import useSimplePromise from '../../../common/hooks/useSimplePromise';

export type AreaSelectionProps = ApiComboBoxProps & ServiceEntitySelectionProps<any> &
{
    itemRenderFields?: string[]
    projectName?: string;
};

const AreaSelection: React.FC<AreaSelectionProps> = ({ selectedItem, data, itemRenderFields,
    name, title, placeholder, filterable, filterFields, dataItemKey, textField, itemRender, content, projectName, ...props }) => {

    const clientFactory = useCallback((c: Configuration) => new WorkItemsApi(c), []);

    const getAllResult = useCallback((c: WorkItemsApi) =>
        c.apiServicesDevOpsWorkItemsGetAreasGet(projectName),
        [projectName]);

    const array = useMemo(() => [], []);
    const emptyResultPromise = useSimplePromise(array);
    const emptyResult = useCallback((c: WorkItemsApi) => emptyResultPromise, [emptyResultPromise]);

    const apiResult = useReactiveApi<any, WorkItemsApi>(clientFactory,!!projectName ? getAllResult : emptyResult);

    return <ApiComboBox apiResult={data ? [data, undefined, undefined, () => { }, undefined] : apiResult} name={name} filterable={filterable ?? true} dataItemKey={dataItemKey ?? 'id'}
        textField={textField ?? 'name'} placeholder={placeholder ?? L('Area')}
        itemRender={(li, props) => <ItemRenderComponent li={li} itemProps={props} fields={itemRenderFields ?? ['name']} />}
        content={content} popupSettings={{ width: 'min(400px,50vw)' }}
        filterFields={filterFields ?? ['name']} {...props} />

}

export default AreaSelection;