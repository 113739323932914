import React, { useEffect } from 'react';
import useModuleContext from '../../module-components/module-context/useModuleContext';
import { Modules } from '../../module-components/Modules';
import { AppParts } from '../AppParts';
import RewindLoginLogo from './application/RewindLoginLogo';
import RewindLogo from './application/RewindLogo';
import CategorySelection from './service-entities/CategorySelection';
import ResourceSelection from './service-entities/ResourceSelection';
import { EntitiesFilter, EntityData, EntityDetails } from './time-tracking/time-entities/TimeEntityComponents';
import TimeEntityEditorFields from './time-tracking/time-entities/TimeEntityEditorFields';
import RewindMinimalLogo from './media/logo-minimal_x.png';
import Ms365Logo from './media/microsoft-365.svg';

const ModuleRegistration: React.FC = () => {
    const { registerComponent } = useModuleContext();

    useEffect(() => {
        registerComponent(AppParts.TimeTracking.TimeEntities.EditorFields, Modules.rewindCore, true, (props: any) => <TimeEntityEditorFields {...props} />);
        registerComponent(AppParts.TimeTracking.TimeEntities.EntityDetails, Modules.rewindCore, true, (props: any) => <EntityDetails {...props} />);
        registerComponent(AppParts.TimeTracking.TimeEntities.EntityData, Modules.rewindCore, true, (props: any) => <EntityData {...props} />);
        registerComponent(AppParts.TimeTracking.TimeEntities.EntitiesFilter, Modules.rewindCore, true, (props: any) => <EntitiesFilter {...props} />);

        registerComponent(AppParts.ServiceEntities.Selection.Resource, Modules.rewindCore, true, (props: any) => <ResourceSelection {...props} />);
        registerComponent(AppParts.ServiceEntities.Selection.Category, Modules.rewindCore, true, (props: any) => <CategorySelection{...props} />);

        registerComponent(AppParts.Application.LoginLogo, Modules.rewindCore, true, (props: any) => <RewindLoginLogo{...props} />);
        registerComponent(AppParts.Application.MainLogo, Modules.rewindCore, true, (props: any) => <RewindLogo{...props} />);
        registerComponent(AppParts.Application.CollapsedLogo, Modules.rewindCore, true, (props: any) => <RewindLogo collapsed {...props} />);
        registerComponent(AppParts.Application.ProviderLogo('Rewind365Provider'), Modules.rewindCore, true, (props: any) => <img src={RewindMinimalLogo} alt="Provider Logo" {...props} />);
        registerComponent(AppParts.Application.ProviderLogo('Rewind365.GraphProvider'), Modules.rewindCore, true, (props: any) => <img src={Ms365Logo} alt="Provider Logo" {...props} />);

    }, [registerComponent]);

    return null;
}

export default ModuleRegistration;