import React, { useCallback, useMemo, useRef, useState } from 'react';
import Button from '../forms/controls/button/Button';
import Icon from '../components/icon/Icon';
import { L } from '../../abp/utils';

const useTakeApiUi = (initialTake: number = 5, incrementTake: number = 5) => {

    const [takeItems, setTakeItems] = useState(initialTake);
    const totalProtocolCount = useRef(0);

    const takeItemControl = useCallback(() => {
        return <>
            <div className="text-muted m-3 text-center">{Math.min(totalProtocolCount.current, takeItems)}/{totalProtocolCount.current} Elemente</div>

            {takeItems < totalProtocolCount.current &&
                <Button className="text-muted load-more-btn mx-auto my-4" onClick={() => setTakeItems(v => Math.min(v + incrementTake, totalProtocolCount.current))} label="" color="light" sm={12} md={3}>
                    <Icon name="icon-time-machine" size="1rem"></Icon>
                    <span>
                        {L('ShowOlderItems')}...
                    </span>
                </Button>
            }
        </>
    }, [takeItems, totalProtocolCount, incrementTake]);

    return { takeItems, totalProtocolCount, takeItemControl };
};

export default useTakeApiUi;