import React from 'react';
import { getIn } from '../../forms/validation/na-va-form/commonUtils';
import './ItemRenderComponent.scss';

export type ItemRenderComponentProps = {
    li: any,
    itemProps: any,
    fields: string[],
    className?: string
}

const ItemRenderComponent: React.FC<ItemRenderComponentProps> = ({ li, itemProps, fields, className }) => {
    var data = itemProps.dataItem;
    if (Object.hasOwn(data, 'extensionData')) {
        data.extensions = JSON.parse(data.extensionData ?? '{}');
    }

    const itemChildren = (
        <div className={`item-render item-render__${className}`}>
            {fields.map(f => {
                return <span className={`data data__${f.replace('.', '-')}`} key={f}>{getIn(data, f.split('.'))}</span>
            })}            
        </div >
    );
    return React.cloneElement(li, li.props, itemChildren);
};

export default ItemRenderComponent;