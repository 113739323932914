import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../common/components/icon/Icon';
import { Action } from './SideNav';
import './SideNavEntry.scss';

export type SideNavEntryProps = {
    index: any;
    action: Action;
    active: boolean;
    selected: boolean;
    isTopLevel: boolean;
    navCollapsed: boolean;
}

const SideNavEntry: React.FC<React.PropsWithChildren<SideNavEntryProps>> = ({ index, action, active, selected, isTopLevel, navCollapsed, children }) => {
    const [isExpanded, setIsExpanded] = useState(selected);

    useEffect(() => { setIsExpanded(selected) }, [selected]);

    return (<li key={index} className={`nav-item nav-link${active ? ' active' : ''} ${selected ? 'selected' : ''} ${isTopLevel ? 'top-level' : ''} ${navCollapsed? 'collapsed-item': ''}`}>
        {/* onClick={e => a.onClick && a.onClick(e, pathname)} */}
        <div className="inner">
            {
                isTopLevel && !navCollapsed && <> {
                    action.children ?
                        <div className="expand-btn" onClick={() => setIsExpanded(!isExpanded)}>
                            {isExpanded ? <Icon name="icon-expand-arrow" size="2rem" /> : <Icon name="icon-forward" size="2rem" />}
                        </div> :
                        <div className="expand-btn spacer" style={{ width: "2rem" }}></div>
                }
                </>
            }
            <Link to={action.destination} >
                {action.iconClassName && <Icon name={action.iconClassName} className="nav-icon" size="2rem" />}
                {!navCollapsed && <span className="caption">{action.caption}</span>}
            </Link>
        </div>
        {isExpanded && !navCollapsed && children && children}
    </li >)
}

export default SideNavEntry;