import React, { useEffect, useState } from 'react';
import Icon from '../icon/Icon';
import "./ExpansionPanel.scss";

const ExpansionPanel: React.FC<React.PropsWithChildren<{ title: string | React.ReactElement, initialExpanded?: boolean, onExpandedChange?: (isExpanded: boolean) => void }>> = ({ title, initialExpanded = false, children, onExpandedChange }) => {

    const [isExpanded, setIsExpanded] = useState(initialExpanded);

    useEffect(() => { onExpandedChange && onExpandedChange(isExpanded) }, [onExpandedChange, isExpanded]);

    return <div className="expansion-panel">
        <div className="title" onClick={() => { setIsExpanded(!isExpanded) }}>
            <div className="top">
                {isExpanded ? <Icon name="icon-expand-arrow" size="2rem" /> : <Icon name="icon-forward" size="2rem" />}
                <h4>
                    {title}
                </h4>
            </div>
        </div>
        {isExpanded && <div className="content">
            {children}
        </div>}
    </div>
}

export default ExpansionPanel;
