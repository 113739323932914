import React from 'react';
import { Route } from 'react-router-dom';

const SdtRoutes: React.FC = () => {
    return <>
        <Route path="app/template-management/templates-list" element={<h1>Seite Vorlagenübersicht</h1>} />
        <Route path="app/template-management/data-management" element={<h1>Seite Stammdanteverwaltung</h1>} />
        <Route path="app/template-management/export-data" element={<h1>Seite Daten exportieren</h1>} />        
    </>
}

export default SdtRoutes;