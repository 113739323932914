import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Features } from '../../data/field-constants/Features';

// https://devtrium.com/posts/how-keyboard-shortcut

const useKeyDown = (keys: string[] | string, node = null, ignoreFeature = false) => {
    var [isKeyDown, setIsKeyDown] = useState(false);


    // handle what happens on key down
    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            var keyList = Array.isArray(keys) ? keys : [keys];

            // check if one of the key is part of the ones we want
            if (keyList.some((key) => event.key === key)) {
                if (!isKeyDown) {                    
                    setIsKeyDown(true);
                }
            }
        },
        [isKeyDown, keys]
    );

    // handle what happens on key up
    const handleKeyUp = useCallback(
        (event: KeyboardEvent) => {
            var keyList = Array.isArray(keys) ? keys : [keys];

            // check if one of the key is part of the ones we want
            if (keyList.some((key) => event.key === key)) {                
                setIsKeyDown(false);
            }
        },
        [keys]
    );

    useEffect(() => {
        if (!ignoreFeature && !abp.features.isEnabled(Features.Application.HotKeys)) {
            return;
        }

        // target is either the provided node or the document
        const targetNode = node ?? document;
        // attach the event listeners
        targetNode &&
            targetNode.addEventListener("keydown", handleKeyDown);
        targetNode &&
            targetNode.addEventListener("keyup", handleKeyUp);

        // remove the event listeners
        return () => {
            targetNode &&
                targetNode.removeEventListener("keydown", handleKeyDown);
            targetNode &&
                targetNode.removeEventListener("keyup", handleKeyUp);
        }
    }, [handleKeyDown, handleKeyUp, node, ignoreFeature]);

    return [isKeyDown];
};

export default useKeyDown;