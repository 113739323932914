import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import useApi from '../../../common/hooks/useApi';
import { Category, CategoryApi, Job, JobApi, JobTask, JobTaskApi, ServiceConnectionDto, ServiceProviderDto, ServiceProvidersApi, WorkItem, WorkItemJobMapping, WorkItemSyncApi, WorkItemTypeMapping } from '../../../client/http';
import { FIELD_MAP_ITEMS_SOURCE, FIELD_MAP_ITEMS_TARGET } from './DevOpsAdministration';
import { L } from '../../../abp/utils';
import Dropdown from '../../../common/forms/validation/controls/dropdown/Dropdown';
import _, { replace } from 'lodash';
import { getIn, setIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { ServiceProviderItemRender } from '../../../scenes/protected-area/side-nav/service-provider-selection/ServiceProviderSelection';

import './CopyJobMappingsWizard.scss';
import Button from '../../../common/forms/controls/button/Button';
import Switch from '../../../common/forms/controls/switch/Switch';
import useLoadingState from '../../../common/hooks/useLoadingState';
import DataViewMessage from '../../../common/components/data-view-message/DataViewMessage';
import Icon from '../../../common/components/icon/Icon';
import { StringDiff } from 'react-string-diff';
import Input from '../../../common/forms/validation/controls/input/Input';
import IconButton from '../../../common/components/icon/IconButton';
import { firstOrDefault, removeItemAll } from '../../../util/EntityUtils';
import useComputation from '../../../common/forms/validation/na-va-form/useComputation';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';
import filter from '../../../util/FilterUtils';
import { showNotification } from '../../../common/components/notifications/NotificationHost';
import { log } from '../../../util/LoggingUtils';

type CopyJobMappingsWizardInput = {
    currentMapFromConnection: any;
    currentMapToConnection: any;
}

type JobMappingChange = {
    id?: string;
    change: 'added' | 'edited' | 'removed';
    areaNames?: string[];
    changedPaths: PathChange[];
}

type PathChange = {
    path: string;
    source: string;
    target: string;
}

type Transformation = {
    path: string;
    searchFor: string;
    replaceBy: string;
}

const FIELD_CURRENT_MAP_ITEMS_SOURCE = "currentMapItemsSource";
const FIELD_CURRENT_MAP_ITEMS_TARGET = "currentMapItemsTarget";


const CopyJobMappingsWizard: React.FC<CopyJobMappingsWizardInput> = ({ currentMapFromConnection, currentMapToConnection }) => {

    const currentMapFromConnectionId = currentMapFromConnection.serviceConnectionId;
    const currentMapToConnectionId = currentMapToConnection.serviceConnectionId;

    // Stepper

    const [step, setStep] = useState(0);
    const [maxStep, setMaxStep] = useState(0);

    useEffect(() => {
        if (step > maxStep)
            setMaxStep(step);
    }, [step, maxStep]);

    const handleStepChange = (e: StepperChangeEvent) => {
        setStep(e.value);
    }

    // Step 0

    const [serviceProviders] = useApi<ServiceProviderDto, ServiceProvidersApi>(c => new ServiceProvidersApi(c), c => c.apiServicesAppServiceProvidersGetServiceProvidersGet());

    const serviceConnections = useMemo(() => (serviceProviders as any).reduce((prev: any, current: any) => {
        return current?.serviceConnections ? [...prev,
        ...current.serviceConnections.map((c: ServiceConnectionDto) => ({ ...current, serviceConnectionName: c.displayName, serviceConnectionId: c.id, serviceConnection: c, serviceConnections: undefined }))] : prev
    }, []), [serviceProviders]);

    const [{ values: targetDataValues, setFieldValue: setTargetDataFieldValue }, setTargetDataState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const mapFromConnectionId = getIn(targetDataValues, FIELD_MAP_ITEMS_SOURCE)?.serviceConnectionId;
    const mapToConnectionId = getIn(targetDataValues, FIELD_MAP_ITEMS_TARGET)?.serviceConnectionId;

    // Step 1

    // Items in current mapping
    const [currentJobMappings, setCurrentJobMappings] = useState<any[] | null>(null);

    // Existing items with target mapping
    const [existingJobMappings, setExistingJobMappings] = useState<any[] | null>(null);

    const [onlySelectNewMappings, setOnlySelectNewMappings] = useState(true);
    const [jobMappingChanges, setJobMappingChanges] = useState<JobMappingChange[]>([]);

    // Items that will be created as they don't exist yet
    const [targetJobMappings, setTargetJobMappings] = useState<any[] | null>([]);
    const groupedTargetJobMappings = useMemo(() => _.groupBy(targetJobMappings, (m: WorkItemJobMapping) => m.projectName), [targetJobMappings]);

    const getEntityChanges = useCallback(<T extends object>(entityA: any, entityB: any, changeSelector: (entity: any, pathChanges: PathChange[]) => T,
        propertyRules: { path: string, function: (source: any, target: any) => { source: any, target: any } }[]): T[] => {

        if (!entityA || !entityB) return [];

        const changes: any[] = [];
        const changedPaths: PathChange[] = [];
        const paths = Object.keys(entityA);

        paths.forEach(p => {

            if (!Object.hasOwn(entityB, p)) return [];

            let source = getIn(entityA, p);
            let target = getIn(entityB, p);

            const matchingRules = propertyRules.filter(r => r?.path === p);
            matchingRules.forEach(r => {
                ({ source, target } = r.function(source, target));
            });

            // Handle arrays
            if (Array.isArray(source)) {
                source = source.join(', ');
                target = target?.join(', ');
            }

            if (source !== target) {
                changedPaths.push({ path: p, source, target });
            }
        })

        if (changedPaths.length) {
            changes.push(changeSelector(entityA, changedPaths));
        }

        return changes;
    }, []);

    useEffect(() => {
        if (!currentJobMappings?.length || !existingJobMappings)
            return;

        const targetItems: any[] = [];
        const changes: JobMappingChange[] = [];

        currentJobMappings.forEach((m: WorkItemJobMapping) => {
            // Search for new mappings by comparing area names
            // If no existing items are present, directly push as added
            if (existingJobMappings?.length === 0 || !existingJobMappings.find((em: WorkItemJobMapping) => m.areaNames?.sort().join(',') === em.areaNames?.sort().join(','))) {
                targetItems.push(m);
                changes.push({ id: m.id, areaNames: m.areaNames, change: 'added', changedPaths: [] });
            }

            if (!onlySelectNewMappings) {
                // Compare mappings by area name and highlight changes
                const matchingItem = existingJobMappings.find((em: WorkItemJobMapping) => m.areaNames?.sort().join(',') === em.areaNames?.sort().join(','));
                if (matchingItem) {
                    const propertyRules = [
                        // Handle area names
                        {
                            path: 'areaNames', function: (source: any, target: any) => {
                                return { source: source.join(', '), target: target?.join(', ') };
                            }
                        },
                        // Handle workItemTypeMappings
                        {
                            path: 'workItemTypeMappings', function: (source: any, target: any) => {
                                return {
                                    source: source.map((s: WorkItemTypeMapping) => `${s.type} (${s.jobTaskBaseNo}, ${s.workTypeCode})`).join(', '),
                                    target: target?.map((t: WorkItemTypeMapping) => `${t.type} (${t.jobTaskBaseNo}, ${t.workTypeCode})`)?.join(', ')
                                };
                            }
                        }
                    ];

                    const result = getEntityChanges(m, matchingItem, (entity, changedPaths) => { return { id: entity.id, areaNames: entity.areaNames, change: 'edited', changedPaths } as JobMappingChange },
                        propertyRules
                    );
                    changes.push(...result);
                    const resultIds = result.map(r => r.id);
                    targetItems.push(...currentJobMappings.filter(x => resultIds.includes(x.id)));
                }
            }
        });

        setTargetJobMappings(targetItems);
        setJobMappingChanges(changes);
    }, [currentJobMappings, existingJobMappings, onlySelectNewMappings, setTargetJobMappings, setJobMappingChanges, getEntityChanges]);

    const [, workItemSyncApi,] = useApi<any, WorkItemSyncApi>(c => new WorkItemSyncApi(c));
    const [startLoading, loadingComplete, loadingError, , jopMappingsDataViewData] = useLoadingState();

    const loadJobMappings = useCallback(() => {

        if (!currentMapFromConnectionId || !currentMapToConnectionId || !mapFromConnectionId || !mapToConnectionId) {
            return;
        }

        startLoading();

        const calls = [];
        calls.push(workItemSyncApi?.apiServicesDevOpsWorkItemSyncGetJobMappingsGet(currentMapFromConnectionId, currentMapToConnectionId));
        calls.push(workItemSyncApi?.apiServicesDevOpsWorkItemSyncGetJobMappingsGet(mapFromConnectionId, mapToConnectionId));

        Promise.all(calls).then(([current, existing]) => {
            current && setCurrentJobMappings((current as any).result);
            existing && setExistingJobMappings((existing as any).result);
            loadingComplete();
        }).catch((e) => loadingError(e));

    }, [currentMapFromConnectionId, currentMapToConnectionId, mapFromConnectionId, mapToConnectionId, workItemSyncApi,
        setCurrentJobMappings, setExistingJobMappings, startLoading, loadingComplete, loadingError]);

    useEffect(() => {
        if (step === 1) {
            loadJobMappings();
        }
    }, [step, loadJobMappings])

    const getChangeStatusIcon = useCallback((change?: JobMappingChange) => {

        switch (change?.change) {
            case 'added':
                return <Icon name="icon-add" size="1.5rem" />
            case 'edited':
                return <Icon name="icon-ball-point-pen" size="1.5rem" />
            case 'removed':
                return <Icon name="icon-trash" size="1.5rem" />
            default:
                return null;
        }
    }, []);

    const getPropertyAsDiff = useCallback((m: WorkItemJobMapping, path: string, change?: JobMappingChange, fallback?: string) => {
        const defaultValue = fallback ?? getIn(m, path);

        if (!change || change.change !== 'edited' || !change.changedPaths.length) {
            return <>{defaultValue}</>
        }

        const changedPath = change.changedPaths.find(c => c.path === path);

        if (!changedPath) {
            return <>{defaultValue}</>
        }

        return <StringDiff newValue={changedPath.target} oldValue={changedPath.source} />
    }, []);


    // Step 2

    const [transformationChanges, setTransformationChanges] = useState<JobMappingChange[]>([]);
    const [transformedMappings, setTransformedMappings] = useState<WorkItemJobMapping[]>([]);
    const groupedTransformedJobMappings = useMemo(() => _.groupBy(transformedMappings, (m: WorkItemJobMapping) => m.projectName), [transformedMappings]);
    const [transformations, setTransformations] = useState<Transformation[]>([]);

    const [{ values: transformationFormValues, setFieldValue: setTransformationFormFieldValue }, setTransformationFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const addTransformation = useCallback(() => {
        setTransformations(t => [...t, {
            path: getIn(transformationFormValues, 'path'),
            searchFor: getIn(transformationFormValues, 'searchFor'),
            replaceBy: getIn(transformationFormValues, 'replaceBy')
        }]);
    }, [transformationFormValues, setTransformations]);

    const removeTransformation = useCallback((transformation: Transformation) => {
        const res = removeItemAll(transformations, transformation);
        setTransformations(res);
    }, [setTransformations, transformations]);

    const applyTransformations = useCallback(() => {

        const allTransformations: Transformation[] = [...transformations, {
            path: getIn(transformationFormValues, 'path'),
            searchFor: getIn(transformationFormValues, 'searchFor'),
            replaceBy: getIn(transformationFormValues, 'replaceBy')
        }];

        if (!allTransformations.length) {
            setTransformedMappings(targetJobMappings ?? []);
            return;
        }

        const visitPaths = <T extends object>(item: T, path: string, currentPath: string = '', searchFor?: string, replaceBy?: string): T => {
            if (!searchFor || !replaceBy) return item;

            if (path?.includes('.')) {
                let pathParts = path?.split('.');
                const current = pathParts.splice(0, 1)[0];

                // array of objects
                if (current.startsWith('[')) {
                    if (!current.endsWith(']')) {
                        throw new Error(`Incomplete index expression "${current}", expected ']'.`);
                    }
                    const index = current.substring(1, current.length - 1);
                    const currentValue = getIn(item, currentPath);

                    if (index === '*') {
                        if (!Array.isArray(currentValue)) {
                            throw new Error(`Value at "${currentPath}" is not an array.`);
                        }

                        const targetValues: any = currentValue.map(x => {
                            return visitPaths(x, pathParts.join('.'), undefined, searchFor, replaceBy);
                        });

                        return setIn(item, currentPath, targetValues);

                    } else {
                        currentValue[index] = visitPaths(currentValue[index], pathParts.join('.'), undefined, searchFor, replaceBy);

                        return setIn(item, currentPath, currentValue);
                    }
                }

                return visitPaths(item, pathParts.join('.'), [currentPath, current].filter(x => !!x).join('.'), searchFor, replaceBy);
            }

            // array of values
            if (path.startsWith('[')) {
                if (!path.endsWith(']')) {
                    throw new Error(`Incomplete index expression "${path}", expected ']'.`);
                }

                const index = path.substring(1, path.length - 1);
                const currentValue = getIn(item, currentPath);

                if (index === '*') {
                    if (!Array.isArray(currentValue)) {
                        throw new Error(`Value at "${currentPath}" is not an array.`);
                    }

                    const targetValues: any = currentValue.map(x => {
                        return x.toString().replace(searchFor, replaceBy);
                    });

                    return setIn(item, currentPath, targetValues);

                } else {
                    currentValue[index] = currentValue[index].toString().replace(searchFor, replaceBy);

                    return setIn(item, currentPath, currentValue);
                }

            }

            const completePath = [currentPath, path].filter(x => !!x).join('.');
            const currentValue = getIn(item, completePath).toString();
            const targetValue = currentValue.replace(searchFor, replaceBy);

            return setIn(item, completePath, targetValue);
        }

        const transformed = (targetJobMappings ?? []).slice().map(m => {

            for (const t of allTransformations) {
                try {
                    m = visitPaths(m, t.path, undefined, t.searchFor, t.replaceBy);
                } catch (error) {
                    // console.log("transformation error", error);
                }
            };

            return m;
        });
        log(transformed);

        setTransformedMappings(transformed);

        const changes: JobMappingChange[] = [];
        targetJobMappings?.forEach(m => {
            const matchingItem = transformed.find((em: WorkItemJobMapping) => m.areaNames?.sort().join(',') === em.areaNames?.sort().join(','));

            if (!matchingItem) return;

            const propertyRules = [
                // Handle area names
                {
                    path: 'areaNames', function: (source: any, target: any) => {
                        return { source: source.join(', '), target: target?.join(', ') };
                    }
                },
                // Handle workItemTypeMappings
                {
                    path: 'workItemTypeMappings', function: (source: any, target: any) => {
                        return {
                            source: source.map((s: WorkItemTypeMapping) => `${s.type} (${s.jobTaskBaseNo}, ${s.workTypeCode})`).join(', '),
                            target: target?.map((t: WorkItemTypeMapping) => `${t.type} (${t.jobTaskBaseNo}, ${t.workTypeCode})`)?.join(', ')
                        };
                    }
                }
            ];

            const result = getEntityChanges(m, matchingItem, (entity, changedPaths) => { return { id: entity.id, areaNames: entity.areaNames, change: 'edited', changedPaths } as JobMappingChange },
                propertyRules
            );
            changes.push(...result);
        });

        setTransformationChanges(changes);

    }, [transformations, transformationFormValues, targetJobMappings, getEntityChanges]);

    useEffect(() => {
        applyTransformations();
    }, [applyTransformations]);

    const pathTemplate = getIn(transformationFormValues, 'pathTemplate')?.path;

    useEffect(() => { pathTemplate && setTransformationFormFieldValue?.("path", pathTemplate); },
        [pathTemplate, setTransformationFormFieldValue]);

    // Step 3

    const [, jobApi] = useApi<Job, JobApi>(c => new JobApi(c));
    const [, jobTaskApi] = useApi<JobTask, JobTaskApi>(c => new JobTaskApi(c));
    const [, categoryApi] = useApi<Category, CategoryApi>(c => new CategoryApi(c));

    const [jobs, setJobs] = useState<Job[] | undefined>(undefined);
    const [jobTasks, setJobTasks] = useState<{ [jobId: string]: JobTask[] } | undefined>(undefined);
    const [categories, setCategories] = useState<Category[] | undefined>(undefined);

    const [loadJobs, jobsLoaded, jobsError, jobsLoading] = useLoadingState();
    const [loadJobTasks, jobTasksLoaded, jobTasksError, jobTasksLoading] = useLoadingState();
    const [loadCategories, categoriesLoaded, categoriesError, categoriesLoading] = useLoadingState();

    const [validationErrors, setValidationErrors] = useState([]);

    const loadReferenceData = useCallback(async () => {
        const target = getIn(targetDataValues, FIELD_MAP_ITEMS_TARGET);
        const jobNos = transformedMappings.map(m => m.jobNo);

        loadJobs();
        loadJobTasks();
        loadCategories();

        let jobData: Job[] | undefined = jobs;
        if (!jobData?.length) {
            jobData = (await jobApi?.apiServicesAppJobGetAllGet(target.providerName, target.serviceConnectionId, undefined,
                JSON.stringify([filter("status").notEqual("Completed"), filter("status").notEqual("Archive")])) as any)?.result;
            setJobs(jobData);
        }

        jobsLoaded();

        const jobIds = (jobData ?? []).filter(j => jobNos.includes(j.designation)).map(j => j.id);
        const jobTaskData: { [jobId: string]: JobTask[] } | undefined = {};

        const tasks = [];
        for (const jid of jobIds) {
            tasks.push(jobTaskApi?.apiServicesAppJobTaskGetAllByJobGet(jid, target.providerName, target.serviceConnectionId));
        }

        const results = await Promise.all(tasks);
        results.forEach(res => {
            const jt = (res as any)?.result as JobTask[];
            if (!firstOrDefault(jt)?.jobId) return;

            jobTaskData[firstOrDefault(jt)!.jobId!] = jt;
        })

        setJobTasks(jobTaskData);

        jobTasksLoaded();

        let categoryData: Category[] | undefined = categories;
        if (!categories?.length) {
            categoryData = (await categoryApi?.apiServicesAppCategoryGetAllGet(target.providerName, target.serviceConnectionId) as any)?.result;

            setCategories(categoryData);
        }

        categoriesLoaded();

    }, [jobApi, jobTaskApi, categoryApi, targetDataValues, transformedMappings,
        loadJobs, loadJobTasks, loadCategories, jobsLoaded, jobTasksLoaded, categoriesLoaded]);

    useEffect(() => {
        if (step === 3) {
            loadReferenceData();
        }
    }, [step, loadReferenceData])

    const isJobNoValid = useCallback((jobNo?: string) => {
        return jobNo && jobs && jobs?.find(j => j.designation === jobNo);
    }, [jobs])


    const isJobTaskBaseNoValid = useCallback((jobNo?: string, jobTaskBaseNo?: string) => {
        if (!jobNo || !jobTasks) return false;
        const jobId = jobs?.find(j => j.designation === jobNo)?.id;
        if (!jobId || !jobTasks || !jobTaskBaseNo) return false;
        const existsExact = !!jobTasks[jobId]?.find(jt => jt.designation?.startsWith(jobTaskBaseNo));

        if (!existsExact) {
            const parts = jobTaskBaseNo.split('.');
            return jobTasks[jobId]?.find(jt => jt.designation?.startsWith(parts.slice(0, -1).join('.')));
        }

        return true;
    }, [jobs, jobTasks]);

    const isCategoryNoValid = useCallback((categoryNo?: string) => {
        return categoryNo && categories && categories?.find(c => c.designation === categoryNo);
    }, [categories])

    const getValidationStatus = useCallback((m: WorkItemJobMapping) => {
        if (jobsLoading) {
            return <Icon name='icon-clock' size="1.5rem" />;
        } else if (!jobsLoading && jobTasksLoading) {
            return <>
                {isJobNoValid(m.jobNo) ?
                    <span className="text-success mx-3">{L('Job')} <Icon name="icon-checked" size="1.5rem" color="var(--bs-success)" /></span> :
                    <span className="text-danger mx-3">{L('Job')} <Icon name="icon-cancel" size="1.5rem" color="var(--bs-danger)" /></span>}
                <Icon name='icon-clock' size="1.5rem" />
            </>;
        } else if (!jobsLoading && !jobTasksLoading && categoriesLoading) {
            return <>
                {isJobNoValid(m.jobNo) ?
                    <span className="text-success mx-3">{L('Job')} <Icon name="icon-checked" size="1.5rem" color="var(--bs-success)" /></span> :
                    <span className="text-danger mx-3">{L('Job')} <Icon name="icon-cancel" size="1.5rem" color="var(--bs-danger)" /></span>}
                {isJobTaskBaseNoValid(m.jobNo, m.defaultJobTaskBaseNo) ?
                    <span className="text-success mx-3">{L('JobTask')} <Icon name="icon-checked" size="1.5rem" color="var(--bs-success)" /></span> :
                    <span className="text-danger mx-3">{L('JobTask')} <Icon name="icon-cancel" size="1.5rem" color="var(--bs-danger)" /></span>}
                <Icon name='icon-clock' size="1.5rem" />
            </>;
        } else {
            return <>
                {isJobNoValid(m.jobNo) ?
                    <span className="text-success mx-3">{L('Job')} <Icon name="icon-checked" size="1.5rem" color="var(--bs-success)" /></span> :
                    <span className="text-danger mx-3">{L('Job')} <Icon name="icon-cancel" size="1.5rem" color="var(--bs-danger)" /></span>}
                {isJobTaskBaseNoValid(m.jobNo, m.defaultJobTaskBaseNo) ?
                    <span className="text-success mx-3">{L('JobTask')} <Icon name="icon-checked" size="1.5rem" color="var(--bs-success)" /></span> :
                    <span className="text-danger mx-3">{L('JobTask')} <Icon name="icon-cancel" size="1.5rem" color="var(--bs-danger)" /></span>}
                {isCategoryNoValid(m.defaultCategoryNo) ?
                    <span className="text-success mx-3">{L('Category')} <Icon name="icon-checked" size="1.5rem" color="var(--bs-success)" /></span> :
                    <span className="text-danger mx-3">{L('Category')} <Icon name="icon-cancel" size="1.5rem" color="var(--bs-danger)" /></span>}
            </>;
        }
    }, [jobsLoading, jobTasksLoading, categoriesLoading, isJobNoValid, isJobTaskBaseNoValid, isCategoryNoValid]);

    const validTransformedMappings = useMemo(() => {
        return _.flatMap(groupedTransformedJobMappings).filter(m => isJobNoValid(m.jobNo) && isJobTaskBaseNoValid(m.jobNo, m.defaultJobTaskBaseNo)
            && isCategoryNoValid(m.defaultCategoryNo) && m.workItemTypeMappings?.every(wim => isJobTaskBaseNoValid(m.jobNo, wim.jobTaskBaseNo) && isCategoryNoValid(wim.workTypeCode)))
    }, [groupedTransformedJobMappings, isJobNoValid, isJobTaskBaseNoValid, isCategoryNoValid]);

    const copyMappings = useCallback(() => {
        validTransformedMappings.forEach(m => {
            workItemSyncApi?.apiServicesDevOpsWorkItemSyncCreateOrUpdateJobMappingPost({ ...m, id: undefined }, undefined, mapFromConnectionId, mapToConnectionId)
                .then((d) => {
                    showNotification("Zuordnung übertragen", m.areaNames?.join(', ') ?? '', 'success', 5000);
                })
                .catch((e) => {
                    showNotification("Fehler bei Übertragung", m.areaNames?.join(', ') ?? '', 'error');
                });
        })
    }, [validTransformedMappings, mapFromConnectionId, mapToConnectionId, workItemSyncApi]);

    // Steps list

    const step0Valid = currentMapFromConnectionId && currentMapToConnectionId && mapFromConnectionId && mapToConnectionId;
    const step1Valid = step0Valid && targetJobMappings?.length;
    const step2Valid = step1Valid && transformedMappings.length;

    const steps = ([
        {
            label: "Ziel festlegen",
            isValid: step0Valid
        },
        {
            label: "Daten wählen",
            disabled: !step0Valid,
            isValid: step1Valid
        },
        {
            label: "Transformationen anwenden",
            disabled: !step1Valid,
            isValid: step2Valid
        },
        {
            label: "Abschließen",
            disabled: !step2Valid,
            isValid: undefined
        }
    ]);

    return <div className="copy-job-mappings-wizard">

        <Stepper
            value={step}
            onChange={handleStepChange}
            items={steps}
            mode="labels"
        />

        <div className="copy-job-mappings-wizard inner row">

            {<div className="step step-0 col" style={{ display: step === 0 ? 'block' : 'none' }}>


                <NaVaForm
                    initialValues={{
                        [FIELD_CURRENT_MAP_ITEMS_SOURCE]: currentMapFromConnection,
                        [FIELD_CURRENT_MAP_ITEMS_TARGET]: currentMapToConnection,
                        [FIELD_MAP_ITEMS_SOURCE]: currentMapFromConnection
                    }}
                    onSubmit={() => { }}
                >
                    <NaVaFormStatePuller onStateChanged={v => setTargetDataState(v)}></NaVaFormStatePuller>

                    <h3>Aktuelle Zuordnung:</h3>

                    <div className="row mx-0 my-2 align-items-center">
                        {L('MapFrom')}
                        <Dropdown name={FIELD_CURRENT_MAP_ITEMS_SOURCE} disabled={true} textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} itemRender={ServiceProviderItemRender} />
                        {L('to_Item')}
                        <Dropdown name={FIELD_CURRENT_MAP_ITEMS_TARGET} disabled={true} textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} itemRender={ServiceProviderItemRender} />
                    </div>

                    <h3>Ziel-Zuordnung:</h3>

                    <div className="row mx-0 my-2 align-items-center">
                        {L('MapFrom')}
                        <Dropdown name={FIELD_MAP_ITEMS_SOURCE} textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} itemRender={ServiceProviderItemRender} />
                        {L('to_Item')}
                        <Dropdown name={FIELD_MAP_ITEMS_TARGET} textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections?.filter((c: any) => c.serviceConnectionId !== currentMapToConnectionId)} itemRender={ServiceProviderItemRender} />
                    </div>



                </NaVaForm>

            </div>
            }

            {step === 1 && <div className="step step-1 col">

                <div className="row">
                    <Switch name="onlySelectNewItems" value={onlySelectNewMappings}
                        onChange={e => setOnlySelectNewMappings(e.target.value)} label="Nur neue Elemente übertragen"></Switch>
                </div>

                {jopMappingsDataViewData?.isLoading ? <DataViewMessage {...jopMappingsDataViewData} /> :

                    <table className="table table-striped table-bordered table-no-outer-border">
                        <thead>
                            <tr>
                                <th className="align-middle text-center">Status</th>
                                <th className="align-middle text-center">Azure DevOps Area Name</th>
                                <th className="align-middle text-center">Projekt-Nr.</th>
                                <th className="align-middle text-center">Kategorie-Nr.</th>
                                <th className="align-middle text-center">Aufgaben-Basis-Nr.</th>
                                <th className="align-middle text-center">Work Item Zuordnungen</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupedTargetJobMappings).sort().map(p => {
                                return <React.Fragment key={p}>

                                    <tr className="table-primary text-center">
                                        <td className="fw-bold" colSpan={6}>{p}</td>
                                    </tr>

                                    {
                                        groupedTargetJobMappings[p]?.map((m: WorkItemJobMapping) => {
                                            const change = jobMappingChanges.find(c => c.id === m.id);

                                            return <tr key={m.id}>
                                                <td className="align-middle text-center">{getChangeStatusIcon(change)}</td>
                                                <td className="align-middle">{getPropertyAsDiff(m, 'areaNames', change, m.areaNames?.join(', '))}</td>
                                                <td className="align-middle">{getPropertyAsDiff(m, 'jobNo', change)}</td>
                                                <td className="align-middle">{getPropertyAsDiff(m, 'defaultCategoryNo', change)}</td>
                                                <td className="align-middle">{getPropertyAsDiff(m, 'defaultJobTaskBaseNo', change)}</td>
                                                <td className="align-middle">{getPropertyAsDiff(m, 'workItemTypeMappings', change, m.workItemTypeMappings?.map(wim => `${wim.type} (${wim.jobTaskBaseNo}, ${wim.workTypeCode})`).join(' - '))}</td>
                                            </tr>
                                        })
                                    }
                                    {/* </div>; */}
                                </React.Fragment>
                            })}
                        </tbody>
                    </table>
                }

            </div>
            }


            {<div className="step step-2 col" style={{ display: step === 2 ? 'block' : 'none' }}>
                <h4>
                    Transformationen
                </h4>
                <div className="transformation-list col-12 col-xl-6">
                    {transformations.length ? transformations.map(t => {
                        return <div key={t.path + t.searchFor} className="dev-ops-mapping work-item-type-mapping">
                            <span className="left">
                                <span>{t.path}</span>
                                <span>{t.searchFor}</span>
                                <span>{t.replaceBy}</span>
                            </span>
                            <IconButton name="icon-trash" size="1rem" onClick={() => removeTransformation(t)} />
                        </div>
                    }) : <span>Keine Transformationen angewendet</span>}
                </div>

                <div className="row mt-3">
                    <div className="col-12 col-xl-6">

                        <h4>Neue Transformation erstellen</h4>

                        <NaVaForm
                            initialValues={{
                            }}
                            onSubmit={() => { addTransformation(); }}
                        >
                            <NaVaFormStatePuller onStateChanged={v => setTransformationFormState(v)}></NaVaFormStatePuller>
                            <div className="row">

                                <div className="col-sm-12 col-md-6">
                                    <Input name="path" />
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <Dropdown name="pathTemplate" data={
                                        [
                                            { name: 'Projekt-Nr.', path: 'jobNo' },
                                            { name: 'Aufgaben-Basis-Nr.', path: 'defaultJobTaskBaseNo' },
                                            { name: 'Kategorie-Nr.', path: 'defaultCategoryNo' },
                                            { name: 'Work Item Zuordnung → Aufgaben-Basis-Nr.', path: 'workItemTypeMappings.[*].jobTaskBaseNo' },
                                            { name: 'Work Item Zuordnung → Kategorie-Nr.', path: 'workItemTypeMappings.[*].workTypeCode' },
                                            { name: 'Work Item Zuordnung → Arbeitselement-Typ', path: 'workItemTypeMappings.[*].type' }
                                        ]
                                    } textField="name" dataItemKey="path" />
                                </div>
                            </div>
                            <Input name="searchFor" label="Suchen nach:" />
                            <Input name="replaceBy" label="Ersetzen durch:" />
                            <Button label="Hinzufügen" color="light" type="submit" className="mt-3" sm={6} md={4} lg={2} xl={4} />
                        </NaVaForm>

                    </div>
                </div>

                <div className="row mt-5 data-view">
                    <div className="col">
                        <table className="table table-striped table-bordered table-no-outer-border">
                            <thead>
                                <tr>
                                    <th className="align-middle text-center">Azure DevOps Area Name</th>
                                    <th className="align-middle text-center">Projekt-Nr.</th>
                                    <th className="align-middle text-center">Kategorie-Nr.</th>
                                    <th className="align-middle text-center">Aufgaben-Basis-Nr.</th>
                                    <th className="align-middle text-center">Work Item Zuordnungen</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(groupedTransformedJobMappings).sort().map(p => {
                                    return <React.Fragment key={p}>

                                        <tr className="table-primary text-center">
                                            <td className="fw-bold" colSpan={6}>{p}</td>
                                        </tr>

                                        {
                                            groupedTransformedJobMappings[p]?.map((m: WorkItemJobMapping) => {
                                                const change = transformationChanges.find(c => c.id === m.id);

                                                return <tr key={m.id}>
                                                    <td className="align-middle">{getPropertyAsDiff(m, 'areaNames', change, m.areaNames?.join(', '))}</td>
                                                    <td className="align-middle">{getPropertyAsDiff(m, 'jobNo', change)}</td>
                                                    <td className="align-middle">{getPropertyAsDiff(m, 'defaultCategoryNo', change)}</td>
                                                    <td className="align-middle">{getPropertyAsDiff(m, 'defaultJobTaskBaseNo', change)}</td>
                                                    <td className="align-middle">{getPropertyAsDiff(m, 'workItemTypeMappings', change, m.workItemTypeMappings?.map(wim => `${wim.type} (${wim.jobTaskBaseNo}, ${wim.workTypeCode})`).join(' - '))}</td>
                                                </tr>
                                            })
                                        }
                                        {/* </div>; */}
                                    </React.Fragment>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>}

            {step === 3 && <div className="step step-3 col">

                <table className="table table-striped table-bordered table-no-outer-border">
                    <thead>
                        <tr>
                            <th className="align-middle text-center">Azure DevOps Area Name</th>
                            <th className="align-middle text-center">Projekt-Nr.</th>
                            <th className="align-middle text-center">Kategorie-Nr.</th>
                            <th className="align-middle text-center">Aufgaben-Basis-Nr.</th>
                            <th className="align-middle text-center">Work Item Zuordnungen</th>
                            {/* <th className="align-middle text-center">Status</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(groupedTransformedJobMappings).sort().map(p => {
                            return <React.Fragment key={p}>

                                <tr className="table-primary text-center">
                                    <td className="fw-bold" colSpan={6}>{p}</td>
                                </tr>

                                {
                                    groupedTransformedJobMappings[p]?.map((m: WorkItemJobMapping) => {
                                        return <tr key={m.id}>
                                            <td className="align-middle">{m.areaNames?.join(', ')}</td>
                                            <td className="align-middle">{jobsLoading ? <Icon name='icon-clock' size="1rem" /> :
                                                isJobNoValid(m.jobNo) ?
                                                    <span className="text-success mx-3">{m.jobNo} <Icon name="icon-checked" size="1rem" color="var(--bs-success)" /></span> :
                                                    <span className="text-danger mx-3">{m.jobNo} <Icon name="icon-cancel" size="1rem" color="var(--bs-danger)" /></span>}</td>
                                            <td className="align-middle">{categoriesLoading ? <Icon name='icon-clock' size="1rem" /> :
                                                isCategoryNoValid(m.defaultCategoryNo) ?
                                                    <span className="text-success mx-3">{m.defaultCategoryNo} <Icon name="icon-checked" size="1rem" color="var(--bs-success)" /></span> :
                                                    <span className="text-danger mx-3">{m.defaultCategoryNo} <Icon name="icon-cancel" size="1rem" color="var(--bs-danger)" /></span>}</td>
                                            <td className="align-middle">{jobTasksLoading ? <Icon name='icon-clock' size="1rem" /> :
                                                isJobTaskBaseNoValid(m.jobNo, m.defaultJobTaskBaseNo) ?
                                                    <span className="text-success mx-3">{m.defaultJobTaskBaseNo} <Icon name="icon-checked" size="1rem" color="var(--bs-success)" /></span> :
                                                    <span className="text-danger mx-3">{m.defaultJobTaskBaseNo} <Icon name="icon-cancel" size="1rem" color="var(--bs-danger)" /></span>}</td>
                                            <td className="align-middle">{jobTasksLoading || categoriesLoading ? <Icon name='icon-clock' size="1rem" /> :
                                                m.workItemTypeMappings?.map(wim => <>
                                                    <span>{wim.type} (</span>
                                                    {isJobTaskBaseNoValid(m.jobNo, wim.jobTaskBaseNo) ?
                                                        <span className="text-success mx-3">{wim.jobTaskBaseNo} <Icon name="icon-checked" size="1rem" color="var(--bs-success)" /></span> :
                                                        <span className="text-danger mx-3">{wim.jobTaskBaseNo} <Icon name="icon-cancel" size="1rem" color="var(--bs-danger)" /></span>}
                                                    <span>, </span>
                                                    {isCategoryNoValid(wim.workTypeCode) ?
                                                        <span className="text-success mx-3">{wim.workTypeCode} <Icon name="icon-checked" size="1rem" color="var(--bs-success)" /></span> :
                                                        <span className="text-danger mx-3">{wim.workTypeCode} <Icon name="icon-cancel" size="1rem" color="var(--bs-danger)" /></span>}
                                                    <span>)</span>
                                                </>)}
                                            </td>
                                            {/* <td className="align-middle text-center">{getValidationStatus(m)}
                                            </td> */}
                                        </tr>
                                    })
                                }
                                {/* </div>; */}
                            </React.Fragment>
                        })}
                    </tbody>
                </table>

                <Button color="primary" label={`${validTransformedMappings?.length}/${_.flatMap(groupedTransformedJobMappings)?.length} gültige Zuordnungen übertragen`} onClick={() => copyMappings()} disabled={!validTransformedMappings.length}></Button>

            </div>
            }

        </div>
        {
            step !== 3 && <div className="stepper-control row justify-content-around mt-3">
                <Button label="Zurück" color="primary" disabled={step === 0 || steps[step - 1]?.disabled} sm={3} onClick={() => setStep(s => s - 1)} />
                <Button label="Weiter" color="primary" disabled={step === steps.length - 1 || steps[step + 1]?.disabled} sm={3} onClick={() => setStep(s => s + 1)} />
            </div>
        }
    </div >
}

export default CopyJobMappingsWizard;