import React, { useRef } from 'react';
import { DatePicker as KendoDatePicker, DatePickerProps as KendoDatePickerProps } from '@progress/kendo-react-dateinputs';
import ControlWrapper, { ControlWrapperProps } from '../control-wrapper/ControlWrapper';
import DateInput from './DateInput';
import { DateTime } from 'luxon';
import "./DatePicker.scss";
import ShortcutKey from '../../../components/shortcut-key/ShortcutKey';

export type DatePickerProps = {
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  innerRef?: ((instance: HTMLInputElement | null) => void);
  format?: string;
  displayShortcutKey?: string;
} & Pick<KendoDatePickerProps, 'name' | 'value' | 'onChange' | 'disabled'> & ControlWrapperProps;

const DatePicker: React.FC<DatePickerProps> = React.memo(props => {
  const title = useRef<HTMLSpanElement | null>(null);
  return (
    <ControlWrapper
      xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl}
      offset={props.offset} offsetSm={props.offsetSm} offsetMd={props.offsetMd} offsetLg={props.offsetLg} offsetXl={props.offsetXl} offsetXxl={props.offsetXxl}
      m={props.m} mt={props.mt} mb={props.mb} ml={props.ml} mr={props.mr} mx={props.mx} my={props.my}
      error={props.error} label={props.label} disabled={props.disabled} title={props.title}
    >
      <KendoDatePicker
        format={props.format || 'DD'}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        dateInput={({ format, ...inputProps }) => (
          <span className="k-widget k-dateinput">
            <span className="k-dateinput-wrap">
              <span ref={title} onClick={(e => {
                const input = (e.target as HTMLElement).parentElement?.querySelector('input');
                (e.target as HTMLElement).style.setProperty('display', 'none');
                input && input.classList.remove('visually-hidden');
                input && input?.select();
                input && input.focus();
              })}>
                {props.value && DateTime.fromJSDate(props.value).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
              </span>
              <DateInput format={"dd.MM.yyyy"} {...inputProps} onBlur={(e) => {
                props.onBlur && props.onBlur(e);
                e.target.classList.add('visually-hidden');
                title.current && title.current.style.setProperty('display', 'block');
              }} innerRef={props.innerRef} placeholder={props.placeholder} className="visually-hidden"
                onFocus={e => {                  
                  const input = (e.target as HTMLInputElement);
                  input.classList.remove('visually-hidden');
                  title.current && title.current.style.setProperty('display', 'none')
                }}
              />
            </span>
          </span>
        )} />
      <ShortcutKey shortcutKey={props.displayShortcutKey} />
    </ControlWrapper>
  )
});

export default DatePicker;
