import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.min.css';

const CodeView: React.FC<{ data?: string, lang: string }> = ({ data, lang }) => {

    // Add language support for C++
    require(`prismjs/components/prism-${lang}`);

    // To remove extra white spaces
    require("prismjs/plugins/normalize-whitespace/prism-normalize-whitespace");

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    return <div className="dev-ops-sync__protocols-code-view">
        <pre>
            <code className={`lang-${lang}`}>
                {data}
            </code>
        </pre>
    </div>
};

export default CodeView;