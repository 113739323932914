import { createContext, useContext } from 'react';
import { NaVaFormValues, NaVaFormContextType } from './types';

// export type NavigateableFormContext = {
//   registerField: (name: string) => void;
//   setFieldValue: (name: string, value: any, shouldValidate?: boolean | undefined, debounceValidation?: boolean | undefined) => void;
//   setFieldTouched: (name: string, touched?: boolean | undefined, shouldValidate?: boolean | undefined, debounceValidation?: boolean | undefined) => void;
//   getFieldElementRef: (name: string) => (element: HTMLElement | null) => void;
//   getFieldKeyDownHandler: (name: string) => (e: React.KeyboardEvent<any>) => void;
// } & Pick<NaVaFormContextType<NaVaFormValues>, 'initialValues' | 'values' | 'errors' | 'touched' | 'handleSubmit' | 'handleReset'>;

export const NaVaFormContext = createContext<NaVaFormContextType<NaVaFormValues> | null>(null);

export function useNaVaFormContext(): NaVaFormContextType<NaVaFormValues> {
  const ctx = useContext(NaVaFormContext);
  if (ctx === null) throw new Error('No NavigateableFormContext provided');
  return ctx;
}
