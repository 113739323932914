import axios from 'axios';
import qs from 'qs';
import { DEFAULT_TENANT } from '../scenes/login/Login';
import AppConsts from './constants';

export const httpClient = axios.create({
  baseURL: AppConsts.remoteServiceBaseUrl,
  timeout: 30000,
  paramsSerializer: params => qs.stringify(params, { encode: false })
});

httpClient.interceptors.request.use(
  function (config) {    
      // if (!!abp.auth.getToken()) {
      //   //@ts-ignore
      //   config.headers.common.Authorization = `Bearer ${abp.auth.getToken()}`;
      // }

      //@ts-ignore
      config.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue('Abp.Localization.CultureName');
    //@ts-ignore
    config.headers.common['Abp.TenantId'] = localStorage.getItem(DEFAULT_TENANT) ?? abp.multiTenancy.getTenantIdCookie();

    return config;
  },
  error => Promise.reject(error)
);

// http.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     //TODO: Present error to user
//     if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
//       console.log({
//         title: error.response.data.error.message,
//         content: error.response.data.error.details,
//       });
//     } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
//       console.log({
//         title: L('LoginFailed'),
//         content: error.response.data.error.message,
//       });
//     } else if (!error.response) {
//       // Show notif
//       // console.log({ content: L('UnknownError') });
//     }

//     setTimeout(() => { }, 1000);

//     return Promise.reject(error);
//   }
// );
