import React from 'react';
import { Switch as KendoSwitch, SwitchProps as KendoSwitchProps } from '@progress/kendo-react-inputs';
import ControlWrapper, { ControlWrapperProps } from '../control-wrapper/ControlWrapper';
import './Switch.scss';

export type SwitchProps = {
  innerRef?: ((instance: HTMLInputElement | null) => void);
  lookupIconClassName?: string;
  onLookupClick?: () => void;
  selectOnFocus?: boolean;  
}
  & Pick<KendoSwitchProps, 'name' | 'value' | 'onChange' | 'onBlur' | 'disabled' | 'onLabel' | 'offLabel'|'onFocus'>
  & ControlWrapperProps;
  
const Switch: React.FC<SwitchProps> = React.memo(props => (
  <ControlWrapper
    xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl}
    offset={props.offset} offsetSm={props.offsetSm} offsetMd={props.offsetMd} offsetLg={props.offsetLg} offsetXl={props.offsetXl} offsetXxl={props.offsetXxl}
    m={props.m} mt={props.mt} mb={props.mb} ml={props.ml} mr={props.mr} mx={props.mx} my={props.my}
    error={props.error}  disabled={props.disabled} className={'switch-control ' + (props.className ?? '')} title={props.title} omitFill={true}
  >
    {/* label={props.label} */}
    <KendoSwitch
      name={props.name}
      ref={e => props.innerRef && props.innerRef(e && e.element)}
      disabled={props.disabled}
      checked={props.value === true}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onLabel={props.onLabel}
      offLabel={props.offLabel}
      size='small'
    />
    {props.label && <span className="switch-label">{props.label}</span>}
  </ControlWrapper>
));

export default Switch;
