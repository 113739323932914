import { Checkbox } from '@progress/kendo-react-inputs';
import { DateTime } from 'luxon';
import React from 'react';
import { L } from '../../abp/utils';
import { UserApi, UserDto } from '../../client/http';
import useApi from '../../common/hooks/useApi';

const UserList: React.FC = () => {
    const [users] = useApi<UserDto, UserApi>((c) => new UserApi(c), (c) => c.apiServicesAppUserGetAllGet(undefined, undefined, undefined, 1000));

    return <div className="user-list p-4 pt-0">
        <div className="headings row mb-2 fw-bold">
            <div className="col-sm-3">
                {L("UserData")}
            </div>
            <div className="col-sm-3">
                {L("UserRole")}
            </div>
            <div className="col-sm-3">
                {L("IsActive")}
            </div>
            <div className="col-sm-3">
                {L("Actions")}
            </div>
        </div>
        {users.map((user) => {
            return <div className="user row align-items-center mb-2">
                <div className="col-sm-3 d-flex flex-column">
                    <span>
                        {user.surname} {user.name}
                    </span>
                    <span>
                        {L("Username")}:&ensp;{user.userName}
                    </span>
                    <span className="text-muted">
                        {user.emailAddress}
                    </span>
                </div>
                <div className="col-sm-3">
                    <span>{user.roleNames?.join(", ")}</span>
                </div>
                <div className="col-sm-3">
                    <Checkbox value={user.isActive} disabled={true} />
                </div>
                <div className="col-sm-3">
                {/* TODO Delete/Edit */}
                </div>
            </div>
        })}
    </div>
}

export default UserList;