import React, { useEffect } from 'react';
import useModuleContext from '../../module-components/module-context/useModuleContext';
import { Modules } from '../../module-components/Modules';
import { AppParts } from '../AppParts';
import CategorySelection from './service-entities/CategorySelection';
import CustomerSelection from './service-entities/CustomerSelection';
import JobDetails from './service-entities/detail-views/JobDetails';
import JobSelection from './service-entities/JobSelection';
import JobTaskSelection from './service-entities/JobTaskSelection';
import TimeEntityEditorFields from './time-tracking/time-entities/TimeEntityEditorFields';
import { EntityData, EntityDetails } from './time-tracking/time-entities/TimeEntityComponents';
import BudgetWidget from './budget-planning/BudgetWidget';
import BusinessCentral from './media/BusinessCentral_scalable.svg'
import JobGrid from './service-entities/JobGrid';

const ModuleRegistration: React.FC = () => {
    const { registerComponent } = useModuleContext();

    useEffect(() => {
        registerComponent(AppParts.Application.ProviderLogo('Rewind365.DynamicsBc.DeLuxProvider'), Modules.rewindBcDelux, true, (props: any) => <img src={BusinessCentral} alt="Provider Logo" {...props} />);

        registerComponent(AppParts.TimeTracking.TimeEntities.EditorFields, Modules.rewindBcDelux, true, (props: any) => <TimeEntityEditorFields {...props} />);
        registerComponent(AppParts.TimeTracking.TimeEntities.EntityData, Modules.rewindBcDelux, true, (props: any) => <EntityData {...props} />);
        registerComponent(AppParts.TimeTracking.TimeEntities.EntityDetails, Modules.rewindBcDelux, true, (props: any) => <EntityDetails {...props} />);

        registerComponent(AppParts.ServiceEntities.Details.Job, Modules.rewindBcDelux, true, (props: any) => <JobDetails {...props} />)
        registerComponent(AppParts.ServiceEntities.Selection.Job, Modules.rewindBcDelux, true, (props: any) => <JobSelection {...props} />)
        registerComponent(AppParts.ServiceEntities.Selection.JobTask, Modules.rewindBcDelux, true, (props: any) => <JobTaskSelection {...props} />)
        registerComponent(AppParts.ServiceEntities.Selection.Customer, Modules.rewindBcDelux, true, (props: any) => <CustomerSelection {...props} />)
        registerComponent(AppParts.ServiceEntities.Selection.Category, Modules.rewindBcDelux, true, (props: any) => <CategorySelection {...props} />)
        
        registerComponent(AppParts.ServiceEntities.Grid.Job, Modules.rewindBcDelux, true, (props: any) => <JobGrid {...props} />)
        
        registerComponent(AppParts.BudgetPlanning.BudgetWidget, Modules.rewindBcDelux, true, (props: any) => <BudgetWidget {...props} />);
    }, [registerComponent]);

    return null;
}

export default ModuleRegistration;