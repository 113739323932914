import React, { useCallback, useEffect, useState } from 'react';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import { AdministrationApi, AdministrationDataDto, EmailConfiguration } from '../../client/http';
import useApi from '../../common/hooks/useApi';
import Input from '../../common/forms/validation/controls/input/Input';
import NaVaFormStatePuller from '../../common/forms/NaVaFormStatePuller';
import useVisibility from '../../common/hooks/useVisibility';
import { L } from '../../abp/utils';
import { NaVaFormContextType, NaVaFormValues } from '../../common/forms/validation/na-va-form/types';
import Switch from '../../common/forms/validation/controls/switch/Switch';
import DataViewMessage from '../../common/components/data-view-message/DataViewMessage';
import Button from '../../common/forms/controls/button/Button';
import { log } from '../../util/LoggingUtils';

const EmailSettingsEditor: React.FC = () => {
    const [{ values, setFieldValue, resetForm }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const [adminData, adminApi, adminDataMessageData, refetch] = useApi<AdministrationDataDto, AdministrationApi>(c => new AdministrationApi(c), c => c.apiServicesAppAdministrationGetCoreAdminDataGet());
    const [saveState, setSaveState] = useState<boolean | undefined>();

    const submitSettings = useCallback(() => {
        setSaveState(undefined)
        adminApi?.apiServicesAppAdministrationUpdateEmailSettingsPut(values).then(() => {
            setSaveState(true);
            setTimeout(() => setSaveState(undefined), 5000);
            refetch();
        }).catch(() => {
            setSaveState(false);
        });
    }, [adminApi, setSaveState, refetch, values]);

    const emailConfiguration = (adminData as any).emailConfiguration;

    useEffect(() => {
        log(emailConfiguration);
        emailConfiguration && Object.keys(emailConfiguration).map(x => {
            if (x === 'smtpEnableSsl' || x === 'smtpUseDefaultCredentials') {
                setFieldValue(x, emailConfiguration[x] === 'true')
            } else {
                setFieldValue(x, emailConfiguration[x])
            }
            return null;
        });
    }, [adminData, emailConfiguration, setFieldValue])

    return <div className="email-setup p-4 pt-0">
        <NaVaForm initialValues={{} as EmailConfiguration}
            onSubmit={submitSettings}>
            <NaVaFormStatePuller onStateChanged={(s) => setFormState(s)} />
            {(!adminData || adminDataMessageData?.isLoading) ? <DataViewMessage {...adminDataMessageData} /> :
                <>
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <h5>{L('ServerSettings')}</h5>
                            <Input name="defaultFromAddress" type="email" label={L('EmailDefaultFromAddress')} sm={12} lg={8} />
                            <Input name="defaultFromDisplayName" type="text" label={L('EmailDefaultFromDisplayName')} sm={12} lg={8} />
                            <Input name="smtpHost" type="text" label={L('EmailSmtpHost')} sm={12} lg={8} />
                            <Input name="smtpPort" type="text" label={L('EmailSmtpPort')} sm={12} lg={8} />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <h5>{L('UserCredentials')}</h5>
                            <Input name="smtpUserName" type="text" label={L('EmailSmtpUserName')} sm={12} lg={8} />
                            <Input name="smtpPassword" type="text" label={L('EmailSmtpPassword')} sm={12} lg={8} />
                            <Switch name="smtpEnableSsl" label={L('EmailSmtpSsl')} sm={12} lg={8} className="my-3" />
                            <Switch name="smtpUseDefaultCredentials" label={L('EmailUseDefaultCredentials')} sm={12} lg={8} className="my-3" />
                        </div>
                    </div>
                    <div className="row mt-5 me-2 justify-content-end align-items-center">
                        <div className="col-sm-6 col-lg-2">
                            {saveState !== undefined && (saveState ? <span className="text-success mx-3">{L('SuccessfullySaved')}</span> :
                                <span className="text-danger mx-3">{L('ErrorSaving')}</span>)}
                        </div>
                        <Button color="primary" label={L('Save')} sm={2} type="submit" />
                    </div>
                </>
            }
        </NaVaForm>
    </div>
}

export default EmailSettingsEditor;