import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DevOpsAppParts } from '../DevOpsAppParts';
import ModuleComponentProvider from '../../../module-components/module-context/ModuleComponentProvider';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import { ProjectSelectionProps } from '../service-entities/ProjectSelection';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { AreaSelectionProps } from '../service-entities/AreaSelection';
import { AppParts } from '../../AppParts';
import Input from '../../../common/forms/validation/controls/input/Input';
import { FIELD_CATEGORY, FIELD_CATEGORY_ID, FIELD_DESIGNATION, FIELD_JOB, FIELD_JOB_ID, FIELD_JOB_TASK, FIELD_JOB_TASK_ID } from '../../../data/field-constants/GeneralConstants';
import { JobSelectionProps, JobTaskSelectionProps, ResourceSelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import ComboBox from '../../../common/forms/validation/controls/combo-box/ComboBox';
import { workItemTypes } from '../DevOpsConstants';
import IconButton from '../../../common/components/icon/IconButton';
import { removeItemAll } from '../../../util/EntityUtils';
import { Job, WorkItemJobMapping, WorkItemSyncApi, WorkItemTypeMapping } from '../../../client/http';
import Button from '../../../common/forms/controls/button/Button';
import { useNaVaFormContext } from '../../../common/forms/validation/na-va-form/NaVaFormContext';
import { FIELD_MAP_ITEMS_SOURCE, FIELD_MAP_ITEMS_TARGET } from './DevOpsAdministration';
import useApi from '../../../common/hooks/useApi';
import _ from 'lodash';
import ExpansionPanel from '../../../common/components/expansion-panel/ExpansionPanel';
import { L } from '../../../abp/utils';
import Dropdown from '../../../common/forms/validation/controls/dropdown/Dropdown';
import useVisibility from '../../../common/hooks/useVisibility';
import { Dialog } from '@progress/kendo-react-dialogs';
import CopyJobMappingsWizard from './CopyJobMappingsWizard';
import Icon from '../../../common/components/icon/Icon';
import { log } from '../../../util/LoggingUtils';

const JobMappingEditor: React.FC<{
    onAddMapping?: (mapping: WorkItemJobMapping) => void
    onCancel?: () => void,
}> = ({ onAddMapping, onCancel }) => {

    const { values: administrationValues } = useNaVaFormContext();

    const sourceConnection = getIn(administrationValues, FIELD_MAP_ITEMS_SOURCE);
    const targetConnection = getIn(administrationValues, FIELD_MAP_ITEMS_TARGET);
    const mapFromConnectionId = sourceConnection.serviceConnectionId;
    const mapToConnectionId = targetConnection.serviceConnectionId;

    const [jobMappings, setJobMappings] = useState<any[] | null>(null);
    const groupedJobMappings = useMemo(() => _.groupBy(jobMappings, (m: WorkItemJobMapping) => m.projectName), [jobMappings]);
    const [, workItemSyncApi,] = useApi<any, WorkItemSyncApi>(c => new WorkItemSyncApi(c));

    const [{ values, isValid, setFieldValue, submitForm }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const projectName = getIn(values, ['project', 'name']);
    const areaName = getIn(values, ['area', 'name']);
    const job = getIn(values, [FIELD_JOB]);
    const jobTaskDesignation = getIn(values, [FIELD_JOB_TASK, FIELD_DESIGNATION]);
    const jobTaskBaseNo = getIn(values, "jobTaskBaseNo");
    const categoryDesignation = getIn(values, [FIELD_CATEGORY, FIELD_DESIGNATION]);
    const defaultWorkTypeCode = getIn(values, "defaultWorkTypeCode");
    const workItemTypeMappings = getIn(values, 'workItemTypeMappings');

    const loadJobMappings = useCallback(() => {

        workItemSyncApi?.apiServicesDevOpsWorkItemSyncGetJobMappingsGet(mapFromConnectionId, mapToConnectionId)
            .then((d) => {
                setJobMappings((d as any).result)
            })
            .catch((e) => log(e));

    }, [mapFromConnectionId, mapToConnectionId, workItemSyncApi]);

    const addJobMapping = useCallback((mapping: WorkItemJobMapping) => {

        workItemSyncApi?.apiServicesDevOpsWorkItemSyncCreateOrUpdateJobMappingPost(mapping, undefined, mapFromConnectionId, mapToConnectionId)
            .then((d) => {
                loadJobMappings();
            })
            .catch((e) => log(e));

    }, [mapFromConnectionId, mapToConnectionId, workItemSyncApi, loadJobMappings]);

    const removeMapping = (id: string) => {

        workItemSyncApi?.apiServicesDevOpsWorkItemSyncDeleteJobMappingDelete(id)
            .then((d) => {
                loadJobMappings();
            })
            .catch((e) => log(e));

    };

    useEffect(() => {
        loadJobMappings();
    }, [loadJobMappings]);

    useEffect(() => {
        if (!jobTaskDesignation) return;

        const noParts = jobTaskDesignation.split('.');
        const baseNo = [...noParts.slice(0, noParts.length - 1), '2'].join('.')

        setFieldValue && setFieldValue("jobTaskBaseNo", baseNo);
    }, [setFieldValue, jobTaskDesignation]);

    const [isCreatingWorkItemMapping, setIsCreatingWorkItemMapping] = useState(true);

    const addWorkItemMapping = useCallback((type: string, jobTaskBaseNo: string, workTypeCode: string) => {
        setFieldValue('workItemTypeMappings', [...workItemTypeMappings, {
            type, jobTaskBaseNo, workTypeCode
        } as WorkItemTypeMapping]);
    }, [setFieldValue, workItemTypeMappings]);

    const removeWorkItemMapping = useCallback((mapping: WorkItemTypeMapping) => {
        log(mapping, removeItemAll(workItemTypeMappings, mapping));
        setFieldValue('workItemTypeMappings', removeItemAll(workItemTypeMappings, mapping));
    }, [setFieldValue, workItemTypeMappings]);

    const cancelCreateWorkItemMapping = useCallback(() => {
        setIsCreatingWorkItemMapping(false);
    }, []);

    useEffect(() => {
        if (!categoryDesignation) return;

        setFieldValue && setFieldValue('defaultWorkTypeCode', categoryDesignation);
    });

    const [{ values: overviewValues }, setOverviewFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const overviewFilter = getIn(overviewValues, "filter")?.toLowerCase();
    const overviewProjectFilter = getIn(overviewValues, "projectFilter");
    const [filteredGroupedMappings, setFilteredGroupedMappings] = useState(groupedJobMappings);
    const jobMappingKeys = Object.keys(groupedJobMappings);

    useEffect(() => {
        if (!jobMappingKeys?.length) return;

        if (!overviewFilter && !overviewProjectFilter) {
            setFilteredGroupedMappings(groupedJobMappings);
            return;
        }
        if (!overviewFilter && !!overviewProjectFilter) {
            setFilteredGroupedMappings({ [overviewProjectFilter]: groupedJobMappings[overviewProjectFilter] });
        }
        else {
            var filtered = {} as any;
            const keys = overviewProjectFilter ? [overviewProjectFilter] : jobMappingKeys;
            keys.map(k => {
                filtered[k] = groupedJobMappings[k]
                    .filter((m: WorkItemJobMapping) =>
                        !!m.areaNames?.find(a => a.toLowerCase().includes(overviewFilter)) ||
                        m.jobNo?.toLowerCase().includes(overviewFilter)
                    );
                return null;
            });
            setFilteredGroupedMappings(filtered);
        }

    }, [overviewFilter, groupedJobMappings, jobMappingKeys]);

    const [copyJobMappingsWindowVisible, showCopyJobMappingsWindow, hideCopyJobMappingsWindow] = useVisibility();

    return <div className="job-mapping-editor">

        <ExpansionPanel title={
            <h4>Projekt-Zuordnungen
                <span className="badge rounded-pill text-bg-secondary ms-3">
                    {jobMappings?.length} Elemente in {jobMappingKeys.length} Projekten
                </span>
            </h4>}>

            <NaVaForm
                initialValues={{
                }}
                onSubmit={() => { }}
            >
                <NaVaFormStatePuller onStateChanged={v => setOverviewFormState(v)}></NaVaFormStatePuller>

                <div className="row my-3">
                    <Input name="filter" sm={7} md={6} lg={4} placeholder={L('Search')} />
                    <div className="col-auto ms-3 my-auto">{L('DevOpsProject')}: </div>
                    <Dropdown name="projectFilter" data={['', ...jobMappingKeys]} sm={5} md={4} lg={3} />
                    <Button color="light" label="" sm="auto" onClick={showCopyJobMappingsWindow}>
                        <Icon name="icon-receive-file" svgStyle={{ transform: "scaleX(-1)" }} size="1.5rem"></Icon>
                        <span>Zuordnungen übertragen</span>
                    </Button>
                </div>

            </NaVaForm>
            {jobMappings?.length ?
                <table className="table table-striped table-bordered table-no-outer-border">
                    <thead>
                        <tr>
                            <th className="align-middle text-center">Azure DevOps Area Name</th>
                            <th className="align-middle text-center">Projekt-Nr.</th>
                            <th className="align-middle text-center">Kategorie-Nr.</th>
                            <th className="align-middle text-center">Aufgaben-Basis-Nr.</th>
                            <th className="align-middle text-center">Work Item Zuordnungen</th>
                            <th className="align-middle text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(filteredGroupedMappings).sort().map(p => {
                            // return <div className="job-mapping project-group" key={p}>
                            return <React.Fragment key={p}>

                                <tr className="table-primary text-center">
                                    <td className="fw-bold" colSpan={6}>{p}</td>
                                </tr>

                                {
                                    _.sortBy(filteredGroupedMappings[p] ?? [], (m: WorkItemJobMapping) => (m.areaNames?.sort()?.join('')) ?? '')?.map((m: WorkItemJobMapping) => {

                                        return <tr key={m.id}>
                                            <td className="align-middle">{m.areaNames?.join(', ')}</td>
                                            <td className="align-middle">{m.jobNo}</td>
                                            <td className="align-middle">{m.defaultCategoryNo}</td>
                                            <td className="align-middle">{m.defaultJobTaskBaseNo}</td>
                                            <td className="align-middle">{m.workItemTypeMappings?.map(wim => `${wim.type} (${wim.jobTaskBaseNo}, ${wim.workTypeCode})`).join(' - ')}</td>
                                            <td className="align-middle text-center">
                                                <IconButton name="icon-trash" size="1rem" onClick={() => removeMapping(m.id!)} />
                                            </td>
                                        </tr>
                                    })
                                }
                                {/* </div>; */}
                            </React.Fragment>
                        })}
                    </tbody>
                </table>
                : <span className="text-muted">Keine Zuordnungen eingerichtet</span>}
        </ExpansionPanel>

        <h3>Neue Zuordnung erstellen</h3>
        <NaVaForm
            initialValues={{
                workItemTypeMappings: []
            }}
            onSubmit={() => {
                const mapping = {
                    id: undefined,
                    projectName: projectName,
                    areaNames: [areaName],
                    jobNo: (job as Job | undefined)?.designation,
                    defaultCategoryNo: defaultWorkTypeCode,
                    defaultJobTaskBaseNo: jobTaskBaseNo,
                    workItemTypeMappings: workItemTypeMappings
                } as WorkItemJobMapping;

                onAddMapping && onAddMapping(mapping);

                addJobMapping(mapping);
            }}>
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>
            {/* <ModuleComponentProvider appPartName={DevOpsAppParts.ServiceEntities.Selection.WorkItem} props={{ foreignKeyName: 'workItemId', name: 'workItem', projectName, disabled: !projectName } as WorkItemSelectionProps} /> */}

            <div className="row">
                <div className="col-xs-12 col-lg-6">
                    <div className="row m-2 gap-1 my-3">
                        <h4>Dev-Ops Daten</h4>
                        <ModuleComponentProvider appPartName={DevOpsAppParts.ServiceEntities.Selection.Project} props={{ foreignKeyName: 'projectName', name: 'project' } as ProjectSelectionProps} />
                        <ModuleComponentProvider appPartName={DevOpsAppParts.ServiceEntities.Selection.Area} props={{ foreignKeyName: 'areaName', name: 'area', projectName, disabled: !projectName } as AreaSelectionProps} />
                    </div>

                    <div className="row m-3 gap-1 my-3">
                        <h4>BC-Daten</h4>
                        <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Job} props={{ name: FIELD_JOB, foreignKeyName: FIELD_JOB_ID, sm: 12, lg: 6 } as JobSelectionProps} getSingleComponent={true} />

                        <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.JobTask} props={{ name: FIELD_JOB_TASK, fieldJobName: FIELD_JOB, foreignKeyName: FIELD_JOB_TASK_ID, sm: 12, lg: 6 } as JobTaskSelectionProps} getSingleComponent={true} />
                        <Input xs={4} name='jobTaskBaseNo' placeholder='JobTaskBaseNo'></Input>

                        <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Category} props={{ name: FIELD_CATEGORY, foreignKeyName: FIELD_CATEGORY_ID, sm: 12, lg: 6, fetchFromAllProviders: false } as ResourceSelectionProps} getSingleComponent={true} />
                        <Input xs={4} name='defaultWorkTypeCode' placeholder='Work Type Code' disabled={true}></Input>
                    </div>
                </div>

                <div className="col-xs-12 col-lg-6">
                    <div className="row m-2 gap-31">
                        <h4>Unterscheidung des Work-Item Typs</h4>

                        {workItemTypeMappings?.length ? workItemTypeMappings.map((m: any) => {
                            return <div key={m.type} className="dev-ops-mapping work-item-type-mapping">
                                <span className="left">
                                    <span>{m.type}</span>
                                    <span>{m.jobTaskBaseNo}</span>
                                    <span>{m.workTypeCode}</span>
                                </span>
                                <IconButton name="icon-delete-file" size="1rem" onClick={() => removeWorkItemMapping(m)} />
                            </div>
                        }) : <span className="text-muted">Keine Mappings eingerichtet</span>}
                        {isCreatingWorkItemMapping ? <WorkItemTypeEditor onAddMapping={addWorkItemMapping} onCancel={cancelCreateWorkItemMapping} job={job} /> :

                            <Button xs={4} offsetXs={4} color="secondary" label="Neues Mapping" onClick={() => { }} />
                        }
                    </div>
                </div>
                <Button xs={4} offsetXs={4} color="primary" label="Speichern" onClick={() => { submitForm && submitForm() }} />
            </div>
        </NaVaForm>

        {copyJobMappingsWindowVisible &&
            <Dialog onClose={hideCopyJobMappingsWindow} title="Zuordnungen übertragen">
                <CopyJobMappingsWizard currentMapFromConnection={getIn(administrationValues, FIELD_MAP_ITEMS_SOURCE)}
                    currentMapToConnection={getIn(administrationValues, FIELD_MAP_ITEMS_TARGET)} />
            </Dialog>
        }

    </div>
};

const WorkItemTypeEditor: React.FC<{
    onAddMapping: (type: string, jobTaskBaseNo: string, workTypeCode: string) => void
    onCancel: () => void,
    job: Job
}> = ({ onAddMapping, onCancel, job }) => {

    const [{ values, setFieldValue }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const jobTaskDesignation = getIn(values, [FIELD_JOB_TASK, FIELD_DESIGNATION]);
    const categoryDesignation = getIn(values, [FIELD_CATEGORY, FIELD_DESIGNATION]);

    useEffect(() => { setFieldValue && setFieldValue(FIELD_JOB, job) }, [setFieldValue, job]);

    useEffect(() => {
        if (!jobTaskDesignation) return;

        const noParts = jobTaskDesignation.split('.');
        const baseNo = [...noParts.slice(0, noParts.length - 1), '2'].join('.');

        setFieldValue && setFieldValue("jobTaskBaseNo", baseNo);
    }, [setFieldValue, jobTaskDesignation]);

    useEffect(() => {
        if (!categoryDesignation) return;

        setFieldValue && setFieldValue('workTypeCode', categoryDesignation);
    }, [setFieldValue, categoryDesignation]);

    return <div className="work-item-type-editor">

        <NaVaForm
            initialValues={{ FIELD_JOB: job }}
            onSubmit={() => { }}>
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>
            <div className="row m-0 my-3 gap1">
                <ComboBox name="type" data={workItemTypes} xs={12} lg={6}></ComboBox>
            </div>

            <div className="row m-0 gap-1 my-3">
                <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.JobTask} props={{ name: FIELD_JOB_TASK, fieldJobName: FIELD_JOB, foreignKeyName: FIELD_JOB_TASK_ID, sm: 12, lg: 6 } as JobTaskSelectionProps} getSingleComponent={true} />
                <Input xs={4} name='jobTaskBaseNo' placeholder='Job Task Base No'></Input>

                <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Category} props={{ name: FIELD_CATEGORY, foreignKeyName: FIELD_CATEGORY_ID, sm: 12, lg: 6, fetchFromAllProviders: false } as ResourceSelectionProps} getSingleComponent={true} />
                <Input xs={4} name='workTypeCode' placeholder='Work Type Code' disabled={true}></Input>
            </div>
            <Button xs={4} offsetXs={4} color="primary" label="Hinzufügen" onClick={() => { onAddMapping && onAddMapping(getIn(values, 'type'), getIn(values, 'jobTaskBaseNo'), getIn(values, 'workTypeCode')) }} />
        </NaVaForm>
    </div>
};

export default JobMappingEditor;