import React, { useCallback, useEffect, useState } from 'react';
import { ChartingTimeInterval, ChartsApi } from '../../../client/http';
import useApi from '../../../common/hooks/useApi';
import { Settings_ProviderName, Settings_ResourceId, Settings_ServiceConnectionId } from '../../../util/SettingNames';
import { Chart, ChartSeries, ChartSeriesItem, ChartTitle, ChartSeriesItemTooltip, ChartTooltip } from '@progress/kendo-react-charts';
import { L } from '../../../abp/utils';
import 'hammerjs';
import ChartingTimeIntervalSelection from '../../../common/components/charting/ChartingTimeInvervalSelection';
import filter from '../../../util/FilterUtils';
import { FIELD_RESOURCE_ID } from '../../../data/field-constants/GeneralConstants';
import { log } from '../../../util/LoggingUtils';

const WorkPerTime: React.FC = () => {
    const [interval, setInterval] = useState<ChartingTimeInterval>(ChartingTimeInterval.NUMBER_2);
    const [data, setData] = useState<any | undefined>(undefined);
    const [averageData, setAverageData] = useState<any | undefined>(undefined);
    const [, chartsApi] = useApi<any, ChartsApi>(c => new ChartsApi(c));

    const loadData = useCallback(() => {
        log("loading chart", chartsApi)
        if (!chartsApi) return;

        const filters = [filter("isPlanningEntity").equals(false), filter(FIELD_RESOURCE_ID).equals(abp.setting.get(Settings_ResourceId))];

        chartsApi.apiServicesAppChartsGetChartDataGet("WorkPerTime", interval, false, abp.setting.get(Settings_ProviderName), abp.setting.getInt(Settings_ServiceConnectionId), JSON.stringify(filters), undefined)
            .then(r => r.json().then(j => setData(j.result)));

    }, [interval, setData, chartsApi]);

    useEffect(() => {
        if (!data?.length) return;

        const sum = data.reduce((acc: number, cur: any) => acc + cur.field, 0);
        const count = data.length;
        const average = sum / count;
        setAverageData(data.map((item: any) => average));
    }, [data]);

    useEffect(() => loadData(), [loadData]);

    return (<div className="charts chart--workPerTime">
        <ChartingTimeIntervalSelection defaultValue={interval} onSelectionChanged={(i) => setInterval(i)} />
        {/* <ChartTitle text={L('Chart_WorkPerTime')} /> */}
        {data &&
            <Chart>
                <ChartTooltip />
                <ChartSeries>
                    <ChartSeriesItem data={data} type="column" field="field" categoryField="categoryField" labels={{ visible: true, }}>
                        <ChartSeriesItemTooltip render={({ point }) => { return point.value.toFixed(2) + " Stunden" }} />
                    </ChartSeriesItem>
                    <ChartSeriesItem type="line" data={averageData} >
                        <ChartSeriesItemTooltip render={({ point }) => { return "Durchschnitt: " + point.value.toFixed(2) + " Stunden" }}/>
                        </ChartSeriesItem>
                </ChartSeries>
            </Chart>}
    </div >);
}

export default WorkPerTime;
