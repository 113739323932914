import React, { useCallback, useState } from 'react';
import { L } from '../../abp/utils';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import { NaVaFormContextType, NaVaFormValues } from '../../common/forms/validation/na-va-form/types';
import Input from '../../common/forms/validation/controls/input/Input';
import Button from '../../common/forms/controls/button/Button';
import './ChangePassword.scss';
import PasswordComplexity from '../../common/components/password-complexity/PasswordComplexity';
import { getIn } from '../../common/forms/validation/na-va-form/commonUtils';
import NaVaFormStatePuller from '../../common/forms/NaVaFormStatePuller';
import useApi from '../../common/hooks/useApi';
import { UserApi } from '../../client/http';
import useLoadingState from '../../common/hooks/useLoadingState';
import { showNotification } from '../../common/components/notifications/NotificationHost';

const ChangePassword: React.FC<{ onComplete: () => void }> = ({ onComplete }) => {

    const [{ values }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const [, userApi] = useApi<any, UserApi>(c => new UserApi(c));
    const [startLoading, loadingComplete, loadingError, isLoading] = useLoadingState();

    const changePassword = useCallback((v: NaVaFormValues) => {
        startLoading();
        userApi?.apiServicesAppUserChangePasswordPost({ currentPassword: v.oldPassword, newPassword: v.password })
            .then(
                () => {
                    loadingComplete();
                    showNotification(L('ChangePassword'), L('PasswordChangedSuccessfully'), 'success');
                })
            .catch(
                err => {
                    loadingError(err.data.error);
                    showNotification(L('ErrorChangingPassword'), L('ErrorChangingPassword'), 'error');
                }
            );
    }, [startLoading, loadingComplete, loadingError, userApi]);

    return <div className="change-password">
        <NaVaForm
            initialValues={{ oldPassword: '', password: '', repeatPassword: '' }}
            onSubmit={changePassword}
            onReset={onComplete}
            validationConfig={{
                oldPassword: [
                    {
                        validate: (obj: any) => !!obj.oldPassword,
                        message: L('PleaseProvidePassword')
                    }
                ],
                password: [
                    {
                        validate: (obj: any) => !!obj.password,
                        message: L('PleaseProvidePassword')
                    }
                ],
                repeatPassword: [
                    {
                        validate: (obj: any) => obj.repeatPassword === obj.password,
                        message: L('ValueMustMatchPassword')
                    }
                ]
            }}>
            <h3 className="pt-0 pb-2">
                <span>{L('ChangePasswordDetail')}</span>
            </h3>
            <NaVaFormStatePuller onStateChanged={s => setFormState(s)} />
            <Input type="password" name="oldPassword" placeholder={L('OldPassword')} label={L('OldPassword')} md={12} mb={2} />
            <Input type="password" name="password" placeholder={L('Password')} label={L('Password')} md={12} mb={2} />
            <Input type="password" name="repeatPassword" placeholder={L('RepeatPassword')} label={L('RepeatPassword')} md={12} mb={2} />
            <PasswordComplexity password={getIn(values, 'password')} />
            <div className="row">
                <Button type="reset" color="secondary" label={L('Cancel')} xs={12} md={6} className="cancel-btn" />
                <Button type="submit" color="primary" label={L('Save')} xs={12} md={6} className="submit-btn" />
            </div>
        </NaVaForm>
    </div>
}

export default ChangePassword;