import { Fade, Slide } from '@progress/kendo-react-animation';
import { Notification, NotificationGroup, NotificationProps } from '@progress/kendo-react-notification';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { arrayBuffer } from 'stream/consumers';
import AppEvents from '../../../module-components/AppEvents';
import { log } from '../../../util/LoggingUtils';
import { DateTime } from 'luxon';

export type AnimationStyle = 'fade' | 'slide';

export type NotificationItem = NotificationProps & {
    timeout?: number;
    animationStyle?: AnimationStyle;
}

const positions = {
    topLeft: {
        top: 0,
        left: '.5rem',
        alignItems: "flex-start",
    },
    topCenter: {
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
    },
    topRight: {
        top: 0,
        right: '.5rem',
        alignItems: "flex-end",
    },
    bottomLeft: {
        bottom: 0,
        left: '.5rem',
        alignItems: "flex-start",
    },
    bottomCenter: {
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
    },
    bottomRight: {
        bottom: 0,
        right: '.5rem',
        alignItems: "flex-end",
    },
};

const ItemWrapper: React.FC<PropsWithChildren<{ animationStyle?: AnimationStyle }>> = ({ animationStyle, children }) => {
    // if (animationStyle === 'fade') {
    //     return <Fade>
    //         {children}
    //     </Fade>
    // }

    // if (animationStyle === 'slide') {
    //     return <Slide direction="up">
    //         {children}
    //     </Slide>
    // }

    return <>{children}</>;
}

export const showNotificationFromItem = (item: NotificationItem) => {
    abp.event.trigger(AppEvents.Notification_Published, item);
};

export const showNotification = (title?: string, content?: string, style?: 'none' | 'success' | 'error' | 'warning' | 'info', timeoutMs?: number, closable: boolean = true) => {
    abp.event.trigger(AppEvents.Notification_Published, {
        children: <>
            {title && <h5 className="mb-2">{title}</h5>}
            {content && <span>{content}</span>}
            {(style === 'error' || style === 'warning') && <div className="text-muted mt-2">{DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</div>}
        </>,
        type: { style },
        timeout: timeoutMs,
        closable
    } as NotificationItem);
};


const NotificationHost: React.FC<{ position?: React.CSSProperties }> = ({ position = positions.bottomRight }) => {

    const [items, setItems] = useState<NotificationItem[]>([]);

    const removeNotification = useCallback((item: NotificationItem) => {
        const index = items.indexOf(item);
        const newItems = items.slice();
        if (index > -1) {
            newItems.splice(index, 1);
        }

        setItems(newItems);
    }, [items, setItems]);

    const handleNotificationEvent = useCallback((item: NotificationItem) => {
        log("Notification ", item);
        setItems(i => [...i, item]);
        if (item.timeout) {
            setTimeout(() => removeNotification(item), item.timeout);
        }
    }, [setItems, removeNotification]);

    useEffect(() => {
        abp.event.on(AppEvents.Notification_Published, handleNotificationEvent);

        return () => { abp.event.off(AppEvents.Notification_Published, handleNotificationEvent); }
    }, [handleNotificationEvent]);

    return <div className="notification-host">
        <NotificationGroup style={position}>
            {items.map((item, i) => {
                const { animationStyle, timeout: timeout, onClose, ...notificationProps } = item;
                return <ItemWrapper animationStyle={animationStyle} key={i}>
                    <Notification {...notificationProps} onClose={(e) => {
                        onClose && onClose(e);
                        removeNotification(item);
                    }} />
                </ItemWrapper>
            })}
        </NotificationGroup>
    </div>
}

export default NotificationHost;