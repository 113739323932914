import React from 'react';
import { useNavigate } from 'react-router-dom';
import { L } from '../../../abp/utils';
import Icon from '../icon/Icon';
import './BackButton.scss';

const BackButton: React.FC<{ path: string }> = ({ path }) => {

    const navigate = useNavigate();

    return <h5 className="back-btn" onClick={() => navigate(path)}>
        <Icon name="icon-forward" size="1.5rem" className="mirror-icon m-0" />
        <span>
            {L('Back')}
        </span>
    </h5>
}

export default BackButton;