export const Features = {
    Administration: {
        Feature: "Rewind365.Core.Administration",
        UserManagement: "Rewind365.Core.Administration.UserManagement",
    },
    Application: {
        Feature: "Rewind365.Core.Application",
        Homepage: "Rewind365.Core.Application.Homepage",
        Dashboards: "Rewind365.Core.Application.Dashboards",
        HotKeys: "Rewind365.Core.Application.HotKeys"
    },
    JobManagement: {
        Feature: "Rewind365.Core.JobManagement"
    },
    Reporting: {
        Feature: "Rewind365.Core.Reporting"
    },
    SettingsManagement: {
        Feature: "Rewind365.Core.SettingsManagement"
    },
    TimeManagement: {
        Feature: "Rewind365.Core.TimeManagement",
        TimeTracking: "Rewind365.Core.TimeManagement.TimeTracking",
        TimePlanning: "Rewind365.Core.TimeManagement.TimePlanning",
    }
}