import React, { useCallback, useEffect } from 'react';
import { SwitchChangeEvent } from '@progress/kendo-react-inputs';
import BaseSwitch, { SwitchProps as BaseSwitchProps } from '../../../controls/switch/Switch';
import useNaVaField from '../../na-va-form/NaVaField';
import { L } from '../../../../../abp/utils';

export type SwitchProps = {
  name: string;
  id?: string;
} & Omit<BaseSwitchProps, 'value' | 'onFocus' | 'innerRef' | 'error'>;

const Switch: React.FC<SwitchProps> = React.memo(({ name, onBlur, onChange, ...props }) => {
  let {
    error,
    value,
    ref,
    setValue
  } = useNaVaField<boolean | undefined>({ name });

  const aggregatedOnChange = useCallback((e: SwitchChangeEvent) => {
    setValue(e.value, true);
    onChange && onChange(e);
  }, [setValue, onChange]);

  // Space bar would normally scroll down the page, handle event when using switch
  const spaceBarListener = useCallback((e: KeyboardEvent) => {
    e.key === " " && e.preventDefault();
    return e.key !== " ";
  }, []);

  return (
    <BaseSwitch
      name={name}
      error={error && L(error)}
      innerRef={ref}
      onChange={aggregatedOnChange}
      value={value}
      onFocus={() => window.addEventListener("keydown", spaceBarListener)}
      onBlur={() => window.removeEventListener("keydown", spaceBarListener)}
      {...props}
    />
  );
});

export default Switch;
