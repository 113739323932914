import React, { useEffect } from 'react';
import { JobDto, TimeEntity } from '../../../../client/http';
import { TimeEntityEntityDetailsProps } from '../../../../module-components/time-tracking/time-entities/TimeEntityComponentProps';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { getExtensionValue } from '../../../../util/EntityUtils';
import { FIELD_LINE_TYPE, FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION, FIELD_UNIT_OF_MEASURE_CODE, LineTypes, LineTypesActivityConfirmation } from '../../field-constants/BcConstants';
import useMethodContext from '../../../../module-components/method-context/useMethodContext';
import { Modules } from '../../../../module-components/Modules';
import { FIELD_DIRECT_COST, FIELD_UNIT_COST, FIELD_UNIT_PRICE } from '../../../../data/field-constants/GeneralConstants';
import Icon from '../../../../common/components/icon/Icon';
import { L } from '../../../../abp/utils';

export const EntityDetails: React.FC<TimeEntityEntityDetailsProps> = (({ entity }) => {
    const { addMethod } = useMethodContext();

    useEffect(() => addMethod("getEntityAmountUnit", Modules.rewindBcDelux, true, (t: TimeEntity) => {
        var uom = getExtensionValue(t, FIELD_UNIT_OF_MEASURE_CODE);
        return uom;
    }), []);

    return null;
});

export const EntityData: React.FC<TimeEntityEntityDetailsProps> = (({ entity }) => {
    const job = getIn(entity, 'jobFk') as JobDto;
    const lineType = getExtensionValue(entity, FIELD_LINE_TYPE);
    const lineTypes = Object.keys(LineTypes).map(k => (LineTypes as any)[k]);
    const lineTypeActivityConf = getExtensionValue(entity, FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION);
    const unitPrice = getExtensionValue(entity, FIELD_UNIT_PRICE) ?? 0;
    const directCost = getExtensionValue(entity, FIELD_DIRECT_COST) ?? 0;
    const unitCost = getExtensionValue(entity, FIELD_UNIT_COST) ?? 0;
    const cost = directCost === 0 ? unitCost : directCost;

    return <>
        <span title={L('JobSearchDescription')}>
            <Icon name="icon-spiral-bound-booklet" size="1.5rem" className="ms-2 me-1" />
            {getExtensionValue(job, 'searchDescription')}
        </span>
        <span title={L('LineType')}>
            <Icon name="icon-heck-for-payment" size="1.5rem" className="ms-2 me-1" />
            {lineType && lineTypes.find(l => l.id === lineType.toString())?.value}
        </span>
        <span title={L('UnitPrice')}>
            <Icon name="icon-deposit" size="1.5rem" className="ms-2 me-1" />
            {`${(unitPrice === 0 ? '-' : unitPrice.toFixed(2))} €`}
        </span>
        <span title={L('DirectCost')}>
            <Icon name="icon-withdrawal" size="1.5rem" className="ms-2 me-1" />
            {`${(cost === 0 ? '-' : cost.toFixed(2))} €`}
        </span>
        <span title={L('LineTypeActivityConfirmation')}>
            <Icon name="icon-price-tag" size="1.5rem" className="ms-2 me-1" />
            {lineTypeActivityConf && LineTypesActivityConfirmation[lineTypeActivityConf]}
        </span>
    </>;
});