import React, { useCallback } from 'react';
import { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import BaseDatePicker, { DatePickerProps as BaseDatePickerProps } from '../../../controls/date-picker/DatePicker';
import useNaVaField from '../../na-va-form/NaVaField';

export type DatePickerProps = Omit<BaseDatePickerProps, 'onChange' | 'onBlur'>;

const DatePicker: React.FC<DatePickerProps> = React.memo(({name, ...props}) => {
  if (!name) throw new Error('name must not be undefined');

  const {
    error,
    value,
    ref,
    setValue
  } = useNaVaField<Date | null>({ name });

  // Special case
  // BaseDatePicker delays triggering onChange to when it loses focus
  // so we trigger setValue with validation onChange and dont call onBlur at all
  const aggregatedOnChange = useCallback((e: DatePickerChangeEvent) => {
    setValue(e.value, true);
    // console.log(e.value);
  }, [setValue]);

  return (
    <BaseDatePicker
      name={name}
      innerRef={ref}
      onChange={aggregatedOnChange}
      // onBlur={aggregatedOnBlur}
      error={error}
      value={value}
      {...props}
    />
  );
});

export default DatePicker;
