import React, { useMemo } from 'react';
import { useNaVaFormContext } from '../../../common/forms/validation/na-va-form/NaVaFormContext';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { FIELD_JOB_TASK, FIELD_ORIG_BLANKET_RES_QTY, FIELD_RES_QTY_SALE, FIELD_RES_QTY_SCHEDULE, FIELD_RES_QTY_USAGE } from '../../../data/field-constants/GeneralConstants';
import { IsEmptyGuid } from '../../../util/Values';
import { Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesLabels, ChartTooltip } from '@progress/kendo-react-charts';
import { L } from '../../../abp/utils';

import './BudgetWidget.scss';
import { JobTask } from '../../../client/http';
import { getExtensionValue } from '../../../util/EntityUtils';

const BudgetWidget: React.FC = () => {

    const { values } = useNaVaFormContext();

    const jobTask = getIn(values, FIELD_JOB_TASK) as JobTask;

    const origBlanketResQty: number = getExtensionValue(jobTask, FIELD_ORIG_BLANKET_RES_QTY);
    const resQtySchedule: number = getExtensionValue(jobTask, FIELD_RES_QTY_SCHEDULE);
    const resQtyUsage: number = getExtensionValue(jobTask, FIELD_RES_QTY_USAGE);
    const resQtySale: number = getExtensionValue(jobTask, FIELD_RES_QTY_SALE);
    const data = [{
        name: 'planning',
        data: [
            {
                category: L('Planned'),
                value: resQtySchedule,
                color: "var(--primary-200)"
            }, {
                category: "Unplanned",
                value: origBlanketResQty - resQtySchedule,
                color: "transparent"
            },
        ]
    }, {
        name: 'usage',
        data: [
            {
                category: L('Used'),
                value: resQtyUsage,
                color: "var(--primary-400)"
            }, {
                category: "Unused",
                value: origBlanketResQty - resQtyUsage,
                color: "transparent"
            },
        ]
    }, {
        name: 'sales',
        data: [
            {
                category: L('Sold'),
                value: resQtySale,
                color: "var(--primary-600)"
            }, {
                category: "Unsold",
                value: origBlanketResQty - resQtySale,
                color: "transparent"
            },
        ]
    }];

    const donutCenterRenderer = () => (
        <div className="row align-items-center">
            <div className="col-auto">
                <h3>{origBlanketResQty} {L('Unit_Hour_Text')}</h3>
                <span>
                    Rahmenwert
                </span>
            </div>
            <div className="col-auto quick-overview">
                <div>
                    <div className="badge color-swatch" style={{ backgroundColor: 'var(--primary-200)' }} />
                    {L('Planned')}: {data.find(x => x.name === 'planning')?.data[0].value.toLocaleString()} {L('Unit_Hour_Text')}
                </div>
                <div>
                    <div className="badge color-swatch" style={{ backgroundColor: 'var(--primary-400)' }} />
                    {L('Used')}: {data.find(x => x.name === 'usage')?.data[0].value.toLocaleString()} {L('Unit_Hour_Text')}
                </div>
                <div>
                    <div className="badge color-swatch" style={{ backgroundColor: 'var(--primary-600)' }} />
                    {L('Sold')}: {data.find(x => x.name === 'sales')?.data[0].value.toLocaleString()} {L('Unit_Hour_Text')}
                </div>
            </div>
        </div>
    );

    const labelContent = (e: any) => `${e.category}: \n ${e.value} Std. (${origBlanketResQty === 0 ? 0 : e.value / origBlanketResQty * 100})`;

    const mapSeries = (series: any, index: number, array: any[]) => (
        <ChartSeriesItem
            type="donut"
            startAngle={150}
            name={series.name}
            data={series.data}
            field="value"
            categoryField="category"
            colorField="color"
        >
        </ChartSeriesItem>
    );
    const renderTooltip = (context: any) => {
        const { category, series, value } = context.point || context;
        return (
            <div>
                {`${category}: \n ${value.toLocaleString()} Std. (${origBlanketResQty === 0 ? 0 : (value / origBlanketResQty * 100).toFixed(0)}%)`}
            </div>
        );
    };


    return useMemo(() => jobTask && !IsEmptyGuid(jobTask?.id) ?
        <div className="budget-widget row align-items-center">
            <div className="col-auto">
                <Chart style={{ width: 100, height: 100 }}>
                    <ChartTooltip render={renderTooltip} />
                    <ChartLegend visible={false} />
                    <ChartArea background="none" />
                    <ChartSeries>{data.map(mapSeries)}</ChartSeries>
                </Chart>
            </div>
            <div className="col-auto">
                {donutCenterRenderer()}
            </div>
        </div>
        : null
        , [jobTask]);
}

export default BudgetWidget;