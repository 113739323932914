import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import './TemplateField.scss';
import { getIn } from '../../forms/validation/na-va-form/commonUtils';

export type TemplateFieldProps = {
    data?: TemplateData;
    templateString?: string;
    disabled?: boolean;
    id: string;
    enableDefaultValues?: boolean;
    replacements?: { [key: string]: any };
    onChange?: (id: string, templateString: string, data: TemplateData) => void
    onDelete?: (id: string) => void;
};

export type TemplateData = {
    id: string;
    color: string;
    name: string;
    defaultValue?: string;
    value: string;
    type: 'text' | 'number' | 'date' | 'boolean';
    rules: string;
    description: string;
};

export const TEMPLATE_FIELD_INITIAL_VALUE = '[name]';

export const parseTemplateString = (templateString: string) => {
    const base = templateString.replace(/^\$+|\$+$/g, '');
    return JSON.parse(base) as TemplateData;
};

export const makeTemplateString = (data: TemplateData) => {
    return `$$${JSON.stringify(data)}$$`;
};

const getIcon = (type: string, size: number = 32, color = 'ffffff') => {
    const url = 'https://img.icons8.com/fluency-systems-regular/';
    let iconName = '';

    switch (type) {
        case 'number':
            iconName = 'numbers-input-form';
            break;
        case 'boolean':
            iconName = 'true-false';
            break;
        case 'date':
            iconName = 'calendar--v1';
            break;
        case 'delete':
            iconName = 'delete--v1';
            break;
        case 'save':
            iconName = 'save';
            break;
        default:
            iconName = 'term';
            break;
    }

    return (
        <img
            width={size}
            height={size}
            src={`${url}/${size}/${color}/${iconName}.png`}
            alt={iconName}
        />
    );
};

const getDefaultValueEditor = (
    type: string,
    value: any,
    onChange: (e: any) => void
) => {

    switch (type) {
        case 'text':
        case 'number':
        case 'date':
            return (
                <div className="input-group input-group-sm">
                    {/* <span class="input-group-text">Default Value</span> */}
                    <input
                        type={type}
                        value={value}
                        placeholder="Default"
                        onChange={(e) => onChange(e.target.value)}
                        className="form-control"
                        aria-label="Name"
                    />
                </div>
            );
        case 'boolean':
            return (
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked={value === 'true'}
                        onChange={(e) => {
                            onChange(e.target.checked ? 'true' : 'false');
                        }}
                    />
                    <label className="form-check-label">Default</label>
                </div>
            );
        default:
            return null;
    }
};

const TemplateField: React.FC<TemplateFieldProps> = ({
    id,
    templateString,
    data: dataProp,
    disabled,
    enableDefaultValues = false,
    replacements,
    onChange,
    onDelete,
}) => {
    const [data, setData] = useState<TemplateData>({} as TemplateData);
    const [isEditMode, setEditMode] = useState(false);
    const [showTypeMenu, setShowTypeMenu] = useState(false);

    const typeMenuRef = useRef(null);

    useEffect(() => {
        let d: TemplateData;

        if (templateString) {
            d = parseTemplateString(templateString) as TemplateData;
        } else {
            d = dataProp as TemplateData;
        }

        // if (!d.type) {
        //     console.log("setting input field type, because it was: ", d, templateString, dataProp);
        //     d.type = 'number';
        // }

        if (d.type) {
            setData(d);

            // If template has initial value, enter edit mode
            if (d.name === TEMPLATE_FIELD_INITIAL_VALUE) {
                setEditMode(true);
            }
        }
    }, [dataProp, templateString]);

    // useEffect(() => {
    //     onChange && onChange(id, makeTemplateString(data), data);
    // }, [data, id]);

    const handleClickOutsideTypeMenu = () => {
        setShowTypeMenu(false);
    };

    useOnClickOutside(typeMenuRef, handleClickOutsideTypeMenu);

    const handleSave = useCallback(() => {
        setEditMode(false);
    }, []);

    const handleTypeMenu = useCallback(() => {
        isEditMode && setShowTypeMenu(true);
    }, [isEditMode]);

    const updateType = useCallback(
        (type: TemplateData['type']) => {
            let color = '';
            switch (type) {
                case 'number':
                    color = '#e74c3c';
                    break;
                case 'boolean':
                    color = '#28b463';
                    break;
                case 'date':
                    color = '#8e44ad';
                    break;
                default:
                    color = '#1e90ff';
                    break;
            }

            handleDataChanged((d) => ({ ...d, type, color, defaultValue: (type === d.type ? d.defaultValue : undefined) }));
            setShowTypeMenu(false);
        },
        [data]
    );

    const handleDataChanged = useCallback((callback: (oldData: TemplateData) => TemplateData) => {
        setData(d => {
            const newData = callback(d);
            onChange && onChange(id, makeTemplateString(newData), newData);
            return newData;
        });
    }, [id, onChange]);

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (isEditMode === true && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditMode]);

    const templateFieldRef = useRef(null);

    useOnClickOutside(templateFieldRef, handleSave);

    const replacement = getIn(replacements, data.name);

    return (
        <span
            className={`template${isEditMode ? ' edit' : ''}`}
            style={{ borderColor: data.color }}
            onDoubleClick={() => !disabled && setEditMode(true)}
            ref={templateFieldRef}
        >
            <div>
                {isEditMode && <div
                    className="icon"
                    style={{ background: data.color }}
                    onClick={() => handleTypeMenu()}
                >
                    {getIcon(data.type)}
                </div>}
                <div className="data" style={{ backgroundColor: data.color }}>
                    <div className="bg-overlay"></div>
                    {isEditMode ? (
                        <div className="input-group input-group-sm">
                            {/* <span class="input-group-text">Name</span> */}
                            <input
                                className="form-control"
                                aria-label="Name"
                                type="text"
                                placeholder="Name"
                                value={data.name}
                                ref={inputRef}
                                onChange={(e) =>
                                    handleDataChanged((d) => ({ ...d, name: e.target.value.replace('.', '') }))
                                }
                            />
                        </div>
                    ) : (
                        data.name && !replacement && <div>{data.name}</div>
                    )}
                    {isEditMode && enableDefaultValues ?
                        getDefaultValueEditor(data.type, data.defaultValue, (val) =>
                            handleDataChanged((d) => ({ ...d, defaultValue: val })))
                        : null}
                    {!isEditMode && (data.defaultValue || replacement) &&
                        <div className="data__default text-muted">{`${replacement ?? data.defaultValue}`}
                        </div>
                    }
                </div>
                {isEditMode && (
                    <>
                        {/* <div className="btn__save" onClick={() => handleSave()}>
                            {getIcon('save', 20, '000')}
                        </div> */}
                        <div className="btn__delete" onClick={() => onDelete && onDelete(id)}>
                            {getIcon('delete', 20, '000')}
                        </div>
                    </>
                )}
            </div>
            <div
                className="menu__type-menu"
                ref={typeMenuRef}
                style={{ display: showTypeMenu ? 'block' : 'none' }}
            >
                <div className="menu-btn" onClick={() => updateType('text')}>
                    {getIcon('text', 32, '000')}
                </div>
                <div className="menu-btn" onClick={() => updateType('number')}>
                    {getIcon('number', 32, '000')}
                </div>
                <div className="menu-btn" onClick={() => updateType('boolean')}>
                    {getIcon('boolean', 32, '000')}
                </div>
                <div className="menu-btn" onClick={() => updateType('date')}>
                    {getIcon('date', 32, '000')}
                </div>
            </div>
        </span>
    );
};

export default TemplateField;
