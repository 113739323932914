import { loadScript } from './utils';
import { KEY_AUTH_INFO, AuthInfo } from '../authentication/authenticator/Authenticator';
import constants from './constants';

declare const abp: any;

const encryptedAuthTokenName = "enc_auth_token";

class SignalRService {
    initSignalR() {
        const auth = localStorage.getItem(KEY_AUTH_INFO);

        if(!auth)
        return;

        const authInfo = JSON.parse(auth || '') as AuthInfo;

        abp.signalr = {
            autoConnect: true,
            connect: undefined,
            hubs: undefined,
            qs: encryptedAuthTokenName + '=' + encodeURIComponent(authInfo.tokenInfo.encryptedToken),
            remoteServiceBaseUrl: constants.remoteServiceBaseUrl,
            url: '/signalr'
        };

        // loadScript(constants.appBaseUrl! + '/signalR/abp.signalr-client.js');
        loadScript('/signalr/abp.signalr-client.min.js');
    }
}
export default new SignalRService();
