import { DateTime } from 'luxon';
import React from 'react';
import { L } from '../../../../abp/utils';
import Icon from '../../../../common/components/icon/Icon';

const JobTaskItem = (li: any, itemProps: any, newProps?: any, highlightItem?: (item: any) => boolean, classSelector?: (item: any) => string, userResourceId?: string) => {

    const isAccountable = userResourceId && itemProps.dataItem.accountableId === userResourceId;
    const isExecutive = userResourceId && itemProps.dataItem.executiveId === userResourceId;
    const itemChildren = (
        <div className={"combo-box-item" +
            (highlightItem && highlightItem(itemProps.dataItem) ? " highlighted" : "") + (classSelector ? " " + classSelector(itemProps.dataItem) : "")}>
            <span className="designation">{itemProps.dataItem.designation}</span>
            <span className="description">{itemProps.dataItem.description1}</span>
            {itemProps.dataItem.isOverdue && <span className="overdue"><Icon className="is-overdue-icon" size="1rem" name="icon-appointment-reminders" /></span>}
            {/* {dueDate && <span className="dueDate">{dueDate}</span>} */}
        </div >
    );
    const className = `${li.props?.className} ${newProps?.className}` + (isAccountable ? ' is-accountable' : ' ') + (isExecutive ? ' is-executive' : ' ');
    const title = [
        (isExecutive ? L('TaskAssignedToYou') : isAccountable ? L('TaskAccountableFor') : ''),
        itemProps.dataItem.isOverdue ? `${abp.utils.formatString(L('TaskIsOverdueBy_1'), -1 * Math.floor(itemProps.dataItem.overdue.as("days")))} (${itemProps.dataItem.endDate.toLocaleString(DateTime.DATE_SHORT)})` : ''
    ].filter(x => !!x).join('. '); // remove empty elements with filter
    return React.cloneElement(li, { ...li.props, ...newProps, className: className, title: title, onMouseOver: (e: any) => e.stopPropagation() }, itemChildren);
};

export default JobTaskItem;