import React, { useEffect } from 'react';
import { useNaVaFormContext } from './validation/na-va-form/NaVaFormContext';
import { NaVaFormContextType, NaVaFormValues } from './validation/na-va-form/types';

const NaVaFormStatePuller: React.FC<{ onStateChanged: (state: NaVaFormContextType<NaVaFormValues>) => void }> = ({ onStateChanged }) => {
    const context = useNaVaFormContext();

    const { values, errors } = context;
    useEffect(() => {
        onStateChanged && onStateChanged(context);
    }, [values, errors]);

    return <></>
}


export default NaVaFormStatePuller;