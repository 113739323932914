import React from 'react';
import { Job } from '../../../../client/http';

const JobDetails: React.FC<{ data: Job }> = React.memo(({ data }) => {
    if (!data) return <></>;

    var parsed = data;
    parsed.extensions = JSON.parse(data.extensionData ?? '{}');
    return <div className="job-details combo-box-footer">
        <div className="main-data">
            <h3>{parsed.extensions.billToName ?? ''}</h3>
            <h1>{parsed.extensions.searchDescription ?? ''}</h1>
            <h1>{parsed.designation}</h1>
            <h2>{parsed.description1 ?? ''}</h2>
            <h2>{parsed.description2 ?? ''}</h2>
            <h2>{parsed.extensions.status ?? ''}</h2>
            {/* <h2>{parsed.jobType ?? ''}</h2> */}
            <h2>{parsed.extensions.salesPersonCode ?? ''}</h2>
            <h2>{parsed.extensions.personResponsible ?? ''}</h2>
        </div>
    </div>
});

export default JobDetails;

export const DesignationBillToNameSearchDescriptionItem = (li: any, itemProps: any) => {
    var parsed = itemProps.dataItem;
    parsed.extensions = JSON.parse(parsed.extensionData ?? '{}');

    const itemChildren = (
        <div className={"combo-box-item"}>
            <span className="designation">{parsed.designation}</span>
            <span className="billToName">{parsed.extensions.billToName}</span>
            <span className="searchDescription">{parsed.extensions.searchDescription}</span>
            {/* {dueDate && <span className="dueDate">{dueDate}</span>} */}
        </div >
    );
    return React.cloneElement(li, li.props, itemChildren);
};