import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import MethodContext, { MethodContextValues } from './MethodContext';
import { log } from '../../util/LoggingUtils';

const MethodContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [methods, setMethods] = useState<MethodContextValues>({});

    const addMethod = useCallback((name: string, module: string, replace: boolean, methodPart: (value: any) => any, key?: string) => {
        setMethods(prev => {
            // check if item with this key is already present
            // if addMethod if called multiple times and no different key is provided, the item will be replaced
            log("trying to add method", name, module, key);
            const arr = [...prev[name] ?? []];
            const foundItem = arr.find(m => m.module === module && m.key === key);
            if (foundItem) {
                if (!replace) return prev;

                arr.splice(arr.indexOf(foundItem), 1);
            }
            log("added method", name, module, key);
            return ({ ...prev, [name]: [...arr, { module, key, methodPart }] })
        });
    }, [setMethods]);
    
    const applyMethod = useCallback((name: string, value: any, module?: string, ignoreModuleSettings?: boolean) => {
        // If no methods are registered, skip and return the original value 
        if (!Object.hasOwn(methods, name) || !methods[name]) {
            return value;
        }

        let items = methods[name];

        // Check if a specific module's methods were requested
        if (module) {
            items = items.filter(i => i.module === module);
        }

        const setting = abp?.setting?.get("App.UseModules");
        let result = value;

        // If use modules setting is not defined or should be ignored, loop through all methods
        if (!setting || ignoreModuleSettings) {
            for (const item of items) {
                result = item.methodPart(result);
            }
        } else {
            // else invoke the methods based on useModules setting
            const modules = setting.split(',');

            for (const mod of modules) {
                const foundItems = items.filter(i => i.module === mod);
                for (const i of foundItems) {
                    result = i.methodPart(result);
                }
            }
        }

        return result;
    }, [methods]);

    useEffect(() => log("methods updated", methods), [methods]);

    return <MethodContext.Provider value={{ addMethod, applyMethod, methods }}>{children}</MethodContext.Provider>
}

export default MethodContextProvider;