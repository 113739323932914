import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Configuration, ReportDto, ReportsApi } from '../../client/http';
import DataViewMessage from '../../common/components/data-view-message/DataViewMessage';
import Icon from '../../common/components/icon/Icon';
import NaVaFormStatePuller from '../../common/forms/NaVaFormStatePuller';
import { getIn } from '../../common/forms/validation/na-va-form/commonUtils';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import { NaVaFormContextType, NaVaFormValues } from '../../common/forms/validation/na-va-form/types';
import { useReactiveApi } from '../../common/hooks/useApi';
import { FIELD_CUSTOMER, FIELD_CUSTOMER_ID, FIELD_ID, FIELD_JOB, FIELD_JOB_ID, FIELD_NAME } from '../../data/field-constants/GeneralConstants';
import ModuleComponentProvider from '../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../modules/AppParts';
import { firstOrDefault, getExtensionValue } from '../../util/EntityUtils';
import filter from '../../util/FilterUtils';
import './ReportList.scss';
import { L } from '../../abp/utils';
import { log } from '../../util/LoggingUtils';
import useTakeApiUi from '../../common/hooks/useTakeApiUi';

const ReportList: React.FC = () => {

    const navigate = useNavigate();
    const [{ values: filterValues }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const jobId = getIn(filterValues, [FIELD_JOB, FIELD_ID]);
    const customerId = getIn(filterValues, [FIELD_CUSTOMER, FIELD_ID]);
    const isExported = getIn(filterValues, 'isExported');

    const filters = useMemo(() => {
        return JSON.stringify([filter('isEditable').equals(!isExported)]);
    }, [isExported]);

    const entityFilters = useMemo(() => {
        const res = [];
        jobId && res.push(filter("values").contains(`"${FIELD_JOB_ID}":"${jobId}"`));
        customerId && res.push(filter("values").contains(`"${FIELD_CUSTOMER_ID}":"${customerId}"`))
        return res.length > 0 ? JSON.stringify(res) : undefined;
    }, [jobId, customerId]);


    const { takeItems, totalProtocolCount, takeItemControl } = useTakeApiUi(20, 10);

    const reportsApi = useCallback((c: Configuration) => new ReportsApi(c), []);
    const getAllReports = useCallback((c: ReportsApi) => c.apiServicesAppReportsGetAllGet(filters, entityFilters, 0, takeItems), [filters, entityFilters, takeItems]);

    const [reports, reportApi, reportsMessageViewData, , totalCount] = useReactiveApi<ReportDto, ReportsApi>(reportsApi, getAllReports);

    useEffect(() => {
        totalProtocolCount.current = totalCount ?? 0;
    }, [totalCount])

    return (<div className="report-list mt-2">

        <div className="filters row my-3">
            <NaVaForm
                initialValues={{ isExported: false }}
                onSubmit={() => { }}>
                <NaVaFormStatePuller onStateChanged={setFormState} />
                <div className="d-flex align-items-center">
                    <ModuleComponentProvider appPartName={AppParts.Reporting.ReportList.EntitiesFilter} props={{}} />
                </div>
            </NaVaForm>
        </div>


        <h5 className="mb-3">Bitte wählen Sie den Bericht aus, den Sie einsehen möchten:</h5>

        {reportsMessageViewData ?
            <DataViewMessage {...reportsMessageViewData} /> :
            reports && reports.length > 0 && reports.map(r => {
                const firstValue = r.dataEntries && firstOrDefault(r.dataEntries)?.values;
                const firstItem = firstValue && firstOrDefault(JSON.parse(firstValue));
                log(firstItem, getIn(firstItem, ['customerFk', FIELD_NAME]))

                return (<div className="report-item" key={r.id}>
                    <div className="expansion-panel" onClick={() => navigate(`/app/reporting/edit/${r.id}`)} >
                        <div className="title">
                            <div className="top">
                                <h5 className="flex-grow-1">
                                    <div className="row">
                                        <div className="col-3 fw-bold text-ellipsis d-flex align-items-center" title={r.no}>
                                            {r.no}
                                            {r.userId !== abp.session.userId && <Icon name="icon-difficult-decision" title={L("ForeignUserRecord")} color="red" size="1.75rem" style={{ marginLeft: '1rem' }}></Icon>}
                                        </div>
                                        <div className="col-3">
                                            {r.reportTitle}
                                        </div>
                                        <div className="col-2">
                                            {r.reportDate && DateTime.fromISO(r.reportDate as any).toLocaleString(DateTime.DATE_MED)}
                                        </div>
                                        <div className="col-3">
                                            {firstItem && getIn(firstItem, ['customerFk', FIELD_NAME])}
                                        </div>
                                        {/* <Button onClick={() => navigate(`/app/reporting/edit/${r.id}`)} label={'Öffnen'} color="primary" /> */}
                                        {getExtensionValue(r, 'ReleasedAt') && <Icon name="icon-lock" size="1.5rem" className="mx-3" />}
                                    </div>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>)
            })
        }

        {takeItemControl()}

        {/* {reports && reports.length < 1 &&
            <DataViewMessage {...DataViewNoData} />
        } */}
    </div>);
}

export default ReportList;