import React from 'react';
import { Outlet } from 'react-router-dom';

const ReportOverview: React.FC = () => {



    return (<div className="report-overview px-4">        

        <Outlet />

    </div>);
}

export default ReportOverview;