import React from 'react';

export type IconProps = { name: IconName, size?: string, className?: string, color?: string, style?: React.CSSProperties, svgStyle?: React.CSSProperties, title?: string }

export type IconName = "icon-open-document" |
    "icon-create-new" |
    "icon-create-new-2" |
    "icon-upload" |
    "icon-appointment-reminders" |
    "icon-download" |
    "icon-add" |
    "icon-vertical-settings-mixer" |
    "icon-login-rounded" |
    "icon-information" |
    "icon-search-in-browser" |
    "icon-hyperlink" |
    "icon-browser-settings" |
    "icon-intelligent-website" |
    "icon-website-error" |
    "icon-webkey" |
    "icon-check-male" |
    "icon-events" |
    "icon-today" |
    "icon-calendar" |
    "icon-calendar-plus" |
    "icon-calendar-error" |
    "icon-calendar-list" |
    "icon-clock-arrow" |
    "icon-clock-add" |
    "icon-remove-clock" |
    "icon-clock" |
    "icon-date-to" |
    "icon-calendar-14" |
    "icon-overtime" |
    "icon-future" |
    "icon-time-machine" |
    "icon-important-file" |
    "icon-linked-file" |
    "icon-linechart-file" |
    "icon-regular-file" |
    "icon-add-file" |
    "icon-check-file" |
    "icon-delete-file" |
    "icon-copy" |
    "icon-file-preview" |
    "icon-print-file" |
    "icon-view-file" |
    "icon-upload-document" |
    "icon-receive-file" |
    "icon-forward-all" |
    "icon-reply-all-arrow" |
    "icon-circular-arrows" |
    "icon-expand-arrow" |
    "icon-sorting-arrows-horizontal" |
    "icon-hexagon-synchronize-" |
    "icon-coin-wallet" |
    "icon-bunch-of-keys-1" |
    "icon-no-key" |
    "icon-graph-report" |
    "icon-error" |
    "icon-agreement" |
    "icon-urgent-message" |
    "icon-smart-home-checked" |
    "icon-cancel" |
    "icon-puzzle" |
    "icon-web-analystics" |
    "icon-collapse-arrow" |
    "icon-forward" |
    "icon-stop" |
    "icon-play" |
    "icon-play-2" |
    "icon-ball-point-pen" |
    "icon-ball-point-pen-2" |
    "icon-cloud-checked" |
    "icon-unavailable-cloud" |
    "icon-remote-working" |
    "icon-error-cloud" |
    "icon-upload-to-cloud" |
    "icon-download-from-cloud" |
    "icon-cloud-line-chart" |
    "icon-cloud-clock" |
    "icon-clock-checked" |
    "icon-empty-box" |
    "icon-filter" |
    "icon-sorting-arrows" |
    "icon-generic-sorting" |
    "icon-sort-amount-up" |
    "icon-sort-by-price" |
    "icon-alphabetical-sorting" |
    "icon-database-error" |
    "icon-accept-database" |
    "icon-add-database" |
    "icon-data-encryption" |
    "icon-remove-data" |
    "icon-spiral-bound-booklet" |
    "icon-print-message" |
    "icon-checked" |
    "icon-lock" |
    "icon-trash" |
    "icon-search" |
    "icon-info" |
    "icon-information-2" |
    "icon-source-code" |
    "icon-source-code-2" |
    "icon-code" |
    "icon-api" |
    "icon-severity" |
    "icon-test-partial-passed" |
    "icon-comments" |
    "icon-confetti" |
    "icon-bullhorn-megaphone" |
    "icon-like" |
    "icon-hearts" |
    "icon-hearts-2" |
    "icon-file-contract" |
    "icon-update-file" |
    "icon-circled-right" |
    "icon-circled-left" |
    "icon-double-right" |
    "icon-synchronize" |
    "icon-synchronize-2" |
    "icon-withdrawal" |
    "icon-price-tag" |
    "icon-average" |
    "icon-gender-neutral-user" |
    "icon-headset" |
    "icon-clipboard" |
    "icon-path-steps" |
    "icon-planned-path" |
    "icon-define-location" |
    "icon-difficult-decision" |
    "icon-deposit" |
    "icon-heck-for-payment" |
    "icon-expand-arrows" |
    "icon-diagonal-arrows-right" |
    "icon-stretch-diagonally" |
    "icon-compress" |
    "icon-fold-arrows" |
    "icon-resize-file" |
    "icon-collapse" |
    "icon-expand" |
    "icon-checklist" |
    "icon-graph" |
    "icon-regular-document" |
    "icon-no-document" |
    "icon-submit-document" |
    "icon-mark-as-favorite" |
    "icon-file-preview-2" |
    "icon-view" |
    "icon-document-splash" |
    "icon-edit-file" |
    "icon-route" |
    "icon-marker" |
    "icon-move-by-trolley-1" |
    "icon-box" |
    "icon-warehouse" |
    "icon-package-delivery-logistics" |
    "icon-add-to-collection" |
    "icon-delivered-box" |
    "icon-edit" |
    "icon-edit-2" |
    "icon-checked-2" |
    "icon-cancel-2" |
    "icon-circled-chevron-up" |
    "icon-image-file" |
    "icon-image-file-2" |
    "icon-document" |
    "icon-folder-invoices-3" |
    "icon-folder-invoices-3-2" |
    "icon-copy-2";


const Icon: React.FC<IconProps> = ({ name, size, className, color, style, svgStyle, title }) => {
    const sizeStyle = size ? { width: size, height: size } : {};
    const colorStyle = color ? { fill: color } : {};
    return (<i className={name + className ? ` ${className}` : ''} style={{ ...sizeStyle, ...colorStyle, ...style }} title={title}>
        <svg style={size ? { width: size, height: size, ...svgStyle } : { ...svgStyle }}>
            <use xlinkHref={`#${name}`}></use>
        </svg>
    </i>);
}

export default Icon;