import { useState, useCallback } from 'react';

// visible, show(), hide()
export type UseVisibilityResult = [boolean, () => void, () => void];

export default function useVisibility(initialVisible?: boolean): UseVisibilityResult {
  const [visible, setVisible] = useState(initialVisible || false);

  const show = useCallback(() => setVisible(true), []);
  const hide = useCallback(() => setVisible(false), []);

  return [visible, show, hide];
}
