import React, { useState, useMemo, useCallback, useEffect, useRef, } from 'react';
import TemplateField, { makeTemplateString, parseTemplateString, TEMPLATE_FIELD_INITIAL_VALUE, TemplateData, } from './TemplateField';
import './TemplateInput.scss';
import useTextWidth from '../../hooks/useTextWidth';
import { log } from '../../../util/LoggingUtils';
import { getIn } from '../../forms/validation/na-va-form/commonUtils';
import useKeyDown from '../../hooks/useKeyDown';
import ShortcutKey from '../shortcut-key/ShortcutKey';
import useKeyCombination from '../../hooks/useKeyCombination';

const makeId = (length: number) => {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};

const TextRender: React.FC<{
    item: TemplateInputItem;
    index: number;
    items: TemplateInputItem[];
    fontSize: string;
    readOnly?: boolean;
    handleItemChange: (id: string, value: string) => void;
    trackCursorPosition: (id: string, position: number) => void;
}> = ({ item, index, items, fontSize, readOnly, handleItemChange, trackCursorPosition }) => {
    const width = useTextWidth({
        text: item.value?.replace(/ /g, '\u00a0'),
        font: `${fontSize} 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif`,
    });

    if (!item) {
        return null;
    }
    return (
        <div
            className={`input-wrapper${index === items?.length - 1 ? ' last' : ''}`}
        >
            {readOnly ? <><span>{item.value?.replace(/ /g, '\u00a0')}</span></> :
                <>
                    <input
                        type="text"
                        value={item.value}
                        key={item.id}
                        size={1}
                        style={{ width: width + 4, fontSize: fontSize }}
                        onChange={(e) => handleItemChange(item.id, e.target.value)}
                        onKeyUp={(e) => trackCursorPosition(item.id, (e.target as any).selectionStart)}
                        onClick={(e) => trackCursorPosition(item.id, (e.target as any).selectionStart)}
                    />
                    <span className="input-highlight" style={{ fontSize: fontSize }} >
                        {item.value?.replace(/ /g, '\u00a0')}
                    </span>
                </>}
        </div >
    );
};

type TemplateInputItem = {
    id: string;
    type: 'text' | 'template';
    value: string;
};

export type TemplateInputProps = {
    value?: string;
    onChange?: (value: string, plainValue: string) => void;
    fontSize?: string;
    readOnly?: boolean;
    replacements?: { [key: string]: any }
}

export const parseTemplateValueString = (value: string) => {
    if (!value || typeof value !== 'string') {
        return [];
    }

    const tokenStart = '$${';
    const tokenStartRegex = '\$\$\{';
    const tokenEnd = '}$$';
    const tokenEndRegex = '\}\$\$';

    const startCount = (value.match(tokenStartRegex) ?? []).length;
    const endCount = (value.match(tokenEndRegex) ?? []).length;

    if (startCount !== endCount)
        throw new Error('Template tag was incomplete: ' + value);

    const items = [] as TemplateInputItem[];
    const characters = value.split('');

    let current = '';

    for (let char of characters) {
        current += char;

        if (current.endsWith(tokenEnd)) {
            items.push({ id: makeId(10), type: 'template', value: current });
            current = '';
        }
        if (current.endsWith(tokenStart)) {
            items.push({ id: makeId(10), type: 'text', value: current.substring(0, current.length - tokenStart.length) });
            current = tokenStart;
        }
    }

    items.push({ id: makeId(10), type: 'text', value: current });

    return items;
}

export const makeTemplateValueString = (values: TemplateInputItem[]) => values.map(i => i.value).join('');

export const makePlainValueString = (values: TemplateInputItem[]) => values.map(i => {
    if (i.type === 'template') {
        return parseTemplateString(i.value).defaultValue ?? '';
    }
    return i.value;
}).join('');

export const makeTemplateDisplayString = (values: TemplateInputItem[]) => values.map(i => {
    if (i.type === 'template') {
        return parseTemplateString(i.value).name ?? '';
    }
    return i.value;
}).join('');

const TemplateInput: React.FC<TemplateInputProps> = React.memo(({ value, fontSize, readOnly = false, replacements, onChange }) => {
    const [items, setItems] = useState<TemplateInputItem[]>([{ id: makeId(10), type: 'text', value: '' }]);
    const [cursorPosition, setCursorPosition] = useState<
        { id: string; position: number } | undefined
    >(undefined);

    const [templates, setTemplates] = useState<Map<string, TemplateData>>(
        new Map()
    );

    useEffect(() => {
        if (value) {
            const data = parseTemplateValueString(value);

            if (JSON.stringify(data.map(x => ({ ...x, id: '' }))) !== JSON.stringify(items.map(x => ({ ...x, id: '' })))) {
                // Data has changed, not only IDs

                if (!data.find(x => x.type === 'template' && !parseTemplateString(x.value).type)) {
                    // log("initializing templateInput with", data);
                    setItems(data);
                }
            }
        } else {
            setItems([{ id: makeId(10), type: 'text', value: '' }]);
        }
    }, [value]);

    useEffect(() => {
        if (items.length === 0) {
            // setItems([
            //     { id: makeId(10), type: 'text', value: '' } as TemplateInputItem,
            // ]);
        }
    }, [items]);

    const callOnChange = useCallback((newItems: TemplateInputItem[]) => {
        // if (!items.find(x => x.type === 'template' && !parseTemplateString(x.value).type)) {                        
        log("templateInput changed", items);
        onChange && onChange(makeTemplateValueString(newItems), '');
        // }        
    }, [onChange, items])

    // useEffect(() => {

    //     console.log("onChange of templateInput changed")
    // }, [onChange]);

    const suggestionList = useMemo(() => {
        return [
            { id: makeId(10), display: 'Text' },
            { id: makeId(10), display: 'Number' },
            { id: makeId(10), display: 'Boolean' },
        ];
    }, []);

    const handleAddTemplate = React.useCallback(() => {
        // setTestValue(
        //   // `${start}$$\{"id": "text", "data": { "name": "example", "type": "${id}", "color": "lightblue"} \}$$${end}`
        //   `${start}$$\{"id": ${new Date().getMilliseconds()}, "name": "example"\}$$${end}`
        // );

        const display: any = 'text';

        let defaultValue;
        switch (display) {
            case 'text':
                defaultValue = '';
                break;
            case 'number':
                defaultValue = 0;
                break;
            case 'boolean':
                defaultValue = 'false';
                break;
            case 'date':
                defaultValue = new Date().toDateString();
                break;
        }

        const item = {
            id: makeId(10),
            type: 'template',
            value: makeTemplateString({
                color: 'dodgerblue',
                defaultValue,
                name: TEMPLATE_FIELD_INITIAL_VALUE,
                type: display.toLowerCase(),
            } as TemplateData),
        } as TemplateInputItem;

        const newItems = items.slice();

        if (!cursorPosition) {
            newItems.push(item);
            newItems.push({
                id: makeId(10),
                type: 'text',
                value: '',
            } as TemplateInputItem);
        } else {
            const currentItem = newItems.find((i) => i.id === cursorPosition.id);

            if (!currentItem) return;

            const currentIndex = newItems.indexOf(currentItem) + 1;

            let after = '';

            if (cursorPosition.position === currentItem.value.length) {
                // cursor is at the end of the current item
                after = '';
            } else {
                let before = currentItem.value.substring(0, cursorPosition.position);
                after = currentItem.value.substring(
                    cursorPosition.position,
                    currentItem.value.length
                );

                currentItem.value = before;
            }

            newItems.splice(currentIndex, 0, item);
            newItems.splice(currentIndex + 1, 0, {
                id: makeId(10),
                type: 'text',
                value: after,
            } as TemplateInputItem);
        }

        callOnChange(newItems);
        setItems(newItems);
    }, [items, cursorPosition, callOnChange]);

    const handleItemChange = useCallback(
        (id: string, value: string) => {
            setItems((i) => {
                const data = i.map(curr =>
                    curr.id === id ? { ...curr, value } : curr
                )
                callOnChange(data);
                return data;
            }
            );
            log("field changed in templateInput")
        },
        [callOnChange]
    );

    const trackCursorPosition = useCallback((id: string, position: number) => {
        setCursorPosition({ id, position });
    }, []);

    const handleTemplateDelete = useCallback(
        (id: string) => {
            setItems(oldItems => {

                const newItems = oldItems.slice();
                setItems(i => { log("deleting ", id, " items are ", oldItems); return i })
                const currentItem = oldItems.find((i) => i.id === id);

                if (!currentItem) return oldItems;

                const index = oldItems.indexOf(currentItem);

                const before = newItems[index - 1];
                const after = newItems[index + 1];
                if (before && after && before.type === 'text' && after.type === 'text') {
                    before.value = before.value + after.value;
                    newItems.splice(index, 2);
                } else {
                    newItems.splice(index, 1);
                }

                callOnChange(newItems);
                return newItems;
            })
        },
        [callOnChange]
    );

    const templateRender = useCallback(
        (item: TemplateInputItem, index: number) => {
            if (!item) {
                return null;
            }
            return (
                <TemplateField templateString={item.value} id={item.id} key={item.id} onDelete={handleTemplateDelete}
                    onChange={handleItemChange} disabled={readOnly} replacements={replacements} />
            );
        }, [readOnly, handleItemChange, handleTemplateDelete, replacements]);

    const getTitle = useCallback(() => {
        return items.map(i => {
            if (i.type === 'text') return i.value;

            var template = parseTemplateString(i.value);
            var replacement = getIn(replacements, template.name);
            return replacement ?? template.name;
        }).join('') ?? '';
    }, [items, replacements]);

    const templateInputRef = useRef<HTMLDivElement | null>(null);
    useKeyCombination('m', 'ctrl', (e) => handleAddTemplate(), { node: templateInputRef.current, preventDefault: true });

    const [isCtrlDown] = useKeyDown('Control', templateInputRef.current as any);

    return (
        <div className={`template-input${readOnly ? ' disabled' : ''}`} title={getTitle()} ref={templateInputRef}>
            {items.map((item, i) => {
                return item.type === 'text' ? (
                    <TextRender
                        item={item}
                        index={i}
                        items={items}
                        readOnly={readOnly}
                        fontSize={fontSize ?? '1rem'}
                        handleItemChange={handleItemChange}
                        trackCursorPosition={trackCursorPosition}
                    />
                ) : (
                    templateRender(item, i)
                );
            })}

            {!readOnly && <span style={{ position: 'relative' }}>
                <div className="btn__addTemplate" onClick={() => handleAddTemplate()}>
                    <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/fluency-systems-regular/24/plus-2-math.png"
                        alt="plus-2-math"
                    />
                </div>
                <ShortcutKey shortcutKey={isCtrlDown ? 'M' : undefined} style={{ left: '0.1rem', marginTop: '-0.3rem', opacity: '100%' }} />
            </span>}
        </div>
    );
});

export default TemplateInput;
