import { GridCustomCellProps, GridCellProps } from '@progress/kendo-react-grid';
import React, { ReactElement, useCallback, useMemo } from 'react';
import TemplateInput from '../template-input/TemplateInput';
import { getIn } from '../../forms/validation/na-va-form/commonUtils';
import './TemplateInputGridCell.scss';
import TemplateInputGridCell from './TemplateInputGridCell';

export type TemplateDataGridCellProps = {
    originalProps: GridCellProps,
    td: ReactElement<HTMLTableCellElement>,
    enterEdit: any,
    editField: string
}

export const TemplateDataGridCellRender: React.FC<TemplateDataGridCellProps> = ({ originalProps, td, enterEdit, editField }) => {
    const dataItem = originalProps.dataItem;
    const isInEdit = dataItem[editField || ""];

    const additionalProps = useMemo(() => ({
        onClick: () => {
            enterEdit(dataItem);
        }
    }), [enterEdit, dataItem]);

    return isInEdit ? <TemplateInputGridCell {...originalProps} tdProps={td.props as any} /> :
        <TemplateDataGridCell {...originalProps} tdProps={{ ...td.props, ...additionalProps } as any} />
    //return  React.cloneElement(td, clonedProps, td.props.children as any);
};

const TemplateDataGridCell: React.FC<GridCustomCellProps> = ({ tdProps, dataIndex, dataItem, field, selectionChange }) => {
    return tdProps ? (
        <td
            {...tdProps}
            className={tdProps.className + " cell cell__template-input"}
            onClick={(e: any) => {
                tdProps.onClick && tdProps.onClick(e)
                selectionChange && selectionChange(e)
            }
            }
            style={{
                ...tdProps.style,
            }}            
        > <div >
                <TemplateInput value={getIn(dataItem, field ?? '')} fontSize=".8rem" readOnly={true} />
            </div>
        </td>
    ) : <></>;
}

export default TemplateDataGridCell;