import React, { useCallback } from 'react';
import { InputChangeEvent } from '@progress/kendo-react-inputs';
import BaseInput, { InputProps as BaseInputProps } from '../../../controls/input/Input';
import useNaVaField from '../../na-va-form/NaVaField';
import { L } from '../../../../../abp/utils';

export type InputProps = {
  name: string;
  id?: string;
} & Omit<BaseInputProps, 'value' | 'onFocus' | 'onKeypress' | 'onKeyUp' | 'innerRef' | 'error'>;

const Input: React.FC<InputProps> = React.memo(({ name, type, onBlur, onChange, ...props }) => {
  let {
    error,
    ref,
    onChange: onChangeField,
    onBlur: onBlurField,
    value
  } = useNaVaField<string | number>({ name, type });

  if (type === 'number') {
    value = !value && value !== 0 ? '' : value.toString();
  }

  const aggregatedOnBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    onBlurField(e);
    onBlur && onBlur(e);
  }, [onBlur, onBlurField]);

  const aggregatedOnChange = useCallback((e: InputChangeEvent) => {
    // Add target to event because Kendo does not seem to set this correctly
    e.target = e.nativeEvent.target;
    onChangeField && onChangeField(e);
    onChange && onChange(e);
  }, [onChangeField, onChange]);

  return (
    <BaseInput
      type={type}
      name={name}
      error={error && L(error)}
      innerRef={ref}
      onBlur={aggregatedOnBlur}
      onChange={aggregatedOnChange}
      value={value}
      {...props}
    />
  );
});

export default Input;
