import { GridCellProps, GridColumn, GridColumnProps, GridHeaderSelectionChangeEvent, GridItemChangeEvent, GridProps, GridSelectionChangeEvent, getSelectedState } from '@progress/kendo-react-grid';
import { Grid } from '@progress/kendo-react-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getIn } from '../../forms/validation/na-va-form/commonUtils';
import { log } from '../../../util/LoggingUtils';
import useVisibility from '../../hooks/useVisibility';
import LoadingIndicator from '../loading-indicator/LoadingIndicator';
import './ApiGrid.scss'
import { UseCrudApiResult } from '../../hooks/useCrudApi';
import { useOnClickOutside } from 'usehooks-ts';
import styled from 'styled-components';
import { c } from '../../../util/ClassUtils';
import { isArray } from 'lodash';
import { NEW_ENTITY_ID } from '../../../util/EntityUtils';

export type CellRenderType = GridProps['cellRender'];
export type ApiGridProps = Omit<GridProps, 'data' | 'selectedField' | 'onHeaderSelectionChanged' | 'cellRender'> &
{
    columns?: GridColumnProps[],
    primaryKeyName?: string,
    isEditable?: boolean,
    isRefetchable?: boolean,
    selectedItems?: string[] | any[],
    onSelectionChanged?: (keys: string[], items: any[]) => void,
    cellRender?: (enterEditMode: (item: any) => void) => CellRenderType
}
export type ApiGridPropsOf<T, TApi> = ApiGridProps & { apiResult: UseCrudApiResult<T, TApi> };

export type ApiGridCellSettings = {

}

export const IN_EDIT_FIELD = 'inEdit';
export const SELECTED_FIELD = 'selected';

const ApiGridComp = styled.div`
    position: relative;
`;

const GridToolbar = styled.div`
    display: flex;
    margin-bottom: 1rem;

    > *:not(:last-child) {
        margin-right: 1rem;
    }
`;

const GridWrapper = styled.div`
    display: flex;
    max-height: 100%;
    flex: 1;
    min-height: 0;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: #000000, $alpha: 0.1);
`

const ApiGrid: React.FC<ApiGridPropsOf<any, any>> = ({ apiResult, columns, primaryKeyName = 'id', children, isEditable, isRefetchable = true, onSelectionChanged, cellRender, className, selectedItems, ...props }) => {

    const [editId, setEditIdX] = useState<string | undefined>(undefined);
    const setEditId = useCallback((value: any) => { log('setEditId'); setEditIdX(value) }, [setEditIdX]);
    const [items, api, create, get, update, remove, messageData, refetch] = apiResult;

    const [loadingVisible, load, loaded] = useVisibility();

    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        // items.map(i => ({ ...i, inEdit: i.id === editId }))
        log("items changed");
        setEditId(undefined);
        setData(items.slice());
    }, [items]);

    useEffect(() => {
        if (!selectedItems?.length)
            return;

        const keys = typeof selectedItems[0] === 'string' ? selectedItems : selectedItems.map(x => getIn(x, primaryKeyName));

        setData(d => d.map(item => {
            if (keys.includes(getIn(item, primaryKeyName))) {
                return {
                    ...item,
                    [SELECTED_FIELD]: true
                }
            } else {
                return {
                    ...item,
                    [SELECTED_FIELD]: false
                }
            }
        }));
    }, [selectedItems, primaryKeyName])

    const handleValidateRow = useCallback(async (current: any): Promise<boolean> => {
        return true;
    }, []);


    useEffect(() => log("data changed", data), [data])

    const handleSaveItem = useCallback(async (): Promise<boolean> => {
        const current = data.find((d) => getIn(d, primaryKeyName) === editId);

        const isValid = await handleValidateRow(current);

        if (!isValid) {
            return false;
        }

        try {

            // Save current item
            if (current) {
                log("saving current item: ", JSON.stringify("current"));
                load();

                if (current.id === NEW_ENTITY_ID) {
                    create && await create(api, { ...current, id: undefined, [SELECTED_FIELD]: undefined });
                } else {
                    update && await update(api, { ...current, [SELECTED_FIELD]: undefined });
                }

                refetch && refetch();

                loaded();
            }

        } catch (error) {
            // TODO show eror
            return false;
        }

        return true;
    }, [data, primaryKeyName, editId, api, create, update, load, loaded, handleValidateRow, refetch]);

    const enterEditMode = useCallback(async (dataItem: any) => {

        if (!isEditable) { return; }

        const validAndSaved = await handleSaveItem();

        if (!validAndSaved) { return; }

        const id = getIn(dataItem, primaryKeyName);
        setEditId(id);

        setData(d => d.map(item => {
            if (getIn(item, primaryKeyName) === id) {
                return {
                    ...item,
                    [IN_EDIT_FIELD]: true
                };
            } else if (getIn(item, primaryKeyName) === editId) {
                return {
                    ...item,
                    [IN_EDIT_FIELD]: false
                };
            }
            else {
                return item;
            }
        }));
    }, [editId, setEditId, primaryKeyName, handleSaveItem]);


    const handleItemChange = useCallback((event: GridItemChangeEvent) => {
        const inEditId = event.dataItem.id;
        const field = event.field ?? '';

        log("handle item change fired");
        // TODO: update data here
        setData(d => d.map((item) =>
            getIn(item, primaryKeyName) === inEditId ? { ...item, [field]: event.value } : item
        ));

    }, [primaryKeyName]);

    const closeEdit = useCallback(() => {
        setData(d => d.map(item => {
            return getIn(item, primaryKeyName) === editId ?
                {
                    ...item,
                    [IN_EDIT_FIELD]: false
                }
                : item
        }));

        setEditId(undefined);
    }, [editId, primaryKeyName])

    const addRecord = useCallback(() => {
        setData(d => [{ id: NEW_ENTITY_ID }, ...d]);
        setEditId(NEW_ENTITY_ID);
    }, []);

    const handleLeaveEditMode = useCallback(async () => {
        if (editId) {
            const validAndSaved = await handleSaveItem();

            if (!validAndSaved) { return; }

            closeEdit();
        }
    }, [editId, handleSaveItem, closeEdit])

    const apiGridRef = useRef(null);
    useOnClickOutside(apiGridRef, handleLeaveEditMode);

    const [selectedState, setSelectedState] = React.useState<{ [id: string]: boolean | number[] }>({});

    const handleSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: primaryKeyName
            });
            setSelectedState(newSelectedState);
            setData(d => d.map(item => {
                return {
                    ...item,
                    [SELECTED_FIELD]: selectedState[getIn(item, primaryKeyName)]
                }
            }));
        },
        [selectedState, primaryKeyName]
    );

    useEffect(() => {
        const selectedIds = Object.entries(selectedState).filter((val => val[1] === true)).map(([key]) => key);

        onSelectionChanged && onSelectionChanged(selectedIds, data.filter(i => selectedIds.includes(getIn(i, primaryKeyName))));
    }, [selectedState]);

    const onHeaderSelectionChange = React.useCallback((event: GridHeaderSelectionChangeEvent) => {
        const checkboxElement = event.syntheticEvent.target as HTMLInputElement;
        const checked = checkboxElement.checked;
        const newSelectedState = {} as any;
        event.dataItems.forEach((item) => {
            newSelectedState[getIn(item, primaryKeyName)] = checked;
        });
        setSelectedState(newSelectedState);
    }, [primaryKeyName]);

    return (<ApiGridComp className={className}>
        <GridToolbar>
            {isEditable && <div onClick={closeEdit}>
                <button
                    title="Add new"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={addRecord}
                >
                    Add new
                </button>
            </div>}
            {isRefetchable && <button
                title="Add new"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={() => refetch && refetch()}
            >
                Refresh
            </button>}

        </ GridToolbar>
        <GridWrapper ref={apiGridRef}>
            <Grid data={data}
                editField={IN_EDIT_FIELD}
                onItemChange={handleItemChange}
                scrollable={props.scrollable ?? 'scrollable'}
                style={{ height: '100%', maxHeight: '100%', minHeight: 0, flex: '1', ...props.style }}
                cellRender={cellRender ? cellRender(enterEditMode) : undefined}
                selectedField={props.selectable?.enabled ? SELECTED_FIELD : undefined}
                onHeaderSelectionChange={props.selectable?.enabled && props.selectable?.mode === 'multiple' ? onHeaderSelectionChange : undefined}
                onSelectionChange={props.selectable?.enabled ? handleSelectionChange : undefined}
                {...props}>
                {children}
                {props.selectable?.enabled ?
                    <GridColumn field={SELECTED_FIELD}
                        headerSelectionValue={/*props.selectable?.enabled && props.selectable?.mode === 'multiple' */true ?
                            (data.findIndex(i => !selectedState[getIn(i, primaryKeyName) as string]) === -1) : undefined} />
                    : null}
                {columns?.map(c => {
                    return (<GridColumn key={c.field} {...c} />);
                })}
            </Grid>
        </GridWrapper>
        {loadingVisible && <StyledLoadingIndicator />}
    </ApiGridComp >)
}

export default ApiGrid;
