import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { Features } from '../../data/field-constants/Features';

// https://devtrium.com/posts/how-keyboard-shortcut

export type Modifier = 'ctrl' | 'shift' | 'alt' | 'meta';
export type KeyCombinationOptions = {
    node?: any;
    preventDefault?: boolean;
    disabled?: () => boolean;
}

const useKeyCombination = (keys: string | string[], modifiers: Modifier | Modifier[], callback: (event: KeyboardEvent) => void, options?: KeyCombinationOptions, ignoreFeature = false) => {
    const { node, preventDefault, disabled } = options ?? {};

    // implement the callback ref pattern
    const callbackRef = useRef(callback);
    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    // handle what happens on key press
    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            // check if one of the key is part of the ones we want
            var keyList = Array.isArray(keys) ? keys : [keys];
            var modifierList = Array.isArray(modifiers) ? modifiers : [modifiers];

            const ctrl = modifierList.includes('ctrl');
            const shift = modifierList.includes('shift');
            const alt = modifierList.includes('alt');
            const meta = modifierList.includes('meta');

            if (keyList.every((key) => event.key === key) && event.ctrlKey === ctrl && event.shiftKey === shift
                && event.altKey === alt && event.metaKey === meta) {
                if (preventDefault ?? true) {
                    event.preventDefault();
                }
                
                if(disabled && disabled()) return;

                callbackRef.current(event);
            }
        },
        [keys, disabled, modifiers, preventDefault]
    );

    useEffect(() => {
        if (!ignoreFeature && !abp.features.isEnabled(Features.Application.HotKeys)) {
            return;
        }

        // target is either the provided node or the document
        const targetNode = node ?? document;
        // attach the event listener
        targetNode &&
            targetNode.addEventListener("keydown", handleKeyPress);

        // remove the event listener
        return () =>
            targetNode &&
            targetNode.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress, node, ignoreFeature]);
};

export default useKeyCombination;