import { DateTime, Duration } from 'luxon';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import NaVaForm from '../../../../common/forms/validation/na-va-form/NaVaForm';
import { NaVaFormValues } from '../../../../common/forms/validation/na-va-form/types';
import './TimeEntityInput.scss';
import Time from '../../../../common/forms/controls/time-input/Time';
import useAuthConfiguration from '../../../../common/hooks/useAuthConfiguration';
import { TimeEntitiesApi, TimeEntity } from '../../../../client/http';
import TimeEntityInputInner from './time-entity-input-inner/TimeEntityInputInner';
import { Settings_ProviderName, Settings_ResourceId, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import { hoursAmountFromDuration, hoursAmountFromTimes, hoursAmountToIsoDuration, mapTime, timeFromDateTime, timeFromJsDate, timeToDateTime } from '../../../../util/DateTimeUtils';
import { getExtensionValue } from '../../../../util/EntityUtils';
import { useNaVaFormContext } from '../../../../common/forms/validation/na-va-form/NaVaFormContext';
import { initial } from 'lodash';
import { log } from '../../../../util/LoggingUtils';

export const FIELD_ID = "id";
export const FIELD_ETAG = "etag";
export const FIELD_PLANNING_DATE = "planningDate";
export const FIELD_DATE_FROM = "dateFrom";
export const FIELD_DATE_TO = "dateTo";
export const FIELD_AMOUNT = "amount";
export const FIELD_NAME = "name";
export const FIELD_DESIGNATION = "designation";
export const FIELD_DESCRIPTION1 = "description1";
export const FIELD_DESCRIPTION2 = "description2";
export const FIELD_RESOURCES = "resources";
export const FIELD_JOB = "job";
export const FIELD_JOB_ID = "jobId";
export const FIELD_JOB_TASK = "jobTask";
export const FIELD_JOB_TASK_ID = "jobTaskId";
export const FIELD_RESOURCE = "resource";
export const FIELD_RESOURCE_ID = "resourceId";
export const FIELD_CATEGORY = "category";
export const FIELD_CATEGORY_ID = "categoryId";
export const FIELD_IS_INVOICED = "isInvoiced";
export const FIELD_IS_BONUS = "isBonus";
export const FIELD_UNIT_PRICE = "unitPrice";
export const FIELD_JOB_JOURNAL_BATCH = "jobJournalBatch";
export const FIELD_JOB_JOURNAL_BATCH_ID = "journalBatchId";
export const FIELD_POST_BY_AMOUNT = "postByAmount";

export type TimeEntityInputProps = {
    isPlanningEntity?: boolean;
    isEditor?: boolean;
    isHostedInForm?: boolean;
    initialValue?: TimeEntity;
    fetchFromAllProviders?: boolean;
    selectedEntity?: any;
    onReset?: () => void;
    onSubmit?: (values: NaVaFormValues) => void;
    onSubmitted?: () => void;
    onDelete?: () => void;
    onCreateNewFromSelected?: () => void;
};

export const MapTimeEntity = (entity?: TimeEntity) => {
    if (entity) {

        if (entity.startTime && !entity.startTime.getMonth)
            entity.startTime = DateTime.fromISO(entity.startTime as unknown as string).toJSDate();
        if (entity.endTime && !entity.endTime.getMonth)
            entity.endTime = DateTime.fromISO(entity.endTime as unknown as string).toJSDate();
        if (entity.extensionData)
            entity.extensions = JSON.parse(entity.extensionData);

        if (entity.startTime)
            (entity as any).planningDate = entity.startTime;
        if (entity.startTime)
            (entity as any).dateFrom = timeFromJsDate(entity.startTime);
        if (entity.endTime)
            (entity as any).dateTo = timeFromJsDate(entity.endTime);


        (entity as any)[FIELD_JOB_JOURNAL_BATCH_ID] = entity?.extensions?.journalBatchId;
    }

    return entity;
}

export type SaveResult = { successful: boolean, errorMessage: string }

const TimeEntityInput: React.FC<TimeEntityInputProps> =
    ({ isPlanningEntity = true, isEditor = false, isHostedInForm = false, fetchFromAllProviders = false, selectedEntity, initialValue, onReset, onSubmit, onSubmitted, onDelete, onCreateNewFromSelected }) => {

        const authConfig = useAuthConfiguration();
        const timeEntityClient = useMemo(() => new TimeEntitiesApi(authConfig), [authConfig]);
        const [isSaving, setIsSaving] = useState<boolean>(false);
        const [lastSaveResult, setLastSaveResult] = useState<SaveResult | undefined>(undefined);

        const startOfHalfHour = useCallback((date: DateTime) => {
            let result = date.startOf("hour");
            if (date.minute <= 30)
                result = result.set({ minute: 30 });
            else {
                result = result.set({ minute: 0 });
                result = result.plus({ hour: 1 });
            }
            return result;
        }, []);

        const nextFrom = startOfHalfHour(DateTime.now());
        const nextTo = startOfHalfHour(DateTime.now()).plus({ hours: 2 })

        const initialStartTime = initialValue?.startTime ? timeFromJsDate(initialValue.startTime) : timeFromDateTime(nextFrom);
        const initialEndTime = initialValue?.endTime ? timeFromJsDate(initialValue.endTime) : timeFromDateTime(nextTo)

        const initialValues = {
            // [FIELD_ID]: initial?.id ?? '',
            // [FIELD_ETAG]: getExtensionValue(initial?.extensions, FIELD_ETAG) ?? '',
            // [FIELD_PLANNING_DATE]: initial?.startTime ? initial.startTime : DateTime.now().toJSDate(),
            // [FIELD_DATE_FROM]: initialStartTime,
            // [FIELD_DATE_TO]: initialEndTime,
            // [FIELD_AMOUNT]: initial?.duration ?? hoursAmountFromTimes(initialStartTime, initialEndTime),
            // [FIELD_NAME]: initial?.name ?? '',
            // [FIELD_DESCRIPTION1]: initial?.description1 ?? '',
            // [FIELD_DESCRIPTION2]: initial?.description2 ?? '',
            // [FIELD_RESOURCE]: initial?.resourceFk ? initial?.resourceFk : null,
            // [FIELD_RESOURCE_ID]: initial?.resourceId ?? '',
            // [FIELD_JOB]: initial?.jobFk ?? null,
            // [FIELD_JOB_ID]: initial?.jobId ?? '',
            // [FIELD_JOB_TASK]: initial?.jobTaskFk ?? null,
            // [FIELD_JOB_TASK_ID]: initial?.jobTaskId ?? '',
            // [FIELD_CATEGORY]: initial?.categoryFk ?? null,
            // [FIELD_CATEGORY_ID]: initial?.categoryId ?? null,
            // [FIELD_IS_INVOICED]: getExtensionValue(initial?.extensions, FIELD_IS_INVOICED) ?? false,
            // [FIELD_IS_BONUS]: getExtensionValue(initial?.extensions, FIELD_IS_BONUS) ?? false,
            // [FIELD_DIFF_SALES_PRICE]: getExtensionValue(initial?.extensions, FIELD_DIFF_SALES_PRICE) ?? 0,
            // [FIELD_JOB_JOURNAL_BATCH_ID]: initial?.extensions?.journalBatchId ?? ''
            [FIELD_ID]: '',
            [FIELD_ETAG]: '',
            [FIELD_PLANNING_DATE]: DateTime.now().toJSDate(),
            [FIELD_DATE_FROM]: initialStartTime,
            [FIELD_DATE_TO]: initialEndTime,
            [FIELD_AMOUNT]: hoursAmountFromTimes(initialStartTime, initialEndTime),
            [FIELD_NAME]: '',
            [FIELD_DESCRIPTION1]: '',
            [FIELD_DESCRIPTION2]: '',
            [FIELD_RESOURCE]: null,
            [FIELD_RESOURCE_ID]: '',
            [FIELD_JOB]: null,
            [FIELD_JOB_ID]: '',
            [FIELD_JOB_TASK]: null,
            [FIELD_JOB_TASK_ID]: '',
            [FIELD_CATEGORY]: null,
            [FIELD_CATEGORY_ID]: "",
            [FIELD_IS_INVOICED]: false,
            [FIELD_IS_BONUS]: false,
            [FIELD_UNIT_PRICE]: 0,
            [FIELD_JOB_JOURNAL_BATCH_ID]: ''
        };

        const [initial, setInitial] = useState<TimeEntity | undefined>(initialValues);

        const createTimeEntity = useCallback((values: NaVaFormValues) => {
            log("Form submitting with these values:", values, "startTime", mapTime(values[FIELD_DATE_FROM], values[FIELD_PLANNING_DATE]).toJSDate(), "endDate", mapTime(values[FIELD_DATE_TO], values[FIELD_PLANNING_DATE]).toJSDate());
            var obj = {
                id: values[FIELD_ID],
                name: values[FIELD_NAME],
                description1: values[FIELD_DESCRIPTION1],
                description2: values[FIELD_DESCRIPTION2],
                startTime: mapTime(values[FIELD_DATE_FROM], values[FIELD_PLANNING_DATE]).toJSDate(),
                endTime: mapTime(values[FIELD_DATE_TO], values[FIELD_PLANNING_DATE]).toJSDate(),
                duration: hoursAmountToIsoDuration(values[FIELD_AMOUNT]) as any,
                isPlanningEntity,
                isEditable: true,
                jobId: values[FIELD_JOB]?.id,
                jobTaskId: values[FIELD_JOB_TASK]?.id,
                resourceId: values[FIELD_RESOURCE]?.id,
                categoryId: values[FIELD_CATEGORY]?.id,
                extensionData: JSON.stringify({ [FIELD_ETAG]: values[FIELD_ETAG], [FIELD_JOB_JOURNAL_BATCH_ID]: values[FIELD_JOB_JOURNAL_BATCH]?.id, [FIELD_POST_BY_AMOUNT]: values[FIELD_POST_BY_AMOUNT], unitOfMeasureId: values[FIELD_CATEGORY]?.extensions?.unitOfMeasureId, unitPrice: values[FIELD_UNIT_PRICE] }),
                serviceConnectionId: abp.setting.getInt(Settings_ServiceConnectionId), //parseInt(abp.setting.get("App.TimeTracking.CurrentServiceConnection"))
                serviceProvider: abp.setting.get(Settings_ProviderName),
            };           

            setIsSaving(true);

            timeEntityClient.apiServicesAppTimeEntitiesCreateOrUpdateEntityPost(obj).then(d => { setLastSaveResult({ successful: true, errorMessage: "" }); onSubmitted && onSubmitted() }).catch(e => {
                e.json().then((b: any) => setLastSaveResult({ successful: false, errorMessage: b.error.message }));
                log("error", e)
            });
        }, [timeEntityClient, isPlanningEntity]);

        useEffect(() => {
            const mappedEntity = MapTimeEntity(initialValue);
            setInitial({ ...initialValues, ...mappedEntity });
        }, [initialValue, setInitial]);


        log(initial);

        return (<div className="time-entity-input">
            <NaVaForm
                //TODO: Get the next empty time slot from the server, try continuing planning, respect current time (e.g. 20 minutes before end of working day)
                initialValues={initial as any}
                validationConfig={{
                    [FIELD_DESCRIPTION1]: [
                        {
                            validate: (obj: any) => !!obj[FIELD_DESCRIPTION1],
                            message: "This field must not be empty"
                        }
                    ],
                    [FIELD_DESCRIPTION2]: [
                        {
                            validate: (obj: any) => !!obj[FIELD_DESCRIPTION1],
                            message: "This field must not be empty"
                        }
                    ],
                    [FIELD_AMOUNT]: [
                        {
                            validate: (values: any) => values[FIELD_AMOUNT] <= 24,
                            message: "Amounts greater than 24h are not yet supported"
                        }
                    ],
                    [FIELD_JOB_TASK]: [
                        {
                            validate: (values: any) => values[FIELD_JOB_TASK] ? values[FIELD_JOB_TASK]["isOverdue"] === false : true,
                            message: "Job Task must not be overdue"
                        }
                    ]
                }}
                onSubmit={(values) => { createTimeEntity(values); onSubmit && onSubmit(values) }}
                enableReinitialize={false}
                validateOnMount={true}
            >
                {/* <InitialFormDataHandler initialData={initial}></InitialFormDataHandler> */}
                <TimeEntityInputInner isEditor={isEditor} isHostedInForm={isHostedInForm}
                    isPlanningEntity={isPlanningEntity} fetchFromAllProviders={fetchFromAllProviders}
                    selectedEntity={selectedEntity} onReset={onReset} /*onSubmit={(values) => { createTimeEntity(values); onSubmit && onSubmit(values) }}*/ onDelete={onDelete}
                    lastSaveResult={lastSaveResult} isSaving={isSaving} initial={initial} onCreateNewFromSelected={onCreateNewFromSelected} />
            </NaVaForm>
        </div>)
    }

export default TimeEntityInput;

// const InitialFormDataHandler: React.FC<{ initialData?: TimeEntity }> = ({ initialData }) => {

//     const { setFieldValue, values } = useNaVaFormContext();

//     useEffect(() => {
//         initialData && Object.keys(initialData).map(k => {
//             setFieldValue(k, (initialData as any)[k]);
//             console.log("Mapped initial value", k, (initialData as any)[k], values[k])
//         })
//     }, [initialData, setFieldValue]);

//     return <></>;
// }