import React, { useCallback } from 'react';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Dropdown as BaseDropdown, DropdownProps as BaseDropdownProps } from '../../../controls/dropdown/Dropdown';
import useNaVaField from '../../na-va-form/NaVaField';

export type DropdownProps = Omit<
  BaseDropdownProps,
  'value' | 'onFocus' | 'onKeyDown' | 'onKeypress' | 'onKeyUp' | 'innerRef'
>;

const Dropdown: React.FC<DropdownProps> = React.memo(({name, dataItemKey, onChange, data, itemRender, ...props}) => {
  const {
    error,
    value,
    ref,
    setValue
  } = useNaVaField({ name });

  const aggregatedOnChange = useCallback((e: DropDownListChangeEvent) => {
    setValue(e.value, true);
    onChange && onChange(e);
  }, [setValue, onChange]);

  return (
    <BaseDropdown
      name={name}
      innerRef={ref}
      dataItemKey={dataItemKey}
      onChange={aggregatedOnChange}
      error={error}
      value={value}
      data={data}
      itemRender={itemRender}
      {...props}
    />
  );
});

export default Dropdown;
