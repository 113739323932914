import React from 'react';
import { L } from '../../../abp/utils';
import { Category, CategoryApi } from '../../../client/http';
import ItemRenderComponent from '../../../common/components/item-render/ItemRenderComponent';
import ApiComboBox from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import useApi from '../../../common/hooks/useApi';
import { FIELD_DESIGNATION, FIELD_NAME } from '../../../data/field-constants/GeneralConstants';
import { CategorySelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';


const CategorySelection: React.FC<CategorySelectionProps> = ({ fetchFromAllProviders, selectedItem, itemRenderFields, data,
    name, title, placeholder, filterable, filterFields, dataItemKey, textField, itemRender, content, footer, ...props }) => {

    const apiResult = useApi<Category, CategoryApi>(c => new CategoryApi(c),
        c => c.apiServicesAppCategoryGetAllGet(fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
            fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId)),
        c => ({ ...c, extensions: JSON.parse(c.extensionData ?? '{}') }));

    return <ApiComboBox apiResult={data ? [data, undefined, undefined, () => { }, undefined] : apiResult} name={name} filterable={true} filterFields={filterFields ?? [FIELD_DESIGNATION, FIELD_NAME]}
        dataItemKey={dataItemKey ?? 'id'} textField={textField ?? FIELD_DESIGNATION} placeholder={placeholder ?? L('CategoryOrWorkType')} popupSettings={{ width: 'min(400px,50vw)' }}
        itemRender={(li, props) => <ItemRenderComponent li={li} itemProps={props} fields={itemRenderFields ?? [FIELD_DESIGNATION, FIELD_NAME]} />}
        content={content} footer={footer} {...props} />
}

export default CategorySelection;