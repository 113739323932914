import { useCallback, useEffect, useState } from "react";

// Check if object is {}
const isEmpty = (object: any) => Object.keys(object).length === 0 && object.constructor === Object;

export const useModuleData = (moduleDataPath: string, overrideValues: boolean = false) => {
    const [data, setData] = useState<any>({});

    const updateData = useCallback(() => {
        const setting = abp?.setting?.get("App.UseModules");
        if (setting) {
            var result: any = {};
            var modules = setting.split(',');

            if (overrideValues)
                modules = modules.reverse();

            for (const module of modules) {
                var data = {};
                try {
                    data = require(`../modules/${module}/${moduleDataPath}`);
                } catch { }

                result = { ...result, ...data };

                // Break loop on first data found when using overrideValues
                if (overrideValues && data && !isEmpty(data))
                    break;
            }
            setData(result);
        } else {
            setTimeout(updateData, 500);
        }
    }, [moduleDataPath, setData, overrideValues]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    return { data };
}

export const useModuleArrayData = (moduleDataPath: string, overrideValues: boolean = false) => {
    const [data, setData] = useState<any[]>([]);

    const updateData = useCallback(() => {
        const setting = abp?.setting?.get("App.UseModules");
        if (setting) {
            var result: any[] = [];
            var modules = setting.split(',');

            if (overrideValues)
                modules = modules.reverse();

            for (const module of modules) {
                var data = [];
                try {
                    data = require(`../modules/${module}/${moduleDataPath}`);
                } catch { }
                result = [...result, ...data];

                // Break loop on first data found when using overrideValues
                if (overrideValues && data && data.length > 0)
                    break;
            }
            setData(result);
        } else {
            setTimeout(updateData, 500);
        }
    }, [moduleDataPath, setData, overrideValues]);

    useEffect(() => {
        updateData();
    }, [updateData]);

    return { data };
}