import React, { useEffect, useState } from 'react';
import { DateInputProps as KendoDateInputProps } from '@progress/kendo-react-dateinputs';
import { Input, InputProps } from '@progress/kendo-react-inputs';
import parseDateFormula from './parseDateFormula';
import { DateTime } from 'luxon';

export type DateInputProps = Omit<KendoDateInputProps<any>, 'format'> & {
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  innerRef?: ((instance: HTMLInputElement | null) => void);
  format?: string;
} & Pick<InputProps, 'onFocus'>;

const DateInput: React.FC<DateInputProps> = ({ placeholder, name, value, format, defaultValue, onChange, onBlur, innerRef,
  /* Filter out incompatible props */ min, max, width, formatPlaceholder, _ref, size, ...props }) => {

  const [textValue, setTextValue] = useState(defaultValue ? DateTime.fromJSDate(defaultValue).toFormat(format ?? '') : undefined);

  useEffect(() => {
    if (value) setTextValue(DateTime.fromJSDate(value).toFormat(format ?? ''));
    else setTextValue('');
  }, [value, format]);

  return (
    <Input
      type="text"
      className="k-input"
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue ? DateTime.fromJSDate(defaultValue).toFormat(format ?? '') : undefined}
      ref={e => innerRef && innerRef(e && e.element)}
      value={textValue}
      // Update only the input's value. Actual onChange is delayed to onBlur for formula evaluation reasons
      onChange={e => setTextValue(e.target.value as string)}
      // Evaluate the current dateformula and trigger change + blur
      onBlur={e => {
        const date = textValue ? parseDateFormula(textValue) : null;
        if (date == null) {
          setTextValue('');
        }
        const lastDate = value;
        const nullDates = [date, lastDate].filter(d => !d);
        if (nullDates.length === 1 || (nullDates.length === 0 && !DateTime.fromJSDate(date!).startOf('day').equals(DateTime.fromJSDate(lastDate!).startOf('day'))))
          onChange && onChange({
            syntheticEvent: e,
            nativeEvent: e.nativeEvent,
            value: date,
            target: e.target
          });
        else
          value && setTextValue(DateTime.fromJSDate(value).toFormat(format ?? ''));
        onBlur && onBlur(e);
      }}      
      {...props}
    />
  );
};

export default DateInput;
