import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import React, { ReactNode, useEffect, useState } from 'react';
import Button from '../../forms/controls/button/Button';
import './Wizard.scss';

export type WizardProps = {
    className?: string;
    steps: WizardStep[];
    header: ReactNode;
}

export type WizardStep = {
    label: string;
    disabled?: boolean;
    isValid?: boolean;
    component: ReactNode;
    hideWhenInactive?: boolean;
}

const Wizard: React.FC<WizardProps> = ({ className, steps, header }) => {

    // Stepper

    const [step, setStep] = useState(0);
    const [maxStep, setMaxStep] = useState(0);

    useEffect(() => {
        if (step > maxStep)
            setMaxStep(step);
    }, [step, maxStep]);

    const handleStepChange = (e: StepperChangeEvent) => {
        setStep(e.value);
    }


    return <div className={`app-wizard${className ? ' ' + className : ''}`}>

        {header && (typeof header === 'string' ? <h1>{header}</h1> : header)}

        <Stepper
            value={step}
            onChange={handleStepChange}
            items={steps}
            mode="labels"
        />

        <div className="wizard-inner row">
            {steps.map((s, i) => {
                return (i === step || s.hideWhenInactive) && (<div className={`step-${i} col`} key={s.label + i} style={(i !== step && s.hideWhenInactive) ? { display: 'none' } : { display: 'block' }}>{s.component}</div>)
            })}
        </div>

        <div className="stepper-control row justify-content-around">
            <Button label="Zurück" color="primary" disabled={step === 0 || steps[step - 1]?.disabled} sm={3} onClick={() => setStep(s => s - 1)} />
            <Button label="Weiter" color="primary" disabled={step === steps.length - 1 || steps[step + 1]?.disabled} sm={3} onClick={() => setStep(s => s + 1)} />
        </div>

    </div>
}

export default Wizard;