export const FIELD_ID = 'id';
export const FIELD_NO = 'no';
export const FIELD_LINE_NUMBER = 'lineNumber'
export const FIELD_NAME = 'name';
export const FIELD_NAME2 = 'name2';
export const FIELD_DESCRIPTION = 'description';
export const FIELD_DESCRIPTION1 = 'description1';
export const FIELD_DESCRIPTION2 = 'description2';
export const FIELD_DESIGNATION = 'designation';
export const FIELD_IS_EDITABLE = 'isEditable';
export const FIELD_IDENTIFIER = 'identifier';
export const FIELD_FIRST_NAME = 'firstName';
export const FIELD_SURNAME = 'surname';
export const FIELD_IS_ENABLED = 'isEnabled';
export const FIELD_TENANT_ID = 'tenantId';
export const FIELD_ETAG = "etag";
export const FIELD_PLANNING_DATE = "planningDate";
export const FIELD_START_DATE = "startDate";
export const FIELD_START_TIME = "startTime";
export const FIELD_DATE_FROM = "dateFrom";
export const FIELD_END_DATE = "endDate";
export const FIELD_END_TIME = "endTime";
export const FIELD_DATE_TO = "dateTo";
export const FIELD_AMOUNT = "amount";
export const FIELD_AMOUNT_MISC = "amountMisc";
export const FIELD_AMOUNT_UNIT = "amountUnit";
export const FIELD_RESOURCES = "resources";
export const FIELD_JOB = "job";
export const FIELD_JOB_ID = "jobId";
export const FIELD_JOB_TASK = "jobTask";
export const FIELD_JOB_TASK_ID = "jobTaskId";
export const FIELD_RESOURCE = "resource";
export const FIELD_RESOURCE_ID = "resourceId";
export const FIELD_CATEGORY = "category";
export const FIELD_CATEGORY_ID = "categoryId";
export const FIELD_CUSTOMER = "customer";
export const FIELD_CUSTOMER_ID = "customerId";
export const FIELD_CUSTOMER_NAME = "customerName";
export const FIELD_UNIT_PRICE = "unitPrice";
export const FIELD_DIRECT_COST = "directCost";
export const FIELD_UNIT_COST = "unitCost";
export const FIELD_POST_BY_AMOUNT = "postByAmount";
export const FIELD_EXTENSIONS = "extensions";
export const FIELD_IS_PLANNING_ENTITY = "isPlanningEntity";
export const FIELD_CREATOR_USER_ID = "creatorUserId";
export const FIELD_ORIG_BLANKET_RES_QTY = "origBlanketResQty";
export const FIELD_RES_QTY_SCHEDULE = "resQtySchedule";
export const FIELD_RES_QTY_USAGE = "resQtyUsage";
export const FIELD_RES_QTY_SALE = "resQtySale";

export const FIELD_USE_DURATION = "useDuration";
export const FIELD_USE_TIME = "useTime";
export const FIELD_IS_INVOICED = "isInvoiced";
export const FIELD_IS_BONUS = "isBonus";
export const FIELD_FORCE_NON_TIME_BASED_SESSION = "forceNonTimeBasedSession";

export const FIELD_IS_TEMPLATE = "isTemplate";

export const FIELD_JOB_TYPE = "jobType";
export const FIELD_JOB_TYPE_CODE = "jobTypeCode";
export const FIELD_JOB_TYPE_ID = "jobTypeId";

export const FIELD_POSTING_TYPE = "postingType";