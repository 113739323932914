import React, { PropsWithChildren, useCallback } from 'react';
import { usePastDelay, lazy } from 'react-lazy-no-flicker';

const Fallback: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const pastDelay = usePastDelay(1000);
    return pastDelay ? <>{children ?? <div />}</> : null;
}

// const ModuleComponentPart: React.FC<{ component: any, data?: any }> = ({ component, data }) => {
//     // const Component = lazy(() => import(path), { time_before_fallback: 1000, minimum_fallback_time: 0 }) //, [path]);
//     const Component = useMemo(() => component, [component]);

//     return <React.Suspense>

//         <Component {...(data)} />
//     </React.Suspense>
// }

const ModuleFileComponentProvider: React.FC<{ modulePath: string, appPartName: string, moduleName?: string, data?: any, inheritForModule?: string, overrideValues?: boolean, fallback?: React.ReactNode }> =
    React.memo(({ modulePath, appPartName, moduleName, data, inheritForModule, overrideValues = false, fallback }) => {

        const getModules = useCallback(() => {
            // if a module name gets provided, we want to specifically get the component from that module
            let modules = moduleName ? [moduleName] : abp.setting.get("App.UseModules")?.split(',');

            // if we only want the most specific component, reverse the search order
            if (inheritForModule || overrideValues)
                modules = modules.reverse();

            // if the component should be inherited we are looking for the next most specific component
            // and therefore remove the given module from the search list
            if (inheritForModule)
                modules = modules.slice(modules.indexOf(inheritForModule) + 1);

            return modules;
        }, [moduleName, inheritForModule, overrideValues])

        const getComponents = useCallback(() => {
            const components: any[] = [];

            const modules = getModules();

            // App or settings are not yet loaded
            if (!modules)
                return;

            for (const module of modules) {
                let itemData: any = {}
                try {
                    itemData = require(`../modules/${module}/${modulePath}${modulePath ? '/' : ''}_items.json`);
                } catch { }
                if (itemData[appPartName]) {
                    components.push(lazy(() => import('../modules' + itemData[appPartName]), { time_before_fallback: 1000, minimum_fallback_time: 0 }));
                    if (overrideValues || inheritForModule)
                        break;
                }
            }
            return components;
        }, [getModules, modulePath, appPartName, overrideValues, inheritForModule]);
                
        return <>
            <React.Suspense fallback={<Fallback>{fallback}</Fallback>} >
                {getComponents()?.map((Component, i) =>
                    <Component {...(data ?? {})} key={i} />
                )}
            </React.Suspense>
        </>
    });

export default ModuleFileComponentProvider;