import React, { useCallback, useEffect } from 'react';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import { useState } from 'react';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import Input from '../../../common/forms/validation/controls/input/Input';
import ModuleComponentProvider from '../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../AppParts';
import { FIELD_ID, FIELD_RESOURCE, FIELD_RESOURCE_ID } from '../../../data/field-constants/GeneralConstants';
import { ResourceSelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import Button from '../../../common/forms/controls/button/Button';
import { L } from '../../../abp/utils';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { Resource, ResourceApi, WorkItemSyncApi } from '../../../client/http';
import useApi from '../../../common/hooks/useApi';
import { useNaVaFormContext } from '../../../common/forms/validation/na-va-form/NaVaFormContext';
import { FIELD_MAP_ITEMS_SOURCE, FIELD_MAP_ITEMS_TARGET } from './DevOpsAdministration';
import IconButton from '../../../common/components/icon/IconButton';
import ExpansionPanel from '../../../common/components/expansion-panel/ExpansionPanel';
import { log } from '../../../util/LoggingUtils';

const RessourceMappingEditor: React.FC<{
    onAddMapping?: (mapping: any) => void,
    onCancel?: () => void,
}> = ({ onAddMapping, onCancel }) => {

    const { values: administrationValues } = useNaVaFormContext();

    const sourceConnection = getIn(administrationValues, FIELD_MAP_ITEMS_SOURCE);
    const targetConnection = getIn(administrationValues, FIELD_MAP_ITEMS_TARGET);
    const mapFromConnectionId = sourceConnection.serviceConnectionId;
    const mapToConnectionId = targetConnection.serviceConnectionId;

    const [resourceMappings, setResourceMappings] = useState<any[] | null>(null);

    const [, workItemSyncApi,] = useApi<any, WorkItemSyncApi>(c => new WorkItemSyncApi(c));
    const [{ values, isValid, submitForm, resetForm }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const [resources] = useApi<Resource, ResourceApi>(c => new ResourceApi(c), c => c.apiServicesAppResourceGetAllGet(targetConnection.serviceProviderName, mapToConnectionId));

    const loadResourceMappings = useCallback(() => {

        workItemSyncApi?.apiServicesDevOpsWorkItemSyncGetResourceMappingsGet(mapFromConnectionId, mapToConnectionId)
            .then((d) => {
                setResourceMappings((d as any).result)
            })
            .catch((e) => log(e));

    }, [mapFromConnectionId, mapToConnectionId, workItemSyncApi]);

    const addResourceMapping = useCallback((email: string, resourceId: string) => {

        workItemSyncApi?.apiServicesDevOpsWorkItemSyncCreateOrUpdateResourceMappingPost(email, resourceId, mapFromConnectionId, mapToConnectionId)
            .then((d) => {
                loadResourceMappings();
            })
            .catch((e) => log(e));

    }, [mapFromConnectionId, mapToConnectionId, workItemSyncApi, loadResourceMappings]);

    const removeMapping = (id: number) => {

        workItemSyncApi?.apiServicesDevOpsWorkItemSyncDeleteMappingDelete(id)
            .then((d) => {
                loadResourceMappings();
            })
            .catch((e) => log(e));

    };

    useEffect(() => {
        loadResourceMappings();
    }, [loadResourceMappings]);

    return <div className="resource-mapping-editor">

        <ExpansionPanel title={
            <h4>Ressourcen-Zuordnungen
                <span className="badge rounded-pill text-bg-secondary ms-3">
                    {resourceMappings?.length} Elemente
                </span>
            </h4>}>
            {resourceMappings?.length ?
                <table className="table table-striped table-bordered table-no-outer-border">
                    <thead>
                        <tr>
                            <th className="align-middle text-center">Azure DevOps User</th>
                            <th className="align-middle text-center">Zugeordnete Ressource</th>
                            <th className="align-middle text-center">Name</th>
                            <th className="align-middle text-center">Ressourcen-ID</th>
                            <th className="align-middle text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {resourceMappings.map((m: any) => {
                            const mappedResource = resources.find(r => r.id === m.targetValue);

                            return <tr key={m.type}>
                                <td className="align-middle">{m.sourceValue}</td>
                                <td className="align-middle">{mappedResource?.designation}</td>
                                <td className="align-middle">{mappedResource?.username}</td>
                                <td className="align-middle">{m.targetValue}</td>
                                <td className="align-middle text-center">
                                    <IconButton name="icon-trash" size="1rem" onClick={() => removeMapping(m.id)} />
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                : <span className="text-muted">Keine Zuordnungen eingerichtet</span>}
        </ExpansionPanel>

        <h3>Neue Zuordnung erstellen</h3>

        <NaVaForm
            initialValues={{
            }}
            onSubmit={() => {
                onAddMapping && onAddMapping({
                    email: getIn(values, "email"),
                    resourceId: getIn(values, [FIELD_RESOURCE, FIELD_ID])
                });
                addResourceMapping(getIn(values, "email"), getIn(values, [FIELD_RESOURCE, FIELD_ID]));
                resetForm();
            }}
            validationConfig={{
                "email": [
                    {
                        validate: (obj: any) => !!getIn(obj, "email"),
                        message: L('ValidationFieldMustNotBeEmpty')
                    }
                ],
                [FIELD_RESOURCE]: [
                    {
                        validate: (obj: any) => getIn(obj, [FIELD_RESOURCE, FIELD_ID]),
                        message: L('ValidationFieldMustNotBeEmpty')
                    }
                ]
            }}
            validateOnMount={true}
        >
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>

            <div className="row">
                <Input type="email" name="email" xs={6} lg={4} />
                <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Resource} props={{ name: FIELD_RESOURCE, foreignKeyName: FIELD_RESOURCE_ID, xs: 6, lg: 4, data: resources, fetchFromAllProviders: false } as ResourceSelectionProps} getSingleComponent={true} />
                <Button color="primary" label={L('Add')} xs={4} md={2} disabled={!isValid} onClick={() => submitForm && submitForm()} />
            </div>

        </NaVaForm>
    </div >
}

export default RessourceMappingEditor;