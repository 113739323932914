import React, { ReactNode, useCallback } from 'react';
import { Dropdown, DropdownProps } from '../../../../common/forms/controls/dropdown/Dropdown';
import classNames from 'classnames';
import Input, { InputProps } from '../../../../common/forms/controls/input/Input';
import { DatePickerProps } from '../../../../common/forms/controls/date-picker/DatePicker';
import { PlmControlColumn } from '../../template-management/plm-job-list/components/PlmControlComponents';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import TemplateInput, { TemplateInputProps } from '../../../../common/components/template-input/TemplateInput';
import './InputGroup.scss';

export type InputGroupProps =
    {
        label: ReactNode;
        isCollapsed?: boolean;
        isEditMode?: boolean;
        valueRender?: ReactNode
    } & (
        { type: 'string' } & TemplateInputProps |
        { type: 'number' } & Pick<InputProps, 'name' | 'className' | 'value' | 'onChange' | 'disabled' | 'type'> |
        { type: 'selection' } & Pick<DropdownProps, 'name' | 'className' | 'value' | 'data' | 'dataItemKey' | 'textField' | 'onChange' | 'disabled' | 'popupSettings'> |
        { type: 'date' } & Pick<DatePickerProps, 'name' | 'className' | 'value' | 'onChange' | 'disabled'>
    )

// const controlRender: React.FC<Omit<InputGroupProps, 'label' | 'isCollapsed' | 'valueRender'>> = ({ type, ...props }) => {
//     switch (type) {
//         case 'number':
//             return <Input {...props} />
//         case 'selection':
//             return <Dropdown {...props} />
//         case 'date':
//             return <DatePicker {...props} />
//     }
// }

const InputGroup: React.FC<InputGroupProps> = ({ label, isCollapsed = false, isEditMode = false, valueRender, type, ...inputProps }) => {

    const valDisplay = valueRender ?? inputProps.value?.toString();

    const controlRender = useCallback(() => {
        switch (type) {
            case 'string':
                return <TemplateInput {...inputProps as any} />
            case 'number':
                return <Input {...inputProps as any} />
            case 'selection':
                return <Dropdown {...inputProps as any} />
            case 'date':
                return <DatePicker {...inputProps as any} />
        }
    }, [type, inputProps]);

    return <div className={classNames('input-group', `type__${type}`, { 'collapsed': isCollapsed })}>
        <span className="input-label">
            {label}{isCollapsed && ':'}
        </span>
        {isCollapsed ?
            <>{typeof valDisplay === 'string' ? <div>{valDisplay}</div> : valDisplay}</>
            : controlRender()
        }
    </div>
}

export default InputGroup;