import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { JobGridProps } from '../../../../module-components/service-entities/ServiceEntityControlProps';
import ModuleComponentProvider from '../../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../../AppParts';
import TemplateInputGridCell from '../../../../common/components/api-grid/TemplateInputGridCell';
import TemplateDataGridCell from '../../../../common/components/api-grid/TemplateDataGridCell';
import NaVaForm from '../../../../common/forms/validation/na-va-form/NaVaForm';
import NaVaFormStatePuller from '../../../../common/forms/NaVaFormStatePuller';
import Switch from '../../../../common/forms/validation/controls/switch/Switch';
import { NaVaFormContextType, NaVaFormValues } from '../../../../common/forms/validation/na-va-form/types';
import { L } from '../../../../abp/utils';
import Wizard from '../../../../common/components/wizard/Wizard';
import { getExtensionValue } from '../../../../util/EntityUtils';
import _ from 'lodash';
import TemplateFieldManagement from './TemplateFieldManagement';
import Button from '../../../../common/forms/controls/button/Button';
import useApi from '../../../../common/hooks/useApi';
import { Job, JobApi, JobDto, JobState } from '../../../../client/http';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import filter from '../../../../util/FilterUtils';
import { showNotification } from '../../../../common/components/notifications/NotificationHost';
import { log } from '../../../../util/LoggingUtils';
import PlmControl, { PlmControlProps } from '../plm-job-list/PlmControl';
import { defaultColumnConfig } from '../../data/columnConfigurations';
import { PlmControlSection } from '../plm-job-list/components/PlmControlComponents';

const DataManagement: React.FC = () => {

    const [selectedTemplate, setSelectedTemplate] = useState();
    const handleTemplateSelected = useCallback((keys: string[], items: any[]) => {
        if (items.length > 0) {
            getExtensionValue(items[0], '')
            setSelectedTemplate(items[0]);
        } else {
            setSelectedTemplate(undefined);
        }
    }, []);

    const [data, setData] = useState<any[]>([]);
    const handleTemplateFieldDataUpdated = useCallback((data: any) => {
        setData(data);
    }, [setData]);

    const fieldManagement = useMemo(() => {
        return <TemplateFieldManagement dataItem={selectedTemplate} onDataChanged={handleTemplateFieldDataUpdated} />;
    }, [selectedTemplate, handleTemplateFieldDataUpdated]);

    const templateSelection = useMemo(() => {
        return <>
            <TemplateSelection handleSelectionChanged={handleTemplateSelected} />
            {/* {fieldManagement} */}
        </>
    }, [handleTemplateSelected/*, fieldManagement*/]);

    const completionStep = useMemo(() => {
        return <CompletionStep data={data} />
    }, [data]);


    const steps = useMemo(() => [
        {
            label: "Vorlage auswählen",
            isValid: !!selectedTemplate,
            component: templateSelection,
            hideWhenInactive: true
        },
        {
            label: "Vorlagenfelder",
            disabled: !selectedTemplate,
            isValid: true,
            component: fieldManagement,
            hideWhenInactive: true
        },
        {
            label: "Abschließen",
            disabled: true,
            isValid: false,
            component: completionStep
        }
    ], [selectedTemplate, templateSelection, fieldManagement, completionStep]);

    return <div className="data-management my-3 px-4">
        {/* <h5>Stammdatenverwaltung</h5> */}

        <Wizard
            header=""
            steps={steps}
        />

    </div >
};

const TemplateSelection: React.FC<{ handleSelectionChanged: (keys: string[], items: any[]) => void }> = ({ handleSelectionChanged }) => {

    const [{ values: filterValues }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const handleJobSelection = useCallback((selection: { section: PlmControlSection, keys?: string[], items?: any[] }[]) => {
        const section = selection.find(x => x.section === 'job');

        handleSelectionChanged(section?.keys ?? [], section?.items ?? []);

    }, [handleSelectionChanged])

    return <div className="template-selection">
        <div className="filters row my-3">
            <h5 className="mb-3">Vorlagenart:</h5>
            <NaVaForm
                initialValues={{ selectJobTemplates: true }}
                onSubmit={() => { }}>
                <NaVaFormStatePuller onStateChanged={setFormState} />
                <div className="d-flex align-items-center">
                    <h5 className="mx-3">{L('Item')}</h5>
                    <Switch name="selectJobTemplates" disabled={true} />
                    <h5 className="mx-3">{L('Job')}</h5>
                </div>
            </NaVaForm>
        </div>

        <h5 className="mb-3">Bitte wählen Sie die Vorlage aus, die Sie nutzen möchten:</h5>

        {/* <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Grid.Job} getSingleComponent={true}
            props={{
                isEditable: false,
                cells: {
                    edit:
                    {
                        text: TemplateInputGridCell
                    }
                    , data: TemplateDataGridCell
                }, selectable: {
                    enabled: true,
                    mode: 'single'
                },
                onSelectionChanged: handleSelectionChanged,
                internalFilters: [filter("IsTemplate").equals(true)]
            } as JobGridProps} /> */}

        <PlmControl columnConfig={defaultColumnConfig} showTemplates onSelectionChanged={handleJobSelection} selectable readOnly={true} />
    </div>
}

const CompletionStep: React.FC<{ data: any }> = ({ data }) => {

    // const [internalData, setInternalData] = useState<any>();

    // useEffect(() => {

    // }, [data]);
    const [, api] = useApi<Job, JobApi>(c => new JobApi(c));

    const handleSave = useCallback(async () => {
        log(data, {
            ...data[0],
            jobState: (data[0].jobState ?? '') === '' ? 0 : parseInt(data[0].jobState),
            id: undefined,
            serviceProvider: data.serviceProvider ?? abp.setting.get(Settings_ProviderName),
            serviceConnectionId: data.serviceConnectionId ?? abp.setting.getInt(Settings_ServiceConnectionId),
            isTemplate: false,
            templateId: data[0].id
        });

        try {


            await api?.apiServicesAppJobCreatePost({
                ...data[0],
                jobState: (data[0].jobState ?? '') === '' ? 0 : parseInt(data[0].jobState),
                id: undefined,
                serviceProvider: data.serviceProvider ?? abp.setting.get(Settings_ProviderName),
                serviceConnectionId: data.serviceConnectionId ?? abp.setting.getInt(Settings_ServiceConnectionId),
                isTemplate: false,
                templateId: data[0].id
            });

            showNotification('Speichern erfolgreich', 'Datensatz erfolgreich aktualisiert', 'success', 5000, true);

        } catch (error) {

            showNotification('Speichern fehlgeschlagen', 'Fehler beim Speichern aufgetreten', 'error', undefined, true);
        }
    }, [data, api]);

    return <div className="completion">
        <h5>Abschließen des Vorgangs</h5>
        <div>Bitte überprüfen Sie die angelegten Daten und speichern Sie sie dann:</div>

        <h5 className="mt-3">{L('Job')}</h5>
        <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Grid.Job} getSingleComponent={true}
            props={{
                isEditable: false,
                isRefetchable: false,
                data,
                cells: {
                    edit:
                    {
                        text: TemplateInputGridCell
                    }
                    , data: TemplateDataGridCell
                }, selectable: {
                    enabled: false
                }
            } as JobGridProps} />

        <Button md={4} offsetMd={4} mt={3} color="primary" label={L('Save')} onClick={() => handleSave()} />
    </div>
}

export default DataManagement;