const dot = (value: string) => value + ':';

const Application = (base: string) => ({
    MainLogo: base + 'MainLogo',
    LoginLogo: base + 'LoginLogo'
});

const ServiceEntity = (base: string) => ({ WorkItem: base + 'WorkItem', Area: base + 'Area', Project: base + 'Project' });

const ServiceEntities = (base: string) => ({
    Details: ServiceEntity(base + dot('Details')),
    Selection: ServiceEntity(base + dot('Selection')),
    View: ServiceEntity(base + dot('View'))
});


export const DevOpsAppParts = {
    Administration: dot('DevOps') + 'Administration',
    ServiceEntities: ServiceEntities(dot('DevOps') + dot('ServiceEntities')),
};