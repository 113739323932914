// Inspired by https://medium.com/@karenmarkosyan/how-to-manage-promises-into-dynamic-queue-with-vanilla-javascript-9d0d1f8d4df5
type PromiseExecutor<T> = () => Promise<T>;

interface PromiseQueueEntry<T> {
  execute: PromiseExecutor<T>;
  resolve(value: T | PromiseLike<T>): void;
  reject(reason?: any): void;
}

interface PromiseQueue<T> extends PromiseQueueImpl<T> {}

class PromiseQueueImpl<T> {
  private _queue: PromiseQueueEntry<T>[] = [];
  private _pendingPromise = false;

  public enqueue(execute: PromiseExecutor<T>) {
    return new Promise<T>((resolve, reject) => {
        this._queue.push({
            execute,
            resolve,
            reject,
        });
        void this._dequeue();
    });
  }

  private async _dequeue() {
    if (this._pendingPromise) return;
    const item = this._queue.shift();
    if (!item) return;
    try {
      this._pendingPromise = true;
      const value = await item.execute();
      this._pendingPromise = false;
      item.resolve(value);
      void this._dequeue();
    } catch (err) {
      this._pendingPromise = false;
      item.reject(err);
      void this._dequeue();
    }
  }
}

const PromiseQueue = <T>(): PromiseQueue<T> => new PromiseQueueImpl<T>();

export default PromiseQueue;
