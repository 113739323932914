import React, { useCallback, useMemo } from 'react';
import { useReactiveApi } from '../../../common/hooks/useApi';
import { Configuration, WorkItem, WorkItemsApi } from '../../../client/http';
import useSimplePromise from '../../../common/hooks/useSimplePromise';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { DateTime } from 'luxon';

export type WorkItemViewProps = {
    projectName: string
};

const WorkItemView: React.FC<WorkItemViewProps> = ({ projectName }) => {

    const clientFactory = useCallback((c: Configuration) => new WorkItemsApi(c), []);

    const query = useMemo(() =>
        "SELECT [System.Id]" +
        "FROM WorkItems " +
        `WHERE [System.TeamProject] IN ('${projectName}') ` +
        "ORDER BY [System.ChangedDate] DESC"
        , [projectName]);

    const getAllResult = useCallback((c: WorkItemsApi) =>
        c.apiServicesDevOpsWorkItemsGetWorkItemsGet(projectName, query, ['System.Id', 'System.WorkItemType', 'System.Title', 'System.AreaPath', 'System.State', 'System.AssignedTo', 'System.Tags', 'System.CreatedDate', 'System.ChangedDate']),
        [projectName, query]);

    const array = useMemo(() => [], []);
    const emptyResultPromise = useSimplePromise(array);
    const emptyResult = useCallback((c: WorkItemsApi) => emptyResultPromise, [emptyResultPromise]);
    const mapFunction = useCallback((w: any) => {
        let mappedFields = {} as any;
        Object.keys(w.fields).map(k => {
            mappedFields[k.replace('.', '_')] = w.fields[k];
        });

        return { ...w, ...mappedFields };
    }, []);

    const [data, api] = useReactiveApi<any, WorkItemsApi>(clientFactory, !!projectName ? getAllResult : emptyResult, mapFunction);

    return <div className="work-item-view p-4">
        <table>
            <tr>
                <th>Id</th>
                <th>Type</th>
                <th>Title</th>
                <th>Assigned To</th>
                <th>State</th>
                <th>Area</th>
                <th>Changed Date</th>
            </tr>
            {
                (data as any)?.length > 0 && (data as any)?.slice(0, 10).map((w: any) => {
                    // var avatarRef = getIn(w, ['System_AssignedTo', '_links', 'avatar', 'href']);
                    // var assignedToAvatar = getIn(w, ['System_AssignedTo', 'imageUrl']);


                    return <tr key={w.id}>
                        <td>{w['System_Id']}</td>
                        <td>{w['System_WorkItemType']}</td>
                        <td>{w['System_Title']}</td>
                        <td>
                            {/* {assignedToAvatar && <img src={assignedToAvatar} alt="Avatar"></img>} */}
                            {getIn(w, ['System_AssignedTo', 'displayName'])}</td>
                        <td>{w['System_State']}</td>
                        <td>{w['System_AreaPath']}</td>
                        <td>{DateTime.fromISO(w['System_ChangedDate']).toRelative()}</td>

                    </tr>
                })
            }
        </table>
    </div>
};


export default WorkItemView;
