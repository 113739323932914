import { DateTime, Duration } from "luxon";

type FilterOperator = 'and' | 'eq' | 'gt' | 'ge' | 'lt' | 'le' | 'ne' | 'or' | 'startsWith' | 'endsWith' | 'contains';

type InputType = string | number | boolean | Date | DateTime | Duration;

class Filter {
    public left: string;
    public operator: FilterOperator = 'eq';
    public right: string = '';
    public rightType: string = '';

    constructor(left: string) {
        this.left = left;
    }

    private setValues(operator: FilterOperator, right: InputType) {

        this.right = right?.toString() ?? '';
        this.operator = operator;

        switch (typeof right) {
            case "bigint":
            case "number":
                this.rightType = "number";
                break;
            case "boolean":
                this.rightType = "boolean";
                break;
            //@ts-expect-error        
            case "object":
                if (right instanceof Date) {
                    this.rightType = "date";
                    this.right = (right as Date).toISOString();
                    break;
                }
                else if (right instanceof DateTime) {
                    this.rightType = "date";
                    this.right = (right as DateTime).toISO();
                    break;
                }
                else if (right instanceof Duration) {
                    this.rightType = "duration";
                    this.right = (right as Duration).toISOTime();
                    break;
                }
            // else: Explicit Fall through to string
            default:
                this.rightType = "string";
                break;
        }
    }

    // public And(right: InputType){
    //     this.SetValues('and', right);
    // }

    public equals(right: InputType) {
        this.setValues('eq', right);
        return this;
    }

    public greaterThan(right: InputType) {
        this.setValues('gt', right);
        return this;
    }

    public greaterOrEqual(right: InputType) {
        this.setValues('ge', right);
        return this;
    }

    public lowerThan(right: InputType) {
        this.setValues('lt', right);
        return this;
    }

    public lowerOrEqual(right: InputType) {
        this.setValues('le', right);
        return this;
    }

    public notEqual(right: InputType) {
        this.setValues('ne', right);

        return this;
    }

    // public Or(right: InputType) {
    //     this.SetValues('gt', right);
    // }

    public startsWith(right: InputType) {
        this.setValues('startsWith', right);
        return this;
    }

    public endsWith(right: InputType) {
        this.setValues('endsWith', right);
        return this;
    }

    public contains(right: InputType) {
        this.setValues('contains', right);
        return this;
    }
}


const filter: (field: string) => Filter = (field: string) => new Filter(field);

export type { Filter };
export type { FilterOperator as FilterOperation };
export default filter;