import { arrayBuffer } from "stream/consumers";
import { getIn, setIn } from "../common/forms/validation/na-va-form/commonUtils";
import { FIELD_EXTENSIONS } from "../data/field-constants/GeneralConstants";

export const NEW_ENTITY_ID = '$newEntity$'

const getExtensionField = (item: any, field: string) => {
    if (!!!item || item === undefined || item == null || !Object.hasOwn(item, field.split('.')[0]))
        return undefined;

    return getIn(item, field);
};

export const FIELD_EXTENSION_DATA = "extensionData";

export const getExtensionValue = (item: any, field: string) => {
    if (!!!item || item === undefined || item == null || !Object.hasOwn(item, FIELD_EXTENSION_DATA))
        return undefined;

    const extensions = item[FIELD_EXTENSIONS];
    if ((!!!extensions || extensions === undefined || extensions == null) && item[FIELD_EXTENSION_DATA]) {
        item.extensions = JSON.parse(item[FIELD_EXTENSION_DATA]);
    }

    return getExtensionField(item[FIELD_EXTENSIONS], field);
}

export const getObjectOrExtensionValue = (item: any, field: string) => {
    if (!!!item || item === undefined || item == null) {
        return undefined;
    }

    if (Object.hasOwn(item, field)) {
        return getIn(item, field);
    }

    return getExtensionValue(item, field);
}

export const setExtensionValue = (item: any, field: string, value: any) => {
    if (!!!item || item === undefined || item == null)
        throw new Error("Could not set extension value, object was undefined or null", item);

    if (!Object.hasOwn(item, FIELD_EXTENSION_DATA))
        throw new Error("Could not set extension value, object is not extendable", item);

    const extensions = item[FIELD_EXTENSIONS];
    if (!!!extensions || extensions === undefined || extensions == null) {
        if (item[FIELD_EXTENSION_DATA]) {
            item.extensions = JSON.parse(item[FIELD_EXTENSION_DATA] ?? '{}');
        } else {
            item.extensions = {};
        }
    }

    (item.extensions as { [id: string]: any })[field] = value;
}

// export const firstOrDefault = <T,>(array: T[]) => array && array.length > 0 ? array[0] : undefined;

export const unique = <T,>(array: T[], key: string) => [...new Map(array.map((item: any) => [item[key as any], item])).values()];

const nthOrDefault = <T,>(array?: T[], primaryKey = 'id', predicate?: (value: T, index: number, array: T[]) => boolean, type: 'first' | 'last' = 'first') => {
    if (!array || array?.length < 1) return undefined;

    let data = array;

    if (predicate) {
        data = array.filter(predicate);
    }

    if (data.length < 1) return undefined;

    return data.sort((a, b) => (a as any)[primaryKey] - (b as any)[primaryKey]).at(type === 'first' ? 0 : -1);
};

export const firstOrDefault = <T,>(array?: T[], primaryKey = 'id', predicate?: (value: T, index: number, array: T[]) => boolean) => nthOrDefault(array, primaryKey, predicate, 'first');

export const lastOrDefault = <T,>(array?: T[], primaryKey = 'id', predicate?: (value: T, index: number, array: T[]) => boolean) => nthOrDefault(array, primaryKey, predicate, 'last');

export const removeItemOnce = (arr: any[], value: any) => {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export const removeItemAll = (arr: any[], value: any) => {
    var i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}