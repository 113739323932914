import React, { useEffect } from 'react';
import useModuleContext from '../../module-components/module-context/useModuleContext';
import { Modules } from '../../module-components/Modules';
import { AppParts } from '../AppParts';
import SdtLoginLogo from './application/SdtLoginLogo';
import SdtLogo from './application/SdtLogo';
import JobTypeSelection from './service-entitites/JobTypeSelection';


const ModuleRegistration: React.FC = () => {
    const { registerComponent } = useModuleContext();

    useEffect(() => {
        // registerComponent(AppParts.BudgetPlanning.JobTaskDetails, Modules.zdiTimeTracking, true, (props: any) => <JobTaskDetails {...props} />);

        // registerComponent(AppParts.TimeTracking.TimeEntities.EditorFields, Modules.zdiTimeTracking, true, (props: any) => <TimeEntityEditorFields {...props} />);
        // registerComponent(AppParts.TimeTracking.TimeEntities.EntityDetails, Modules.zdiTimeTracking, true, (props: any) => <EntityDetails {...props} />);
        // registerComponent(AppParts.TimeTracking.TimeEntities.EntityData, Modules.zdiTimeTracking, true, (props: any) => <EntityData {...props} />);
        // registerComponent(AppParts.TimeTracking.TimeEntities.ContextMenuItemsHost, Modules.zdiTimeTracking, true, (props: any) => <ContextMenuItemsHost {...props} />)

        // registerComponent(AppParts.Reporting.ReportEditor.Tools, Modules.zdiTimeTracking, true, (props: any) => <ReportEditorTools {...props} />);
        // registerComponent(AppParts.Reporting.ReportList.EntitiesFilter, Modules.zdiTimeTracking, true, (props: any) => <ReportListEntitiesFilter{...props} />);

        registerComponent(AppParts.Application.LoginLogo, Modules.sedotecPlm, true, (props: any) => <SdtLoginLogo {...props} />);
        registerComponent(AppParts.Application.MainLogo, Modules.sedotecPlm, true, (props: any) => <SdtLogo {...props} />);
        registerComponent(AppParts.Application.CollapsedLogo, Modules.sedotecPlm, true, (props: any) => <SdtLogo collapsed {...props} />);
        // registerComponent(AppParts.Application.ProviderLogo('Rewind365.Modules.ZdiProvider'), Modules.zdiTimeTracking, true, (props: any) => <img src={ZdiOnButton} alt="Provider Logo" {...props} />);

        // registerComponent(AppParts.ServiceEntities.Selection.Category, Modules.zdiTimeTracking, true, (props: any) => <CategorySelection {...props} />);
        registerComponent(AppParts.ServiceEntities.Selection.JobType, Modules.sedotecPlm, true, (props: any) => <JobTypeSelection {...props} />);
    }, [registerComponent]);

    return null;
}

export default ModuleRegistration;