import React from 'react';
import ApiComboBox, { ApiComboBoxProps } from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import { ServiceEntitySelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import useApi from '../../../common/hooks/useApi';
import { WorkItemsApi } from '../../../client/http';
import { L } from '../../../abp/utils';
import ItemRenderComponent from '../../../common/components/item-render/ItemRenderComponent';

export type ProjectSelectionProps = ApiComboBoxProps & ServiceEntitySelectionProps<any> &
{
    itemRenderFields?: string[]
    projectName?: string;
};

const ProjectSelection: React.FC<ProjectSelectionProps> = ({ selectedItem, data, itemRenderFields,
    name, title, placeholder, filterable, filterFields, dataItemKey, textField, itemRender, content, ...props }) => {

    const apiResult = useApi<any, WorkItemsApi>(c => new WorkItemsApi(c), c => c.apiServicesDevOpsWorkItemsGetProjectsGet(props.projectName));

    return <ApiComboBox apiResult={data ? [data, undefined, undefined, () => { }, undefined] : apiResult} name={name} filterable={filterable ?? true} dataItemKey={dataItemKey ?? 'id'}
        textField={textField ?? 'name'} placeholder={placeholder ?? L('Project')}
        itemRender={(li, props) => <ItemRenderComponent li={li} itemProps={props} fields={itemRenderFields ?? ['name']} />}
        content={content} popupSettings={{ width: 'min(400px,50vw)' }}
        filterFields={filterFields ?? ['name']} {...props} />

}

export default ProjectSelection;