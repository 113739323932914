import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import LoadingSign from './media/loading.json';
import LoadingProgress from './media/loading-progress-bar.json';
import './LoadingIndicator.scss'

const LoadingIndicator: React.FC<{ className?: string, loadingIndicatorType?: 'spinner' | 'progressBar', isHidden?: boolean }> = ({ className, loadingIndicatorType = 'spinner', isHidden = false }) => {

  // <div className={`k-loading-mask ${props.className??''}`}>
  //   <span className="k-loading-text">Loading</span>
  //   <div className="k-loading-image"></div>
  //   <div className="k-loading-color"></div>
  // </div>
  return loadingIndicatorType === 'spinner' ?
    <div className={`loading-container ${className ?? ''}`} style={{ visibility: isHidden ? 'hidden' : 'visible' }}>
      <div className="inner">
        <Player src={LoadingSign} autoplay loop></Player>
      </div>
    </div> :
    <div className={`loading-bar ${className ?? ''}`} style={{ visibility: isHidden ? 'hidden' : 'visible' }}>
      <div className="inner">
        <div className="bar-items">
          <div className="item__1" />
          <div className="item__2" />
          <div className="item__3" />
        </div>
      </div>
    </div>
};

export default LoadingIndicator;
