import { DateTime, Duration } from 'luxon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TimerComponent, { TimerState } from '../timer-component/TimerComponent';
import { TimeEntitiesApi, TimeTrackingSessionDto } from '../../../client/http';
import './TimeTrackingSession.scss';
import NaVaForm from '../../forms/validation/na-va-form/NaVaForm';
import ModuleComponentProvider from '../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../../modules/AppParts';
import { Input as KendoInput } from '@progress/kendo-react-inputs';
import { CirclePicker, HuePicker } from 'react-color';
import ControlWrapper from '../../forms/controls/control-wrapper/ControlWrapper';
import IconButton from '../icon/IconButton';
import { L } from '../../../abp/utils';
import useApi from '../../hooks/useApi';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';
import useDebounce from '../../hooks/useDebounce';
import { log } from '../../../util/LoggingUtils';

type TimeEntity = {
    duration?: Duration;
    startTime?: DateTime;
    endTime?: DateTime;
    name?: string;
    description1?: string;
    description2?: string;
}

type TimeTrackingSessionProps = {
    session: TimeTrackingSessionDto;
    onSessionChanged: (session: TimeTrackingSessionDto) => void
    onTimerUpdated: (sessionId: string, state: string, history: string) => void
}

const TimeTrackingSession: React.FC<TimeTrackingSessionProps> = ({ session, onSessionChanged, onTimerUpdated }) => {

    const [timeEntities, setTimeEntities] = useState<TimeEntity[]>([]);
    const [date, setDate] = useState<DateTime>(DateTime.now());
    const [entitiesDuration, setEntitiesDuration] = useState<Duration>(Duration.fromMillis(0));
    const [isTitleEditMode, setIsTitleEditMode] = useState(false);
    const [titleData, setTitleData] = useState({ name: session.name, color: session.color });
    const [, timeEntitiesApi] = useApi<TimeEntity, TimeEntitiesApi>(c => new TimeEntitiesApi(c));
    const [entityReference, setEntityReference] = useState<TimeEntity | undefined>(undefined);

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(DateTime.now());
        }, 500)

        return () => clearInterval(interval);
    }, []);

    const timeEntityReference = session.timeEntityReferences;
    useEffect(() => {
        timeEntitiesApi?.apiServicesAppTimeEntitiesGetEntityGet(timeEntityReference, abp.setting.get(Settings_ProviderName), abp.setting.getInt(Settings_ServiceConnectionId))
            .then(d => setEntityReference((d as any).result));
    }, [timeEntityReference, timeEntitiesApi, setEntityReference]);

    useEffect(() => {
        if (timeEntities.length > 0) {
            var duration = Duration.fromMillis(0);

            timeEntities.map(t => duration = duration.plus(t.duration ?? (t.endTime ?? date).diff(t.startTime!)));

            const entities = timeEntities.filter(t => t.duration === undefined && t.endTime === undefined);

            // if (entities.length > 1)
            //     throw new Error("Multiple entities are missing an End Time");

            setEntitiesDuration(duration.shiftTo('hours', 'minutes', 'seconds'));
        }
    }, [timeEntities, date, setEntitiesDuration]);

    const onStateChanged = useCallback((state: TimerState) => {
        log("State changed");
        switch (state) {
            case 'Running':
                // event may be called by mistake during hot reload or similar cases
                if (timeEntities.filter(t => t.endTime === undefined).length === 0)
                    timeEntities.push({ startTime: DateTime.now() });
                break;
            case 'Paused':
            case 'Stopped':
                // event may be called by mistake during hot reload or similar cases
                if (timeEntities.length > 0 && timeEntities[timeEntities.length - 1].endTime === undefined) {
                    timeEntities[timeEntities.length - 1].endTime = DateTime.now();
                }
                break;
        }

        onTimerUpdated(session.id!, state, JSON.stringify(timeEntities.map(e => ({ startTime: e.startTime?.toISO(), endTime: e.endTime?.toISO() }))));
    }, [timeEntities]);

    const saveTitleData = useCallback(async () => {

        if (titleData.name === session.name && titleData.color === session.color) {
            setIsTitleEditMode(false)
            return;
        }

        onSessionChanged({ ...session, ...titleData });
        setIsTitleEditMode(false)
    }, [titleData, session, setIsTitleEditMode, onSessionChanged]);

    // Track notifications
    // const [lastNotificationId, setLastNotificationId] = useState("");

    const handleTimerUpdated = useCallback((n: any) => {
        // console.log('original', n.id, n.notification.entityId);
        log(n, n.notification.entityId === session.id);

        if (n.notification.notificationName !== 'TimerUpdated' || n.notification.entityId !== session.id) {
            log("ignoring notification");
            return;
        }

        log("using notification");
        // setLastNotificationId(n.id)},[])
    }, [session.id]);

    useEffect(() => {

        abp.event.on('abp.notifications.received', handleTimerUpdated);

        return () => { abp.event.off('abp.notifications.received', handleTimerUpdated) }
    }, [handleTimerUpdated]);



    return <div className="time-tracking-session" style={{ borderColor: titleData.color ?? 'var(--primary-color)' }}>
        <div className="overview">
            <div className="timer">
                {isTitleEditMode ? <div className="title-editor">
                    <ControlWrapper sm={12}>
                        <KendoInput type="text" defaultValue={titleData.name} onChange={e => setTitleData(d => ({ ...d, name: e.target.value?.toString() }))}></KendoInput>
                    </ControlWrapper>
                    {/* <HuePicker color={titleData.color ?? '#007bff'} onChange={e => setTitleData(d => ({ ...d, color: e.hex }))}></HuePicker> */}
                    <CirclePicker color={titleData.color ?? '#007bff'} onChange={e => setTitleData(d => ({ ...d, color: e.hex }))} />
                    <IconButton name="icon-checked" title={L("Save")} onClick={saveTitleData} />
                </div> :
                    <>
                        <h3 onClick={() => setIsTitleEditMode(true)}>{session.name}</h3>
                        <TimerComponent duration={entitiesDuration} onStateChanged={onStateChanged}></TimerComponent>
                    </>
                }
            </div>
            <div className="meta">
                <NaVaForm
                    initialValues={{}}
                    onSubmit={() => { }}>
                    {/* <ModuleComponentProvider appPartName={AppParts.TimeTracking.TimeEntities.EditorFields} getSingleComponent={true} /> */}
                </NaVaForm>
            </div>
        </div>

    </div>
}

export default TimeTrackingSession;