import React, { useCallback } from 'react';
import BaseMultiSelect, { MultiSelectProps as BaseMultiSelectProps } from '../../../controls/multi-select/MultiSelect';
import useNaVaField from '../../na-va-form/NaVaField';
import { L } from '../../../../../abp/utils';

export type MultiSelectProps = Omit<BaseMultiSelectProps, 'onChange' | 'onBlur'>;

const MultiSelect: React.FC<MultiSelectProps> = React.memo(({name, disabled, ...props}) => {
  if (!name) throw new Error('name must not be undefined');

  const {
    error,
    value,
    ref,
    setValue
  } = useNaVaField<any[] | undefined>({ name });

  // Special case
  // BaseTimeInput delays triggering onChange to when it loses focus
  // so we trigger setValue with validation onChange and dont call onBlur at all
  const aggregatedOnChange = useCallback((value: any[] | undefined) => {
    setValue(value, true);
  }, [setValue]);

  return (
    <BaseMultiSelect
      name={name}
      innerRef={ref}
      onChange={e => aggregatedOnChange(e.value)}
      error={error && L(error)}
      value={value}
      disabled={disabled}
      {...props}
    />
  );
});

export default MultiSelect;
