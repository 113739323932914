export const Permissions = {
    Administration: {
        Permission: "Administration",
        TenantManagement: "Administration.UserManagement",
        UserManagement: "Administration.UserManagement",
    },
    Reporting: {
        Permission: "Reporting",
        ReadWrite: "Reporting.ReadWrite",
        ReadAll: "Reporting.ReadAll",
        WriteAll: "Reporting.WriteAll"
    }
}