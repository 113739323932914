import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { L } from '../../../../../abp/utils';
import { Category, CategoryApi, DataMappingApi, DataMappingDto, Job, JobApi, JobJournalBatchApi, JobJournalBatchDto, JobTask, JobTaskApi, Resource, ResourceApi, SettingsApi } from '../../../../../client/http';
import { Settings_ResourceId, Settings_ProviderName } from '../../../../../util/SettingNames';
import ComboBox from '../../../../../common/forms/validation/controls/combo-box/ComboBox';
import { useNaVaFormContext } from '../../../../../common/forms/validation/na-va-form/NaVaFormContext';
import useAuthConfiguration from '../../../../../common/hooks/useAuthConfiguration';
import { FIELD_CATEGORY, FIELD_CATEGORY_ID, FIELD_DESCRIPTION1, FIELD_DESCRIPTION2, FIELD_DESIGNATION, FIELD_ID, FIELD_JOB, FIELD_JOB_ID, FIELD_JOB_JOURNAL_BATCH, FIELD_JOB_TASK, FIELD_JOB_TASK_ID, FIELD_JOB_JOURNAL_BATCH_ID, FIELD_RESOURCE, FIELD_RESOURCE_ID } from '../TimeEntityInput';
import { getExtensionValue } from '../../../../../util/EntityUtils';
import { NullGuid } from '../../../../../util/Values';
import useComputation from '../../../../../common/forms/validation/na-va-form/useComputation';
import useJobMetaSelectorComputations from './JobMetaSelectorComputations';
import filter from '../../../../../util/FilterUtils';
import { divide } from 'lodash';
import { DateTime } from 'luxon';
import bcUtils from '../../../../../util/DynamicsBcUtils';
import { getIn } from '../../../../../common/forms/validation/na-va-form/commonUtils';
import Icon from '../../../../../common/components/icon/Icon';
import { log } from '../../../../../util/LoggingUtils';


const JobMetaSelector: React.FC<{ fetchFromAllProviders?: boolean, disabled?: boolean }> = ({ fetchFromAllProviders, disabled: isDisabled = false }) => {
    const authConfig = useAuthConfiguration();
    // const jobClient = useMemo(() => new JobApi(authConfig), [authConfig]);
    // const jobTaskClient = useMemo(() => new JobTaskApi(authConfig), [authConfig]);
    const categoriesClient = useMemo(() => new CategoryApi(authConfig), [authConfig]);
    const resourceClient = useMemo(() => new ResourceApi(authConfig), [authConfig]);
    const jobJournalBatchClient = useMemo(() => new JobJournalBatchApi(authConfig), [authConfig]);
    const settingsClient = useMemo(() => new SettingsApi(authConfig), [authConfig]);
    // const dataMappingClient = useMemo(() => new DataMappingApi(authConfig), [authConfig]);
    // const [jobs, setJobs] = useState<Job[]>([]);
    // const [jobTasks, setJobTasks] = useState<JobTask[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
    const [resources, setResources] = useState<Resource[]>([]);
    // const [journalBatches, setJournalBatches] = useState<JobJournalBatchDto[]>([]);
    // const [dataMappings, setDataMappings] = useState<DataMappingDto[]>([]);
    // const [userResourceId, setUserResourceId] = useState<string>("");

    const { values, setFieldValue, touched } = useNaVaFormContext();
    // const jobFilters: any[] = [filter("status").NotEqual("Completed"), filter("status").NotEqual("Archive")];
    // const jobTaskFilters: any[] = [filter("hide").Equals(false)];

    // useEffect(() => console.log("effect hit"), [jobs, jobTasks, categories, resources, journalBatches, dataMappings, userResourceId, values]);

    // Retrieve initial data
    useEffect(() => {

        //@ts-ignore
        // jobClient.apiServicesAppJobGetAllGet(undefined, JSON.stringify(jobFilters), fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName)).then(r => setJobs(r.result));
        // //@ts-ignore
        // categoriesClient.apiServicesAppCategoryGetAllGet(fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName)).then(r => setCategories(r.result.map(c => ({ ...c, extensions: JSON.parse(c.extensionData) }))));
        //@ts-ignore
        // resourceClient.apiServicesAppResourceGetAllGet(fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName)).then(r => setResources(r.result));
        //@ts-ignore
        // jobJournalBatchClient.apiServicesBcDeluxJobJournalBatchGetAllGet().then(j => setJournalBatches(j.result));
        //@ts-ignore
        // dataMappingClient.apiServicesAppDataMappingGetAllFilteredGet("ResourceMapping", abp.session.userId).then(m => setDataMappings(m.result));
    }, [/*setJobs, setCategories, setResources, setJournalBatches, jobClient, categoriesClient, resourceClient, jobJournalBatchClient, fetchFromAllProviders*/]);

    // Retrieve User ResourceId
    // useEffect(() => {
    //     const mappings = dataMappings.filter(m => m.targetServiceProvider === abp.setting.get(Settings_ProviderName));
    //     setUserResourceId((mappings && mappings.length > 0 && mappings[0].targetValue) || "");
    // }, [dataMappings, setUserResourceId]);

    // Retrieve Job Tasks
    const job = values[FIELD_JOB];

    useEffect(() => {
        if (job && job.id) {
            // if ((!jobTasks || jobTasks.length < 1 || jobTasks.find(j => j.jobId !== job.id)))
            //     //@ts-ignore
            //     jobTaskClient.apiServicesAppJobTaskGetAllGet(undefined, JSON.stringify([filter("jobId").Equals(job.id)]), JSON.stringify(jobTaskFilters)).then(r => setJobTasks(r.result.map(j => {
            //         const dueDate: string = getExtensionData(j, "toFinishUntil");
            //         j.endDate = DateTime.fromISO(dueDate);
            //         j.overdue = dueDate && !dueDate.startsWith("0001-01-01T00:00:00") && DateTime.fromISO(dueDate).diffNow('days');
            //         j.isOverdue = j.overdue && j.overdue.as('seconds') < 0;
            //         return j;
            //     })));
        } else {
            // setJobTasks([]);
            // setFieldValue(FIELD_JOB_TASK, null);
            setFieldValue(FIELD_CATEGORY, null);
        }
    }, [job, /*jobTaskClient, setJobTasks,*/ setFieldValue]);

    const resource = values[FIELD_RESOURCE];

    // Save currently used Resource
    useEffect(() => {
        if (resource && resource?.id && resource?.id !== abp.setting.get(Settings_ResourceId)) {
            settingsClient.apiServicesAppSettingsUpdateUserSettingPut(abp.session.tenantId, abp.session.userId, Settings_ResourceId, resource.id);
            log("Saved user as resource: ", resource?.username, resource?.id);
        }
    }, [resource, settingsClient]);

    const resourceId = values[FIELD_RESOURCE_ID]

    // Initially set Resource
    useEffect(() => {
        const settingsResourceId = abp.setting.get(Settings_ResourceId);
        if (resourceId === NullGuid && resources && !Object.keys(touched).find(k => k === FIELD_RESOURCE)) {
            setFieldValue(FIELD_RESOURCE_ID, settingsResourceId);
        }
    }, [resourceId, setFieldValue, resources]);

    // useJobMetaSelectorComputations(jobs, jobTasks, categories, resources, journalBatches);



    const jobTask = values[FIELD_JOB_TASK];
    // useEffect(() => {
    //     if (!jobTask) {
    //         setFilteredCategories(categories);
    //         setFieldValue(FIELD_CATEGORY, null);
    //     }


    //     const defaultWorkTypeFilter = getExtensionValue(jobTask, "defaultWorkTypeFilter");
    //     const defaultWorkType = getExtensionValue(jobTask, "defaultWorkTypeCode");

    //     if (!defaultWorkTypeFilter)
    //         setFilteredCategories(categories);
    //     else {
    //         const filteredCats = bcUtils.applyBcFilter(categories as any[], defaultWorkTypeFilter, "designation");
    //         if (filteredCats) {
    //             setFilteredCategories(filteredCats);

    //             if (filteredCats.length === 1)
    //                 setFieldValue(FIELD_CATEGORY, filteredCats[0]);
    //         } else {
    //             setFilteredCategories(categories);
    //         }
    //     }

    //     if (defaultWorkType) {
    //         const defaultCat = defaultWorkType && categories.find(c => c.designation === defaultWorkType);
    //         if (defaultCat)
    //             setFieldValue(FIELD_CATEGORY, defaultCat);
    //         else
    //             setFieldValue(FIELD_CATEGORY, null);
    //     }

    // }, [jobTask, categories, setFieldValue]);

    return (<div className="job-meta-selector">
        <h6 className="fw-bold">{L('ProjectData')}</h6>
        <div className="row job-data">
            <div className="row">
                {/* <ComboBox title={values[FIELD_JOB] && "Job"} name={FIELD_JOB} onChange={x => console.log("changed", x)} filterable={true} data={jobs} dataItemKey="id" textField="description1" itemRender={DesignationBillToNameSearchDescriptionItem}
                    placeholder={L('Job')} lg={6} filterFields={[FIELD_DESIGNATION, FIELD_DESCRIPTION1, FIELD_DESCRIPTION2]} footer={(i) => <JobDetails data={i} />}
                    content={() => <JobDetails data={values[FIELD_JOB]} />} disabled={isDisabled} /> */}

                {/* <ComboBox title={values[FIELD_JOB_TASK] && "JobTask"} name={FIELD_JOB_TASK} filterable={true} data={jobTasks} dataItemKey="id" textField="description1" placeholder={L('JobTask')}
                    lg={6} itemRender={(li, item) => JobTaskItem(li, item, { className: li.props.className + " job-task " + getExtensionData(item.dataItem, "jobTaskType") }, undefined, undefined, userResourceId)}
                    filterFields={[FIELD_DESIGNATION, FIELD_DESCRIPTION1]} footer={(i) => <JobTaskDetails data={i} userResourceId={userResourceId} />}
                    content={() => <JobTaskTooltip data={values[FIELD_JOB_TASK]} />} disabled={isDisabled || !!!values[FIELD_JOB]} /> */}

                {/* <ComboBox name={FIELD_RESOURCE} filterable={true} data={resources} dataItemKey="id" textField="designation" placeholder={L('Resource')} lg={6} itemRender={ResourceItem}
                    filterFields={[FIELD_DESIGNATION, "username"]} disabled={isDisabled} /> */}

                {/* <ComboBox name={FIELD_CATEGORY} filterable={true} data={filteredCategories} dataItemKey="id" textField="designation" placeholder={L('CategoryOrWorkType')} lg={6} itemRender={DesignationNameItem}
                    disabled={isDisabled} /> */}
                {/* <ComboBox name={FIELD_JOB_JOURNAL_BATCH} filterable={true} data={journalBatches} dataItemKey="id" textField="name" placeholder={L('JobJournalBatchName')} lg={6} itemRender={NameDescriptionItem}
                    disabled={isDisabled || values[FIELD_ID]} /> */}

                {/* {values[FIELD_JOB_JOURNAL_BATCH_ID]}<br />
                {values[FIELD_JOB_JOURNAL_BATCH]?.name + " " + values[FIELD_JOB_JOURNAL_BATCH]?.description} */}
                {/* {JSON.stringify(touched)} */}
                {/* <div className="k-list-item job-task">
                    <Icon className="is-overdue-icon" size="1rem" name="icon-appointment-reminders" />
                </div>
                {userResourceId} */}
            </div>
        </div>
    </div>);
}

export default JobMetaSelector;

// const JobDetails: React.FC<{ data: Job }> = ({ data }) => {
//     if (!data) return <></>;

//     var parsed = data;
//     parsed.extensions = JSON.parse(data.extensionData ?? '{}');
//     return <div className="job-details combo-box-footer">
//         <div className="main-data">
//             <h3>{parsed.extensions.billToName ?? ''}</h3>
//             <h1>{parsed.extensions.searchDescription ?? ''}</h1>
//             <h1>{parsed.designation}</h1>
//             <h2>{parsed.description1 ?? ''}</h2>
//             <h2>{parsed.description2 ?? ''}</h2>
//             <h2>{parsed.extensions.status ?? ''}</h2>
//             {/* <h2>{parsed.jobType ?? ''}</h2> */}
//             <h2>{parsed.extensions.salesPersonCode ?? ''}</h2>
//             <h2>{parsed.extensions.personResponsible ?? ''}</h2>
//         </div>
//     </div>
// }

// const JobTaskDetails: React.FC<{ data: JobTask, userResourceId: string }> = ({ data, userResourceId }) => {
//     if (!data) return <></>;
//     var parsed = data;
//     parsed.extensions = JSON.parse(data.extensionData ?? '{}');

//     var isAccountable = userResourceId && parsed.accountableId === userResourceId;
//     var isExecutive = userResourceId && parsed.executiveId === userResourceId;
//     return <div className="job-details combo-box-footer">
//         <div className="main-data">
//             <h1>{parsed.designation}</h1>
//             <h2>{parsed.description1 ?? ''}</h2>
//             <h2>{parsed.description2 ?? ''}</h2>
//             <h3>{L(parsed.customState ?? '')}</h3>
//             {isExecutive ? <div className="is-executive">
//                 {L('TaskAssignedToYou')}
//             </div> : isAccountable ? <div className="is-accountable">
//                 {L('TaskAccountableFor')}
//             </div>
//                 : <></>}

//         </div>
//     </div>
// }

// const JobTaskTooltip: React.FC<{ data: JobTask }> = ({ data }) => {
//     if (!data) return <></>;
//     var parsed = data;
//     parsed.extensions = JSON.parse(data.extensionData ?? '{}');

//     return <div className="job-details combo-box-footer">
//         <div className="main-data">
//             <h1>{parsed.designation}</h1>
//             <h2>{parsed.description1 ?? ''}</h2>
//             <h2>{parsed.description2 ?? ''}</h2>
//             <h3>{L(parsed.customState ?? '')}</h3>
//         </div>
//     </div>
// }

// const JobTaskItem = (li: any, itemProps: any, newProps?: any, highlightItem?: (item: any) => boolean, classSelector?: (item: any) => string, userResourceId?: string) => {

//     const isAccountable = userResourceId && itemProps.dataItem.accountableId === userResourceId;
//     const isExecutive = userResourceId && itemProps.dataItem.executiveId === userResourceId;
//     const itemChildren = (
//         <div className={"combo-box-item" +
//             (highlightItem && highlightItem(itemProps.dataItem) ? " highlighted" : "") + (classSelector ? " " + classSelector(itemProps.dataItem) : "")}>
//             <span className="designation">{itemProps.dataItem.designation}</span>
//             <span className="description">{itemProps.dataItem.description1}</span>
//             {itemProps.dataItem.isOverdue && <span className="overdue"><Icon className="is-overdue-icon" size="1rem" name="icon-appointment-reminders" /></span>}
//             {/* {dueDate && <span className="dueDate">{dueDate}</span>} */}
//         </div >
//     );
//     const className = `${li.props?.className} ${newProps?.className}` + (isAccountable ? ' is-accountable' : ' ') + (isExecutive ? ' is-executive' : ' ');
//     const title = [
//         (isExecutive ? L('TaskAssignedToYou') : isAccountable ? L('TaskAccountableFor') : ''),
//         itemProps.dataItem.isOverdue ? `${abp.utils.formatString(L('TaskIsOverdueBy_1'), -1 * Math.floor(itemProps.dataItem.overdue.as("days")))} (${itemProps.dataItem.endDate.toLocaleString(DateTime.DATE_SHORT)})` : ''
//     ].filter(x => !!x).join('. '); // remove empty elements with filter
//     return React.cloneElement(li, { ...li.props, ...newProps, className: className, title: title, onMouseOver: (e: any) => e.stopPropagation() }, itemChildren);
// };

// const DesignationBillToNameSearchDescriptionItem = (li: any, itemProps: any) => {
//     var parsed = itemProps.dataItem;
//     parsed.extensions = JSON.parse(parsed.extensionData ?? '{}');

//     const itemChildren = (
//         <div className={"combo-box-item"}>
//             <span className="designation">{parsed.designation}</span>
//             <span className="billToName">{parsed.extensions.billToName}</span>
//             <span className="searchDescription">{parsed.extensions.searchDescription}</span>
//             {/* {dueDate && <span className="dueDate">{dueDate}</span>} */}
//         </div >
//     );
//     return React.cloneElement(li, li.props, itemChildren);
// };

// const NameDescriptionItem = (li: any, itemProps: any) => {
//     const itemChildren = (
//         <div className="combo-box-item">
//             <span className="name">{itemProps.dataItem.name}</span>
//             <span className="description">{itemProps.dataItem.description}</span>
//         </div>
//     );
//     return React.cloneElement(li, li.props, itemChildren);
// };


// const DesignationNameItem = (li: any, itemProps: any) => {
//     const itemChildren = (
//         <div className="combo-box-item">
//             <span className="designation">{itemProps.dataItem.designation}</span>
//             <span className="name no-highlight">{itemProps.dataItem.name}</span>
//         </div>
//     );
//     return React.cloneElement(li, li.props, itemChildren);
// };

// const ResourceItem = (li: any, itemProps: any) => {
//     const itemChildren = (
//         <div className="combo-box-item">
//             <span className="designation">{itemProps.dataItem.designation}</span>
//             <span className="username">{itemProps.dataItem.username}</span>
//         </div>
//     );
//     return React.cloneElement(li, li.props, itemChildren);
// }
