import { map } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { getUserSessionInformations, SessionInformation } from '../../abp/getUserConfiguration';
import { useAuthenticator } from '../../authentication/authenticator/Authenticator';
import { SettingDefinition, SettingsApi } from '../../client/http/api';
import Input from '../../common/forms/validation/controls/input/Input';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import useApi from '../../common/hooks/useApi';
import useAuthConfiguration from '../../common/hooks/useAuthConfiguration';
import useMethodContext from '../../module-components/method-context/useMethodContext';
import ModuleComponentProvider from '../../module-components/module-context/ModuleComponentProvider';
import { SettingsListProps } from '../../module-components/settings/SettingsComponentProps';
import { AppParts } from '../../modules/AppParts';
import Setting, { SettingLevel } from './setting/Setting';

const settings: { name: string, level: SettingLevel, isEditable: boolean }[] = [
    // {
    //     name: 'App.TimeTracking.DefaultServiceProvider',
    //     level: 'Tenant',
    //     isEditable: false
    // },
    // {
    //     name: 'App.TimeTracking.DefaultServiceConnection',
    //     level: 'Tenant',
    //     isEditable: false
    // },
    // {
    //     name: 'App.TimeTracking.CurrentServiceConnection',
    //     level: 'Tenant',
    //     isEditable: false
    // },
    // {
    //     name: 'App.TimeTracking.RequireDescriptionForTimeEntities',
    //     level: 'Tenant',
    //     isEditable: false
    // },
]

const Settings: React.FC = () => {

    const { applyMethod } = useMethodContext();

    const [settingDefinitions, settingDefinitionApi] = useApi<SettingDefinition, SettingsApi>(c => new SettingsApi(c), c => c.apiServicesAppSettingsGetSettingDefinitionsGet());

    return (<div className="settings row my-3 px-4">
        <h3>
            Einstellungen
        </h3>

        <div className="settings-list">
            {settings.map(s => {
                return <Setting name={s.name} isEditable={s.isEditable} level={s.level} key={s.name} settingDefinition={settingDefinitions.find(d => d.name === s.name)} />
            })}
            <ModuleComponentProvider appPartName={AppParts.Settings.SettingList} getSingleComponent={false} props={{ settingDefinitions } as SettingsListProps} />
        </div>

        <h5>Es wurde keine Einstellungen hinterlegt</h5>
    </div >)
}

export default Settings;