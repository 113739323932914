import React, { useCallback, useRef } from 'react';
import { DropDownList, DropDownListProps } from '@progress/kendo-react-dropdowns';
import ControlWrapper, { ControlWrapperProps } from '../control-wrapper/ControlWrapper';

export type DropdownProps = {
  name: string;
  innerRef?: ((instance: HTMLElement | null) => void);
  onBlur?: () => void;
} & ControlWrapperProps & Pick<DropDownListProps, 'data' | 'dataItemKey' | 'textField' | 'defaultItem' | 'onChange' | 'value' | 'disabled' | 'itemRender' | 'popupSettings'>;

export const Dropdown: React.FC<DropdownProps> = React.memo(({ innerRef, onBlur, ...props }) => {
  const listenerRef = useRef<{ element: HTMLElement; listener: () => void } | null>(null);

  const actualInnerRef = useCallback((e: DropDownList | null) => {
    const element = e && e.element && e.element.querySelector<HTMLElement>('span[role=listbox].k-dropdown-wrap');
    innerRef && innerRef(element);

    const listenerInfo = listenerRef.current;
    if (listenerInfo && listenerInfo.element && listenerInfo.listener) {
      listenerInfo.element.removeEventListener('blur', listenerInfo.listener);
    }
    if (element) {
      listenerRef.current = {
        element,
        listener: () => onBlur && onBlur()
      };
    }
  }, [innerRef, onBlur]);

  return (
    <ControlWrapper
      xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl}
      offset={props.offset} offsetSm={props.offsetSm} offsetMd={props.offsetMd} offsetLg={props.offsetLg} offsetXl={props.offsetXl} offsetXxl={props.offsetXxl}
      m={props.m} mt={props.mt} mb={props.mb} ml={props.ml} mr={props.mr} mx={props.mx} my={props.my}
      error={props.error} label={props.label} className={props.className} title={props.title}
    >
      <DropDownList
        name={props.name}
        ref={actualInnerRef}
        data={props.data}
        dataItemKey={props.dataItemKey}
        textField={props.textField}
        defaultItem={props.defaultItem}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
        itemRender={props.itemRender}
        popupSettings={props.popupSettings}
      />
    </ControlWrapper>
  );
});
