import { useCallback, useRef } from "react";

const useFetchAutoAbort = () => {

  const abortController = useRef<AbortController | null>(null);

  const createAbortController = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort(); // Cancel the previous request
    }

    const controller = new AbortController();
    abortController.current = controller;
    return controller;
  }, [abortController]);

  return createAbortController;
}

export default useFetchAutoAbort;