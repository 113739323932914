import React from 'react';
import './ControlWrapper.scss';

export type BootstrapWidth = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto';
export type BootstrapSpacing = 0 | 1 | 2 | 3 | 4 | 5;

export interface ControlWrapperProps {
  omitFill?: boolean;
  disabled?: boolean;
  label?: string | React.ReactNode;
  error?: string | string[];
  xs?: BootstrapWidth;
  sm?: BootstrapWidth;
  md?: BootstrapWidth;
  lg?: BootstrapWidth;
  xl?: BootstrapWidth;
  xxl?: BootstrapWidth;
  offset?: BootstrapWidth;
  offsetXs?: BootstrapWidth;
  offsetSm?: BootstrapWidth;
  offsetMd?: BootstrapWidth;
  offsetLg?: BootstrapWidth;
  offsetXl?: BootstrapWidth;
  offsetXxl?: BootstrapWidth;
  m?: BootstrapSpacing;
  mt?: BootstrapSpacing;
  mb?: BootstrapSpacing;
  ml?: BootstrapSpacing;
  mr?: BootstrapSpacing;
  mx?: BootstrapSpacing;
  my?: BootstrapSpacing;
  className?: string;
  title?: string;
  style?: React.CSSProperties;
  suffix?: string | React.ReactNode;
}

const ControlWrapper: React.FC<React.PropsWithChildren<ControlWrapperProps>> = props => {
  const formGroupClasses: string[] = ['control-wrap'];

  props.xs && formGroupClasses.push(`col-${props.xs}`);
  props.sm && formGroupClasses.push(`col-sm-${props.sm}`);
  props.md && formGroupClasses.push(`col-md-${props.md}`);
  props.lg && formGroupClasses.push(`col-lg-${props.lg}`);
  props.xl && formGroupClasses.push(`col-xl-${props.xl}`);
  props.xxl && formGroupClasses.push(`col-xxl-${props.xxl}`);

  props.offset && formGroupClasses.push(`offset-${props.offset}`);
  props.offsetXs && formGroupClasses.push(`offset-xs-${props.offsetXs}`);
  props.offsetSm && formGroupClasses.push(`offset-sm-${props.offsetSm}`);
  props.offsetMd && formGroupClasses.push(`offset-md-${props.offsetMd}`);
  props.offsetLg && formGroupClasses.push(`offset-lg-${props.offsetLg}`);
  props.offsetXl && formGroupClasses.push(`offset-xl-${props.offsetXl}`);
  props.offsetXxl && formGroupClasses.push(`offset-xxl-${props.offsetXxl}`);

  props.m !== undefined && formGroupClasses.push(`m-${props.m}`);
  props.mt !== undefined && formGroupClasses.push(`mt-${props.mt}`);
  props.mb !== undefined && formGroupClasses.push(`mb-${props.mb}`);
  props.ml !== undefined && formGroupClasses.push(`ms-${props.ml}`);
  props.mr !== undefined && formGroupClasses.push(`me-${props.mr}`);
  props.mx !== undefined && formGroupClasses.push(`mx-${props.mx}`);
  props.my !== undefined && formGroupClasses.push(`my-${props.my}`);

  const className = [
    'inner-wrap',
    !props.omitFill ? 'fill' : null,
    props.disabled ? 'disabled' : null,
    React.Children.toArray(props.children).filter((child) =>  React.isValidElement(child) && (child as any).name === 'ShortcutKey').length > 1 ? 'multiple' : null,
    props.error ? 'error' : null,
    props.suffix ? 'has-suffix' : null
  ].filter(c => c).join(' ');
  return (
    <span className={formGroupClasses.join(' ') + ' ' + (props.className ?? '')} title={props.title} style={props.style}>
      {props.label &&
        <span className="control-wrap-caption">
          {props.label}
        </span>
      }
      <span className={className}>
        {props.children}
        {
          props.suffix && <span className="suffix">
            <span className="suffix-content">
              {props.suffix}
            </span>
          </span>
        }
      </span>
    </span>
  );
};

export default ControlWrapper;
