import React, { useCallback, useMemo, useState } from 'react';
import { JobTask, JobTaskApi, JobTaskDto, JobTypeApi, JobTypeDto } from '../../../../client/http';
import { PlmControlBaseProps, selectPlmControlSortProperty, PlmControlGroupRenderDisplay, PlmDataControlProps, isTemplateFilter, RowSkeletons } from './components/PlmControlComponents';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { FIELD_DESIGNATION, FIELD_ID, FIELD_IS_TEMPLATE, FIELD_JOB_ID, FIELD_JOB_TYPE_CODE, FIELD_JOB_TYPE_ID } from '../../../../data/field-constants/GeneralConstants';
import { NEW_ENTITY_ID, getExtensionValue, setExtensionValue } from '../../../../util/EntityUtils';
import useInternalPlmControlData from '../../hooks/useInternalPlmControlData';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../../util/SettingNames';
import { showNotification } from '../../../../common/components/notifications/NotificationHost';
import PlmJobTypeControl, { PlmJobTypeControlProps } from './PlmJobTypeControl';
import filter from '../../../../util/FilterUtils';
import useApi from '../../../../common/hooks/useApi';
import { NullGuid } from '../../../../util/Values';
import PlmJobPlanningLineList from './PlmJobPlanningLinesControl';
import PlmJobTaskTransferLineList from './PlmJobTaskTransferLineControl';
import _ from 'lodash';
import useInternalPlmListData from '../../hooks/useInternalPlmListData';
import usePlmControlContext from './plm-control-context/usePlmControlContext';
import { PlmColumnDataDisplay } from './components/PlmControlDataDisplay';
import PlmColumnHeaderDisplay from './components/PlmControlHeaderDisplay';
import useLoadingState from '../../../../common/hooks/useLoadingState';

export type PlmJobTaskListProps = {
    jobNumber: string;
    jobId: string
    readOnly?: boolean;
}

export type PlmJobTaskControlProps = PlmDataControlProps & {
    jobNumber: string;
    jobId: string
    jobTask: JobTask;
}

export const PlmJobTaskControl: React.FC<PlmJobTaskControlProps> = ({ jobTask, jobNumber, jobId, refetch }) => {

    const { controlState, showTemplates } = usePlmControlContext();

    const [internalJobTask, , handleChange, handleAction, handleSave] = useInternalPlmControlData<JobTaskDto, JobTaskApi>(
        jobTask,
        {
            [FIELD_JOB_ID]: jobId,
            [FIELD_IS_TEMPLATE]: showTemplates === true
        },
        'jobTask',
        c => new JobTaskApi(c),
        c => c.apiServicesAppJobTaskCreatePost,
        c => c.apiServicesAppJobTaskUpdatePut,
        c => c.apiServicesAppJobTaskDeleteDelete,
        refetch
    );

    const isExpanded = internalJobTask && !!controlState.jobTask.expanded?.find(x => x.key === getIn(internalJobTask, FIELD_ID));

    const [jobType, setJobType] = useState<JobTypeDto | undefined>(undefined);
    const [, jobTypeApi] = useApi<JobTypeDto, JobTypeApi>(c => new JobTypeApi(c));
    const jobTypeId = getExtensionValue(internalJobTask, FIELD_JOB_TYPE_ID);
    const [startLoading, loadingComplete, loadingError, isJobTypeLoading] = useLoadingState();

    const fetchJobType = useCallback(async (jtId?: string) => {
        jtId = jtId ?? jobTypeId;

        if (!jobTypeApi || !jtId || jtId === NEW_ENTITY_ID || jtId === NullGuid) return;

        try {
            startLoading();

            const result = await jobTypeApi.apiServicesModulesSdtPlmJobTypeGetByIdGet(jtId, abp.setting.getInt(Settings_ServiceConnectionId),
                abp.setting.get(Settings_ProviderName));

            setJobType((result as any).result);
            loadingComplete();
        } catch (error) {
            loadingError("Fehler beim Datenabruf");
        }
    }, [jobTypeApi, jobTypeId, startLoading, loadingComplete, loadingError])

    const handleJobTypeChanged = useCallback(async (action: Parameters<PlmJobTypeControlProps['onJobTypeChanged']>[0], id?: string, code?: string) => {
        try {
            if (internalJobTask) {

                let jt: JobTaskDto;

                switch (action) {
                    case 'deleted':
                        jt = { ...internalJobTask };
                        setExtensionValue(jt, FIELD_JOB_TYPE_ID, undefined);
                        setExtensionValue(jt, FIELD_JOB_TYPE_CODE, undefined);
                        setJobType(undefined);
                        break;
                    case 'created':
                    case 'updated':
                    default:
                        jt = { ...internalJobTask };
                        setExtensionValue(jt, FIELD_JOB_TYPE_ID, id);
                        setExtensionValue(jt, FIELD_JOB_TYPE_CODE, code);
                        await handleSave(jt);
                        await fetchJobType(id);
                        break;
                }

            }

        } catch (error) {
            showNotification('Zuordnen der Projektart fehlgeschlagen', 'Fehler beim Speichern aufgetreten', 'error', undefined, true);
            return false;
        }
    }, [internalJobTask, handleSave, fetchJobType]);

    const localDispatch = useCallback((args: Parameters<PlmControlBaseProps['dispatch']>[0]) => {
        // fetch job type on expand
        if (args.type === 'expanded/changed' && args.payload.value === true) {
            fetchJobType();
        }
    }, [fetchJobType]);

    return <>
        <PlmColumnDataDisplay item={internalJobTask} section="jobTask" level={2} className="plm-job-task-item" expandable
            onLocalDispatch={localDispatch} onChange={handleChange} onAction={handleAction} />

        {isExpanded && <>
            <div className="job-tasks-meta-container item-row level-3">
                <PlmJobTypeControl jobId={jobId} jobType={jobType} isLoading={isJobTypeLoading} onJobTypeChanged={handleJobTypeChanged} />
            </div>
            <PlmControlGroupRenderDisplay item={internalJobTask} section="jobTaskTransfer" level={2} className="plm-job-task-transfer-item"
                wrapperClassName="row__jtTransfer level-3" onChange={handleChange} onAction={handleAction} expandable />
            <PlmJobTaskTransferLineList jobId={jobId} jobTaskId={getIn(internalJobTask, FIELD_ID)} jobNumber={jobNumber} jobTaskNumber={getIn(internalJobTask, FIELD_DESIGNATION)} />
            <PlmJobPlanningLineList jobId={jobId} jobTaskId={getIn(internalJobTask, FIELD_ID)} />
            <hr className="item-row level-2 my-0 border-dark"></hr>
        </>}
    </>
}

const PlmJobTaskList: React.FC<PlmJobTaskListProps> = ({ jobNumber, jobId }) => {

    const { controlState, fetchFromAllProviders, readOnly, showTemplates, dispatch } = usePlmControlContext();

    const internalJobTaskControlStateFilters = controlState?.jobTask?.filter?.internalFilters;
    const internalJobTaskFilters = useMemo(() => [
        ...internalJobTaskControlStateFilters,
        ...isTemplateFilter(showTemplates),
        filter(FIELD_JOB_ID).equals(jobId)
    ], [internalJobTaskControlStateFilters, showTemplates, jobId]);

    const externalJobTaskFilters = controlState?.jobTask?.filter?.externalFilters;

    const getAll = useCallback((c: JobTaskApi) => {
        return c.apiServicesAppJobTaskGetAllGet(
            fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
            fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId),
            JSON.stringify(internalJobTaskFilters),
            JSON.stringify(externalJobTaskFilters)
        );
    }, [fetchFromAllProviders, internalJobTaskFilters, externalJobTaskFilters]);

    const [jobTasks, fetchJobTasks, addRow, isLoading] = useInternalPlmListData<JobTaskDto, JobTaskApi>(
        c => new JobTaskApi(c),
        getAll,
        'jobTask'
    );

    const childDispatch = useCallback((args: Parameters<PlmControlBaseProps['dispatch']>[0]) => {
        args.payload?.parents?.push({ section: 'job', key: jobId });

        dispatch(args);
    }, [dispatch, jobId]);

    return (<>

        <PlmColumnHeaderDisplay section="jobTask" level={2} onAction={addRow} readOnly={readOnly || jobId === NEW_ENTITY_ID} />

        {_.sortBy(jobTasks ?? [], (j) => selectPlmControlSortProperty(j))?.map(jt => {
            return (
                <PlmJobTaskControl jobTask={jt} jobNumber={jobNumber} jobId={jobId} refetch={fetchJobTasks} key={getIn(jt, FIELD_ID)} />
            )
        })}

        {isLoading && <RowSkeletons level={3} />}
    </>)
}

export default PlmJobTaskList;