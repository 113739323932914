import { DateTime } from 'luxon';
import React from 'react';
import constants from '../../../abp/constants';
import buildData from '../../../data/build.json';
import './AppVersion.scss';

const AppVersion: React.FC<{ className?: string }> = ({ className }) => {
    const build = buildData.buildId.replace('#', 'v.')
    return <div className={"app-version" + (className ? ' ' + className : '')}>
        {constants.appTitle}<span>&ensp;</span>
        {buildData && <>
            {build.startsWith('v.') ? build : 'v. ' + build}
            <br />
            Build {DateTime.fromISO(buildData.buildTime).toLocaleString(DateTime.DATE_MED)}
            <span>&ensp;</span>
            #{buildData.sourceVersion.substring(0, 8)}
        </>}
        {/* &copy; 2018-{new Date().getFullYear()} Orgavision S.à r.l. */}
    </div>
};

export default AppVersion;