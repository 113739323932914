interface Time {
  hour: number;
  minute: number;
  toString(): string;
  equals(comparand: Time): boolean;
}

function Time(hour: number, minute: number): Time {
  if (hour < 0 || hour > 24) throw new Error(`Hour must be a numer between 0 and 24, but got ${hour}`);
  if (minute < 0 || hour > 59) throw new Error(`Minute must be a numer between 0 and 59, but got ${minute}`);
  return {
    hour,
    minute,
    toString() {
      const hourStr = hour.toString(10).padStart(2, '0');
      const minuteStr = minute.toString(10).padStart(2, '0');
      return `${hourStr}:${minuteStr}`;
    },
    equals(comparand: Time) {
      return this.hour === (comparand?.hour ?? 0) && this.minute === (comparand?.minute ?? 0);
    }
  };
}

export default Time;
