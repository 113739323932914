import React, { useCallback } from 'react';
import { L } from '../../../abp/utils';
import './TimeTrackingSessions.scss';
import TimeTrackingSession from '../../../common/components/time-tracking-session/TimeTrackingSession';
import useApi from '../../../common/hooks/useApi';
import { TimeTrackingSessionDto, TimeTrackingSessionsApi } from '../../../client/http';
import DataViewMessage from '../../../common/components/data-view-message/DataViewMessage';


const TimeTrackingSessions: React.FC = () => {

    const [timeTrackingSessions, timeTrackingSessionsApi, dataViewMessageData, refetch] = useApi<TimeTrackingSessionDto, TimeTrackingSessionsApi>(c => new TimeTrackingSessionsApi(c), c => c.apiServicesAppTimeTrackingSessionsGetAllGet());

    const onSessionChanged = useCallback((s: TimeTrackingSessionDto) => {
        timeTrackingSessionsApi?.apiServicesAppTimeTrackingSessionsUpdatePut(s).then(() => refetch(true));
    }, [timeTrackingSessionsApi, refetch]);

    const onTimerUpdated = useCallback((sessionId: string, state: string, history: string) => {
        timeTrackingSessionsApi?.apiServicesAppTimeTrackingSessionsTrackTimerPost(sessionId, state, history);
    }, [timeTrackingSessionsApi]);

    return (<div className="time-tracking-sessions">
        <h2>{L('ManageTimeTrackingSessions')}</h2>

        <div className="sessions-overview">
            {dataViewMessageData ? <DataViewMessage {...dataViewMessageData}></DataViewMessage> :
                timeTrackingSessions?.length > 0 && timeTrackingSessions.map((s, i) => {
                    return (<TimeTrackingSession session={s} key={i} onSessionChanged={onSessionChanged} onTimerUpdated={onTimerUpdated} />);
                })}
        </div>
    </div>)
};

export default TimeTrackingSessions;