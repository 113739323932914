import React from 'react';
import { L } from '../../../../abp/utils';
import { JobTask } from '../../../../client/http';

const JobTaskTooltip: React.FC<{ data: JobTask }> = ({ data }) => {
    if (!data) return <></>;
    var parsed = data;
    parsed.extensions = JSON.parse(data.extensionData ?? '{}');

    return <div className="job-details combo-box-footer">
        <div className="main-data">
            <h1>{parsed.designation}</h1>
            <h2>{parsed.description1 ?? ''}</h2>
            <h2>{parsed.description2 ?? ''}</h2>
            <h3>{L(parsed.customState ?? '')}</h3>
        </div>
    </div>
}

export default JobTaskTooltip;