import { ReactNode, useCallback } from "react";
import { ActionColumn, ActionColumnActionType, Column, PlmControlSection, SelectionColumn } from "./PlmControlComponents";
import usePlmControlContext from "../plm-control-context/usePlmControlContext";
import classNames from "classnames";
import { PlmControlLevel } from "./PlmControlDataDisplay";
import Button from "../../../../../common/forms/controls/button/Button";
import Icon from "../../../../../common/components/icon/Icon";
import { L } from "../../../../../abp/utils";

export type PlmColumnHeaderDisplayProps = {
    section: PlmControlSection;
    level: PlmControlLevel;
    headersLevel?: PlmControlLevel;
    readOnly?: boolean;
    onAction?: (action: ActionColumnActionType, section: PlmControlSection) => void;
    hasNoData?: boolean;
    noDataLabel?: ReactNode;
}

export const PlmColumnHeaderDisplay: React.FC<PlmColumnHeaderDisplayProps> = ({ section, level, headersLevel = level + 1, readOnly, hasNoData, noDataLabel, onAction }) => {
    const { columnConfig, selectable } = usePlmControlContext();

    const handleAction = useCallback((action: ActionColumnActionType) => {
        if (readOnly) return;

        // Only allow addRow action
        if (action === 'addRow') {
            onAction && onAction(action, section);
        }
    }, [section, readOnly, onAction]);


    if (hasNoData) {
        return (<div className={classNames('item-row headers headers__empty my-2 d-flex align-items-center', `level-${level}`)}>
            <span className="me-3">
                {noDataLabel ?? L('NoDataPresent')}
            </span>
            {!readOnly &&
                <Button color="light" label="" onClick={() => handleAction('addRow')}>
                    <Icon name="icon-add" size="1.5rem" />
                    {L('Create')}
                </Button>
            }
        </div>)
    }

    return (<div className={classNames('item-row headers has-action', `level-${level}`)}>
        {selectable && <SelectionColumn isHeader />}
        {columnConfig[section].map(c => {
            return (<Column $columnCount={columnConfig[section].length} $level={headersLevel} key={c?.name} title={c?.displayName}>
                {c?.displayName}
            </Column>)
        })}
        <ActionColumn readOnly={readOnly} isHeader onAction={handleAction} />
    </div>)
}

export default PlmColumnHeaderDisplay;