import React from 'react';
import ControlWrapper, { ControlWrapperProps } from '../../../common/forms/controls/control-wrapper/ControlWrapper';
import './DashboardCard.scss';

export type DashboardCardProps = React.PropsWithChildren<{
    title?: React.ReactElement | string;
    subtitle?: React.ReactElement | string;
}> & ControlWrapperProps

const DashboardCard: React.FC<DashboardCardProps> = (props) => {
    const {title, subtitle, children, ...controlWrapperProps} = props;
    return (<ControlWrapper {...controlWrapperProps} style={{ margin: '1rem' }}>
        <div className="dashboard-card">
            <div className="body">
                {title && <h5 className='title'>{title}</h5>}
                {subtitle && <h6 className="subtitle">{subtitle}</h6>}
                {children}
            </div>
        </div>
    </ControlWrapper>)
}

export default DashboardCard;