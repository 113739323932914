import React, { useCallback, useEffect, useState } from 'react';
import constants from '../../abp/constants';
import { getUserSessionInformations, SessionInformation } from '../../abp/getUserConfiguration';
import { useAuthenticator } from '../../authentication/authenticator/Authenticator';
import { ServiceFunctionProviderDto, ServiceProviderDto } from '../../client/http';
import GroupPredictionsOverview from '../../common/components/predictions/GroupPredictionsOverview';
import useAuthConfiguration from '../../common/hooks/useAuthConfiguration';
import TimeEntityInput from '../time-tracking/time-entities/time-entity-input/TimeEntityInput';
import DashboardCard from './dashboard-card/DashboardCard';
import './Dashboard.scss';
import WorkPerTime from './charts/WorkPerTime';
import { L } from '../../abp/utils';
import ModuleComponentProvider from '../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../modules/AppParts';

const Dashboard: React.FC = () => {

    const [providers, setProviders] = useState<ServiceProviderDto[]>([]);
    const config = useAuthConfiguration();

    const { user } = useAuthenticator();
    const [sessionInfo, setSessionInfo] = useState<SessionInformation>();

    useEffect(() => {
        user && void getUserSessionInformations(user.tokenInfo.token).then(result => setSessionInfo(result.data));
    }, [user])

    const getGreeting = useCallback((): string => {
        const date = new Date();
        const hours = date.getHours();
        if (hours >= 4 && hours < 11) {
            return "Guten Morgen"
        } else if (hours >= 11 && hours < 18) {
            return "Guten Tag"
        } else {
            return "Guten Abend"
        }
    }, []);

    const getCallToAction = useCallback((): string => {
        const rand = Math.floor(Math.random() * 10);

        const date = new Date();
        const hours = date.getHours();
        if (hours >= 4 && hours < 11) {
            switch (rand) {
                case 0:
                case 1:
                default:
                    return 'Kommen Sie gut in den Tag!';
                case 2:
                case 3:
                    return "Schön, dass Sie wieder da sind!"
                case 4:
                    return "Schön, dass Sie hier sind!"
                case 5:
                    return 'Womit möchten Sie den Tag beginnen?'
                case 6:
                case 7:
                    return 'Willkommen zurück!'
                case 8:
                case 9:
                case 10:
                    return 'Was möchten Sie als Nächstes tun?'
            }
        } else if (hours >= 11 && hours < 18) {
            switch (rand) {
                case 0:
                case 1:
                default:
                    return 'Viel Erfolg heute!';
                case 2:
                case 3:
                    return "Schön, dass Sie wieder da sind!"
                case 4:
                    return "Schön, dass Sie hier sind!"
                case 5:
                    return 'Wie möchten Sie fortfahren?'
                case 6:
                case 7:
                    return 'Willkommen zurück!'
                case 8:
                case 9:
                case 10:
                    return 'Was möchten Sie als Nächstes tun?'
            }
        } else {
            switch (rand) {
                case 0:
                case 1:
                default:
                    return 'Kommen Sie gut durch den Abend!';
                case 2:
                case 3:
                    return "Schön, dass Sie wieder da sind!"
                case 4:
                    return "Schön, dass Sie hier sind!"
                case 5:
                    return 'Wie möchten Sie fortfahren?'
                case 6:
                case 7:
                    return 'Willkommen zurück!'
                case 8:
                case 9:
                case 10:
                    return 'Was möchten Sie als Nächstes tun?'
            }
        }

    }, []);

    const [greeting] = useState(getGreeting());
    const [callToAction] = useState(getCallToAction());

    // useEffect(() => {

    //     //@ts-ignore
    //     new ServiceProvidersApi(config, config?.basePath).apiServicesAppServiceProvidersGetServiceProvidersGet().then(d => setProviders(d.result));
    // }, [setProviders])   

    return (<div className="app-dashboard p-3">


        <h1 style={{ fontWeight: 600 }}>{greeting} {sessionInfo?.result?.user ? <span>{sessionInfo.result.user.name} {sessionInfo.result.user.surname}</span> : /*user?.name*/ ""}.<br />
            <div className="call-to-action fw-light mt-3">{`${callToAction}`}</div></h1>

        <div className="row p-3 cards-list">

            {constants.customer === "ORV" &&
                <>
                    <DashboardCard sm={12} title={L('Chart_WorkPerTime')}>                        
                        <WorkPerTime/>
                    </DashboardCard>

                    <ModuleComponentProvider appPartName={AppParts.Dashboard.Cards} getSingleComponent={false} />

                    <DashboardCard sm={6} title="Neue Zeiterfassung">
                        {/* <TimeTrackingSession /> */}
                        <TimeEntityInput isPlanningEntity={false} />
                    </DashboardCard>

                    <DashboardCard sm={6} title="Neues Ereignis planen">
                        <TimeEntityInput />
                    </DashboardCard>

                    <DashboardCard sm={12} title="Empfehlungen für Sie">
                        <GroupPredictionsOverview />
                    </DashboardCard>
                </>
            }

            {/* <Icon name="icon-agreement" size="3rem"/> */}

            {providers && providers.map((p, i) => (<div className="row" key={p.providerName}><h3>{p.providerName}</h3>
                {["jobProvider", "jobTaskProvider", "categoryProvider", "resourceProvider", "timeEntityProvider"].map((x, j) => {
                    //@ts-ignore
                    const provider = (p[x] as ServiceFunctionProviderDto);
                    return (
                        <div style={{ paddingLeft: "2rem" }} key={i + j}>
                            <h4>{x}</h4>
                            <div style={{ paddingLeft: "2rem" }}>
                                <span>Lesen {provider.isReadSupported ? "✔" : "❌"}</span>
                                <span>Schreiben {provider.isWriteSupported ? "✔" : "❌"}</span>
                                <span>Löschen {provider.isDeleteSupported ? "✔" : "❌"}</span>
                            </div>
                        </div>
                    )
                })}
            </div>))}
        </div>
    </div>);
}
export default Dashboard;