import Time from './Time';
// Pattern describing valid inputs for the TimeInput
// Periods may be omitted
// Examples:
// 15 => 15:00
// 1249 => 12:49
// 23:10 => 23:10
const timeFormulaPattern = /^(\d{2}):?(\d{2})?$/;

function parseTimeFormula(timeStr: string): Time | null {

  let match: RegExpMatchArray | null;
  if ((match = timeFormulaPattern.exec(timeStr)) && match.length > 1) {
    let [hour, minute] = match.slice(1, 3).map(i => parseInt(i, 10));
    if (!hour) hour = 0;
    if (!minute) minute = 0;
    try {
      return Time(hour, minute);
    } catch {
      return null;
    }
  }
  return null;
}

export default parseTimeFormula;
