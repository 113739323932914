import React from 'react';
import MethodContext from './MethodContext';
import MethodContextProvider from './MethodContextProvider';

const withMethodContext = (Component: any) => ({ ...props }) => (
    <MethodContextProvider>
        <Component {...props} />    
    </MethodContextProvider>
)

export default withMethodContext;