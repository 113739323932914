import { AxiosResponse } from 'axios';
import { AuthInfo } from '../authentication/authenticator/Authenticator';
import { UserInfo } from '../authentication/authenticator/types';
import { httpClient } from './httpClient';

export const getUserConfiguration = (): Promise<AxiosResponse<any>> => httpClient.get('/AbpUserConfiguration/GetAll');
export const getTenantUserConfiguration = (tenantId: number): Promise<AxiosResponse<any>> => {
    return httpClient.get('/AbpUserConfiguration/GetAll', {
        headers:{
            "Abp.TenantId": tenantId
        }
    });
}
export const getAuthorizedUserConfiguration = (authToken: string): Promise<AxiosResponse<any>> => {    
    return httpClient.get('/AbpUserConfiguration/GetAll', {
        headers: {
            "authorization": `bearer ${authToken}`
        }
    });
}

export const getUserProfilePicture = (authToken: string): Promise<AxiosResponse<any>> => httpClient.get('/api/services/app/Profile/GetProfilePicture', {
    headers: {
        "authorization": `bearer ${authToken}`
    }
})

export type SessionInformation = {

    "result": {

        "user": {
            "name": string,
            "surname": string,
            "userName": string,
            "emailAddress": string,
            "profilePictureId": string,
            "id": number
        },
        "tenant": {
            "tenancyName": string,
            "name": string,
            "logoId": string,
            "logoFileType": string,
            "customCssId": string,
            "subscriptionEndDateUtc": Date,
            "isInTrialPeriod": boolean,
            "subscriptionPaymentType": number,
            "edition": {
                "displayName": string,
                "trialDayCount": number,
                "monthlyPrice": number,
                "annualPrice": number,
                "isHighestEdition": boolean,
                "isFree": boolean,
                "id": number
            },
            "creationTime": Date,
            "paymentPeriodType": number,
            "subscriptionDateString": string,
            "creationTimeString": string,
            "id": number
        },
        "application": {
            "version": string,
            "releaseDate": Date,
            "currency": string,
            "currencySign": string,
            "allowTenantsToChangeEmailSettings": boolean,
            "features": {
                "additionalProp1": boolean,
                "additionalProp2": boolean,
                "additionalProp3": boolean
            }
        },
        "theme": {
            "baseSettings": {
                "theme": string,
                "layout": {
                    "layoutType": string
                },
                "header": {
                    "desktopFixedHeader": boolean,
                    "mobileFixedHeader": boolean,
                    "headerSkin": string,
                    "minimizeDesktopHeaderType": string,
                    "headerMenuArrows": boolean
                },
                "subHeader": {
                    "fixedSubHeader": boolean,
                    "subheaderStyle": string
                },
                "menu": {
                    "position": string,
                    "asideSkin": string,
                    "fixedAside": boolean,
                    "allowAsideMinimizing": boolean,
                    "defaultMinimizedAside": boolean,
                    "submenuToggle": string
                },
                "footer": {
                    "fixedFooter": boolean
                }
            },
            "isLeftMenuUsed": boolean,
            "isTopMenuUsed": boolean,
            "isTabMenuUsed": boolean,
            "allowMenuScroll": boolean
        }
    }
}

export const getUserSessionInformations = (authToken: string): Promise<AxiosResponse<SessionInformation>> => httpClient.get('/api/services/app/Session/GetCurrentLoginInformations', {
    headers: {
        "authorization": `bearer ${authToken}`
    }
})

// NOTE: Some stuff from EISA, left disabled for now

// export const getUserSessionTimeout = (): number => abp.setting.getInt('Eisa.Session.Timeout'); /*1000 * 60 * 1.2*/
// export const getUserAutoSaveTimeout = (): number => abp.setting.getInt('Eisa.AutoSave.Timeout'); /*1000 * 30*/

// export const getUserSessionProperties = (user: AuthInfo): any => {
//     // Retrieve mappings from config
//     const properties = abp.custom.SessionProperties;
//     if (!properties || properties.length < 1) return [];

//     // Retrieve application parts from config (e.g. 'Mission' or 'Export')
//     const parts = Object.keys(properties[0]);
//     // Retrieve local values which override the claim values
//     const storageKey = 'sessionProperties';
//     const localValues = JSON.parse(!!localStorage.getItem(storageKey) ? localStorage.getItem(storageKey)! : '{}');
//     // Retrieve a list of properties which exist either as claim or locally
//     const claims = Object.keys({ ...user.tokenInfo.payload ?? {} as {}, ...localValues }).filter(x => properties.map(p => p.sessionProperty).includes(x));

//     // Reduce to the final from of {"part": {"mapped_property": "property_value_with_precedence"}}
//     return parts.reduce<{}>((parts, currPart) => {
//         (parts as any)[currPart] = properties.filter(prop => !!(prop as any)[currPart as any] && claims.includes(prop.sessionProperty)).reduce((prev, curr) => {
//             (prev as any)[(curr as any)[currPart]] = (localValues)[curr.sessionProperty] ?? (user.tokenInfo.payload as any)[curr.sessionProperty];
//             return prev;
//         }, {})
//         return parts;
//     }, {});
// }