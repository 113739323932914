import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { L } from '../../../../../abp/utils';
import DatePicker from '../../../../../common/forms/validation/controls/date-picker/DatePicker';
import TimeInput from '../../../../../common/forms/validation/controls/time-input/TimeInput';
import Input from '../../../../../common/forms/validation/controls/input/Input';
import Button from '../../../../../common/forms/controls/button/Button';
import { useNaVaFormContext } from '../../../../../common/forms/validation/na-va-form/NaVaFormContext';
import Switch from '../../../../../common/forms/validation/controls/switch/Switch';
import { FIELD_AMOUNT, FIELD_CATEGORY, FIELD_CATEGORY_ID, FIELD_DATE_FROM, FIELD_DATE_TO, FIELD_DESCRIPTION1, FIELD_DESCRIPTION2, FIELD_DESIGNATION, FIELD_ID, FIELD_IS_BONUS, FIELD_IS_INVOICED, FIELD_JOB, FIELD_JOB_ID, FIELD_JOB_JOURNAL_BATCH, FIELD_JOB_TASK, FIELD_JOB_TASK_ID, FIELD_JOB_JOURNAL_BATCH_ID, FIELD_PLANNING_DATE, FIELD_RESOURCE, FIELD_RESOURCES, FIELD_RESOURCE_ID, TimeEntityInputProps, SaveResult, FIELD_UNIT_PRICE } from '../TimeEntityInput';
import useTimeEntityInputInnerComputations from './TimeEntityInputInnerComputations';
import JobMetaSelector from './JobMetaSelector';
import Icon from '../../../../../common/components/icon/Icon';
import './Footers.scss';
import { log } from '../../../../../util/LoggingUtils';

type EditorWrapperType = React.PropsWithChildren<Pick<TimeEntityInputProps, 'isEditor' | 'onReset' | 'onSubmit' | 'onDelete' | 'onCreateNewFromSelected' | 'selectedEntity'>>;

const EditorWrapper: React.FC<EditorWrapperType> =
    ({ isEditor = false, selectedEntity, onReset, onSubmit, onDelete, onCreateNewFromSelected, children }) => {
        const { isValid, setFieldValue, setFieldTouched, values, touched } = useNaVaFormContext();
        const isNewItem = selectedEntity && !selectedEntity.id;

        return isEditor ? (<div className="row editor-container">
            <div className="left col">
                {children}
            </div>
            <div className="col time-entity-actions">
                {/* <Button label="" onClick={() => { onReset && onReset() }} type="reset" disabled={!!!selectedEntity && Object.keys(touched).length === 0} color="light"><Icon name='icon-clock-add' size='2.5rem' /> <br></br>Neue Arbeitseinheit</Button>
                <Button label="" onClick={() => { onDelete && onDelete() }} color="danger" disabled={!!!selectedEntity}><Icon name='icon-remove-clock' size='2.5rem' /> Arbeitseinheit löschen</Button>
            <Button label="" onClick={() => { onSubmit && onSubmit(values) }} type="button" color="primary" disabled={!isValid}><Icon name='icon-clock-checked' size='2.5rem' /> Arbeitseinheit speichern</Button> */}
                <div className="top">
                    <Button label="" onClick={() => { onReset && onReset() }} type="reset" disabled={(isNewItem && Object.keys(touched).length === 0) && (selectedEntity && !isNewItem)} color="light"><Icon name="icon-cancel" size="1.5rem" />{isNewItem ? "Zurücksetzen" : "Abbrechen"}</Button>
                    <Button label="" onClick={() => { onDelete && onDelete() }} color="danger" disabled={!selectedEntity || isNewItem}><Icon name="icon-remove-clock" size="1.5rem" color="white" />Löschen</Button>
                    <Button label="" onClick={() => { onCreateNewFromSelected && onCreateNewFromSelected() }} type="button" disabled={!selectedEntity || isNewItem} color="light"><Icon name="icon-clock-arrow" size="1.5rem" />Zu neuem Elem. umwandeln</Button>
                </div>

                <div className="bottom">
                    <Button label="" onClick={() => { onSubmit && onSubmit(values) }} type="button" color="primary" disabled={!isValid}><Icon name="icon-clock-checked" size="1.5rem" color="white" />Speichern</Button>
                </div>
            </div>
        </div>) : <>{children}</>
    }

export type TimeEntityInputInnerProps = Omit<TimeEntityInputProps, 'initialValue' | 'onSubmit'> & { lastSaveResult?: SaveResult, isSaving?: boolean, initial?: any }

const TimeEntityInputInner: React.FC<TimeEntityInputInnerProps> =
    ({ isPlanningEntity = true, isEditor = false, isHostedInForm = false, selectedEntity, fetchFromAllProviders, onReset, onDelete, onCreateNewFromSelected, lastSaveResult, isSaving, initial }) => {

        const [isExpanded, setIsExpanded] = useState(isEditor);
        const { values, touched, setValues, submitForm } = useNaVaFormContext();

        const startSession = useCallback(() => {

        }, []);

        useEffect(() => {
            // If required, check here if touched before overriding initial values              
            setValues(initial, true);
        }, [initial, setValues]);

        useTimeEntityInputInnerComputations();
        log("render");

        const isDisabled = selectedEntity && !selectedEntity.isEditable;

        return (
            <EditorWrapper isEditor={isEditor} selectedEntity={selectedEntity} onReset={onReset} onSubmit={() => submitForm()} onDelete={onDelete} onCreateNewFromSelected={onCreateNewFromSelected}>

                <div className="container inner">
                    <div className="row dates">
                        <DatePicker name={FIELD_PLANNING_DATE} label="" sm={12} lg={5} xxl={3} disabled={isDisabled}></DatePicker>
                        <div className="col-12 col-lg-7 col-xxl-9 time-part">
                            {(!isPlanningEntity || isEditor) &&
                                <>
                                    <Input name={FIELD_AMOUNT} type="number" placeholder="Menge" disabled={isDisabled} className="amount-input" sm={6} lg={3} />
                                    <span className="caption">{L('or')}</span>
                                    {!isEditor && <Button type="button" onClick={startSession} label={L('StartWork')} color="secondary" className="action-btn" disabled={isDisabled}/>}
                                </>}
                            {(isPlanningEntity || isEditor) && <>
                                <span className="caption">{L('from')}</span>
                                <TimeInput name={FIELD_DATE_FROM} label="" disabled={isDisabled} className="time-input" />
                                <span className="caption">{L('to')}</span>
                                <TimeInput name={FIELD_DATE_TO} label="" disabled={isDisabled} className="time-input" />
                            </>}
                        </div>
                        {isEditor && <div className="col-12 col-lg-7 col-xxl-9 time-part">
                            {/* <Input name={FIELD_AMOUNT} type="number" placeholder="Menge" disabled={false} className="amount-input" sm={6} lg={3} />
                        <span>{L('or')}</span>
                        <Button type="button" onClick={startSession} label={L('StartWork')} color="secondary" className="action-btn" /> */}
                        </div>}
                    </div>
                    <div className="row description">
                        <Input name={FIELD_DESCRIPTION1} placeholder={L('Description')} sm={12} lg={6} disabled={isDisabled}></Input>
                        <Input name={FIELD_DESCRIPTION2} placeholder={L('Description2')} sm={12} lg={6} disabled={isDisabled}></Input>
                    </div>
                    {/* <div className="row resource">
                    <MultiSelect name={FIELD_RESOURCES} placeholder={L('InvitePersons')} sm={12} data={PERSONS}></MultiSelect>
                </div> */}
                    {isExpanded && (
                        <>
                            <div className="row job">
                                <JobMetaSelector fetchFromAllProviders={fetchFromAllProviders} disabled={isDisabled}></JobMetaSelector>
                            </div>
                            {/* TODO: ZUum testen */}
                            <div className="save-state">
                                {lastSaveResult && <span className={`text-${lastSaveResult.successful ? 'success' : 'danger'}`}>{lastSaveResult.successful ? "Speichern erfolgreich" : lastSaveResult.errorMessage}</span>}
                            </div>
                        </>
                    )}
                    {isEditor && (
                        <div className="row meta">
                            <div className="col-12 d-flex">
                                <Switch name={FIELD_IS_INVOICED} label={L('IsInvoiced')} disabled={isDisabled}></Switch>
                                <Switch name={FIELD_IS_BONUS} label={L('IsBonus')} disabled={isDisabled}></Switch>
                                <Input name={FIELD_UNIT_PRICE} label={L('UnitPrice')} type="number" disabled={isDisabled}></Input>
                                {values[FIELD_JOB_TASK] && values[FIELD_JOB_TASK].isOverdue && <div className="rounded-pill bg-danger text-light p-2 px-3 ">
                                    <Icon name="icon-appointment-reminders" className="me-2" size="1.5rem" color="var(--bs-light)"></Icon>
                                    Die ausgewählte Aufgabe darf nicht bereits überfällig sein.
                                </div>}
                            </div>
                        </div>
                    )}

                    {!isEditor &&
                        <div className="row action mt-4">
                            <div className="left">
                                <Button type='reset' onClick={() => { !isEditor && setIsExpanded(false); onReset && onReset() }} label={L('Cancel')} color={'light'} disabled={isDisabled} />
                            </div>
                            <div className="right">
                                {!isExpanded && <Button onClick={() => setIsExpanded(true)} label={L('Continue')} color='secondary' disabled={isDisabled}/>}
                                <Button type='submit' label={L('Create')} color={'primary'} disabled={isDisabled}/>
                            </div>
                        </div>
                    }
                </div>
            </EditorWrapper>
        );
    }

export default TimeEntityInputInner;