import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getUserSessionInformations as getUserSessionInformation, SessionInformation } from '../../../../abp/getUserConfiguration';
import { L } from '../../../../abp/utils';
import { useAuthenticator } from '../../../../authentication/authenticator/Authenticator';
import { JobJournalBatchApi, JobJournalBatchDto, Resource, TimeEntity } from '../../../../client/http';
import ItemRenderComponent from '../../../../common/components/item-render/ItemRenderComponent';
import FormValidationConfiguration from '../../../../common/forms/validation/configuration/functional/FormValidationConfiguration';
import Outlet, { oItem, OutletHostProps } from '../../../../common/components/outlet/Outlet';
import ComboBox from '../../../../common/forms/validation/controls/combo-box/ComboBox';
import Input from '../../../../common/forms/validation/controls/input/Input';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { useNaVaFormContext } from '../../../../common/forms/validation/na-va-form/NaVaFormContext';
import { NaVaFormValues } from '../../../../common/forms/validation/na-va-form/types';
import useApi from '../../../../common/hooks/useApi';
import useSettings from '../../../../common/hooks/useSettings';
import { FIELD_AMOUNT_UNIT, FIELD_CATEGORY, FIELD_CATEGORY_ID, FIELD_DESCRIPTION1, FIELD_DIRECT_COST, FIELD_ETAG, FIELD_EXTENSIONS, FIELD_ID, FIELD_JOB, FIELD_JOB_ID, FIELD_JOB_TASK, FIELD_JOB_TASK_ID, FIELD_RESOURCE, FIELD_RESOURCE_ID, FIELD_UNIT_PRICE } from '../../../../data/field-constants/GeneralConstants';
import useMethodContext from '../../../../module-components/method-context/useMethodContext';
import ModuleComponentProvider from '../../../../module-components/module-context/ModuleComponentProvider';
import ModuleFileComponentProvider from '../../../../module-components/ModuleFileComponentProvider';
import { Modules } from '../../../../module-components/Modules';
import { JobSelectionProps, JobTaskSelectionProps } from '../../../../module-components/service-entities/ServiceEntityControlProps';
import { getExtensionValue } from '../../../../util/EntityUtils';
import { Settings_ProviderName, Settings_ResourceId } from '../../../../util/SettingNames';
import { NullGuid } from '../../../../util/Values';
import { AppParts } from '../../../AppParts';
import { FIELD_JOB_JOURNAL_BATCH, FIELD_JOB_JOURNAL_BATCH_ID, FIELD_LINE_TYPE, FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION, FIELD_UNIT_OF_MEASURE_CODE, FIELD_UNIT_OF_MEASURE_ID, LineTypes, LineTypesActivityConfirmation } from '../../field-constants/BcConstants';
import { Settings_LineTypeFilter } from '../../ModuleSettings';
import AppEvents from '../../../../module-components/AppEvents';
import useComputation from '../../../../common/forms/validation/na-va-form/useComputation';
import IconButton from '../../../../common/components/icon/IconButton';
import './TimeEntityEditorFields.scss';
import useKeyCombination from '../../../../common/hooks/useKeyCombination';
import useKeyDown from '../../../../common/hooks/useKeyDown';
import { log } from '../../../../util/LoggingUtils';

export const lineTypesData = () => {
    const lineTypes = Object.keys(LineTypes).map(k => ({
        id: getIn(LineTypes, k).id,
        value: getIn(LineTypes, k).value
    }));

    const lineTypeFilters = abp.setting.get(Settings_LineTypeFilter) ?? ''.split(',');

    return lineTypeFilters && lineTypeFilters.length > 0 ? lineTypes.filter(l => lineTypeFilters.includes(l.id)) : lineTypes;
};

const TimeEntityEditorFields: React.FC<{ userResourceId: string, disabled: boolean } & OutletHostProps> = (props) => {
    const { userResourceId, oItems, disabled: isDisabled } = props;

    const { values, setFieldValue, touched, initialValues, focusField } = useNaVaFormContext();
    const { addMethod } = useMethodContext();
    const [journalBatches] = useApi<JobJournalBatchDto, JobJournalBatchApi>(c => new JobJournalBatchApi(c), c => c.apiServicesBcDeluxJobJournalBatchGetAllGet(abp.setting.get(Settings_ProviderName)));
    const { updateSetting } = useSettings();

    useEffect(() => log("Initial values updated", [initialValues]), [initialValues])

    // Provide description1 from user's name
    const { user } = useAuthenticator();
    const [sessionInfo, setSessionInfo] = useState<SessionInformation | undefined>(undefined);
    useEffect(() => {
        user && getUserSessionInformation(user.tokenInfo.token).then((result: any) => setSessionInfo(result.data));
    }, [user]);

    // Override description1
    const resource = getIn(values, FIELD_RESOURCE) as Resource;
    const getDescription1 = useCallback((resource: Resource) => {
        if (resource?.firstName)
            return resource.firstName;

        return (sessionInfo?.result?.user &&
            `${sessionInfo.result.user.surname.toUpperCase()} ${sessionInfo.result.user.name}`) ?? '';
    }, [sessionInfo]);

    useEffect(() => {
        const desc1 = getDescription1(resource);
        const currentDesc1 = getIn(values, FIELD_DESCRIPTION1);
        if (!!resource && !Object.keys(touched).find(k => k === FIELD_DESCRIPTION1) && (!currentDesc1 || currentDesc1 !== desc1)) {
            setFieldValue(FIELD_DESCRIPTION1, desc1);
        }
    }, [resource, getDescription1, setFieldValue]);

    const lineTypesActivityConfirmationData = useMemo(() => Object.keys(LineTypesActivityConfirmation).map(k => ({
        id: k,
        value: LineTypesActivityConfirmation[k]
    })), []);

    useEffect(() => {
        addMethod("getInitialValues", Modules.rewindBcDelux, true, (prop: { initialValues: TimeEntity, initialValue: TimeEntity }) => {
            return {
                ...prop,
                initialValues: {
                    ...prop.initialValues,
                    [FIELD_DESCRIPTION1]: getDescription1(resource),
                    [FIELD_JOB]: null,
                    [FIELD_JOB_ID]: '',
                    // [FIELD_JOB_TASK]: null,
                    [FIELD_JOB_TASK_ID]: '',
                    [FIELD_CATEGORY]: null,
                    [FIELD_CATEGORY_ID]: '',
                    // [FIELD_RESOURCE]: null,
                    [FIELD_RESOURCE_ID]: '',
                    [FIELD_JOB_JOURNAL_BATCH_ID]: '',
                    [FIELD_LINE_TYPE]: LineTypes.Usage,
                    [FIELD_UNIT_PRICE]: 0,
                    [FIELD_DIRECT_COST]: 0,
                    [FIELD_UNIT_OF_MEASURE_ID]: '',
                    [FIELD_UNIT_OF_MEASURE_CODE]: ''
                }
            }
        });
    }, [addMethod, getDescription1, lineTypesActivityConfirmationData]);

    useEffect(() => {
        addMethod("mapTimeEntity", Modules.rewindBcDelux, true, ((entity: TimeEntity | any) => {
            if (entity) {
                entity[FIELD_ETAG] = getExtensionValue(entity, FIELD_ETAG);
                entity[FIELD_JOB_JOURNAL_BATCH_ID] = getExtensionValue(entity, FIELD_JOB_JOURNAL_BATCH_ID);
                entity[FIELD_UNIT_OF_MEASURE_ID] = getExtensionValue(entity, FIELD_UNIT_OF_MEASURE_ID);
                entity[FIELD_UNIT_OF_MEASURE_CODE] = getExtensionValue(entity, FIELD_UNIT_OF_MEASURE_CODE);
                entity[FIELD_AMOUNT_UNIT] = getExtensionValue(entity, FIELD_UNIT_OF_MEASURE_CODE);
                entity[FIELD_UNIT_PRICE] = getExtensionValue(entity, FIELD_UNIT_PRICE) ?? 0;
                entity[FIELD_DIRECT_COST] = getExtensionValue(entity, FIELD_DIRECT_COST) ?? 0;
                entity[FIELD_LINE_TYPE] = lineTypesData().find(d => d.id === getExtensionValue(entity, FIELD_LINE_TYPE));
                entity[FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION] = lineTypesActivityConfirmationData.find(d => d.id === getExtensionValue(entity, FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION))
            }

            return entity;
        }));

        addMethod("mapCreateTimeEntity", Modules.rewindBcDelux, true, (data: { value: TimeEntity, values: NaVaFormValues }) => {
            const { values } = data;
            return {
                ...data,
                value: {
                    ...data.value,
                    jobId: getIn(values, [FIELD_JOB, FIELD_ID]),
                    jobTaskId: getIn(values, [FIELD_JOB_TASK, FIELD_ID]),
                    resourceId: getIn(values, [FIELD_RESOURCE, FIELD_ID]),
                    categoryId: getIn(values, [FIELD_CATEGORY, FIELD_ID]),
                    extensions: {
                        ...data.value.extensions,
                        [FIELD_ETAG]: getIn(values, FIELD_ETAG),
                        [FIELD_JOB_JOURNAL_BATCH_ID]: getIn(values, [FIELD_JOB_JOURNAL_BATCH, FIELD_ID]),
                        [FIELD_UNIT_OF_MEASURE_ID]: getIn(values, FIELD_UNIT_OF_MEASURE_ID) ?? getIn(values, [FIELD_CATEGORY, FIELD_EXTENSIONS, FIELD_UNIT_OF_MEASURE_ID]),
                        [FIELD_UNIT_OF_MEASURE_CODE]: getIn(values, FIELD_UNIT_OF_MEASURE_CODE) ?? getIn(values, [FIELD_CATEGORY, FIELD_EXTENSIONS, FIELD_UNIT_OF_MEASURE_CODE]),
                        [FIELD_UNIT_PRICE]: getIn(values, FIELD_UNIT_PRICE),
                        [FIELD_DIRECT_COST]: getIn(values, FIELD_DIRECT_COST),
                        [FIELD_LINE_TYPE]: getIn(values, [FIELD_LINE_TYPE, FIELD_ID]),
                        [FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION]: getIn(values, [FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION, FIELD_ID])
                    }
                } as TimeEntity
            }
        });

        addMethod("getValidationConfig", Modules.rewindBcDelux, false, ((c?: FormValidationConfiguration) => {
            return {
                ...c,
                [FIELD_JOB]: [
                    {
                        validate: (obj: any) => getIn(obj, [FIELD_JOB, FIELD_ID]) != null && getIn(obj, [FIELD_JOB, FIELD_ID]) !== undefined && getIn(obj, [FIELD_JOB, FIELD_ID]) !== '00000000-0000-0000-0000-000000000000',
                        message: L('Validation_FieldMustNotBeEmpty')
                    }
                ],
                [FIELD_JOB_TASK]: [
                    {
                        validate: (obj: any) => getIn(obj, [FIELD_JOB_TASK, FIELD_ID]) != null && getIn(obj, [FIELD_JOB_TASK, FIELD_ID]) !== undefined && getIn(obj, [FIELD_JOB_TASK, FIELD_ID]) !== '00000000-0000-0000-0000-000000000000',
                        message: L('Validation_FieldMustNotBeEmpty')
                    }
                ],
                [FIELD_RESOURCE]: [
                    {
                        validate: (obj: any) => getIn(obj, [FIELD_RESOURCE, FIELD_ID]) != null && getIn(obj, [FIELD_RESOURCE, FIELD_ID]) !== undefined && getIn(obj, [FIELD_RESOURCE, FIELD_ID]) !== '00000000-0000-0000-0000-000000000000',
                        message: L('Validation_FieldMustNotBeEmpty')
                    }
                ],
                [FIELD_CATEGORY]: [
                    {
                        validate: (obj: any) => getIn(obj, [FIELD_CATEGORY, FIELD_ID]) != null && getIn(obj, [FIELD_CATEGORY, FIELD_ID]) !== undefined && getIn(obj, [FIELD_CATEGORY, FIELD_ID]) !== '00000000-0000-0000-0000-000000000000',
                        message: L('Validation_FieldMustNotBeEmpty')
                    }
                ]
            }
        }));

    }, [addMethod, lineTypesActivityConfirmationData]);

    const journalBatchId = values[FIELD_JOB_JOURNAL_BATCH_ID];
    useEffect(() => {
        if (journalBatchId && journalBatches && journalBatches.length > 0) {
            const batch = journalBatches.find(b => b.id === journalBatchId);
            batch && setFieldValue(FIELD_JOB_JOURNAL_BATCH, batch);
        }
    }, [journalBatchId, journalBatches, setFieldValue]);

    // Initially set resource id
    const resourceId = values[FIELD_RESOURCE]?.id;
    const resourceIdFieldValue = values[FIELD_RESOURCE_ID];
    useEffect(() => {
        const settingsResourceId = abp.setting.get(Settings_ResourceId);
        // Check for empty string '' as it is our initial value
        // effect executes already before the form is ready, value would then be undefined
        log("trying to update resource ", (resourceIdFieldValue === undefined || resourceIdFieldValue === ''), resourceId, !!!resourceId, resourceId === NullGuid, touched, !Object.keys(touched).find(k => k === FIELD_RESOURCE), resourceIdFieldValue === '' && (!resourceId || resourceId === NullGuid) && !Object.keys(touched).find(k => k === FIELD_RESOURCE));
        if ((resourceIdFieldValue === undefined || resourceIdFieldValue === '') && (!resourceId || resourceId === NullGuid) && !Object.keys(touched).find(k => k === FIELD_RESOURCE)) {
            setFieldValue(FIELD_RESOURCE_ID, settingsResourceId);
            log("updated resource to ", settingsResourceId);
        }
        // setFieldValue(FIELD_RESOURCE_ID, abp.setting.get(Settings_ResourceId));
    }, [setFieldValue, touched, resourceIdFieldValue]);

    // Update resource id, if it's changed manually
    useEffect(() => {
        if (resourceId && resourceId !== NullGuid && Object.keys(touched).includes(FIELD_RESOURCE)) {
            updateSetting({ name: Settings_ResourceId, value: resourceId, level: 'User' });
            abp.event.trigger(AppEvents.TimeEntityEditorFields_ResourceFilterUpdated, resourceId, false);
        }
    }, [updateSetting, resourceId, touched]);

    useEffect(() => {
        if (resourceId && resourceId !== NullGuid) {
            abp.event.trigger(AppEvents.TimeEntityEditorFields_ResourceFilterUpdated, resourceId, true);
        }
    }, [resourceId])
    // Update Unit of Measure from Work Type
    // const category = getIn(values, FIELD_CATEGORY);
    // const categoryUom = getExtensionValue(category, FIELD_UNIT_OF_MEASURE_CODE);
    // const uomCode = getIn(values, FIELD_UNIT_OF_MEASURE_CODE);
    // const uomId = getIn(values, FIELD_UNIT_OF_MEASURE_ID);
    // useEffect(() => {
    //     if (categoryUom && (!uomCode || uomCode !== categoryUom)) {
    //         setFieldValue(FIELD_UNIT_OF_MEASURE_CODE, categoryUom);
    //     }
    // });

    useComputation({
        dependency: [FIELD_CATEGORY, FIELD_ID],
        target: [FIELD_UNIT_OF_MEASURE_ID],
        computation: (_, getValue) => getExtensionValue(getValue(FIELD_CATEGORY), FIELD_UNIT_OF_MEASURE_ID),
        condition: (id) => !!id
    });

    useComputation({
        dependency: [FIELD_CATEGORY, FIELD_ID],
        target: [FIELD_UNIT_OF_MEASURE_CODE],
        computation: (_, getValue) => getExtensionValue(getValue(FIELD_CATEGORY), FIELD_UNIT_OF_MEASURE_CODE),
        condition: (id) => !!id
    });

    // Use Unit of Measure Code as Amount Unit
    const uomCode = getIn(values, FIELD_UNIT_OF_MEASURE_CODE);
    const amountUnit = getIn(values, FIELD_AMOUNT_UNIT);
    useEffect(() => {
        if (uomCode && uomCode !== '' && uomCode !== amountUnit) {
            setFieldValue(FIELD_AMOUNT_UNIT, uomCode);
        }
    });

    const hasId = !!values[FIELD_ID]
    const localOItems = useMemo(() => {
        const lineTypItem = oItem("rewind-bc-delux$div.jjl-data::after",
            <>
                <ComboBox name={FIELD_LINE_TYPE_ACTIVITY_CONFIRMATION} label={L('LineTypeActivityConfirmation')}
                    data={lineTypesActivityConfirmationData} dataItemKey="id" textField="value" sm={6} lg={4} disabled={isDisabled} />
                <ComboBox name={FIELD_JOB_JOURNAL_BATCH} label={L('JobJournalBatch')} sm={6} lg={4} disabled={isDisabled || hasId}
                    data={journalBatches} dataItemKey="id" textField="name" filterable={true} itemRender={(li, props) => <ItemRenderComponent li={li} itemProps={props} fields={['name', 'description']} />} />
            </>
        )

        const item = oItem("rewind-core$div.description::after",
            <>
                <Outlet name="rewind-bc-delux$div.jjl-data::before" items={oItems}></Outlet>
                <div className="row jjl-data">
                    <ComboBox name={FIELD_LINE_TYPE} label={L('LineType')} data={lineTypesData()} dataItemKey="id" textField="value" sm={6} lg={4} disabled={isDisabled} />

                    <Input name={FIELD_UNIT_PRICE} type="number" label={L('UnitPrice')} disabled={isDisabled} sm={4} lg={3} />
                    <Input name={FIELD_DIRECT_COST} type="number" label={L('DirectCost')} disabled={isDisabled} sm={4} lg={3} />
                    <Outlet name="rewind-bc-delux$div.jjl-data" items={oItems}></Outlet>
                </div>
                <Outlet name="rewind-bc-delux$div.jjl-data::after" items={[lineTypItem, ...(oItems ?? [])]} wrapper={<div className="row additional-data px-0" />}></Outlet>
            </>);
        return oItems ? [...oItems, item] : [item]
    }, [oItems, lineTypesData, isDisabled, journalBatches, hasId]);

    const showJobTaskDetails = getIn(values, "showJobTaskDetails");

    //Keyboard shortcuts
    useKeyCombination('p', 'ctrl', (e) => focusField(FIELD_JOB));
    const [isCtrlDown] = useKeyDown('Control');

    return <React.Fragment key="time-entity-fields-rewind-bc-delux">
        <Outlet name="rewind-bc-delux$div.job-data::before" items={oItems}></Outlet>
        <div className="row job-data">
            <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Job}
                props={{ name: FIELD_JOB, foreignKeyName: FIELD_JOB_ID, sm: 12, lg: 6, disabled: isDisabled, displayShortcutKey: isCtrlDown ? 'P' : undefined } as JobSelectionProps} getSingleComponent={true} />
            <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.JobTask}
                props={{
                    name: FIELD_JOB_TASK, fieldJobName: FIELD_JOB, foreignKeyName: FIELD_JOB_TASK_ID, userResourceId, sm: 12, lg: 6, disabled: isDisabled,
                    suffix: <IconButton size="1.5rem"
                        onClick={() => setFieldValue('showJobTaskDetails', !!!showJobTaskDetails)}
                        title={showJobTaskDetails ? L('HideJobTaskDetails') : L('ShowJobTaskDetails')}
                        color={showJobTaskDetails ? 'var(--primary-600)' : undefined}
                        name={showJobTaskDetails ? 'icon-information' : 'icon-information-2'} />
                } as JobTaskSelectionProps} getSingleComponent={true} />

            <div className={`col-12 job-task-details ${showJobTaskDetails && 'visible'}`} style={showJobTaskDetails ? { maxHeight: '400px', opacity: 1 } : { maxHeight: 0, opacity: 0 }}>
                <ModuleComponentProvider appPartName={AppParts.BudgetPlanning.JobTaskDetails}
                    props={{}} getSingleComponent={true} />
            </div>

            <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Resource}
                props={{ name: FIELD_RESOURCE, foreignKeyName: FIELD_RESOURCE_ID, sm: 12, lg: 6, disabled: isDisabled } as JobTaskSelectionProps} getSingleComponent={true} />
            <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Category}
                props={{ name: FIELD_CATEGORY, foreignKeyName: FIELD_CATEGORY_ID, sm: 12, lg: 6, disabled: isDisabled } as JobTaskSelectionProps} getSingleComponent={true} />
        </div>
        <Outlet name="rewind-bc-delux$div.job-data::after" items={oItems}></Outlet>


        <ModuleComponentProvider
            appPartName={AppParts.TimeTracking.TimeEntities.EditorFields}
            props={{ ...props, oItems: localOItems }} inheritForModule={Modules.rewindBcDelux} />

        <Outlet name="rewind-bc-delux$::after" items={oItems}></Outlet>
    </React.Fragment>
}

export default TimeEntityEditorFields;