import { DateTime, Duration } from 'luxon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { L } from '../../../abp/utils';
import { DataMappingApi, DataMappingDto, TimeEntitiesApi, TimeEntity, TimeTrackingSessionDto, TimeTrackingSessionsApi } from '../../../client/http';
import Icon from '../../../common/components/icon/Icon';
import IconButton from '../../../common/components/icon/IconButton';
import Button from '../../../common/forms/controls/button/Button';
import DateInput from '../../../common/forms/controls/date-picker/DateInput';
import DatePicker from '../../../common/forms/validation/controls/date-picker/DatePicker';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import { useNaVaFormContext } from '../../../common/forms/validation/na-va-form/NaVaFormContext';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import useAuthConfiguration from '../../../common/hooks/useAuthConfiguration';
import { Settings_FilterOwnEntitiesByDefault, Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';
import filter from '../../../util/FilterUtils';
import EntitiesList, { mapTimeEntitiesForEntitiesList } from './entities-list/EntitiesList';
import './TimeEntities.scss';
import { FIELD_DATE_FROM, FIELD_DATE_TO, FIELD_JOB_FILTER, FIELD_TEXT_FILTER } from '../../../data/field-constants/FilterConstants';
import TimeEntityInput from './time-entity-input/TimeEntityInput';
import TimeEntityEditor from './time-entity-input/TimeEntityInput2';
import ModuleComponentProvider from '../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../../modules/AppParts';
import { FIELD_CATEGORY_ID, FIELD_CREATOR_USER_ID, FIELD_ID, FIELD_IS_EDITABLE, FIELD_JOB, FIELD_JOB_ID, FIELD_JOB_TASK_ID, FIELD_RESOURCE, FIELD_RESOURCE_ID } from '../../../data/field-constants/GeneralConstants';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { DateTimeMaxValue, DateTimeMinValue } from '../../../util/DateTimeUtils';
import useDebounce from '../../../common/hooks/useDebounce';
import { TimeEntityEntitiesFilterProps } from '../../../module-components/time-tracking/time-entities/TimeEntityComponentProps';
import useLoadingState from '../../../common/hooks/useLoadingState';
import DataViewMessage from '../../../common/components/data-view-message/DataViewMessage';
import useMethodContext from '../../../module-components/method-context/useMethodContext';
import { Modules } from '../../../module-components/Modules';
import { useLocation, useNavigate } from 'react-router-dom';
import useDataLoadingState from '../../../common/hooks/useDataLoadingState';
import Switch from '../../../common/forms/validation/controls/switch/Switch';
import useApi from '../../../common/hooks/useApi';
import AppEvents from '../../../module-components/AppEvents';
import { abort } from 'process';
import useFetchAutoAbort from '../../../common/hooks/useFetchAutoAbort';
import { IsEmptyGuid } from '../../../util/Values';

export const FormListener: React.FC<{ handleChange: (values: NaVaFormValues) => void }> = ({ handleChange }) => {
    const { values } = useNaVaFormContext();

    useEffect(() => handleChange && handleChange(values), [handleChange, values]);
    return <></>
}

const TimeEntities: React.FC = () => {

    const authConfig = useAuthConfiguration();
    const entitiesClient = useMemo(() => new TimeEntitiesApi(authConfig), [authConfig]);
    const [startLoading, loadingComplete, loadingError, , dataViewMessageData] = useDataLoadingState();
    const dataMappingClient = useMemo(() => new DataMappingApi(authConfig), [authConfig]);
    const [entities, setEntities] = useState<TimeEntity[]>([]);
    const [dataMappings, setDataMappings] = useState<DataMappingDto[]>([]);
    // const [defaultDateFilter, setDefaultDateFilter] = useState<{ fromDate: DateTime, toDate: DateTime}>({ fromDate: DateTime.now().minus({ months: 1 }).startOf('day'), toDate: DateTime.now().endOf('day') });
    const [selectedEntity, setSelectedEntity] = useState<TimeEntity | undefined>(undefined);
    const [{ values: filterValues, setFieldValue, touched: filterValuesTouched }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const userResourceId = filterValues && getIn(filterValues, [FIELD_RESOURCE, FIELD_ID]);
    const jobId = filterValues && getIn(filterValues, [FIELD_JOB, FIELD_ID]);
    const fromDateFilter = filterValues && getIn(filterValues, FIELD_DATE_FROM);
    const toDateFilter = filterValues && getIn(filterValues, FIELD_DATE_TO);
    const userId = (filterValues && getIn(filterValues, 'filterOwnEntities')) ? abp.session.userId : undefined;
    const showReadOnlyEntities = filterValues && getIn(filterValues, 'showReadOnlyEntities') === true;
    const [isEditorCollapsed, setIsEditorCollapsed] = useState(false);

    const entityFilter = useMemo(() => {
        const res = [filter("isPlanningEntity").equals(false)];
        userResourceId && res.push(filter(FIELD_RESOURCE_ID).equals(userResourceId))
        jobId && res.push(filter(FIELD_JOB_ID).equals(jobId));
        userId && res.push(filter(FIELD_CREATOR_USER_ID).equals(userId));
        !showReadOnlyEntities && res.push(filter(FIELD_IS_EDITABLE).equals(true));
        return res;
    }, [userResourceId, jobId, userId, showReadOnlyEntities]);

    const queryFilter = useMemo(() => ({ entity: entityFilter, fromDate: fromDateFilter, toDate: toDateFilter }), [fromDateFilter, toDateFilter, entityFilter])
    const debouncedFilter = useDebounce(queryFilter, 500);


    // Incoming state if entity is preselected

    const location = useLocation();

    useEffect(() => {

        if (!location?.state || !Object.keys(location.state as any).length) return;
        if (!setFieldValue) return;

        const jobId = getIn((location?.state as any), FIELD_JOB_ID) as string;
        const jobTaskId = getIn((location?.state as any), FIELD_JOB_TASK_ID) as string;
        const categoryId = getIn((location?.state as any), FIELD_CATEGORY_ID) as string;

        var temp = {} as any;

        if (!IsEmptyGuid(jobId)) {
            temp[FIELD_JOB_ID] = jobId;
            // setFieldValue(FIELD_JOB_ID, jobId);
        }

        if (!IsEmptyGuid(jobTaskId)) {
            temp[FIELD_JOB_TASK_ID] = jobTaskId;
            // setFieldValue(FIELD_JOB_TASK_ID, jobTaskId);
        }

        if (!IsEmptyGuid(categoryId)) {
            temp[FIELD_CATEGORY_ID] = categoryId;
            // setFieldValue(FIELD_CATEGORY_ID, categoryId);
        }

        setSelectedEntity(val => ({ ...val, ...temp, id: '', isEditable: true }));

    }, [location, setFieldValue]);


    const createAbortController = useFetchAutoAbort();

    const refreshEntities = useCallback(() => {
        const { entity, fromDate, toDate } = debouncedFilter;
        !entity && console.error("EntityFilter is undefined, please check you aren't missing a resource mapping for this user on the service provider");
        // Don't load without date filters
        if (entitiesClient && entity && fromDate && toDate) {
            startLoading();
            entitiesClient.apiServicesAppTimeEntitiesGetAllEntitiesGet(abp.setting.get(Settings_ProviderName), abp.setting.getInt(Settings_ServiceConnectionId),
                DateTime.fromJSDate(fromDate).toJSDate(), DateTime.fromJSDate(toDate).toJSDate(),
                JSON.stringify(entity), undefined, ['jobFk', 'jobTaskFk', 'customerFk', 'resourceFk'], { signal: createAbortController().signal })
                .then(s => {
                    //@ts-ignore
                    setEntities(_.groupBy(s.result.map(mapTimeEntitiesForEntitiesList), 'planningDate'));
                    loadingComplete((s as any).result);
                })
                .catch((e: any) => {
                    if (e instanceof DOMException && e.name === "AbortError") {
                        return;
                    }

                    loadingError(e?.error?.message ?? e?.statusText ?? '');
                });
        }
    }, [entitiesClient, debouncedFilter, startLoading, loadingComplete, loadingError, createAbortController]);

    const deleteEntity = useCallback(() => {
        if (!!selectedEntity && entitiesClient && window.confirm(L("ConfirmDeleteDialog"))) {
            entitiesClient.apiServicesAppTimeEntitiesDeleteEntityByIdDelete(selectedEntity.id, abp.setting.get(Settings_ProviderName),
                abp.setting.getInt(Settings_ServiceConnectionId)).then(() => {
                    setSelectedEntity(undefined);
                    refreshEntities();
                });
        }
    }, [selectedEntity, entitiesClient, refreshEntities]);

    const createNewFromSelected = useCallback(() => {
        if (selectedEntity) {
            setSelectedEntity({ ...selectedEntity, id: "", isEditable: true });
        }
    }, [selectedEntity]);

    useEffect(() => {
        refreshEntities();
    }, [refreshEntities]);

    // Reporting context menu item from project
    const { addMethod } = useMethodContext();
    const navigate = useNavigate();
    const [, timeTrackingSessionsApi] = useApi<TimeTrackingSessionDto, TimeTrackingSessionsApi>(c => new TimeTrackingSessionsApi(c));

    useEffect(() => {
        addMethod("createTimeEntitiesContextMenuItems", Modules.zdiTimeTracking, true, (data: { entity: TimeEntity, values: any[] }) => {
            const { entity, values } = data;
            const returnValues = [...values,
                // {
                //     label: 'Sitzung erstellen',
                //     icon: <Icon name="icon-future" size="1.5rem" className="me-2" />,
                //     command: () => { timeTrackingSessionsApi?.apiServicesAppTimeTrackingSessionsCreateFromTimeEntityPost(entity.id).then(x => navigate('/app/time-tracking/sessions')) }
                // }
            ];
            return { entity, values: returnValues };
        })
    }, [filterValues, addMethod, navigate]);

    // Update resource filter according to current value in editor
    const setResourceFilter = useCallback((resourceId: string, initial: boolean) => {
        if (initial) {
            // Initial executes on every change but we only want to use it, if there has not been a value yet
            if (!getIn(filterValues, FIELD_RESOURCE_ID) && !getIn(filterValues, FIELD_RESOURCE) && !Object.keys(filterValuesTouched).find(k => k === FIELD_RESOURCE)) {
                resourceId && setFieldValue(FIELD_RESOURCE_ID, resourceId);
            }
        } else {
            resourceId && setFieldValue(FIELD_RESOURCE_ID, resourceId);
        }
    }, [setFieldValue]);

    useEffect(() => {
        abp.event.on(AppEvents.TimeEntityEditorFields_ResourceFilterUpdated, setResourceFilter);

        return () => {
            abp.event.off(AppEvents.TimeEntityEditorFields_ResourceFilterUpdated, setResourceFilter);
        }
    }, [setResourceFilter]);

    // Expand editor when another item is selected
    useEffect(() => {
        selectedEntity && setIsEditorCollapsed(false);
    }, [selectedEntity]);

    const isNewItem = !!!selectedEntity?.id;

    const filterOwnEntitiesByDefault = abp.setting.get(Settings_FilterOwnEntitiesByDefault) === 'true';

    return (<div className="time-entities">
        {/* <h2>{L('ManageTimeEntities')}</h2> */}

        <div style={{ display: isEditorCollapsed ? 'none' : 'block' }}>
            {isNewItem ?
                <h2>Neue Zeiteinheit erstellen:</h2> :
                <>
                    <h2 className="d-inline-block">Bestehende Zeiteinheit bearbeiten:</h2>
                    {/* <span className="text-muted ms-3">
                    ({selectedEntity.id})</span> */}
                </>
            }

            {/* <TimeEntityInput isEditor={true} isPlanningEntity={false} selectedEntity={selectedEntity} onReset={() => setSelectedEntity(undefined)} initialValue={selectedEntity}
            onSubmitted={() => refreshEntities()} onDelete={() => deleteEntity()} onCreateNewFromSelected={() => createNewFromSelected()} /> */}

            <TimeEntityEditor isPlanningEntity={false} selectedEntity={selectedEntity} onReset={() => setSelectedEntity(undefined)} initialValue={selectedEntity}
                onSubmitted={() => refreshEntities()} onDelete={() => deleteEntity()} onCreateNewFromSelected={() => createNewFromSelected()} />
        </div>
        <hr></hr>
        <div className={`divider--editor-divider ${isEditorCollapsed ? 'expand-editor' : 'collapse-editor'}`} onClick={() => setIsEditorCollapsed((c) => !c)}>
            <Icon name="icon-collapse-arrow" size="2rem" />
        </div>
        <div className="row list">


            <NaVaForm initialValues={{
                [FIELD_TEXT_FILTER]: null,
                [FIELD_JOB_FILTER]: null,
                [FIELD_DATE_FROM]: DateTime.now().minus({ days: 8 }).startOf('week').toJSDate(),
                [FIELD_DATE_TO]: DateTime.now().endOf('day').toJSDate(),
                [FIELD_RESOURCE_ID]: '',
                'filterOwnEntities': filterOwnEntitiesByDefault
            }} onSubmit={() => { }}>
                <NaVaFormStatePuller onStateChanged={setFormState} />
                <div className="row px-3 mb-3 align-items-center filter-area">
                    <DatePicker name={FIELD_DATE_FROM} sm={4} lg={3} xl={2} />
                    <DatePicker name={FIELD_DATE_TO} sm={4} lg={3} xl={2} />
                    <ModuleComponentProvider appPartName={AppParts.TimeTracking.TimeEntities.EntitiesFilter} props={{ entities } as TimeEntityEntitiesFilterProps} />
                    <Switch name="showReadOnlyEntities" className="switch-align-label filter__isEditable" label={<Icon name="icon-lock" size="1.5rem" className="icon__isEditable"></Icon>} sm={2} lg={1} xl={1}></Switch>
                    <Switch name="filterOwnEntities" className="switch-align-label" label={L('OnlyOwnEntities')} sm={4} lg={3} xl={'auto'}></Switch>
                    <div className="col-auto d-flex align-items-center" title={L('Refresh')}>
                        <IconButton name="icon-circular-arrows" onClick={() => refreshEntities()} style={{ margin: 0 }}></IconButton>
                    </div>
                </div>
            </NaVaForm>
            {<EntitiesList entities={entities} onEntitySelected={(e: TimeEntity) => setSelectedEntity(e)} contextMenuEnabled={true} dataViewMessageData={dataViewMessageData} />}
        </div>
        <ModuleComponentProvider appPartName={AppParts.TimeTracking.TimeEntities.ContextMenuItemsHost} props={{ filterValues }} getSingleComponent={false} />
    </div >)


}

export default TimeEntities;