import React, { useEffect } from 'react';
import { L } from '../../abp/utils';
import ExpansionPanel from '../../common/components/expansion-panel/ExpansionPanel';
import DashboardCard from '../dashboard/dashboard-card/DashboardCard';
import UserEditor from './UserEditor';
import UserList from './UserList';
import EmailSettingsEditor from './EmailSettingsEditor';
import { Features } from '../../data/field-constants/Features';
import { useNavigate } from 'react-router-dom';
import { Permissions } from '../../data/field-constants/Permissions';

const RewindCoreAdministration: React.FC = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!abp.auth.isAnyGranted(Permissions.Administration.Permission, Permissions.Administration.UserManagement)) {
            navigate("/")
        }
    }, [navigate]);

    return <div className="app-administration administration__rewind-core my-3 px-4">
        <h3>{L('ApplicationAdministration')}</h3>

        <div className="row p-3 cards-list">
            {abp.features.getValue(Features.Administration.UserManagement) === "true" &&
                <>
                    <DashboardCard sm={12}>
                        <ExpansionPanel title={<h5 className="fw-semibold">{L("UserOverview")}</h5>}>
                            <UserList></UserList>
                        </ExpansionPanel>
                    </DashboardCard>
                    <DashboardCard sm={12}>
                        <ExpansionPanel title={<h5 className="fw-semibold">{L("CreateNewUser")}</h5>}>
                            <UserEditor></UserEditor>
                        </ExpansionPanel>
                    </DashboardCard>
                </>
            }
            <DashboardCard sm={12}>
                <ExpansionPanel title={<h5 className="fw-semibold">{L("EmailSettings")}</h5>}>
                    <EmailSettingsEditor />
                </ExpansionPanel>
            </DashboardCard>
        </div>

    </div>
}

export default RewindCoreAdministration;