import React, { useEffect, useMemo } from 'react';
import { CategoryDto, CustomerDto, DataMappingApi, DataMappingDto, JobDto, JobTaskDto, ResourceDto, TimeEntity } from '../../../../client/http';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { useNaVaFormContext } from '../../../../common/forms/validation/na-va-form/NaVaFormContext';
import useApi from '../../../../common/hooks/useApi';
import { FIELD_ID, FIELD_JOB, FIELD_JOB_ID, FIELD_RESOURCE, FIELD_RESOURCE_ID } from '../../../../data/field-constants/GeneralConstants';
import ModuleComponentProvider from '../../../../module-components/module-context/ModuleComponentProvider';
import { JobSelectionProps, ResourceSelectionProps } from '../../../../module-components/service-entities/ServiceEntityControlProps';
import { TimeEntityEntitiesFilterProps, TimeEntityEntityDetailsProps } from '../../../../module-components/time-tracking/time-entities/TimeEntityComponentProps';
import { unique } from '../../../../util/EntityUtils';
import { Settings_ProviderName } from '../../../../util/SettingNames';
import { AppParts } from '../../../AppParts';
import Icon from '../../../../common/components/icon/Icon';
import { L } from '../../../../abp/utils';

export const EntityDetails: React.FC<TimeEntityEntityDetailsProps> = (({ entity }) => {
    const job = getIn(entity, 'jobFk') as JobDto;
    const jobTask = getIn(entity, 'jobTaskFk') as JobTaskDto;
    const customer = getIn(entity, 'customerFk') as CustomerDto;

    return <div>
        {/* <div className="d-flex flex-column">
            <span>{job && job.designation}</span>
            <span>{job && job.description1}</span>
        </div> */}
        <div className="data__customer">
            {customer && customer.name}
        </div>
        <div className="data__jobTask">
            {jobTask && jobTask.description1}
        </div>
    </div>;
});

export const EntityData: React.FC<TimeEntityEntityDetailsProps> = (({ entity }) => {
    const job = getIn(entity, 'jobFk') as JobDto;
    const jobTask = getIn(entity, 'jobTaskFk') as JobTaskDto;
    const resource = getIn(entity, 'resourceFk') as ResourceDto;
    const category = getIn(entity, 'categoryFk') as CategoryDto;

    return <>
        <span title={L('Job')}>
            {job?.designation}
        </span>
        <span title={L('Resource')}>
            <Icon name="icon-gender-neutral-user" size="1.5rem" className="ms-2 me-1" />
            {resource?.designation}
        </span>
        <span title={L('JobTask')}>
            <Icon name="icon-clipboard" size="1.5rem" className="ms-2 me-1" />
            {jobTask?.description1}
        </span>
        <span title={L('Category')}>
            {category?.designation}
        </span>
    </>;
});

export const EntitiesFilter: React.FC<TimeEntityEntitiesFilterProps> = ({ entities }) => {
    const mappedEntities = useMemo(() => {
        if (!entities || Object.keys(entities).length < 1)
            return [];

        return Object.keys(entities).reduce((prev: any, curr: string) => [...prev, ...entities[curr as any] as []], []);
    }, [entities]);

    // Set own resource as default    
    const { setFieldValue } = useNaVaFormContext();

    const [dataMappings] = useApi<DataMappingDto, DataMappingApi>((c) => new DataMappingApi(c), (c) => c.apiServicesAppDataMappingGetAllFilteredGet("ResourceMapping", abp.session.userId));       // Retrieve User ResourceId

    useEffect(() => {
        const mappings = dataMappings.filter(m => m.targetServiceProvider === abp.setting.get(Settings_ProviderName));
        const resourceId = (mappings && mappings.length > 0 && mappings[0].targetValue) || "";
        // setFieldValue && setFieldValue(FIELD_RESOURCE_ID, resourceId)
    }, [dataMappings, setFieldValue]);

    return <>
        {/* <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Resource}
            props={{ name: FIELD_RESOURCE, foreignKeyName: FIELD_RESOURCE_ID, data: mappedEntities && [...unique(mappedEntities.map((e: TimeEntity) => e?.resourceFk).filter(x => !!x), FIELD_ID)], sm: 4, md: 3, lg: 2 } as ResourceSelectionProps} /> */}
        <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Resource}
            props={{ name: FIELD_RESOURCE, foreignKeyName: FIELD_RESOURCE_ID, sm: 4, md: 3, lg: 2 } as ResourceSelectionProps} />
        <ModuleComponentProvider appPartName={AppParts.ServiceEntities.Selection.Job}
            props={{ name: FIELD_JOB, foreignKeyName: FIELD_JOB_ID, data: mappedEntities && [...unique(mappedEntities.map((e: TimeEntity) => e?.jobFk).filter(x => !!x), FIELD_ID)], sm: 4, md: 3, } as JobSelectionProps} />
    </>
}