import classNames from 'classnames';
import React, { ReactNode } from 'react';
import './Container.scss'

export type ContainerProps = {
    className?: string
    label?: ReactNode;
    collapsedLabel?: ReactNode;
    isCollapsed?: boolean;
}

const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({ label, collapsedLabel = label, className, isCollapsed = false, children }) => {
    return <div className={classNames('container', className, { 'labelled-container': !!label, collapsed: isCollapsed })}>
        {(label || collapsedLabel) && <span className="container-label">
            {isCollapsed ? collapsedLabel : label}
        </span>}
        {children}
    </div>
}

export default Container;