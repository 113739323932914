import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataMappingApi, DataMappingDto, TimeEntitiesApi, TimeEntity } from '../../../../client/http';
import { DataViewMessageProps } from '../../../../common/components/data-view-message/DataViewMessage';
import Icon from '../../../../common/components/icon/Icon';
import Button from '../../../../common/forms/controls/button/Button';
import NaVaFormStatePuller from '../../../../common/forms/NaVaFormStatePuller';
import FormValidationConfiguration from '../../../../common/forms/validation/configuration/functional/FormValidationConfiguration';
import NaVaForm from '../../../../common/forms/validation/na-va-form/NaVaForm';
import { useNaVaFormContext } from '../../../../common/forms/validation/na-va-form/NaVaFormContext';
import { NaVaFormContextType, NaVaFormValues } from '../../../../common/forms/validation/na-va-form/types';
import useApi from '../../../../common/hooks/useApi';
import useLoadingState from '../../../../common/hooks/useLoadingState';
import useMethodContext from '../../../../module-components/method-context/useMethodContext';
import withMethodContext from '../../../../module-components/method-context/withMethodContext';
import ModuleComponentProvider from '../../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../../../modules/AppParts';
import { Settings_ProviderName } from '../../../../util/SettingNames';
import { Modules } from '../../../../module-components/Modules';
import { getIn } from '../../../../common/forms/validation/na-va-form/commonUtils';
import { FIELD_IS_EDITABLE } from '../../../../data/field-constants/GeneralConstants';
import useKeyCombination from '../../../../common/hooks/useKeyCombination';
import useKeyDown from '../../../../common/hooks/useKeyDown';
import { log } from '../../../../util/LoggingUtils';

export type TimeEntityInputProps = {
    isPlanningEntity?: boolean;
    initialValue?: TimeEntity;
    fetchFromAllProviders?: boolean;
    selectedEntity?: any;
    onReset?: () => void;
    onSubmit?: (values: NaVaFormValues) => void;
    onSubmitted?: () => void;
    onDelete?: () => void;
    onCreateNewFromSelected?: () => void;
};

const TimeEntityEditor: React.FC<TimeEntityInputProps> = ({ isPlanningEntity = true, fetchFromAllProviders = false, selectedEntity, initialValue, onReset, onSubmit, onSubmitted, onDelete, onCreateNewFromSelected }) => {
    const [saving, saved, saveError, isSaving, saveDataViewMessageData] = useLoadingState();
    const [{ values, validateFormWithLowPriority }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const [, timeEntitiesApi] = useApi<TimeEntity, TimeEntitiesApi>(c => new TimeEntitiesApi((c)));

    const { applyMethod, addMethod } = useMethodContext();

    const initialValues = useMemo((): any => {
        return {
            // [FIELD_ID]: '',
            // [FIELD_ETAG]: '',            
            // [FIELD_NAME]: '',
            // [FIELD_DESCRIPTION1]: '',
            // [FIELD_DESCRIPTION2]: '',
            // [FIELD_RESOURCE]: null,
            // [FIELD_RESOURCE_ID]: '',
            // [FIELD_JOB]: null,
            // [FIELD_JOB_ID]: '',
            // [FIELD_JOB_TASK]: null,
            // [FIELD_JOB_TASK_ID]: '',
            // [FIELD_CATEGORY]: null,
            // [FIELD_CATEGORY_ID]: "",
            // [FIELD_IS_INVOICED]: false,
            // [FIELD_IS_BONUS]: false,
            // [FIELD_UNIT_PRICE]: 0,
            // [FIELD_JOB_JOURNAL_BATCH_ID]: ''
        }
    }, []);

    const createTimeEntity = useCallback((values: NaVaFormValues) => {
        const data = applyMethod("mapCreateTimeEntity", { value: {}, values }).value as TimeEntity;
        data.extensionData = JSON.stringify(data.extensions);
        //Don't post extensions
        data.extensions = undefined;

        saving();

        // console.log(data, values);
        timeEntitiesApi?.apiServicesAppTimeEntitiesCreateOrUpdateEntityPost(data as any).then(() => {
            saved();
            onSubmitted && onSubmitted();
        })
            .catch(r => {
                r.json().then((e: any) => saveError(e.error.message));
            })
    }, [saving, saved, saveError, onSubmitted, applyMethod, timeEntitiesApi]);


    const [initial, setInitial] = useState<TimeEntity | undefined>(initialValues);

    // Initial Values
    useEffect(() => {
        const mappedEntity = applyMethod('mapTimeEntity', initialValue);       

        setInitial({ ...applyMethod("getInitialValues", { initialValues, initialValue }).initialValues, ...mappedEntity });
    }, [initialValue, initialValues, setInitial, applyMethod]);

    // ...applyMethod("getInitialValues", initialValues)

    // Validation config
    const [validationConfig, setValidationConfig] = useState<FormValidationConfiguration>({});

    useEffect(() => {
        log("updating validation config");
        setValidationConfig(applyMethod("getValidationConfig", {}));
        validateFormWithLowPriority && validateFormWithLowPriority();
    }, [applyMethod, setValidationConfig, validateFormWithLowPriority]);

    return <NaVaForm initialValues={initial as any} validationConfig={validationConfig}
        onSubmit={(values) => { createTimeEntity(values); onSubmit && onSubmit(values); }}
        enableReinitialize={false}
        validateOnMount={true} >
        <TimeEntityInput isPlanningEntity={isPlanningEntity} fetchFromAllProviders={fetchFromAllProviders}
            selectedEntity={selectedEntity} /*onSubmit={(values) => { createTimeEntity(values); onSubmit && onSubmit(values) }}*/ onDelete={onDelete}
            lastSaveResult={saveDataViewMessageData} isSaving={isSaving} initial={initial} onCreateNewFromSelected={onCreateNewFromSelected}
            onReset={() => {
                onReset && onReset();
                // Rerender NaVaForm by force by changing the surrounding MethodContext
                // This way initial useEffects() from different Application Parts will run, which they wouldn't when simply resetting the form
                // TODO: find a better way to do this
                addMethod("reset-form", Modules.rewindCore, true, () => { return new Date(); });
            }} />
        {/* <NaVaFormStatePuller onStateChanged={s => setFormState(s)} /> */}
    </NaVaForm >
}

export const TimeEntityInput: React.FC<TimeEntityInputProps & { lastSaveResult?: DataViewMessageProps, isSaving?: boolean, initial?: any }> = (
    { isPlanningEntity = true, selectedEntity, fetchFromAllProviders, onReset, onDelete, onCreateNewFromSelected, lastSaveResult, isSaving, initial: initialValue }
) => {

    const { isValid, setValues, setTouched, validateForm, values, initialValues, touched, submitForm } = useNaVaFormContext();

    const [dataMappings] = useApi<DataMappingDto, DataMappingApi>(c => new DataMappingApi(c), c => c.apiServicesAppDataMappingGetAllFilteredGet("ResourceMapping", abp.session.userId));
    const [userResourceId, setUserResourceId] = useState<string>("");

    // Retrieve User ResourceId
    useEffect(() => {
        const mappings = dataMappings.filter(m => m.targetServiceProvider === abp.setting.get(Settings_ProviderName));
        setUserResourceId((mappings && mappings.length > 0 && mappings[0].targetValue) || "");
    }, [dataMappings, setUserResourceId]);

    const isDisabled = selectedEntity && !selectedEntity.isEditable;
    const isNewItem = selectedEntity && !selectedEntity.id;

    const { applyMethod } = useMethodContext();

    useEffect(() => {
        // If required, check here if touched before overriding initial values              
        setValues({ ...values, ...initialValue }, true);
        setTouched({});

        // Validate the form when initializing it
        // This will initially run the computations
        // Timeout is required to make sure initialization is complete
        setTimeout(() => validateForm(), 1500);
        //, { ...values, ...applyMethod("getInitialValues", initialValue) }, initialValue);
    }, [initialValue, setValues, setTouched]);

    // Keyboard shortcuts
    useKeyCombination('s', 'ctrl', (e) => submitForm(), { disabled: () => !isValid });
    useKeyCombination('u', 'ctrl', (e) => onCreateNewFromSelected && onCreateNewFromSelected(), { disabled: () => false });
    const [isCtrlDown] = useKeyDown('Control');

    return <div className="time-entity-input">

        <div className="row editor-container">
            <div className="left col">

                <div className="container inner">
                    {/* <ModuleFileComponentProvider modulePath="time-tracking/time-entities"
                        appPartName={AppParts.TimeTracking.TimeEntities.EditorFields}
                        data={{ userResourceId, disabled: isDisabled, initialValue }} overrideValues={true} /> */}
                    <ModuleComponentProvider appPartName={AppParts.TimeTracking.TimeEntities.EditorFields}
                        props={{ userResourceId, disabled: isDisabled, initialValue, isPlanningEntity }} getSingleComponent={true} />
                    <div className="save-state">
                        {lastSaveResult && <span className={`text-${lastSaveResult.state === 'successful' ? 'success' : 'danger'}`}>{lastSaveResult.state === 'successful' ? "Speichern erfolgreich" : lastSaveResult.message}</span>}
                    </div>
                </div>
            </div>
            <div className="time-entity-actions col-md-12 col-lg-3 col-xxl-2">
                {/* <Button label="" onClick={() => { onReset && onReset() }} type="reset" disabled={!!!selectedEntity && Object.keys(touched).length === 0} color="light"><Icon name='icon-clock-add' size='2.5rem' /> <br></br>Neue Arbeitseinheit</Button>
                <Button label="" onClick={() => { onDelete && onDelete() }} color="danger" disabled={!!!selectedEntity}><Icon name='icon-remove-clock' size='2.5rem' /> Arbeitseinheit löschen</Button>
            <Button label="" onClick={() => { onSubmit && onSubmit(values) }} type="button" color="primary" disabled={!isValid}><Icon name='icon-clock-checked' size='2.5rem' /> Arbeitseinheit speichern</Button> */}
                <div className="top">
                    <Button label="" onClick={() => { onReset && onReset() }} type="reset" disabled={(isNewItem && Object.keys(touched).length === 0) && (selectedEntity && !isNewItem)} color="light"><Icon name="icon-cancel" size="1.5rem" />{isNewItem ? "Zurücksetzen" : "Abbrechen"}</Button>
                    <Button label="" onClick={() => { onDelete && onDelete() }} color="danger" disabled={!selectedEntity || isNewItem || getIn(selectedEntity, FIELD_IS_EDITABLE) === false}><Icon name="icon-remove-clock" size="1.5rem" color="white" />Löschen</Button>
                    <Button label="" onClick={() => { onCreateNewFromSelected && onCreateNewFromSelected() }} type="button" disabled={!selectedEntity || isNewItem} color="light" displayShortcutKey={isCtrlDown ? 'U' : undefined}>
                        <Icon name="icon-clock-arrow" size="1.5rem" />
                        Zu neuem Elem. umwandeln
                    </Button>
                </div>

                <div className="bottom">
                    <Button label="" onClick={() => { submitForm() }} type="button" color="primary" disabled={!isValid} displayShortcutKey={isCtrlDown ? 'S' : undefined}><Icon name="icon-clock-checked" size="1.5rem" color="white" />Speichern</Button>
                </div>
            </div>
        </div>
    </div>
}

export default withMethodContext(TimeEntityEditor);