import { useMemo, useCallback } from 'react';
import { isObject, getIn } from './commonUtils';
import { useNaVaFormContext } from './NaVaFormContext';
import { GetFieldPropsOptions, FieldInputProps } from './types';

function useNaVaField<TValue>(nameOrOptions: string | GetFieldPropsOptions<TValue>): FieldInputProps<TValue> {
  const ctx = useNaVaFormContext();
  const isAnObject = isObject(nameOrOptions);
  const name = isAnObject ? (nameOrOptions as GetFieldPropsOptions<TValue>).name : nameOrOptions as string;

  const valueState = useMemo(() => getIn(ctx.values, name), [ctx.values, name]);
  const error = useMemo(() => getIn(ctx.errors, name), [ctx.errors, name]);

  const { setFieldValue, setFieldError, setFieldTouched } = ctx;
  const ref = useMemo(() => ctx.getFieldRef.call(undefined, name), [ctx.getFieldRef, name]);
  const setValue = useCallback((value: any, shouldValidate?: boolean) => setFieldValue(name, value, shouldValidate), [setFieldValue, name]);
  const setTouched = useCallback((value: boolean, shouldValidate?: boolean) => setFieldTouched(name, value, shouldValidate), [setFieldTouched, name]);
  const setError = useCallback((value: any) => setFieldError(name, value), [setFieldError, name]);

  const field: FieldInputProps<any> = {
    name,
    value: valueState,
    error,
    touched: !!getIn(ctx.touched, name),
    ref,
    onChange: ctx.handleChange,
    onBlur: ctx.handleBlur,
    setValue,
    setTouched,
    setError
  };
  if (isAnObject) {
    const {
      type,
      value: valueProp, // value is special for checkboxes
      as: is,
      multiple
    } = nameOrOptions as GetFieldPropsOptions<TValue>;

    if (type === 'checkbox') {
      if (valueProp === undefined) {
        field.checked = !!valueState;
      } else {
        field.checked = !!(
          Array.isArray(valueState) && ~valueState.indexOf(valueProp)
        );
        field.value = valueProp;
      }
    } else if (type === 'radio') {
      field.checked = valueState === valueProp;
      field.value = valueProp;
    } else if (is === 'select' && multiple) {
      field.value = field.value || [];
      field.multiple = true;
    }
  }
  return field;
}

export default useNaVaField;
