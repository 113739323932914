import React from 'react';
import { L } from '../../../../abp/utils';
import { JobTask } from '../../../../client/http';

const JobTaskDetails: React.FC<{ data: JobTask, userResourceId: string }> = ({ data, userResourceId }) => {
    if (!data) return <></>;
    var parsed = data;
    parsed.extensions = JSON.parse(data.extensionData ?? '{}');

    var isAccountable = userResourceId && parsed.accountableId === userResourceId;
    var isExecutive = userResourceId && parsed.executiveId === userResourceId;
    return <div className="job-details combo-box-footer">
        <div className="main-data">
            <h1>{parsed.designation}</h1>
            <h2>{parsed.description1 ?? ''}</h2>
            <h2>{parsed.description2 ?? ''}</h2>
            <h3>{L(parsed.customState ?? '')}</h3>
            {isExecutive ? <div className="is-executive">
                {L('TaskAssignedToYou')}
            </div> : isAccountable ? <div className="is-accountable">
                {L('TaskAccountableFor')}
            </div>
                : <></>}

        </div>
    </div>
}

export default JobTaskDetails;