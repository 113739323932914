import React from 'react';
import './ShortcutKey.scss';

const ShortcutKey: React.FC<{ shortcutKey?: string, alwaysVisible?: boolean, style?: React.CSSProperties }> = ({ shortcutKey, alwaysVisible, style }) => {
    return (!!shortcutKey || alwaysVisible) ?
        <span className="shortcut-key" style={style}>
            <kbd>{shortcutKey}</kbd>
        </span>
        : null;
}

export default ShortcutKey;