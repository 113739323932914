import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import _ from 'lodash';
import { Settings } from 'luxon';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import constants from '../abp/constants';
import { getUserConfiguration } from '../abp/getUserConfiguration';
import signalRService from '../abp/signalRService';
import { getCurrentClockProvider, loadLocalizationFiles, setLocalization } from '../abp/utils';
import Authenticator from '../authentication/authenticator/Authenticator';
import LoadingIndicator from '../common/components/loading-indicator/LoadingIndicator';
import ProtectedRoute from '../common/routing/ProtectedRoute';
import Error from '../scenes/error/Error';
import Login from '../scenes/login/Login';
import ProtectedArea from '../scenes/protected-area/ProtectedArea';
import './App.scss';
import '../icons/css/styles.css';
import { ReactComponent as Icons } from '../icons/plan-365-pastel-glyph.min.svg';
import ModuleContextProvider from '../module-components/module-context/ModuleContextProvider';
import withMethodContext from '../module-components/method-context/withMethodContext';
import ResetPassword from '../scenes/login/ResetPassword';
import { ErrorBoundary } from 'react-error-boundary';

const ENDPOINT_LOGIN = constants.remoteServiceBaseUrl!.replace(/\/$/, '') + '/api/TokenAuth/Authenticate';
const ENDPOINT_REFRESH_TOKEN = constants.remoteServiceBaseUrl!.replace(/\/$/, '') + '/api/TokenAuth/RefreshToken';

enum LoadingState {
  NONE,
  LOADING,
  LOADED,
  ERROR
}

const App: React.FC = () => {

  const [configLoadingState, setConfigLoadingState] = useState(LoadingState.NONE);

  function loadConfig() {
    setConfigLoadingState(LoadingState.LOADING);

    getUserConfiguration()
      .then(result => {
        _.merge(abp, result.data.result);
        // extend(true, abp, result.data.result);
        abp.clock.provider = getCurrentClockProvider(result.data.result.clock.provider);
        Settings.defaultLocale = abp.localization.currentLanguage.name;
        if (abp.clock.provider.supportsMultipleTimezone) Settings.defaultZone = abp.timing.timeZoneInfo.iana.timeZoneId;
        signalRService.initSignalR();
        setLocalization();
        loadLocalizationFiles();

        setConfigLoadingState(LoadingState.LOADED);
      })
      .catch(err => {
        setConfigLoadingState(LoadingState.ERROR);
      });
  }

  useEffect(loadConfig, []);

  return (
    <LocalizationProvider language={abp?.localization?.currentLanguage?.name ?? 'de'}>
      <IntlProvider locale={abp?.localization?.currentLanguage?.name ?? 'de'}>
        <Icons style={{ display: "none" }}></Icons>
        <div className="app-background"></div>
        <div className="app-container container-fluid p-0">
          <div className="row w-100" style={{ margin: 0 }}>
            <ErrorBoundary fallbackRender={(p) => <Error
              message={
                <>
                  Hoppla, da ist etwas schief gelaufen.
                  <br />
                  <br />
                  Bitte versuchen Sie es erneut und kontaktieren Sie ihren Administrator, falls das Problem bestehen bleibt.
                </>}
              onClickReload={() => window.location.reload()}
              isFullScreen={true}
              type="FatalError"
            />}>
              {configLoadingState === LoadingState.LOADED &&
                <Router>
                  <ModuleContextProvider>
                    <Authenticator authenticationUrl={ENDPOINT_LOGIN} refreshTokenUrl={ENDPOINT_REFRESH_TOKEN}>
                      <Routes>
                        <Route path="/" element={
                          <Navigate to="/app" />
                        }>
                        </Route>
                        <Route path="/app/*"
                          element={
                            <ProtectedRoute redirectTo={`/login?returnUrl=${encodeURIComponent('/app')}`}>
                              <ProtectedArea />
                            </ProtectedRoute>
                          } />
                        <Route path="/login" element={
                          <div className="row align-items-center vh-100 login-container">
                            <div className="inner">
                              <Login />
                            </div>
                          </div>
                        }>
                        </Route>
                        <Route path="/reset-credentials" element={
                          <div className="row align-items-center vh-100 login-container">
                            <div className="inner">
                              <ResetPassword />
                            </div>
                          </div>
                        }>
                        </Route>
                      </Routes>
                    </Authenticator>
                  </ModuleContextProvider>
                </Router>}
              {configLoadingState === LoadingState.LOADING &&
                <LoadingIndicator />
              }
              {configLoadingState === LoadingState.ERROR &&
                <Error
                  message={
                    <>
                      Es konnte keine Verbindung zum Server hergestellt werden.
                      <br />
                      <br />
                      Bitte versuchen Sie es erneut und kontaktieren Sie ihren Administrator, falls das Problem bestehen bleibt.
                    </>}
                  onClickReload={loadConfig}
                  isFullScreen={true}
                  type="BadGateway"
                />
              }
            </ErrorBoundary>
          </div>
        </div>
      </IntlProvider>
    </LocalizationProvider>
  );
}

export default withMethodContext(App);
