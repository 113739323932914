import { defineFont } from '@progress/kendo-drawing/pdf';
import { PDFExport } from '@progress/kendo-react-pdf';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReportDto, ReportsApi, ReportTemplateDto, ReportTemplatesApi } from '../../client/http';
import BackButton from '../../common/components/back-button/BackButton';
import ExpansionPanel from '../../common/components/expansion-panel/ExpansionPanel';
import Icon from '../../common/components/icon/Icon';
import LoadingIndicator from '../../common/components/loading-indicator/LoadingIndicator';
import Button from '../../common/forms/controls/button/Button';
import { Dropdown } from '../../common/forms/controls/dropdown/Dropdown';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import useApi from '../../common/hooks/useApi';
import ComponentProvider from '../../module-components/ComponentProvider';
import ModuleComponentProvider from '../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../modules/AppParts';
import { getExtensionValue } from '../../util/EntityUtils';
import ReportDataView from './ReportDataView';
import ReportOptionControl, { parseOptionDefaultValue } from './ReportOptionControl';
import Error from '../error/Error';

const ReportEditor: React.FC = () => {

    const navigate = useNavigate();

    const { reportId } = useParams();
    const [, reportApi] = useApi<ReportDto, ReportsApi>(c => new ReportsApi(c));
    const [report, setReport] = useState<ReportDto | undefined>(undefined);
    const [loadingError, setLoadingError] = useState(false);
    const [reportVersion, setReportVersion] = useState<string | undefined>();
    const reportVersions = report ? report.dataEntries?.map(e => e.version!) : undefined;
    const selectedReportData = report?.dataEntries?.find(e => e.version === reportVersion);

    useEffect(() => {
        setReportVersion(reportVersions ? reportVersions[reportVersions.length - 1] : "");
    }, [setReportVersion, report]);

    const [, reportTemplateApi] = useApi<ReportTemplateDto, ReportTemplatesApi>(c => new ReportTemplatesApi(c));
    const [reportTemplate, setReportTemplate] = useState<ReportTemplateDto | undefined>(undefined);

    useEffect(() => {
        reportApi && reportId && reportApi?.apiServicesAppReportsGetGet(parseInt(reportId)).then(r => setReport((r as any).result)).catch(() => setLoadingError(true));
    }, [reportId, reportApi])

    useEffect(() => {
        report && reportTemplateApi && reportTemplateApi.apiServicesAppReportTemplatesGetGet(report.templateId).then(r => setReportTemplate((r as any).result)).catch(() => setLoadingError(true));
    }, [report, reportTemplateApi]);

    const reportDataValues = JSON.parse(selectedReportData?.values ?? "[]");

    const reportDataOptions = JSON.parse(selectedReportData?.optionValues ?? "{}");

    const reportRef = useRef<PDFExport>(null);

    useEffect(() => {
        defineFont({
            'Source Sans Pro': '/fonts/SourceSansPro-Regular.ttf',
            'Source Sans Pro|Bold': '/fonts/SourceSansPro-Bold.ttf',
            'Source Sans Pro|Semibold': '/fonts/SourceSansPro-SemiBold.ttf',
            'DejaVu Sans': '/fonts/DejaVuSans.ttf',
            'DejaVu Sans|Bold': '/fonts/DejaVuSans-Bold.ttf'
        });
    }, [])

    const isDisabled = !report || !report.isEditable;

    return (loadingError ? <>
        <Error type="NotFound" message="Dieses Element existiert nicht - Haben Sie sich verirrt?" isFullScreen={false} onClickReloadLabel="Bring mich zurück" onClickReload={() => navigate('/app/reporting/edit', { replace: true })} />
    </> : (reportTemplate && report ? <div className="report-editor mt-2">

        <BackButton path="/app/reporting/edit" />

        <h3>{reportTemplate?.name} {report.no}</h3>

        <p>
            <div>{report?.reportTitle}</div>
            <div>Berichtsdatum: {DateTime.fromISO(report.reportDate! as any).toLocaleString(DateTime.DATE_SHORT)}</div>
        </p>

        <div className="row ms-0">
            <Dropdown data={reportVersions} name="version" sm={2} value={reportVersion} label="Gewählte Berichtsversion" onChange={(e) => setReportVersion(e.target.value)} />
            <Button label="" color="light" sm={3} lg={3} onClick={() => { navigate('/app/reporting/create', { state: { report, reportTemplate } }) }} disabled={isDisabled}>
                <Icon name="icon-add" size="1.5rem" />
                Neue Version erstellen
            </Button>
            <ModuleComponentProvider appPartName={AppParts.Reporting.ReportEditor.Tools} props={{ report, reportTemplate, disabled: isDisabled }} />
            <Button color="primary" onClick={() => {
                reportRef.current?.save();
            }} label="" sm={3} lg={3}>
                <Icon name="icon-print-file" size="1.5rem" color="#FFF" />
                <span>Bericht als PDF herunterladen</span>
            </Button>
        </div>
        {selectedReportData && <div className="info mt-2">
            <span>Erstellt am {DateTime.fromISO((selectedReportData.creationTime!) as any).toLocaleString({ ...DateTime.DATETIME_FULL, timeZoneName: undefined })}</span>
        </div>}
        {isDisabled && <div className="info fw-bold text-primary mt-2">
            <span>Dieser Bericht wurde am {DateTime.fromISO(getExtensionValue(report, 'ReleasedAt')).toLocaleString({ ...DateTime.DATETIME_FULL, timeZoneName: undefined })} freigegeben und kann nicht mehr bearbeitet werden!</span>
        </div>}

        <ExpansionPanel title="Berichtsoptionen">
            <NaVaForm
                initialValues={{
                    ...reportTemplate.options?.filter(o => o.defaultValue).reduce((val, value, index) => {
                        return { ...val, [value.identifier!]: parseOptionDefaultValue(value.type!, reportDataOptions[value.identifier!]) };
                    }, {})
                }}
                enableReinitialize={true}
                onSubmit={() => { }}>

                {reportTemplate.options?.filter(o => o.isEnabled).map((o) =>
                    (<ReportOptionControl option={o} disabled={true} />)
                )}
            </NaVaForm>
        </ExpansionPanel>

        <div className="data-wrapper mt-4">
            <ReportDataView data={reportDataValues} type={reportTemplate.dataType ?? ""} />
        </div>

        <div className="report-view" style={{ opacity: '0', height: 0, overflow: 'hidden' }}>
            {reportTemplate?.templatePath &&
                <ComponentProvider componentPath={reportTemplate.templatePath} data={{
                    title: report.reportTitle, options: reportDataOptions, report: report,
                    templateData: reportTemplate, dataEntry: selectedReportData, data: reportDataValues, pdfRef: reportRef, isPreview: false
                }} />
            }
        </div>

    </div> :
        <LoadingIndicator />));
}

export default ReportEditor;