import React, { useCallback, useEffect, useState } from 'react';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import Input from '../../../common/forms/validation/controls/input/Input';
import { DataExchangeProtocol, WorkItemSyncApi } from '../../../client/http';
import useDebounce from '../../../common/hooks/useDebounce';
import useApi from '../../../common/hooks/useApi';
import { DateTime } from 'luxon';
import './SyncProtocolsList.scss';
import useLoadingState from '../../../common/hooks/useLoadingState';
import LoadingIndicator from '../../../common/components/loading-indicator/LoadingIndicator';
import useVisibility from '../../../common/hooks/useVisibility';
import { Dialog } from '@progress/kendo-react-dialogs';
import { L } from '../../../abp/utils';
import Button from '../../../common/forms/controls/button/Button';
import Icon from '../../../common/components/icon/Icon';
import CodeView from '../../../common/components/code-view/CodeView';

const SyncProtocolsList: React.FC = () => {

    const [itemCount, setItemCount] = useState(20);
    const [protocols, setProtocols] = useState<DataExchangeProtocol[] | undefined>(undefined);
    const [{ values, setFieldValue }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    const [, workItemSyncApi] = useApi<any, WorkItemSyncApi>(c => new WorkItemSyncApi(c));
    const [startLoading, loadingComplete, loadingError, isLoading] = useLoadingState();

    const workItemIdFilter = getIn(values, "workItemId");
    const debouncedWorkItemIdFilter = useDebounce<string>(workItemIdFilter, 1000);

    const loadProtocols = useCallback(() => {
        startLoading();
        workItemSyncApi?.apiServicesDevOpsWorkItemSyncGetSyncProtocolsGet(itemCount, undefined, debouncedWorkItemIdFilter)
            .then(d => { setProtocols((d as any).result?.items); loadingComplete(); }).catch(e => loadingError(e));
    }, [workItemSyncApi, itemCount, debouncedWorkItemIdFilter]);

    useEffect(() => {
        setItemCount(20);
    }, [debouncedWorkItemIdFilter, loadProtocols]);

    useEffect(() => {
        loadProtocols();
    }, [loadProtocols]);

    const [dialogData, setDialogData] = useState({ data: '', lang: '' });
    const [dialogVisible, showDialog, hideDialog] = useVisibility();

    const showLogDetails = useCallback((data?: string, lang?: string) => {
        lang = lang ?? 'json';

        if (lang === 'json') {
            try {
                data = JSON.stringify(JSON.parse(data ?? '{}'), undefined, 4);
            } catch {
                data = data ?? '';
            }
        } else {
            data = data ?? '';
        }

        setDialogData({ data, lang });
        showDialog();
    }, [showDialog]);

    return <div className="sync-protocols-list">
        <NaVaForm
            initialValues={{}}
            onSubmit={() => { }}
        >
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>

            <div className="row my-3">
                <Input name="workItemId" type="number" sm={6} md={4} lg={3} placeholder="Work Item ID" />
            </div>
        </NaVaForm>

        {!isLoading && protocols?.length ? <table className="table table-striped table-bordered table-no-outer-border">
            <thead>
                <tr>
                    <th className="text-center">Success</th>
                    <th className="text-center">Work Item id</th>
                    <th>Request Time</th>
                    <th>Request Message</th>
                    <th>Response Time</th>
                    <th>Response Message</th>
                    <th>User Friendly Response</th>
                    <th>Comment</th>
                    <th className="text-center">State</th>
                </tr>
            </thead>
            <tbody>
                {protocols.map(p => {
                    return <tr key={p.id}>
                        <td className="text-center align-middle">{p.exchangeState === 'Closed' ? <Icon name="icon-checked" color="var(--bs-success)" size="1.5rem" /> : <Icon name="icon-cancel" color="var(--bs-danger)" size="1.5rem" />}</td>
                        <td className="text-center align-middle">{p.externalReference}</td>
                        <td className="align-middle">
                            {DateTime.fromISO(p.requestStartDate as any ?? "").toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}&nbsp;
                            ({DateTime.fromISO(p.requestStartDate as any ?? "").toRelative()})</td>
                        <td className="align-middle">
                            <div className="d-flex align-items-center">
                                <div className="text-ellipsis truncate-json">
                                    {p.requestMessage}
                                </div>
                                <Button className="ms-2" color="light" label="" onClick={() => showLogDetails(p.requestMessage)}>
                                    <Icon name="icon-file-preview" size="1rem" />&nbsp;
                                    {L('Show')}
                                </Button>
                            </div>
                        </td>
                        <td className="align-middle">{DateTime.fromISO(p.requestEndDate as any ?? "").toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</td>
                        <td className="align-middle">
                            <div className="d-flex align-items-center">
                                <div className="text-ellipsis truncate-json">
                                    {p.responseMessage}
                                </div>
                                <Button className="ms-2" color="light" label="" onClick={() => showLogDetails(p.responseMessage)}>
                                    <Icon name="icon-file-preview" size="1rem" />&nbsp;
                                    {L('Show')}
                                </Button>
                            </div>
                        </td>
                        <td className="align-middle">{p.userFriendlyResponseMessage}</td>
                        <td className="align-middle">{p.comment}</td>
                        <td className="text-center align-middle">{p.exchangeState}</td>
                    </tr>
                })}
            </tbody>
        </table> :
            <span className="text-muted">Keine Protokolle vorhanden</span>
        }
        {isLoading && <LoadingIndicator />}
        {dialogVisible && <Dialog title={L('Details')} onClose={hideDialog}>
            <CodeView {...dialogData} />
        </Dialog>}
    </div>
}



export default SyncProtocolsList;
