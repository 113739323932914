import _, { update } from 'lodash';
import { DateTime, Duration } from 'luxon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Icon from '../../../../common/components/icon/Icon';
import { ContextMenu } from 'primereact/contextmenu';
import { TimeEntity } from '../../../../client/http';
import ModuleComponentProvider from '../../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../../../modules/AppParts';
import { TimeEntityEntityDetailsProps } from '../../../../module-components/time-tracking/time-entities/TimeEntityComponentProps';
import { MenuItem } from 'primereact/menuitem';
import useMethodContext from '../../../../module-components/method-context/useMethodContext';
import IconButton, { PlaceholderGroup } from '../../../../common/components/icon/IconButton';
import { L } from '../../../../abp/utils';

import './EntitiesList.scss';
import { Settings_TimeBasedAmountUnits } from '../../../../util/SettingNames';
import { removeItemAll } from '../../../../util/EntityUtils';
import DataViewMessage, { DataViewLoading, DataViewMessageProps } from '../../../../common/components/data-view-message/DataViewMessage';
import { log } from '../../../../util/LoggingUtils';

export const mapTimeEntitiesForEntitiesList = (s: TimeEntity) => {
    //@ts-ignore
    const startTime = DateTime.fromISO(s.startTime).toJSDate();
    //@ts-ignore
    const endTime = DateTime.fromISO(s.endTime).toJSDate();

    const difference = startTime && endTime && DateTime.fromJSDate(endTime).diff(DateTime.fromJSDate(startTime)).shiftTo('days', 'hours', 'minutes').toObject();
    const duration = (`${s.duration}` !== 'PT0S' || difference === undefined) ? Duration.fromISO(`${s.duration!}`).shiftTo('days', 'hours', 'minutes').toObject()
        : { days: difference.days, hours: difference.hours, minutes: difference.minutes }

    return {
        ...s,
        startTime,
        endTime,
        duration,
        //@ts-ignore
        planningDate: s.startTime && DateTime.fromISO(s.startTime).set({ hour: 0, minute: 0, second: 0 })
    }
}

const Timeline: React.FC = () => {

    const data = new Array(25)
        .fill(null)
        .map((item, index) => index);

    const captions = data.map((item) => {
        return (
            <span key={item}>{(item !== 0 && item !== data.length - 1) && `${item}:00`}</span>
        );
    });

    const markers = data.map((item) => {
        return (
            <span key={item} className="marker">
                <span className="caption" key={item}>{(item !== 0 && item !== data.length - 1) && `${item.toString().padStart(2, "0")}:00`}</span>
            </span>
        );
    });


    return <div className="timeline">
        <div className="markers">
            {markers}
        </div>
        {/* <div className="captions">
            {captions}
        </div> */}
    </div>
}

const DayContainer: React.FC<{
    date: string, items: TimeEntity[], initialExpanded: boolean, showDetails: boolean, contextMenuEnabled?: boolean,
    onEntitySelected?: (entity: TimeEntity) => void, onShowDetails?: () => void, onExpandedChanged?: (date: string, isExpanded: boolean) => void, onExpandAll?: () => void
}> =
    ({ date, items, initialExpanded, showDetails, contextMenuEnabled, onEntitySelected, onShowDetails, onExpandedChanged, onExpandAll }) => {

        const day = useMemo(() => DateTime.fromISO(date), [date]);
        const [isExpanded, setIsExpanded] = useState(initialExpanded);
        useEffect(() => setIsExpanded(initialExpanded), [initialExpanded]);
        useEffect(() => log("onexpanded changed", day.toISO()), [day]);
        useEffect(() => onExpandedChanged && onExpandedChanged(date, isExpanded), [onExpandedChanged, date, isExpanded]);
        const cm = useRef<ContextMenu>(null);
        const { applyMethod } = useMethodContext();

        const [contextMenuItem, setContextMenuItem] = useState<TimeEntity | undefined>(undefined);
        const [contextMenuItems, setContextMenuItems] = useState<MenuItem[]>([]);
        const onContextMenu = useCallback((entity: TimeEntity, event: React.MouseEvent<Element, Event>) => {
            setContextMenuItem(entity);
            const val = applyMethod("createTimeEntitiesContextMenuItems", { entity, values: [] });
            log(val);
            setContextMenuItems(val.values);
            cm.current?.show(event);
        }, [cm, setContextMenuItem, setContextMenuItems, applyMethod]);

        const formatTime = useCallback((date: Date) => {
            return date.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit'
            });
        }, []);

        const isEmptyTime = useCallback((date: Date) => !date || (date.getHours() === 0 && date.getMinutes() === 0), []);

        const totalDuration = items.reduce((val, curr) => {
            const amountUnit = applyMethod("getEntityAmountUnit", curr) ?? '';
            const isTimeBasedEntity = JSON.parse(abp.setting.get(Settings_TimeBasedAmountUnits)).includes(amountUnit);
            if (!isTimeBasedEntity)
                return val;

            return val.plus(Duration.fromObject(curr.duration!));
        }, Duration.fromMillis(0)).shiftTo('days', 'hours', 'minutes');

        return <div className="day-container" key={day.toString()}>
            {contextMenuEnabled && <ContextMenu model={contextMenuItems} ref={cm} breakpoint="767px" />}
            <div className="title" onClick={() => { setIsExpanded(!isExpanded) }}>
                <div className="top">
                    {isExpanded ? <Icon name="icon-expand-arrow" size="2rem" /> : <Icon name="icon-forward" size="2rem" />}
                    <h4>
                        <div className="left">
                            {day.toLocaleString({ ...DateTime.DATETIME_MED_WITH_WEEKDAY, hour: undefined, minute: undefined })}
                        </div>
                        <div className="right">
                            <div className="actions-part">
                                <div className="actions">
                                    <PlaceholderGroup>
                                        <IconButton name="icon-information-2" title={L('ShowDetails')} onClick={(e) => { e.stopPropagation(); e.preventDefault(); onShowDetails && onShowDetails() }} size="1.5rem" color="var(--gray-600)" usePlaceholders={true} />
                                        <IconButton name="icon-generic-sorting" title={L('ExpandAll')} onClick={(e) => { e.stopPropagation(); e.preventDefault(); onExpandAll && onExpandAll() }} size="1.5rem" color="var(--gray-600)" usePlaceholders={true} />
                                    </PlaceholderGroup>
                                </div>
                                {/* <div className="action-placeholders">
                                    <div className="action-placeholder"></div>
                                    <div className="action-placeholder"></div>
                                </div> */}
                            </div>
                            <span>
                                {totalDuration.hours ? `${totalDuration.hours + (totalDuration.days ?? 0) * 24} Std. ` : ''}{`${totalDuration?.minutes ?? 0} Min.`}
                            </span>
                        </div>
                    </h4>
                </div>
                <div className="time-bars">
                    {items.filter(d => !isEmptyTime((d as any).startTime) && !isEmptyTime((d as any).endTime) && !d.useDuration).map((e, i) => {
                        return (<div className="time-bar" style={{
                            marginLeft: 100 / 24 * (e.startTime?.getHours() ?? 0) + 100 / 24 / 60 * (e.startTime?.getMinutes() ?? 0) + '%',
                            marginRight: 100 / 24 * (24 - (e.endTime?.getHours() ?? 0)) - (100 / 24 / 60 * ((e.endTime?.getMinutes() ?? 0))) + '%'
                        }} key={i}></div>)
                    })}
                    <Timeline></Timeline>
                </div>
            </div>
            {isExpanded && <div className="items-list">
                {items.sort((a, b) => (a.startTime?.getMilliseconds() ?? 0) - (b.startTime?.getMilliseconds() ?? 0)).map((e, i) => {
                    const amountUnit = applyMethod("getEntityAmountUnit", e) ?? '';
                    const isTimeBasedEntity = JSON.parse(abp.setting.get(Settings_TimeBasedAmountUnits)).includes(amountUnit);
                    // const duration = Duration.fromISOTime(e.duration as string);
                    const hourAmount = e.duration ? (e.duration.hours ?? 0) + (e.duration.days ?? 0) * 24 : 0;
                    return (<div className="time-entity" key={i} onClick={() => onEntitySelected && onEntitySelected(e)} onContextMenu={(event) => onContextMenu(e, event)}>
                        <div className="inner">

                            <div className="left">
                                <div className="data__description1">{e.description1 ? <>{e.description1}</> : ''}</div>
                                <div className="data__description2">{e.description2 ? e.description2 : ''}</div>
                            </div>
                            <div className="center">
                                {!e.isEditable ?
                                    <span className="icon__isEditable">
                                        <Icon name="icon-lock" size="1.5rem" className="mx-3" />
                                    </span>
                                    : <div className="mx-3" style={{ width: "1.5rem" }} />
                                }
                                <ModuleComponentProvider appPartName={AppParts.TimeTracking.TimeEntities.EntityDetails} props={{ entity: e } as TimeEntityEntityDetailsProps} getSingleComponent={false} />
                            </div>
                            <div className="right">
                                <span>
                                    {isTimeBasedEntity ?
                                        ((hourAmount > 0 ? `${hourAmount} Std. ` : '') + `${e.duration?.minutes ?? 0} Min.`)
                                        : `${(e as any).amount} ${amountUnit}`}
                                </span>
                                {!e.useDuration && <span>{(e.startTime && e.endTime) ? `${formatTime(e.startTime)} - ${formatTime(e.endTime)} ` : ''}</span>}
                            </div>
                        </div>
                        {showDetails && <div className="data">
                            <ModuleComponentProvider appPartName={AppParts.TimeTracking.TimeEntities.EntityData} props={{ entity: e } as TimeEntityEntityDetailsProps} getSingleComponent={false} />
                        </div>}
                    </div>)
                })}
            </div>}
        </div>
    }

export type EntitiesListProps = {
    entities: any;
    contextMenuEnabled?: boolean;
    onEntitySelected?: (entity: TimeEntity) => void;
    dataViewMessageData?: DataViewMessageProps;
}

const EntitiesList: React.FC<EntitiesListProps> = ({ entities, onEntitySelected, contextMenuEnabled, dataViewMessageData }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [expandAll, setExpandAll] = useState(false);
    const [expansionStates, setExpansionStates] = useState<Map<string, boolean>>(new Map());

    const setExpansionState = useCallback((date: string, isExpanded: boolean) => {
        setExpansionStates(m => {
            const states = new Map(m);
            states.set(date, isExpanded);
            return states;
        });
    }, []);

    const updateExpandAll = useCallback(() => {
        const value = !expandAll;
        setExpandAll(value);
        setExpansionStates(m => {
            const states = new Map(m);
            states.forEach((_, key) => {
                states.set(key, value);
            });
            return states;
        })
    }, [expandAll]);

    const getInitialExpanded = useCallback((date: string, index: number) => {
        if (expandAll) {
            return true;
        }
        const mapEntry = expansionStates.get(date);
        if (mapEntry) {
            return mapEntry;
        }
        return index === 0;
    }, [expandAll, expansionStates]);

    return <div className="entities-list">
        {dataViewMessageData && <DataViewMessage {...dataViewMessageData} loadingIndicatorType={expansionStates?.size > 0 ? 'progressBar' : 'spinner'} />}
        {(!dataViewMessageData || (dataViewMessageData.isLoading && expansionStates?.size > 0)) && Object.keys(entities).sort((a, b) => DateTime.fromISO(b).toMillis() - DateTime.fromISO(a).toMillis()).map((date: any, i) => {
            return <DayContainer date={date} initialExpanded={getInitialExpanded(date, i)} items={entities[date] as TimeEntity[]} key={date} showDetails={showDetails}
                onEntitySelected={e => onEntitySelected && onEntitySelected(e)} onShowDetails={() => setShowDetails(x => !x)} onExpandAll={updateExpandAll}
                contextMenuEnabled={contextMenuEnabled} onExpandedChanged={setExpansionState} />
        })
        }
    </div>
}

export default EntitiesList;