import React, { useEffect, useState } from 'react';
import { L } from '../../../abp/utils';
import { Category, CategoryApi } from '../../../client/http';
import ItemRenderComponent from '../../../common/components/item-render/ItemRenderComponent';
import ApiComboBox from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import { useNaVaFormContext } from '../../../common/forms/validation/na-va-form/NaVaFormContext';
import useApi from '../../../common/hooks/useApi';
import { FIELD_CATEGORY, FIELD_DESIGNATION, FIELD_JOB_TASK, FIELD_NAME } from '../../../data/field-constants/GeneralConstants';
import { CategorySelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import bcUtils from '../../../util/DynamicsBcUtils';
import { getExtensionValue } from '../../../util/EntityUtils';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';


const CategorySelection: React.FC<CategorySelectionProps> = ({ fetchFromAllProviders, selectedItem, itemRenderFields,
    name, title, placeholder, filterable, filterFields, dataItemKey, textField, itemRender, content, footer, ...props }) => {

    const [categories, api, dataViewMessage, fetch, totalCount] = useApi<Category, CategoryApi>(c => new CategoryApi(c),
        c => c.apiServicesAppCategoryGetAllGet(fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
            fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId)),
        c => ({ ...c, extensions: JSON.parse(c.extensionData ?? '{}') }));

    const { values, setFieldValue } = useNaVaFormContext();
    const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);

    const jobTask = values[FIELD_JOB_TASK];
    useEffect(() => {
        if (!jobTask) {
            setFilteredCategories(categories);
            setFieldValue(FIELD_CATEGORY, null);
        }

        const defaultWorkTypeFilter = getExtensionValue(jobTask, "defaultWorkTypeFilter");
        const defaultWorkType = getExtensionValue(jobTask, "defaultWorkTypeCode");

        if (!defaultWorkTypeFilter)
            setFilteredCategories(categories);
        else {
            const filteredCats = bcUtils.applyBcFilter(categories as any[], defaultWorkTypeFilter, "designation");
            if (filteredCats && filteredCats.length > 0) {
                setFilteredCategories(filteredCats);

                if (filteredCats.length === 1)
                    setFieldValue(FIELD_CATEGORY, filteredCats[0]);
            } else {
                setFilteredCategories(categories);
            }
        }

        if (defaultWorkType) {
            const defaultCat = defaultWorkType && categories.find(c => c.designation === defaultWorkType);
            if (defaultCat)
                setFieldValue(FIELD_CATEGORY, defaultCat);
            else
                setFieldValue(FIELD_CATEGORY, null);
        }

    }, [jobTask, categories, setFieldValue]);

    return <ApiComboBox apiResult={[filteredCategories, api, dataViewMessage, fetch, totalCount]} name={name} filterable={true} filterFields={filterFields ?? [FIELD_DESIGNATION, FIELD_NAME]}
        dataItemKey={dataItemKey ?? 'id'} textField={textField ?? FIELD_DESIGNATION} placeholder={placeholder ?? L('CategoryOrWorkType')} popupSettings={{ width: 'min(400px,50vw)' }}
        itemRender={(li, props) => <ItemRenderComponent li={li} itemProps={props} fields={itemRenderFields ?? [FIELD_DESIGNATION, FIELD_NAME]} />}
        content={content} footer={footer} {...props} />
}

export default CategorySelection;