import React, { useState, useCallback } from 'react';
import { TimeEntity } from '../../../client/http';
import TimeEntityInput from '../../time-tracking/time-entities/time-entity-input/TimeEntityInput';

const PlanningEntities: React.FC = () => {

    const [selectedEntity, setSelectedEntity] = useState<TimeEntity | undefined>(undefined);
    const refreshEntities = useCallback(() => { }, []);
    const deleteEntity = useCallback(() => { }, []);
    const createNewFromSelected = useCallback(() => { }, []);

    return (<div className="planning-entities">


        <TimeEntityInput isEditor={true} isPlanningEntity={true} selectedEntity={selectedEntity} onReset={() => setSelectedEntity(undefined)} initialValue={selectedEntity}
            onSubmitted={() => refreshEntities()} onDelete={() => deleteEntity()} onCreateNewFromSelected={() => createNewFromSelected()} />
    </div>)
}

export default PlanningEntities