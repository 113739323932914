import * as React from 'react';
import ApiComboBox, { ApiComboBoxProps } from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import { ServiceEntitySelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import { JobTypeApi, JobTypeDto } from '../../../client/http';
import useApi from '../../../common/hooks/useApi';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';
import { FIELD_DESCRIPTION1, FIELD_DESCRIPTION2, FIELD_DESIGNATION, FIELD_ID } from '../../../data/field-constants/GeneralConstants';
import { L } from '../../../abp/utils';
import { colRo } from '../data/columnConfigurations';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { getExtensionValue } from '../../../util/EntityUtils';
import { CellRender } from '../template-management/plm-job-list/components/PlmControlComponents';

export type JobTypeSelectionProps = ApiComboBoxProps & ServiceEntitySelectionProps<JobTypeDto> & {
    jobTypeFilters?: any[];
    jobId?: string;
}

const templatedJobTypeItemRenderColumns = [
    colRo(FIELD_DESIGNATION, undefined, undefined, undefined, 'data'),  //code "",
    colRo(FIELD_DESCRIPTION1, undefined, undefined, undefined, 'data'),  //displayName "",
    colRo(FIELD_DESCRIPTION2, undefined, undefined, undefined, 'data'),
]

export const JobTypeItemRender = (li: any, itemProps: any) => {
    var parsed = itemProps.dataItem;
    parsed.extensions = JSON.parse(parsed.extensionData ?? '{}');

    const itemChildren = (
        <div className={"combo-box-item"}>
            <span className="designation">{getIn(parsed, FIELD_DESIGNATION)}</span>
            <span className="billToName">{getIn(parsed, FIELD_DESCRIPTION1)}</span>
            <span className="searchDescription">{getIn(parsed, FIELD_DESCRIPTION2)}</span>        
        </div >
    );
    return React.cloneElement(li, li.props, itemChildren);
};

export const TemplatedJobTypeItemRender = (li: any, itemProps: any) => {
    var item = itemProps.dataItem;

    const itemChildren = (
        <div className={"combo-box-item"}>
            {templatedJobTypeItemRenderColumns.map(c => {
                return <CellRender columnConfig={c} value={c.isExtensionField ? getExtensionValue(item, c?.name) : getIn(item, c?.name)}
                    onChange={() => { }} isEditMode={false} />
            })}
        </div >
    );
    return React.cloneElement(li, li.props, itemChildren);
};


const JobTypeSelection: React.FC<JobTypeSelectionProps> = ({ fetchFromAllProviders,
    jobTypeFilters, selectedItem, data, name, title, placeholder, filterable,
    filterFields, dataItemKey, textField, itemRender, content, footer, ...props }) => {

    const apiResult = useApi<JobTypeDto, JobTypeApi>(c => new JobTypeApi(c), c => c.apiServicesModulesSdtPlmJobTypeFindByJobGet(props.jobId, fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),
        fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId), JSON.stringify(jobTypeFilters)));

    return <ApiComboBox apiResult={apiResult} title={title} name={name}
        filterable={filterable ?? true} filterFields={filterFields ?? [FIELD_DESIGNATION, FIELD_DESCRIPTION1, FIELD_DESCRIPTION2]}
        dataItemKey={dataItemKey ?? FIELD_ID} textField={textField ?? FIELD_ID} itemRender={itemRender}
        placeholder={placeholder ?? L('JobType')} footer={footer}
        content={content} {...props} />
}

export default JobTypeSelection;