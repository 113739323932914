import { getValue } from "@testing-library/user-event/dist/utils";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { hoursAmountFromDuration, hoursAmountFromTimes, hoursAmountToDuration, timeFromDateTime, timeToDateTime } from "../../../../../util/DateTimeUtils";
import { Settings_ResourceId } from "../../../../../util/SettingNames";
import { useNaVaFormContext } from "../../../../../common/forms/validation/na-va-form/NaVaFormContext";
import useComputation from "../../../../../common/forms/validation/na-va-form/useComputation";
import { FIELD_AMOUNT, FIELD_DATE_FROM, FIELD_DATE_TO } from "../TimeEntityInput";
import { log } from "../../../../../util/LoggingUtils";


export default function useTimeEntityInputInnerComputations(): void {
    const { touched, values, setFieldValue, errors } = useNaVaFormContext();

    // Amount <-> Start- / End-Time

    useComputation({
        dependency: FIELD_AMOUNT,
        target: FIELD_DATE_TO,
        // Only compute if amount is valid (not > 24 for example)
        computation: (amount, getValue) => !Object.keys(errors).find(k => k === FIELD_AMOUNT) ?
            timeFromDateTime(timeToDateTime(getValue(FIELD_DATE_FROM)).plus(hoursAmountToDuration(amount))) : getValue(FIELD_DATE_TO),
        condition: (amount, getValue) =>
            !(getValue(FIELD_DATE_TO) as DateTime).equals(timeToDateTime(getValue(FIELD_DATE_FROM)).plus(hoursAmountToDuration(amount)))
    });

    useComputation({
        dependency: FIELD_DATE_FROM,
        target: FIELD_DATE_TO,
        computation: (dateFrom, getValue) =>
            timeFromDateTime(timeToDateTime(dateFrom).plus(hoursAmountToDuration(getValue(FIELD_AMOUNT)))),
        condition: (_, getValue) => hoursAmountToDuration(getValue(FIELD_AMOUNT)).as("hours") > 0
    });

    useComputation({
        dependency: FIELD_DATE_TO,
        target: FIELD_AMOUNT,
        computation: (dateTo, getValue) => {
            log(getValue(FIELD_AMOUNT), hoursAmountFromTimes(getValue(FIELD_DATE_FROM), dateTo));
            return hoursAmountFromTimes(getValue(FIELD_DATE_FROM), dateTo);
        },
        condition: (dateTo, getValue) => getValue(FIELD_AMOUNT) !== hoursAmountFromTimes(getValue(FIELD_DATE_FROM), dateTo)
    });
}
