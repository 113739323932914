import React, { useCallback, useEffect, useState } from 'react';
import ModuleComponentProvider from '../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../modules/AppParts';
import NaVaForm from '../../common/forms/validation/na-va-form/NaVaForm';
import Input from '../../common/forms/validation/controls/input/Input';
import { L } from '../../abp/utils';
import AppVersion from '../../common/components/app-version/AppVersion';
import { NaVaFormContextType, NaVaFormValues } from '../../common/forms/validation/na-va-form/types';
import { getIn } from '../../common/forms/validation/na-va-form/commonUtils';
import axios from 'axios';
import { ResetPasswordInput, ResetPasswordOutput, ResolveTenantIdInput } from '../../client/http';
import LoadingIndicator from '../../common/components/loading-indicator/LoadingIndicator';
import Button from '../../common/forms/controls/button/Button';
import "./Login.scss";
import constants from '../../abp/constants';
import { AbpListData, AbpResponse, AbpTenant, DEFAULT_TENANT } from './Login';
import Dropdown from '../../common/forms/validation/controls/dropdown/Dropdown';
import { useParams, useSearchParams } from 'react-router-dom';
import NaVaFormStatePuller from '../../common/forms/NaVaFormStatePuller';
import PasswordComplexity from '../../common/components/password-complexity/PasswordComplexity';
import { getTenantUserConfiguration } from '../../abp/getUserConfiguration';
import _ from 'lodash';

const ResetPassword: React.FC = () => {
    const getTenantsEndpointUrl = constants.remoteServiceBaseUrl!.replace(/\/$/, '') + '/api/services/app/Tenant/GetTenants?MaxResultCount=100';
    const sendResetEndpointUrl = constants.remoteServiceBaseUrl!.replace(/\/$/, '') + '/api/services/app/Account/SendPasswordResetCode';
    const resolveTenantIdEndpointUrl = constants.remoteServiceBaseUrl!.replace(/\/$/, '') + '/api/services/app/Account/ResolveTenantId';
    const resetPasswordEndpointUrl = constants.remoteServiceBaseUrl!.replace(/\/$/, '') + '/api/services/app/Account/ResetPassword';

    const [tenants, setTenants] = useState<{ caption: string; value: number | null }[]>([]);
    const [isRequest, setIsRequest] = useState(true);

    const [searchParams] = useSearchParams();
    const c = searchParams.get('c');
    const tenantId = searchParams.get('tenantId');
    const userId = searchParams.get('userId');
    const resetCode = searchParams.get('resetCode');

    const [{ values, setFieldValue, resetForm }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);
    
    useEffect(() => {
        if (c || (userId && resetCode)) setIsRequest(false);
        else setIsRequest(true);

        if (!setFieldValue) return;

        if (c) {
            setFieldValue('c', c);
            axios.post<AbpResponse<number | undefined>>(resolveTenantIdEndpointUrl, { c } as ResolveTenantIdInput).then(r => setFieldValue('tenantId', r.data.result));
        }
        else {
            // setFieldValue('resetCode', resetCode);
            // setFieldValue('userId', parseInt(userId ?? '0'));
            // setFieldValue('tenantId', parseInt(tenantId ?? '0'));
        }

    }, [setIsRequest, c, tenantId, userId, resetCode, setFieldValue, resolveTenantIdEndpointUrl]);


    useEffect(() => {
        isRequest && axios.get<AbpResponse<AbpListData<AbpTenant>>>(getTenantsEndpointUrl)
            .then(result => {
                const tenants = [
                    // {
                    //   caption: 'Ohne Mandant',
                    //   value: null
                    // },
                    ...result.data.result.items.filter(t => t.tenancyName.toLowerCase() !== 'default').map(t => ({ caption: t.name, value: t.id }))
                ];
                setTenants(tenants);
            })
            .catch(() => { });
    }, [getTenantsEndpointUrl]);

    const [isLoading, setIsLoading] = useState(false);
    const [resultMessage, setResultMessage] = useState<string>();
    const [successful, setSuccessful] = useState<boolean | undefined>();
    const submit = useCallback((v: NaVaFormValues) => {
        const requestPayload = {
            emailAddress: v.emailAddress,
        };
        const headers = v.tenant.value
            ? {
                'Abp.TenantId': v.tenant.value
            }
            : undefined;
        setIsLoading(true);
        setResultMessage(undefined);
        setSuccessful(undefined);
        axios.post<AbpResponse<ResetPasswordOutput>>(sendResetEndpointUrl, requestPayload, {
            headers
        }).then(
            response => {
                if (!response.data.success) {
                    throw response.data.error;
                }
                setIsLoading(false);
                setSuccessful(true);
                setResultMessage(L('ResetMailSuccessfullySent'))
            }
        ).catch(
            err => {
                setIsLoading(false);
                setSuccessful(false);
                setResultMessage(L('ErrorSendingResetMail'));
            }
        );

    }, [sendResetEndpointUrl]);

    const resetPassword = useCallback(() => {        
        const payload = values as ResetPasswordInput;
        const tenantId = getIn(values, 'tenantId');
        const headers = tenantId
            ? {
                'Abp.TenantId': tenantId
            }
            : undefined;

        setIsLoading(true);
        setResultMessage(undefined);
        setSuccessful(undefined);

        axios.post<AbpResponse<ResetPasswordOutput>>(resetPasswordEndpointUrl, payload, {
            headers
        }).then(
            response => {
                if (!response.data.success) {
                    throw response.data.error;
                }
                setIsLoading(false);
                setSuccessful(true);
                setResultMessage(L('PasswordSuccessfullyReset'));
            }).catch(
                err => {
                    setIsLoading(false);
                    setSuccessful(false);
                    setResultMessage(L('ErrorResettingPassword'));
                }
            );

    }, [values, resetPasswordEndpointUrl]);

    const getDefaultTenant = useCallback(() => {
        if (!tenants || tenants.length < 1)
            return '';

        const defaultTenant = localStorage.getItem(DEFAULT_TENANT);
        const foundTenant = tenants.find(i => i.value?.toString() === defaultTenant);

        if (defaultTenant && foundTenant)
            return foundTenant;

        return tenants[0];
    }, [tenants]);

    const [, rerender] = useState({});

    const updateConfig = useCallback((tenantId: number) => {
      getTenantUserConfiguration(tenantId).then(result => {
        _.merge(abp, result.data.result);
        rerender({});
      });
    }, []);

    return <div className="p-4">
        <div className="row align-items-center">
            <div className="col-12 col-md-5 p-4 d-flex align-items-center">
                <ModuleComponentProvider appPartName={AppParts.Application.LoginLogo} getSingleComponent={true} />
            </div>
            <div className="col-12 border-sm-left col-md-6 p-4">
                {isRequest ? <>
                    <h1 className="pt-0 pb-2">
                        <span>{L('ForgotPassword')}</span>
                    </h1>
                    <NaVaForm initialValues={{ emailAddress: '', tenant: getDefaultTenant() }} onSubmit={submit} enableReinitialize={true}
                        validationConfig={{
                            tenant: [
                                {
                                    validate: (obj: any) => !!obj.tenant,
                                    message: L('PleaseProvideTenantName')
                                }
                            ],
                            username: [
                                {
                                    validate: (obj: any) => !!obj.emailAddress,
                                    message: L('PleaseProvideUsername')
                                }
                            ]
                        }}>
                        {isLoading ?
                            <LoadingIndicator /> :
                            <>
                                <div className="form-row">
                                    <Dropdown name="tenant" data={tenants} disabled={!tenants} textField="caption" dataItemKey="value" md={12} mb={2} onChange={(e) => e.target.value.value && updateConfig(e.target.value.value)}/>
                                    <Input type="email" name="emailAddress" placeholder={L('Email')} md={12} mb={2} id="input-email" />
                                    <div className="subtitle">Sie erhalten einen Link zum Zurücksetzen Ihres  Passworts per Mail</div>
                                    <Button type="submit" color="primary" label={L('Continue')} md={12} className="submit-btn" />
                                </div>
                                {
                                    resultMessage &&
                                    <div className={`${successful ? 'bg-success' : 'bg-danger'} mt-3 p-4 shadow text-light login-message`}>
                                        {resultMessage}
                                    </div>
                                }
                            </>
                        }
                    </NaVaForm>
                </> :
                    <>
                        <h1 className="pt-0 pb-2">
                            <span>{L('ResetPassword')}</span>
                        </h1>
                        <NaVaForm initialValues={{ tenantId: '', c: '' }} onSubmit={resetPassword}
                            validationConfig={{
                                password: [
                                    {
                                        validate: (obj: any) => !!obj.password,
                                        message: L('PleaseProvidePassword')
                                    }
                                ],
                                repeatPassword: [
                                    {
                                        validate: (obj: any) => obj.repeatPassword === obj.password,
                                        message: L('ValueMustMatchPassword')
                                    }
                                ]
                            }}>
                            {isLoading ?
                                <LoadingIndicator /> :
                                <>
                                    <div className="form-row">
                                        <NaVaFormStatePuller onStateChanged={s => setFormState(s)} />
                                        <Input type="password" name="password" placeholder={L('Password')} md={12} mb={2} />
                                        <Input type="password" name="repeatPassword" placeholder={L('RepeatPassword')} md={12} mb={2} />
                                        <PasswordComplexity password={getIn(values, 'password')} />
                                        <Button type="submit" color="primary" label={L('Reset')} md={12} className="submit-btn" />
                                    </div>
                                    {
                                        resultMessage &&
                                        <div className={`${successful ? 'bg-success' : 'bg-danger'} mt-3 p-4 shadow text-light login-message`}>
                                            {resultMessage}
                                        </div>
                                    }
                                </>
                            }
                        </NaVaForm>
                    </>
                }
            </div>
        </div>
        <AppVersion />
    </div>
}

export default ResetPassword;