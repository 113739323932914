import constants from "../abp/constants";

const isLoggingEnabled = () => {
    const overrideLogging = localStorage.getItem('enable-logging');
        
    return !((!overrideLogging || overrideLogging !== 'K4DykfJfm06HqteewXWc') && !constants.log);
}

export const log = isLoggingEnabled() ? console.log.bind(console) : (...data: any) => { };

export const warn = isLoggingEnabled() ? console.warn.bind(console) : (...data: any) => { };

export const error = isLoggingEnabled() ? console.error.bind(console) : (...data: any) => { };