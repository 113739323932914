import { DateTime } from "luxon";

// Pattern describing valid inputs for the DateControl
// Periods may be omitted
// Examples (Assuming a current date of 01.01.2020):
// 15 => 15.01.2020
// 3003 => 30.03.2020
// 300329 => 30.03.2029
// 300330 => 30.03.1930
// 30032019 => 30.03.3019
const dateFormulaPattern = /^(\d{2})\.?(?:(\d{2})\.?(\d{2}|\d{4})?)?$/;

function parseDateFormula(str: string): Date | null {
  if (str === 'h') return DateTime.now().toJSDate();
  if (str === 'g') return DateTime.now().minus({ day: 1 }).toJSDate();

  let match: RegExpMatchArray | null;
  if ((match = dateFormulaPattern.exec(str)) && match.length > 1) {
    const today = new Date();
    let [day, month, year] = match.slice(1, 4).map(i => parseInt(i, 10));
    if (day === 0 || month === 0 || year === 0) return null;

    if (!year) year = today.getFullYear();
    else if (year < 100) year += (year < 30) ? 2000 : 1900;

    if (!month) month = today.getMonth();
    else month--;

    // return new Date(year, month, day); // returns for example 01-06-2021T00:00:00 GMT+02:00 (00:00)
    return new Date(Date.UTC(year, month, day));  // returns for example 01-06-2021T02:00:00 GMT+02:00 (02:00) -> converts to 00:00 in UTC
  }
  return null;
}

export default parseDateFormula;
