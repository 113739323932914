import React, { useCallback, useMemo, useState } from 'react';
import Error from '../../../scenes/error/Error';
import ModuleComponentProvider from '../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../AppParts';
import TemplateDataGridCell from '../../../common/components/api-grid/TemplateDataGridCell';
import TemplateInputGridCell from '../../../common/components/api-grid/TemplateInputGridCell';
import { getExtensionValue } from '../../../util/EntityUtils';
import { JobGridProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import filter from '../../../util/FilterUtils';
import PlmJobList from './plm-job-list/PlmControl';
import { defaultColumnConfig } from '../data/columnConfigurations';
import Button from '../../../common/forms/controls/button/Button';
import { L } from '../../../abp/utils';
import useApi from '../../../common/hooks/useApi';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import Dropdown from '../../../common/forms/validation/controls/dropdown/Dropdown';
import { ServiceProviderItemRender } from '../../../scenes/protected-area/side-nav/service-provider-selection/ServiceProviderSelection';
import { SdtPlmDataExportApi, ServiceConnectionDto, ServiceProviderDto, ServiceProvidersApi } from '../../../client/http';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { Settings_ServiceConnectionId } from '../../../util/SettingNames';
import { FIELD_DESIGNATION, FIELD_ID } from '../../../data/field-constants/GeneralConstants';
import { showNotification } from '../../../common/components/notifications/NotificationHost';
import { log } from '../../../util/LoggingUtils';
import PlmControl from './plm-job-list/PlmControl';
import { PlmControlSection, defaultPlmControlState } from './plm-job-list/components/PlmControlComponents';
import Input from '../../../common/forms/validation/controls/input/Input';
import { useDebounce } from 'usehooks-ts';
import useLoadingState from '../../../common/hooks/useLoadingState';
import LoadingIndicator from '../../../common/components/loading-indicator/LoadingIndicator';

const FIELD_TARGET_SERVICE_CONNECTION = 'targetServiceConnection';

const ExportData: React.FC = () => {
    const [selectedJob, setSelectedJob] = useState();

    const handleJobSelectionChanged = useCallback((selection: { section: PlmControlSection, keys?: string[], items?: any[] }[]) => {
        const items = selection.filter(s => s.section === 'job')?.at(0)?.items ?? [];
        if (items.length > 0) {
            getExtensionValue(items[0], '')
            setSelectedJob(items[0]);
        }
    }, []);

    const [serviceProviders] = useApi<ServiceProviderDto, ServiceProvidersApi>(c => new ServiceProvidersApi(c), c => c.apiServicesAppServiceProvidersGetServiceProvidersGet());

    const serviceConnections = useMemo(() => (serviceProviders as any).reduce((prev: any, current: any) => {
        return current?.serviceConnections ? [...prev,
        ...current.serviceConnections.map((c: ServiceConnectionDto) => ({ ...current, serviceConnectionName: c.displayName, serviceConnectionId: c.id, serviceConnection: c, serviceConnections: undefined }))] : prev
    }, []), [serviceProviders]);

    const [{ values }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const targetServiceConnection = getIn(values, FIELD_TARGET_SERVICE_CONNECTION);

    const [startDataExport, exportComplete, exportFailed, isExporting] = useLoadingState();
    const [, exportApi] = useApi<any, SdtPlmDataExportApi>(c => new SdtPlmDataExportApi(c));

    const exportData = useCallback(async () => {
        if (exportApi && selectedJob && targetServiceConnection?.serviceConnectionId) {
            try {
                startDataExport();
                var result = await exportApi.apiServicesModulesSdtPlmSdtPlmDataExportExportJobPost(abp.setting.getInt(Settings_ServiceConnectionId), targetServiceConnection.serviceConnectionId, getIn(selectedJob, FIELD_ID));
                log("data export result: ", result);
                exportComplete();
                showNotification('Export erfolgreich', 'Datensatz erfolgreich exportiert', 'success', 5000, true);
            } catch (error) {
                var errorMsg = (await (error as any).json()).error.message;
                exportFailed('');
                showNotification('Export fehlgeschlagen', 'Fehler beim Export aufgetreten: ' + errorMsg, 'error', undefined, true);
            }

        }

    }, [selectedJob, exportApi, targetServiceConnection]);

    const designation = getIn(values, FIELD_DESIGNATION);
    const debouncedDesignation = useDebounce<string>(designation, 1500);

    const controlStateConfig = useMemo(() => {
        return {
            ...defaultPlmControlState,
            job: {
                ...defaultPlmControlState.job,
                filter: {
                    ...defaultPlmControlState.job.filter,
                    internalFilters: [...defaultPlmControlState.job.filter?.internalFilters ?? [], filter(FIELD_DESIGNATION).contains(debouncedDesignation)]
                }
            }
        }
    }, [debouncedDesignation]);

    return <div className="export-data my-3 px-4">

        <h5 className="mb-3">Bitte wählen Sie das Zielsystem für die Datenübertragung aus:</h5>

        <NaVaForm
            initialValues={{

            }}
            onSubmit={() => { }}
        >
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>

            <div className="row mx-0 my-2 align-items-center">
                <Dropdown name={FIELD_TARGET_SERVICE_CONNECTION} textField="serviceConnectionName" dataItemKey="serviceConnectionId" sm={12} lg={4} data={serviceConnections} itemRender={ServiceProviderItemRender} />
            </div>

            <div className="row mx-0 my-3 align-items-center">
                <Input name={FIELD_DESIGNATION} label={L('Designation')} sm={6} md={3} />
            </div>

        </NaVaForm>


        <h5 className="mb-3">Bitte wählen Sie das Projekt aus, das Sie exportieren möchten:</h5>

        <PlmControl columnConfig={defaultColumnConfig} controlStateConfig={controlStateConfig} showTemplates={false} onSelectionChanged={handleJobSelectionChanged} selectable constrainToList readOnly={true} />

        <div className="col-12 col-md-6 col-lg-4 offset-0 offset-md-3 offset-lg-4 mt-3">
            <LoadingIndicator loadingIndicatorType='progressBar' isHidden={!isExporting}/>
            <Button color="primary" label={L('Export')} disabled={!selectedJob || !targetServiceConnection || isExporting} onClick={exportData} mt={3} md={6} offsetMd={3} lg={4} offsetLg={4}/>
        </div>

        {/* <PlmJobList columnConfig={defaultColumnConfig} showTemplates={true} /> */}

    </div>
}

export default ExportData;