import React from 'react';
import { ReactComponent as Logo } from '../../appLogo.svg';
import Button from '../../common/forms/controls/button/Button';
import { ReactComponent as NoConnection } from './media/mirage-no-connection.svg'
import { ReactComponent as NotFound } from './media/mirage-page-not-found.svg'
import { ReactComponent as FatalError } from './media/mirage-fatal-error.svg'
import { ReactComponent as BadGateway } from './media/mirage-bad-gateway.svg'


import './Error.scss';
import { L } from '../../abp/utils';

export interface ErrorProps {
  message: string | React.ReactNode;
  isFullScreen?: boolean;
  type?: 'NoConnection' | 'NotFound' | 'FatalError' | 'BadGateway';
  onClickReloadLabel?: string;
  onClickReload?: () => void;
}

const Error: React.FC<ErrorProps> = ({ message, isFullScreen, type, onClickReloadLabel, onClickReload }) => {

  const Wrapper = (props: { children: React.ReactNode }) => {
    return isFullScreen ? <div className="app-error fullscreen row align-items-center vh-100 vw-100">
      <div className="offset-3 col-6">
        {props.children}
      </div>
    </div> :
      <div className="app-error">
        {props.children}
      </div>
  }

  const getImage = () => {
    switch (type) {
      case 'NoConnection':
        return <NoConnection />
      case 'NotFound':
        return <NotFound />
      case 'FatalError':
        return <FatalError />
      case 'BadGateway':
        return <BadGateway />
      default:
        return <Logo />
    }
  }

  return (<Wrapper>
    <div className={"p-4 inner" + (isFullScreen ? ' shadow' : '')}>
      <div className="row">
        <div className="col-8 offset-2 col-lg-4 offset-lg-4 p-4 img-wrapper">
          {getImage()}
        </div>
      </div>
      <div className="row">
        <div className="col-12 border-sm-left p-4">
          <h1 className={`pt-0 pb-2 mb-5 ${onClickReload && 'mb-1'}`}>{message}</h1>
          {onClickReload &&
            <Button label={onClickReloadLabel ?? (abp.localization.sources.length > 0 ? L('Retry') : "Erneut versuchen")} color="primary" onClick={onClickReload} />
          }
        </div>
      </div>
    </div>
  </Wrapper>
  )
};

export default Error;
