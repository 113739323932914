import React from 'react';
import PlmJobList from './PlmJobList';
import PlmControlContextProvider, { PlmControlContextProviderProps } from './plm-control-context/PlmControlContextProvider';
import './PlmControl.scss';
import withMethodContext from '../../../../module-components/method-context/withMethodContext';

export type PlmControlProps = PlmControlContextProviderProps & {
    type?: 'Job' | 'Item';
    constrainToList?: boolean;
};

const PlmControl: React.FC<React.PropsWithChildren<PlmControlProps>> = ({ type = 'Job', constrainToList = false, children, ...baseProps }) => {
    return (<PlmControlContextProvider {...baseProps}>
        {type === 'Job' ? <PlmJobList constrainToList={constrainToList}/> : null}
        {children}
    </PlmControlContextProvider>)
}

export default withMethodContext(PlmControl);