import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../../common/components/loading-indicator/LoadingIndicator';
import EntitiesList, { mapTimeEntitiesForEntitiesList } from '../time-tracking/time-entities/entities-list/EntitiesList';

const ReportDataView: React.FC<{ data?: any, type: string, isLoading?: boolean, customView?: (data: any | undefined) => React.ReactElement, onEntitySelected?: (item: any) => void }> = ({ data, type, isLoading, customView, onEntitySelected }) => {

    const [internalData, setInternalData] = useState<any>(undefined);

    useEffect(() => {
        if (!data)
            return;
            
        switch (type) {
            case "Orgavision.Rewind365.TimeManagement.TimeEntity":
                const mapped = data.map(mapTimeEntitiesForEntitiesList);
                setInternalData(_.groupBy(mapped as any[], 'planningDate') as any)
                break;
            default:
                setInternalData(data);
        }
    }, [type, data])

    if (isLoading)
        return <LoadingIndicator></LoadingIndicator>

    if (customView)
        return customView(data);

    switch (type) {
        case "Orgavision.Rewind365.TimeManagement.TimeEntity":            
            return internalData ? <EntitiesList entities={internalData} onEntitySelected={onEntitySelected} /> : <></>
        default:
            return <div>{JSON.stringify(data)}</div>
    }
}

export default ReportDataView;