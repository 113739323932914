import React from 'react';

export type MethodContextType = {
    addMethod: (name: string, module: string, replace: boolean, methodPart: (value: any) => any, key?: string) => void,
    applyMethod: (name: string, value: any, module?: string, ignoreModuleSettings?: boolean) => any,
    methods: MethodContextValues
}

export type MethodContextValues = { [name: string]: { module: string, key?: string, methodPart: (value: any) => any }[] }

const MethodContext = React.createContext({});

export default MethodContext;