import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthenticator } from '../../authentication/authenticator/Authenticator';

type ProtectedRouteProps = React.PropsWithChildren<{
  redirectTo: string;
}>;

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, redirectTo, ...props }) => {
//   const { isAuthenticated } = useAuthenticator(); 

//   return (
//     <Route {...props}>
//       {isAuthenticated
//         ? children
//         :
//         <Navigate to={redirectTo} />
//       }
//     </Route>
//   );
// };

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, redirectTo }) => {
  const { isCurrentlyAuthenticated, refreshToken, user } = useAuthenticator();
  const [isActuallyAuthenticated, setIsActuallyAuthenticated] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isCurrentlyAuthenticated()) {
      setIsActuallyAuthenticated(true);
    }
    else {
      if(user?.tokenInfo.refreshToken){
        refreshToken(abp.session.tenantId ?? null);        
      }
      else{
        setIsActuallyAuthenticated(false);
      }
    }
  }, [refreshToken, isCurrentlyAuthenticated, setIsActuallyAuthenticated]);

  if (isActuallyAuthenticated === undefined)
    return null;

  return <>
    {isActuallyAuthenticated ? children : <Navigate to={redirectTo} />}
  </>
}

export default ProtectedRoute;
