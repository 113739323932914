const noToInt = (no: string): number=> parseInt(no.replace(".", ""));

function applyBcFilter<T extends { [dummyKeyName: string]: string }>(values: T[] | undefined, filter: string | undefined, propertyName: string): (T[] | undefined) {
    if (!values || values.length === 0 || !filter)
        return values;

    // First handle or filters ('|')
    var filterParts = filter.split('|');

    var predicates: ((value: T) => boolean)[] = [];

    filterParts.forEach(part => {
        const isRange = part.includes('..');

        if (isRange) {
            const [start, end] = part.split('..');
            predicates.push((value: T) => noToInt(value[propertyName]) >= noToInt(start) && noToInt(value[propertyName]) <= noToInt(end));
        }
        else {
            predicates.push((value: T) => value[propertyName] === part);
        }
    });

    return values.reduce((results: T[], item) => {
        const keep = predicates.reduce((keepItem, predicate) => {
            return keepItem || predicate(item)
        }, false);
        return keep ? results.concat(item) : results;
    }, [])

}

// const testData = [
//     { prop: "1" },
//     { prop: "2" },
//     { prop: "3" },
//     { prop: "4" },
//     { prop: "5" },
//     { prop: "6" },
//     { prop: "7" },
//     { prop: "8" },
//     { prop: "9" },
//     { prop: "10" },
//     { prop: "11" },
//     { prop: "12" },
//     { prop: "13" },
//     { prop: "14" },
//     { prop: "15" },
// ]

// console.log(applyBcFilter(testData, "1|3|4|7..11|2", "prop"));

const bcUtils = { applyBcFilter };

export default bcUtils;