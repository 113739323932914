import React, { useCallback, useEffect } from 'react';
import NaVaFormStatePuller from '../../../common/forms/NaVaFormStatePuller';
import { useState } from 'react';
import { NaVaFormContextType, NaVaFormValues } from '../../../common/forms/validation/na-va-form/types';
import NaVaForm from '../../../common/forms/validation/na-va-form/NaVaForm';
import Input from '../../../common/forms/validation/controls/input/Input';
import ModuleComponentProvider from '../../../module-components/module-context/ModuleComponentProvider';
import { AppParts } from '../../AppParts';
import { FIELD_ID, FIELD_RESOURCE, FIELD_RESOURCE_ID } from '../../../data/field-constants/GeneralConstants';
import { ResourceSelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import Button from '../../../common/forms/controls/button/Button';
import { L } from '../../../abp/utils';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { Resource, ResourceApi, WorkItemSyncApi } from '../../../client/http';
import useApi from '../../../common/hooks/useApi';
import { useNaVaFormContext } from '../../../common/forms/validation/na-va-form/NaVaFormContext';
import { FIELD_MAP_ITEMS_SOURCE, FIELD_MAP_ITEMS_TARGET } from './DevOpsAdministration';
import IconButton from '../../../common/components/icon/IconButton';
import ExpansionPanel from '../../../common/components/expansion-panel/ExpansionPanel';
import { stat } from 'fs/promises';
import ComboBox from '../../../common/forms/validation/controls/combo-box/ComboBox';
import { log } from '../../../util/LoggingUtils';

const devOpsStates = ["New", "Active", "Closed", "Removed", "Resolved"]

const StateMappingEditor: React.FC<{
    onAddMapping?: (mapping: any) => void,
    onCancel?: () => void,
}> = ({ onAddMapping, onCancel }) => {

    const { values: administrationValues } = useNaVaFormContext();

    const sourceConnection = getIn(administrationValues, FIELD_MAP_ITEMS_SOURCE);
    const targetConnection = getIn(administrationValues, FIELD_MAP_ITEMS_TARGET);
    const mapFromConnectionId = sourceConnection.serviceConnectionId;
    const mapToConnectionId = targetConnection.serviceConnectionId;

    const [stateMappings, setStateMappings] = useState<any[] | null>(null);

    const [states, workItemSyncApi,] = useApi<any, WorkItemSyncApi>(c => new WorkItemSyncApi(c), c => c.apiServicesDevOpsWorkItemSyncGetJobTaskStatesGet());
    const [{ values, isValid, submitForm, resetForm }, setFormState] = useState<NaVaFormContextType<NaVaFormValues>>({} as any);

    const loadStateMappings = useCallback(() => {

        workItemSyncApi?.apiServicesDevOpsWorkItemSyncGetWorkItemStateMappingsGet(mapFromConnectionId, mapToConnectionId)
            .then((d) => {
                setStateMappings((d as any).result)
            })
            .catch((e) => log(e));

    }, [mapFromConnectionId, mapToConnectionId, workItemSyncApi]);

    const addStateMapping = useCallback((devOpsState: string, taskState: string) => {

        workItemSyncApi?.apiServicesDevOpsWorkItemSyncCreateOrUpdateWorkItemStateMappingPost(devOpsState, taskState, mapFromConnectionId, mapToConnectionId)
            .then((d) => {
                loadStateMappings();
            })
            .catch((e) => log(e));

    }, [mapFromConnectionId, mapToConnectionId, workItemSyncApi, loadStateMappings]);

    const removeMapping = (id: number) => {

        workItemSyncApi?.apiServicesDevOpsWorkItemSyncDeleteMappingDelete(id)
            .then((d) => {
                loadStateMappings();
            })
            .catch((e) => log(e));

    };

    useEffect(() => {
        loadStateMappings();
    }, [loadStateMappings]);

    return <div className="state-mapping-editor">

        <ExpansionPanel title={
            <h4>Status-Zuordnungen
                <span className="badge rounded-pill text-bg-secondary ms-3">
                    {stateMappings?.length} Elemente
                </span>
            </h4>}>
            {stateMappings?.length ?
                <table className="table table-striped table-bordered table-no-outer-border">
                    <thead>
                        <tr>
                            <th className="align-middle text-center">Azure DevOps Status</th>
                            <th className="align-middle text-center">Zugeordneter Aufgabenstatus</th>
                            <th className="align-middle text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stateMappings.map((m: any) => {
                            return <tr key={m.type}>
                                <td className="align-middle">{m.sourceValue}</td>
                                <td className="align-middle">{m.targetValue}</td>
                                <td className="align-middle text-center">
                                    <IconButton name="icon-trash" size="1rem" onClick={() => removeMapping(m.id)} />
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                : <span className="text-muted">Keine Zuordnungen eingerichtet</span>}
        </ExpansionPanel>

        <h3>Neue Zuordnung erstellen</h3>

        <NaVaForm
            initialValues={{
                devOpsState: undefined,
                taskState: undefined
            }}
            onSubmit={() => {
                onAddMapping && onAddMapping({
                    devOpsState: getIn(values, 'devOpsState'),
                    taskState: getIn(values, 'taskState')
                });
                addStateMapping(getIn(values, 'devOpsState'), getIn(values, 'taskState'));
                resetForm();
            }}
            validationConfig={{
                'devOpsState': [
                    {
                        validate: (obj: any) => getIn(obj, 'devOpsState'),
                        message: L('ValidationFieldMustNotBeEmpty')
                    }
                ],
                'taskState': [
                    {
                        validate: (obj: any) => !!getIn(obj, 'taskState'),
                        message: L('ValidationFieldMustNotBeEmpty')
                    }
                ]
            }}
            validateOnMount={true}
        >
            <NaVaFormStatePuller onStateChanged={v => setFormState(v)}></NaVaFormStatePuller>

            <div className="row">
                <ComboBox name="devOpsState" xs={6} lg={4} data={devOpsStates} />
                <ComboBox name="taskState" xs={6} lg={4} data={states} />
                <Button color="primary" label={L('Add')} xs={4} md={2} disabled={!isValid} onClick={() => submitForm && submitForm()} />
            </div>

        </NaVaForm>
    </div >
}

export default StateMappingEditor;