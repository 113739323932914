import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo } from 'react';
import { L } from '../../../abp/utils';
import { Configuration, JobTask, JobTaskApi } from '../../../client/http';
import ApiComboBox from '../../../common/forms/validation/controls/api-combo-box/ApiComboBox';
import { getIn } from '../../../common/forms/validation/na-va-form/commonUtils';
import { useNaVaFormContext } from '../../../common/forms/validation/na-va-form/NaVaFormContext';
import { useReactiveApi } from '../../../common/hooks/useApi';
import useSimplePromise from '../../../common/hooks/useSimplePromise';
import { FIELD_DESCRIPTION1, FIELD_DESIGNATION, FIELD_JOB_TASK } from '../../../data/field-constants/GeneralConstants';
import { JobTaskSelectionProps } from '../../../module-components/service-entities/ServiceEntityControlProps';
import { getExtensionValue } from '../../../util/EntityUtils';
import filter from '../../../util/FilterUtils';
import { Settings_ProviderName, Settings_ServiceConnectionId } from '../../../util/SettingNames';
import JobTaskDetails from './detail-views/JobTaskDetails';
import JobTaskItem from './item-views/JobTaskItem';
import JobTaskTooltip from './tooltips/JobTaskTooltip';

const JobTaskSelection: React.FC<JobTaskSelectionProps> = ({ fetchFromAllProviders, jobTaskFilters, selectedItem, userResourceId, fieldJobName,
    name, title, placeholder, filterable, filterFields, dataItemKey, textField, itemRender, content, footer, disabled, ...props }) => {
    const { values, setFieldValue } = useNaVaFormContext();

    const defaultJtFilter = useMemo(() => [filter("hide").equals(false)], []);
    const jtFilters = jobTaskFilters ?? defaultJtFilter;

    // Retrieve Job Tasks
    const job = values[fieldJobName];

    const clientFactory = useCallback((c: Configuration) => new JobTaskApi(c), []);
    const getAllResult = useCallback((c: JobTaskApi) =>
        c.apiServicesAppJobTaskGetAllGet(fetchFromAllProviders ? undefined : abp.setting.get(Settings_ProviderName),fetchFromAllProviders ? undefined : abp.setting.getInt(Settings_ServiceConnectionId),
            JSON.stringify([filter("jobId").equals(job.id)]), JSON.stringify(jtFilters)),
        [fetchFromAllProviders, job, jtFilters]);
    const array = useMemo(() => [], []);
    const emptyResultPromise = useSimplePromise(array);
    const emptyResult = useCallback((c: JobTaskApi) => emptyResultPromise, [emptyResultPromise]);
    const mapFunction = useCallback((j: any) => {
        const dueDate: string = getExtensionValue(j, "toFinishUntil");
        j.endDate = DateTime.fromISO(dueDate);
        j.overdue = dueDate && !dueDate.startsWith("0001-01-01T00:00:00") && DateTime.fromISO(dueDate).diffNow('days');
        j.isOverdue = j.overdue && j.overdue.as('seconds') < 0;
        return j;
    }, []);


    const apiResult = useReactiveApi<JobTask, JobTaskApi>(clientFactory, !!job?.id ? getAllResult : emptyResult, mapFunction);

    const jobTask = values[name];
    useEffect(() => {
        if (!job?.id) {
            setFieldValue(FIELD_JOB_TASK, null);
            // setFieldValue(FIELD_CATEGORY, null);
        }
    }, [job, setFieldValue]);

    useEffect(() => {
        if (job?.id && jobTask?.jobId && job.id !== jobTask.jobId) {
            setFieldValue(FIELD_JOB_TASK, null);            
        }
    }, [job, jobTask, setFieldValue]);


    return <ApiComboBox apiResult={apiResult} title={title ?? (values[name] && "JobTask")} name={name}
        filterable={true} filterFields={filterFields ?? [FIELD_DESIGNATION, FIELD_DESCRIPTION1]} dataItemKey={dataItemKey ?? 'id'}
        textField="description1" placeholder={placeholder ?? L('JobTask')} itemRender={itemRender ?? ((li, item) => JobTaskItem(li, item, { className: li.props.className + " job-task " + getExtensionValue(item.dataItem, "jobTaskType") }, undefined, undefined, userResourceId))}
        footer={(i) => <JobTaskDetails data={i} userResourceId={userResourceId} />}
        content={() => selectedItem ?? getIn(values, name) ? <JobTaskTooltip data={selectedItem ?? getIn(values, name)} /> : undefined} disabled={disabled || !!!values[fieldJobName]} {...props} />
}

export default JobTaskSelection;