import React, { useEffect, useRef, useState } from 'react';
import { Input, InputProps } from '@progress/kendo-react-inputs';
import ControlWrapper, { ControlWrapperProps } from '../control-wrapper/ControlWrapper';
import parseTimeFormula from './parseTimeFormula';
import Time from './Time';

export type TimeInputProps = Pick<InputProps, 'name' | 'placeholder'> & {
  defaultValue?: Time;
  value?: Time | null;
  onChange?: (value: Time | null) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  innerRef?: ((instance: HTMLInputElement | null) => void);
} & ControlWrapperProps;

const TimeInput: React.FC<TimeInputProps> = props => {

  const [textValue, setTextValue] = useState(props.defaultValue ? props.defaultValue.toString() : undefined);

  useEffect(() => {
    // Allow changing the displayed text using the value prop.
    if (props.value) {
      setTextValue(props.value.toString());
    }
    else setTextValue('');
  }, [props.value]);

  return (
    <ControlWrapper
      xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl} xxl={props.xxl}
      offset={props.offset} offsetSm={props.offsetSm} offsetMd={props.offsetMd} offsetLg={props.offsetLg} offsetXl={props.offsetXl} offsetXxl={props.offsetXxl}
      m={props.m} mt={props.mt} mb={props.mb} ml={props.ml} mr={props.mr} mx={props.mx} my={props.my}
      error={props.error} label={props.label} className={props.className} title={props.title}
    >
      <Input
        type="text"
        title={props.error as string}
        name={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue ? props.defaultValue.toString() : undefined}
        ref={e => props.innerRef && props.innerRef(e && e.element)}
        value={textValue}
        disabled={props.disabled}
        // Update only the input's value. Actual onChange is delayed to onBlur for formula evaluation reasons
        onChange={e => setTextValue(e.target.value as string)}
        // Evaluate the current dateformula and trigger change + blur
        onBlur={e => {
          const time = textValue ? parseTimeFormula(textValue) : null;
          const lastTime = props.value ?? null;

          const nullTimes = [time, lastTime].filter(t => !t);
          if (nullTimes.length === 1 || (nullTimes.length === 0 && !time!.equals(lastTime!)))
            props.onChange && props.onChange(time);

          if (nullTimes.length === 2)
            setTextValue('');

          props.onBlur && props.onBlur(e);
        }}
      />
    </ControlWrapper>
  );
};

export default TimeInput;
